import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    cart: [],
    lastProductRemoved: {
        idx: null,
        item: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_TO_CART: return updateObject(state, {cart: state.cart.concat(action.product)});
        case actionTypes.EDIT_QUANTITY: return editProductQuantity(state, action.productId, action.quantity);
        case actionTypes.REMOVE_FROM_CART:
            const removedItemIdx = state.cart.findIndex(p => p.ID === action.productId);
            const removedItem = {...state.cart[removedItemIdx]};
            const updatedCart = state.cart.filter(p => p.ID !== action.productId);
            return updateObject(state, {cart: updatedCart, lastProductRemoved: {idx: removedItemIdx, item: removedItem}});
        case actionTypes.UPDATE_CART: return updateObject(state, {cart: action.items});
        case actionTypes.RESTORE_LAST_PRODUCT: return restoreLastItemRemoved(state);
        case actionTypes.EMPTY_CART: return updateObject(state, {cart: []});
        default: return state;
    }
};

const editProductQuantity = (state, productId, quantity) => {
    let cart = [...state.cart];
    let productIndex = cart.findIndex(item => item.ID === productId);
    if (productIndex !== -1) {
        cart[productIndex].Quantity = quantity;
        return updateObject(state, {cart: cart});
    } else {
        return state;
    }
}

const restoreLastItemRemoved = (state) => {
    if (state.lastProductRemoved.idx !== null) {
        const cart = [...state.cart];
        cart.splice(state.lastProductRemoved.idx, 0, state.lastProductRemoved.item);
        const newLastProductRemoved = {idx: null, item: null};
        return updateObject(state, { cart: cart, lastProductRemoved: newLastProductRemoved });
    } else {
        return state;
    }
}

export default reducer;