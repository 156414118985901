import React from 'react';
import './CartHeader.css';
import { connect } from 'react-redux';
import cartIcon from '../../../../res/images/cart.png';



const cartHeader = props => {

    return (
        <div className="cart"  >
            <img src={cartIcon} alt="cart-icon" />
            {
                <span>{props.storedCart.length}</span>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        storedCart: state.eshop.cart
    };
};

export default connect(mapStateToProps, null)(cartHeader);