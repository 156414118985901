import React, {Component} from 'react';
import { connect } from 'react-redux';
import './Coatings.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import check from   '../../../res/images/check.png';
import axios from '../../../axios';
import { withRouter } from "react-router-dom";
import { Icon, Sidebar, PaddedContainer, Slider, Table, RadioButton} from 'precise-ui';

const { Skeleton } = require('precise-ui');
const { Button } = require('precise-ui');
const ExcelJS = require('exceljs');

class Coatings extends Component {

    timeout = null;   
    sliderFilterTimer = null;

    state = {
        selectedMaterial: {id: 0, value: ''},
        selectedItem : 0,
        materials: [],
        items : [], 
        filters : [],
        showMaterialSkeleton: true,
        showFiltersItemSkeleton: true,
        activeFilters: [],
        selectedItems: [],
        hiddenFilters: [],
        isScrolling: false
    }

    sortSelection = 0;
  
    lensesProductsResults = [
        {
            status: "RELEASED",
            cssClass: "released",
            title: "Released",
            lense: false,
            color:  "#94C046"
        },
        {
            status: "NOTAPPLICABLE",
            cssClass: "not-applicable",
            title: "Not feasible",
            lense: false,
            color:  "#EB1C23"
        },
        {
            status: null,
            cssClass: "not-tested",
            title: "Not tested yet",
            lense: false,
            color:  "#FFFFFF"
        }
    ];

    legendItems = this.lensesProductsResults.slice();

    excelStyle = {
        defaultColumnWidth: 9,
        headerStyle: {   
            fixedHeaders: {
                numberOfFixedHeaders: 1,
                colorARGB: "FF333399",
                fontColor: "FFFFFFFF",
                sizeDescriptionWidth: 20
            },
            computedHeaders: {
                colorARGB: "FFCCFFFF",
                alignment: { textRotation: 90 },
            }
            
        },
        legendStyle: {
            marginRows: 4,
            startingColumnIndex: 2
        }

    };

    
    componentDidMount () {
        if(this.props.isPageVisible) {

            if(this.props.match.params.materialId){
                let idMaterial = this.props.match.params.materialId;
                this.manageMaterialSelected(idMaterial);
            }
            else {
                this.getLensProductMaterials();
            }           

            window.addEventListener("scroll", this.onScroll);
            window.scrollTo(0, 0);
        }
        else {
            this.props.history.push("/home");
        }      
    }
       
    
    componentDidUpdate(prevProps) {
        if (prevProps.user?.catalogueRole != this.props.user?.catalogueRole) {
            this.getLensProductMaterials();
        }
		if (this.props.match.params.materialId !== prevProps.match.params.materialId) {
            if (this.props.match.params.materialId) {
                this.manageMaterialSelected(this.props.match.params.materialId, 0);
            } else {
                this.setState({
                    ...this.state,
                    selectedMaterial: {id: 0, value: ''},
                    selectedItem : 0,
                    materials: [],
                    items : [], 
                    filters : [],
                    showMaterialSkeleton: true,
                    showFiltersItemSkeleton: true,
                    activeFilters: [],
                    selectedItems : [],
                    hiddenFilters: [],
                    isScrolling: false
                }, this.getLensProductMaterials)
            }
        }
    }

    
    manageMaterialSelected(idMaterial) {
        let data = {
            materialId: idMaterial
        }

        axios.post('/getLensProductMaterialById', JSON.stringify(data),{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if(response.data){
                let material = response.data;
                this.setState(() => ({
                    selectedMaterial: {id: material.ID, value: material.Value}
                }))
                
                this.getHomePagePromise(this.state.selectedMaterial.id).then(
                    bool => {
                        this.setState(() => ({
                            showFiltersItemSkeleton: true,
                        })) ;
                    }                   
                )
            }                        
        })
        .catch(err => {
            console.log(err);
        });
    }

    
    getLensProductMaterials() {
        let data = {
            role: this.props.user.catalogueRole
        }

        axios.post('/getLensProductMaterials', JSON.stringify(data),{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {

            if( response && response.data && typeof response.data.Values !== "undefined" ) {
                this.setState(() => ({
                    materials: response.data.Values,
                    showMaterialSkeleton: false
                }))
            }
        
        })
        .catch(err => {
            console.log(err);
        });
    }

    
    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
        if (this.sliderFilterTimer) {
            clearTimeout(this.sliderFilterTimer)
        }
    }

    
    onScroll = () => {
        this.setState({ isScrolling: true });
    };

    
    handleChangeFilter(filterCategory, filterId, filterValue) {

        this.setState(() => ({
            ...this.state,
            showFiltersItemSkeleton: true
        }))

        this.sortSelection = this.sortSelection + 1;
        let fCategory = this.state.activeFilters.filter(e => e.category === filterCategory)[0];

        if (fCategory.category === 'Coating Type') {
            fCategory.sortSelection = this.sortSelection;
            fCategory.values.pop();
            fCategory.values.push({code: filterValue, value: filterId});
        }
        else if( fCategory.sortSelection === 999 ) {
            fCategory.sortSelection = this.sortSelection;
            fCategory.values.push({code: filterValue, value: filterId});
        }
        else if(fCategory.category === 'Coating Color' ) {
            let fValue = fCategory.values.filter(e => e.value === filterId);
            if(fValue.length > 0) {
                let index = fCategory.values.indexOf(fValue[0]);
                fCategory.values.splice(index, 1);
            }
            else {
                fCategory.values.push({code: filterValue, value: filterId});  
            }
        }
        else {
            fCategory.sortSelection = 999;
            fCategory.values.pop();
        }
        this.startFilterItems();
    }

    
    startFilterItems() {
        if(this.state.selectedItems.length > 0 ) {
            this.filterBySelectedItem(this.state.selectedItems);
        }
        else {
            this.getFiltersAndItems();
        }
    }

    
    getActiveFiltersCount() {
        let activeFiltersCount = 0;
        this.state.activeFilters.forEach(element => {
            if(element.values.length > 0 ){
                activeFiltersCount++;
            }
        });

        return activeFiltersCount;
    }

    
    getActiveFiltersAndSelectionCount() {
        let activeFiltersCount = this.getActiveFiltersCount();
        return activeFiltersCount + this.state.selectedItems.length;
    }
    
    
    getFiltersAndItems() {

       let activeFiltersCount = this.getActiveFiltersCount();

        if(activeFiltersCount === 0 ) {
            this.getHomePage(this.state.selectedMaterial.id);
        }
        else {
            let activeFilters = {
                filters: this.state.activeFilters,
                selectedMaterial: this.state.selectedMaterial.id
            }

            axios.post('/getFiltersAndLensesProducts', JSON.stringify(activeFilters),{
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                this.setState(() => ({
                    ...this.state,
                    items: response.data.Items,
                    filters: response.data.Filters,
                    showFiltersItemSkeleton: false
                }))
                window.scrollTo(0, 0);               
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    
    handleClickMaterial(material) {
        this.props.history.push("/coatings/"+material.ID);      
    }

    
    initToFirstValueAFilter(filters, initToFirstValueFilterCategory){
        const firstFilterValue = filters.find(filter => filter.FilterCategory == initToFirstValueFilterCategory).Values[0];
        this.handleChangeFilter(initToFirstValueFilterCategory, firstFilterValue["ID"], firstFilterValue["Value"]);
    }

    
    getHomePage(materialID) {
    
        let materialId = {
            id: materialID
        }

        axios.post('/getLensesProductsCoatingTypeFiltersBySelectedMaterial', JSON.stringify(materialId),{
            headers: {
                'Content-Type': 'application/json',
            }
        }
        )
        .then(response => {

            let filters = response.data.Filters;
            let activeFilters = this.getActiveFilters(filters);
            const initAsOpenFilter = "Coating Type"

            this.setState(() => ({
                items: response.data.Items,
                filters: filters,
                activeFilters: activeFilters,
                hiddenFilters: activeFilters.filter(f => f.category !== initAsOpenFilter).map(f => f.category)  
            }));

            this.initToFirstValueAFilter(filters, "Coating Type");     

            window.scrollTo(0, 0);
        })
        .catch(err => {
            console.log(err);
        });
    }

    
    getHomePagePromise(materialID) {
    
        return new Promise((resolve, reject) => {
            let materialId = {
                id: materialID
            }

            axios.post('/getLensesProductsCoatingTypeFiltersBySelectedMaterial', JSON.stringify(materialId),{
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            )
            .then(response => {

                let filters = response.data.Filters;
                let activeFilters = this.getActiveFilters(filters);
                const initAsOpenFilter = "Coating Type"

                this.setState(() => ({
                    items: response.data.Items,
                    filters: filters,
                    activeFilters: activeFilters,
                    hiddenFilters: activeFilters.filter(f => f.category !== initAsOpenFilter).map(f => f.category)  
                }));

                this.initToFirstValueAFilter(filters, "Coating Type");     

                window.scrollTo(0, 0);
                resolve('ok');
            })
            .catch(err => {
                console.log(err);
                resolve(err);
            });
        });
    }

    
    getActiveFilters(filters) {
        let arr = [];
        filters.forEach(element => {
            let filterCatObj = { category: element.FilterCategory, values: [], sortSelection: 999};
            arr.push(filterCatObj);
        });
        return arr;
    }

    
    checkedFilter(item, filtrValue){
        let filterValues = this.state.activeFilters.filter(e => e.category === item.FilterCategory)[0];
        if(filterValues?.values?.length > 0 ?? false) {
            return filterValues.values.find((f) => f.value === filtrValue)
        }
        return false;
    }

    
    isHiddenFilter(item){
        let hiddenFilters = this.state.hiddenFilters;
        let hiddenFilterIndex = hiddenFilters.findIndex(e => e === item.FilterCategory);
        if(hiddenFilterIndex >= 0 ) {
            return true;
        }
        else {
            return false;
        }
    }

    
    handleHideFilter(item){
        let hiddenFilters = this.state.hiddenFilters;
        let hiddenFilterIndex = hiddenFilters.findIndex(e => e === item.FilterCategory);
        if(hiddenFilterIndex >= 0 ) {
            hiddenFilters.splice(hiddenFilterIndex, 1);
        }
        else {
            hiddenFilters.push(item.FilterCategory);
        }

        this.setState(() => ({
            hiddenFilters: hiddenFilters
        }))
    }


    
    renderFilters(item) {
        if ( item.FilterCategory === 'Coating Type') {
            return <div key={item.FilterCategory} className={this.isHiddenFilter(item) ? 'hidden filter-item-container' : 'filter-item-container'}>
            {
                    item.Values.map((filterItem) => (
                        <div className="filter-item" key={filterItem.ID}>
                            <label class="filter-radio-label"> {filterItem.Value}
                                <input type="radio" value={filterItem.ID} checked={this.checkedFilter(item, filterItem.ID)}  onChange={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Value)} name="coating-types" className="filter-radio" />
                                <span className={"radio-checkmark "+( this.checkedFilter(item, filterItem.ID) ? 'checked' : '') }></span>
                            </label>
                        </div>   
                    ))   
            }
            </div>  
        }
        else if( item.FilterCategory === 'Coating Color') {
            return <div key={item.FilterCategory} className={this.isHiddenFilter(item) ? 'hidden filter-item-container-color' : 'filter-item-container-color'}>
            {
                    item.Values.map((filterItem) => (
                        <div key={"lens-color-"+filterItem.ID} className="lens-color-container tooltip-on-hover" >
                            <div className="filter-item f-item-coating-color" key={filterItem.ID} 
                                style={{ background: "linear-gradient(90deg, " + filterItem.Extra.PrimaryHexCode + " 25%, " + filterItem.Extra.SecondaryHexCode + " 50%, " + filterItem.Extra.PrimaryHexCode + " 75%"}} onClick={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Value)}>
                            {
                                this.checkedFilter(item, filterItem.ID) ?
                                <img src={check} className="selected-color-filter"/>
                                :
                                null
                            }                            
                            </div>
                            <div className="tooltip">{filterItem.Value} </div>         
                        </div>
                    ))   
            }
            </div>  
        }
        else {
            return <div key={item.FilterCategory} className={this.isHiddenFilter(item) ? 'hidden filter-item-container' : 'filter-item-container'}>
            {
                    item.Values.map((filterItem) => (
                        <div className="filter-item" key={filterItem.ID}>
                            <label className="filter-checkbox-label">
                                <input type="checkbox" value={filterItem.ID} checked={this.checkedFilter(item, filterItem.ID)}  onChange={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Value)} name="lenses-products" className="filter-checkbox" />
                                <span className="btn-checkbox-style">&nbsp;</span>
                                <span className="checkbox-label">{filterItem.Value} </span>
                                
                            </label>
                        </div>   
                    ))   
            }
            </div>  
        }   
        
    }

    
    renderActiveSelections() {
        return <div> 
            {
                this.state.selectedItems.map((selectedItem) => (                 
                    <p className="selected-filter-block" onClick={ () => this.removeSelectedItem(selectedItem)}>
                        {selectedItem.type}: {selectedItem.code}
                    </p>                  
                ))

            }
            </div>  
    }

    renderActiveFilters() {
        return <div> 
            {
                this.state.activeFilters.map((activeFilter) => (          
                    activeFilter.sortSelection != 999 ?
                        activeFilter.values.map((activeFilterItem) => ( 
                            <p key={"active_filter"+activeFilter.category} className={"selected-filter-block "+(activeFilter.category == "Coating Type" ? "not-removible" : "")} onClick={ () => activeFilter.category != "Coating Type" && this.removeSelectedFilter(activeFilter, activeFilterItem)}>
                                {activeFilter.category}: {activeFilterItem.code}
                            </p>
                        ))
                        :
                        null
                ))

            }
            </div>
    }

    
    renderRemoveAllFilters() {
        return  this.getActiveFiltersAndSelectionCount() > 1 ?
            <div class="clear-filters">
                <Button icon="Close" buttonStyle="secondary" style={{width: '180px' }}  onClick={ () => { this.handleClickRemoveAllFilters() }} > Clear all filters </Button>
            </div>               
            :
            <div></div>            
    }

    
    handleClickRemoveAllFilters() {
        this.setState(() => ({
            ...this.state,
            showFiltersItemSkeleton: true
        }))

        let arr = [];
        this.state.filters.forEach(element => {
            let filterCatObj = { category: element.FilterCategory, values: [], sortSelection: 999};
            arr.push(filterCatObj);
        });

        this.setState(() => ({
            activeFilters: arr
        }), this.getHomePage(this.state.selectedMaterial.id))              
    }

    
    removeSelectedFilter (activeFilter, activeFilterItem) {
        this.handleChangeFilter(activeFilter.category,activeFilterItem.value, activeFilterItem.code);       
    }

    
    removeSelectedItem(selectedItem) {

        let selectedItems = this.state.selectedItems;
        selectedItems = selectedItems.filter(function( obj ) {
            return obj !== selectedItem;
        });
        
        this.setState(() => ({
            ...this.state,
            showFiltersItemSkeleton: true,
            selectedItems: selectedItems
        }))

        if (selectedItems.length > 0) {
            this.filterBySelectedItem(selectedItems);  
        }
        else {
            this.getFiltersAndItems();
        }                  
    }

    
    handleBackMaterial() {
        this.setState(() => ({
            selectedMaterial: {id: 0, value: ''},
            selectedItem : 0,
            items : [], 
            filters : [],
            showFiltersItemSkeleton: true,
            activeFilters: [],
            selectedItems: [],
            hiddenFilters: [],
            showFiltersItemSkeleton: true
        }));       
    }

    
    filterBySelectedItem(selectedItem) {

        let activeFilters = {
            filters: this.state.activeFilters,
            selectedMaterial: this.state.selectedMaterial.id,
            selectedItems: selectedItem
        }

        axios.post('/getCoatingsFiltersBySelectedItems', JSON.stringify(activeFilters),{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            this.setState(() => ({
                ...this.state,
                items: response.data.Items,
                filters: response.data.Filters,
                showFiltersItemSkeleton: false
            }))

            
            window.scrollTo(0, 0);
        })
        .catch(err => {
            console.log(err);
        });
    }

    
    renderSkeletonMaterial() {
        return <div className="skeleton-material">
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />

            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />

            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />

            
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
        </div>
    }

    
    renderSkeletonFilters() {
        return <div className="skeleton-catalogue">
            <div className="skeleton-filters">
                <div className="filter-block">
                    <p className="filter-title"> Coating Type </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>                     
                </div> 
                <div className="filter-block">
                    <p className="filter-title"> Coating color </p>
                    <div className="sk-filter-cat-color">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />                   
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                    </div>                     
                </div>  
             
                <div className="filter-block">
                    <p className="filter-title"> Shape Type </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>                     
                </div>                
                <div className="filter-block">
                    <p className="filter-title"> Material Plant </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>                     
                </div>                
                <div className="filter-block">
                    <p className="filter-title"> Coating Plant </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>                     
                </div>                
            </div>
           
        </div>
    }

    
    renderSkeletonTable() {
        return <div className="skeleton-items"> 
                    <div className="product-list">
                        <Skeleton width="100%" height="45vh" />
                    </div>
                    <div class="legend-row"> 
                    {
                        this.legendItems.map(() => {
                            return  <div class="legend-item">
                                        <div class="status-color">
                                            <Skeleton width="100%" height="100%" />
                                        </div>
                                    </div>;
                        })
                    }              
                    </div>
                </div>
    }

    renderExportButtonSkeleton(){
        return  <Skeleton width="139px" height="38px" />;
    }

    setEmptyTable(columnsData, data) {
        columnsData.splice(0, columnsData.length);
        data.splice(0, data.length);
    }
   

    
    renderItems() {

        let columnsData = [];
        let data = [];
        try{

            const matrix = this.state.items.Items.slice();    
            if(!matrix || matrix.length < 1 ) throw new Error();   
    
            const headers = matrix[0].slice(1);
            if(!headers || headers.length < 1 ) throw new Error();   
    
            columnsData = headers.map(treatment => treatment ? treatment.Description : null );
            data = matrix.slice(1);

            if(data.length < 1) throw new Error(); 
            
        }catch{
            this.setEmptyTable(columnsData, data);
        }

        const originCellClasses = data.length > 0 ? "" : "empty-table";
        const renderHead = () => (
        <thead>
            <tr>
                <th id="origin-cell" key="origin" className={originCellClasses}>
                    <div class="text-right white">Coatings</div>
                    <div class="text-left grey">Size</div>
                </th>
            {columnsData.map(column => (
                <th key={column}><div class="header">{column}</div></th>
            ))}
            </tr>
        </thead>
        );

        const renderCell = (e) => {
            const obj = e.value;

            const isFirstColumn = e.column == 0;

            if(isFirstColumn) return <div class="cell size-description">{obj.SizeDescription}</div>
      
            return this.renderStatusDiv(obj);

          }
        
        return <Table 
                    id="lenses-products-table"
                    className={originCellClasses}
                    data={data} 
                    headRenderer={renderHead} 
                    cellRenderer={renderCell}
                    placeholder="No data available"
                    borderless
                /> ;
    }

    renderLegend() {    

        return (
            <div class="legend-row"> 
            {
                this.legendItems.map(item => {
                    return <div class="legend-item" key={item.status}>
                        {this.renderStatusDiv(item.status)}
                        <div class="title">{item.title}</div>
                    </div>;
                })
            }
            </div>
        );
    }

    renderStatusDiv(status) {
        const result = this.lensesProductsResults.find(result => result.status == status);
        if(!result){ return null; }

        const colorClass = "status-color "+ result.cssClass;
        return <div class={colorClass}>
                {
                    result.lense ?
                    <Icon name="Search" color="white" />
                    :
                    null
                }
                </div>;
        
    }

    
    renderFilterMenu = displayClasses => {
        const classes = "filter-menu " + displayClasses;
        return (
            <div className={classes}>
                {
                    this.state.showFiltersItemSkeleton ?
                        this.renderSkeletonFilters()
                    :
                    <div>
                        {
                            this.state.filters.map((item) => (
                                item.Values.length > 0 ?
                                <div key={item.FilterCategory} class="filter-block" >
                                    <p className={this.isHiddenFilter(item) ? ' filter-title hidden-filter' : 'filter-title'} onClick={ () => { this.handleHideFilter(item) }}> 
                                        {item.FilterCategory}
                                    </p>
                                    {
                                        this.renderFilters(item)
                                    }
                                </div>
                                :
                                null
                            ))
                        }
                    </div>
                   
                }                             
            </div>
        );
    }

    
    renderSidebar = () => (
        <Sidebar 
            position="left"
            activator=
                {
                    <div class="d-block d-md-none filter-sidebar-activator">
                        Filters ({this.state.selectedItems.length + this.getActiveFiltersCount()})
                    </div>
                }
        >
            <PaddedContainer gutter="small">
                <div style={{marginTop: '15px'}}>
                    <div>
                        {this.renderActiveSelections()}
                        {this.renderActiveFilters()}
                    </div>
                    {this.renderFilterMenu()}
                </div>
            </PaddedContainer>
        </Sidebar>
    );

    
    renderMobileBackButton = () => (
        <div class="back-material-button" onClick={ () => this.handleBackMaterial()}>
            Back to Material
        </div>
    );

    
    backToTop = () => {
        window.scrollTo(0, 0);

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.setState({ isScrolling: false });
        }, 300);        
    }
    
    
    render () {
    
        return (
            <div className="page-container"> 
            	
                <div className="header-gray-line"> </div>
                {
                    this.state.selectedMaterial.id == 0 ?
                
                        <div className="catalogue-material-content">
                                 
                            <h2> Mirror Combinations </h2>
                            <h3 className="intro-materials"> Please select a material </h3>
                            <div className="material-container">
                                {
                                    this.state.showMaterialSkeleton ?
                                        this.renderSkeletonMaterial()
                                    :
                                    this.state.materials.map((material, index) => (

                                        <div key={material.ID} className="product-card pc-small" onClick={ () => { this.handleClickMaterial(material) }}>
                                            <div className="box" >
                                                <div className="box-content-material">
                                                    <div className="material-box-name">
                                                        <p> {material.Value} </p>    
                                                    </div>
                                                    <div className="material-desc">
                                                        <ul className="material-desc-list">
                                                        {
                                                            material.ExtDescription.map( (materialDesc) => (
                                                                <li>
                                                                    {materialDesc}
                                                                </li>
                                                            ))
                                                        }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                : 
                    <div className="catalogue-container">
                        {
                            this.state.isScrolling ?
                            <div className="arrow-up" onClick={ () => this.backToTop()}>
                                <Icon name='KeyboardArrowUp' style={{color: '#fff'}}  size={3} />
                            </div>
                            :
                            null
                        }                       
                        {this.renderFilterMenu('d-none d-md-flex')}
                        <div>  
                            <div className="catalogue-content">
                                <div className="d-block d-md-none">
                                    {this.renderMobileBackButton()}
                                </div>
                                {this.renderSidebar()}
                                <div className="d-none d-md-block">
                                    <div className="back-material-button back-material-button-full" onClick={ () => this.handleBackMaterial()}>Back to Materials </div>
                                    <div className="header-catalogue">
                                        <div class="selected-material">
                                            Selected Material: <span> {this.state.selectedMaterial.value} </span>
                                        </div> 
                                        { this.state.showFiltersItemSkeleton ?
                                            this.renderExportButtonSkeleton()
                                        : 
                                        <>
                                        <div class="export-button-container"> 
                                            <Button type='button' className="quote-btn" buttonStyle="primary" icon="FileDownload"
                                                theme={{ buttonIconPosition: 'left'}}
                                                onClick={this.exportMatrixHandler}
                                                disabled={this.state?.items?.Items == null || this.state?.items?.Items?.length < 2}
                                            >Export XLSX</Button>
                                        </div>
                                        </>
                                        }                             
                                    </div>
                                   {this.renderRemoveAllFilters()}
                                    <div className="d-flex">
                                        {this.renderActiveSelections()}
                                        {this.renderActiveFilters()}                                    
                                    </div>
                                </div>                              
                                <div className="product-list">
                                    { this.state.showFiltersItemSkeleton ?
                                            this.renderSkeletonTable()
                                        : 
                                        <>
                                        <div class="table-container">{this.renderItems()}</div>
                                        {this.renderLegend()}
                                        </>
                                    }                                        
                                </div>
                            </div>                        
                        </div>
                    </div>
                }
            
            </div>
        )
    }


    formatHeadersStyle(sheet, headersRowIndex, numberOfFixedHeaders, numOfHeaders){

        const headersRow = sheet.getRow(headersRowIndex);
        for(let i = 1; i <= numberOfFixedHeaders; i++){
            headersRow.getCell(i).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: this.excelStyle.headerStyle.fixedHeaders.colorARGB },
            };
            headersRow.getCell(i).font = {
                color:{argb: this.excelStyle.headerStyle.fixedHeaders.fontColor },
            }
        }

        for(let i = 1; i < numOfHeaders; i++){
            const excellColumnIndex = i + numberOfFixedHeaders;
            headersRow.getCell(excellColumnIndex).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: this.excelStyle.headerStyle.computedHeaders.colorARGB },
            };
            headersRow.getCell(excellColumnIndex).alignment = {textRotation: this.excelStyle.headerStyle.computedHeaders.alignment.textRotation};
        }

    }

    excelAddHeaders(sheet, matrix, headersRowIndex = 1){

        let result = [];
        const headersFromMatrix = matrix[0];
        let fixedHeadersIndex = 0;
        result.push({
            header: "Size",
            key: fixedHeadersIndex,
            width: this.excelStyle.headerStyle.fixedHeaders.sizeDescriptionWidth
        });
        fixedHeadersIndex++;
      
        for(let i = 1; i< headersFromMatrix.length; i++){
            const excellColumnIndex = i + fixedHeadersIndex;
            const title = headersFromMatrix[i].Description;
            const header = {
                header: title,
                key: excellColumnIndex
            }
           result.push(header);
        }

        sheet.columns = result;

        this.formatHeadersStyle(sheet, headersRowIndex, this.excelStyle.headerStyle.fixedHeaders.numberOfFixedHeaders, headersFromMatrix.length);

        return headersRowIndex;
        
    }

    excelAddFiltersHeader(sheet, filters) {
        const getCoatingTypeOnly = filters.find(filter => filter.category == "Coating Type").values[0].code;
        sheet.insertRow(1, [getCoatingTypeOnly ]);
        let addedRows = 1;

        return addedRows;

    }
   
    excelAddCompanyHeader(sheet) {
        const currentDate = this.getCurrentDate();
        const txt = `Carl Zeiss Vision Sunlens (${currentDate})`;
        sheet.insertRow(1, [txt ]);
        let addedRows = 1;

        return addedRows;

    }

    excelAddLegend(sheet, currentRow, startingColumnindex) {

        const legentColorsRowIndex = currentRow + this.excelStyle.legendStyle.marginRows;
        const legentTitlesRowIndex =  legentColorsRowIndex + 1;
  
        const legendColorsRow = sheet.getRow(legentColorsRowIndex);
        for(let i = 0; i < this.lensesProductsResults.length; i++){
            const cellIndex = startingColumnindex + (i * 2);
            if(this.lensesProductsResults[i].status == null){
                legendColorsRow.getCell(cellIndex).border = {
                    top: {style:'thin'},
                    left: {style:'thin'},
                    bottom: {style:'thin'},
                    right: {style:'thin'}
                };
                continue;
            }
            const colorHex = this.lensesProductsResults[i].color;
            const colorARGB = colorHex.replace("#","ff");
            legendColorsRow.getCell(cellIndex).fill= {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: colorARGB },
              };
         
        }


        const titlesArray = Array(startingColumnindex - 1).fill(null);
        this.lensesProductsResults.forEach((item) => {
          titlesArray.push(item.title, null);
        });
        titlesArray.pop();

        sheet.insertRow(legentTitlesRowIndex, titlesArray);

        return legentTitlesRowIndex;
    }

    addData(sheet, matrix, currentRow, numOfFixedHeaders){

        currentRow++;
        const matrixDataStartingIndex = 1;

        for(let i = matrixDataStartingIndex; i< matrix.length; i++){

            const matrixData = matrix[i];
            const dataRow = sheet.getRow(currentRow);
            for(let j = 0; j < matrixData.length; j++){
                const elem = matrixData[j];
                const isDescriptionCell = j == 0;
                if(isDescriptionCell) { 
                    const descriptionCell = dataRow.getCell(j + 1);
                    descriptionCell.value = elem.SizeDescription;
                    continue; 
                }
                const result = this.lensesProductsResults.find(result => result.status == elem);
                if(result == null) continue;

                const toBeColoredCell = dataRow.getCell(j + numOfFixedHeaders);
                const colorARGB = result.color.replace("#","ff");
                toBeColoredCell.fill= {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb: colorARGB },
                  };
                toBeColoredCell.border = {
                    top: {style:'thin'},
                    left: {style:'thin'},
                    bottom: {style:'thin'},
                    right: {style:'thin'}
                  };
            }

            currentRow++;
        }
        return currentRow;
    }

    exportMatrixHandler = () => {

        const displayedMatrix = this.state.items.Items;
        const filename = "coatings";
        const numberOfFixedHeaders = this.excelStyle.headerStyle.fixedHeaders.numberOfFixedHeaders;
      
        let currentRow = 0;
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet(filename); 
        sheet.properties.defaultColWidth = this.excelStyle.defaultColumnWidth;

        currentRow = this.excelAddHeaders(sheet, displayedMatrix);
        currentRow+= this.excelAddFiltersHeader(sheet, this.state.activeFilters);
        currentRow+= this.excelAddCompanyHeader(sheet);
        currentRow = this.addData(sheet, displayedMatrix, currentRow, numberOfFixedHeaders);
        this.excelAddLegend(sheet, currentRow, numberOfFixedHeaders + 1)
       

        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename+'.xlsx');
            document.body.appendChild(link);
            link.click();
    
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        });
     
    }

    getCurrentDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); 
        const year = today.getFullYear();
    
        return `${day}/${month}/${year}`;
    }
}

const mapStateToProps = state => {

    let user; 
    if(state.auth.user) {
        user = state.auth.user;
    }
    else {
        user = JSON.parse(localStorage.getItem('user'));        
    }

    return {
        isPageVisible: checkPageVisibilites(user),
        user: user
    };


    function checkPageVisibilites(user) {
        if(user) {
            
            if(user.modulesEnabled == null) {
                return false;
            }
            else {
                let modules = user.modulesEnabled.split(';');
                if(modules.includes("Coatings")) {
                    return true;
                }
                else {
                    return false;
                }
            }       
        }
        else
            return false;        
    }

 
}



export default withRouter(connect(mapStateToProps)(Coatings));