import * as actionTypes from "./actionTypes";
import axios from '../../axios';
import ReactGA from 'react-ga';
import { notify } from "precise-ui/dist/es6";

const addToSessionCart = (product) => {
    product.Available = true;
    return {
        type: actionTypes.ADD_TO_CART,
        product: product
    };
};

export const editQuantity = (productId, quantity) => {
    return {
        type: actionTypes.EDIT_QUANTITY,
        productId: productId,
        quantity: quantity
    }
}

const removeFromSessionCart = productId => {
    return {
        type: actionTypes.REMOVE_FROM_CART,
        productId: productId
    };
};

export const updateCart = items => {
    return {
        type: actionTypes.UPDATE_CART,
        items: items
    };
}

export const addToCart = (product, idzeiss) => {
    return dispatch => {
        dispatch(addToSessionCart(product));
        const data = {
            'ZeissProductID': product.ID,
            'Quantity': product.Quantity,
            'IDZeiss': idzeiss
        };
        axios.post('/addToCart', data)
        .then(result => {
            if (result.data.type === "error") {
                dispatch(removeFromSessionCart(product.ID));
                notify.error({ content: result.data.msg, options: { position: 'top-right', autoClose: 3000 } });
            }
        })
        .catch(error => {
            dispatch(removeFromSessionCart(product.ID));
            console.log(error);
        });
    }
}

const restoreLastProductRemoved = () => {
    return {
        type: actionTypes.RESTORE_LAST_PRODUCT
    };
}

export const removeFromCart = (productId, idzeiss) => {
    return dispatch => {
        //ANALYTICS
       /*ReactGA.plugin.require('ec', { debug: true });

       ReactGA.plugin.execute('ec', 'addProduct', {
           id: productId
       });
       ReactGA.plugin.execute('ec', 'setAction', 'remove');

       ReactGA.ga('send', 'event', 'UX', 'click', 'remove from cart');    */

        dispatch(removeFromSessionCart(productId));
        const data = {
            'ZeissProductID': productId,
            'IDZeiss': idzeiss
        };
        axios.post('/removeFromCart', data)
        .then(result => {
            if (result.data.type === "error") {
                notify.error({ content: result.data.msg, options: { position: 'top-right', autoClose: 3000 } });
                if (result.data.msg !== 'Product not found.') dispatch(restoreLastProductRemoved());
            }
        })
        .catch(error => {
            dispatch(restoreLastProductRemoved());
            console.log(error);
        });
    }
}


const emptySessionCart = () => {
    return {
        type: actionTypes.EMPTY_CART
    };
}

export const emptyCart = (idzeiss) => {
    return dispatch => {
        const data = {
            'IDZeiss': idzeiss
        };
        axios.post('/emptyCart', data)
        .then(result =>  {
            if (result.data.type === "error") {
                notify.error({ content: result.data.msg, options: { position: 'top-right', autoClose: 3000 } });
            } else {
                dispatch(emptySessionCart());
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
}