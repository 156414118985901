import React, { Component } from 'react'
import './CookiePolicy.css';
import { config } from '../../../config';

class CookiePolicy extends Component {

    componentDidMount() {
        this.insertCookiebotDeclarationScript();
    }

    insertCookiebotDeclarationScript = () => {
        const script = document.createElement('script');
        script.setAttribute('id', 'CookieDeclaration');
        script.setAttribute('src', `https://consent.cookiebot.com/${config.cookiebotDomainGroupId}/cd.js`);
        script.setAttribute('type', 'text/javascript');
        const container = document.getElementById('CookieDeclarationContainer');
        container.appendChild(script, container);
    }

    render() {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                <div id="CookieDeclarationContainer" className="m-b-30">
                    <h3> Cookie Policy </h3>
                </div>
            </div>
        );
    }
}

export default CookiePolicy;