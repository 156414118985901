export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_CHANGE_PRIVACY = 'AUTH_CHANGE_PRIVACY';

export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";

export const GET_CATALOGUE = "GET_CATALOGUE";
export const SET_ARTICLE_DETAILS = "SET_ARTICLE_DETAILS";

// eshop actions
export const ADD_TO_CART = "ADD_TO_CART";
export const EDIT_QUANTITY = "EDIT_QUANTITY";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_CART = "UPDATE_CART";
export const RESTORE_LAST_PRODUCT = "RESTORE_LAST_PRODUCT";
export const EMPTY_CART = "EMPTY_CART";

export const TOGGLE_FULLPRIVILEGE_MODAL = "TOGGLE_FULLPRIVILEGE_MODAL";

// stock actions
export const ADD_TO_INVENTORY_CART = "ADD_TO_INVENTORY_CART";
export const EDIT_QUANTITY_INVENTORY_CART = "EDIT_QUANTITY_INVENTORY_CART";
export const REMOVE_FROM_INVENTORY_CART = "REMOVE_FROM_INVENTORY_CART";
export const UPDATE_INVENTORY_CART = "UPDATE_INVENTORY_CART";
export const RESTORE_QUANTITY_INVENTORY_CART = "RESTORE_QUANTITY_INVENTORY_CART";
export const RESTORE_LAST_PRODUCT_INVENTORY_CART = "RESTORE_LAST_PRODUCT_INVENTORY_CART";
export const EMPTY_INVENTORY_CART = "EMPTY_INVENTORY_CART";