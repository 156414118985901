import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    open: false
};

const toggleFullPrivilegeModal = (state, action) => {
    return updateObject( state, { 
        open: !state.open
     } );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.TOGGLE_FULLPRIVILEGE_MODAL: return toggleFullPrivilegeModal(state, action);
        default:
            return state;
    }
};

export default reducer;