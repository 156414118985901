import axios from '../../axios';
import {config} from '../../config';

export class ZeissIDUtils {

    OpenidConfiguration;
    clientId = config.clientId;
    uri = config.frontend;
    loginMethod = 'B2C_1A_ZeissIdNormalSignIn';
    
    constructor() {
        this.OpenidConfiguration = {
            issuer: '',
            jwks_uri: '',
            authorization_endpoint: '',
            end_session_endpoint: ''
        };
    }

    login() {
        
        this.getOpenidConfiguration(this.loginMethod).then(() => {
            let loginUrl = this.OpenidConfiguration.authorization_endpoint;
            let loginParms = 
                {
                    "redirect_uri": this.uri + 'signin-oidc',
                    "response_type": "id_token",
                    "client_id":  this.clientId,
                    "scope": "openid",
                    "response_mode": "query"
                };
    
            let esc = encodeURIComponent;
            let queryString = Object.keys(loginParms)
            .map(k => esc(k) + '=' + esc(loginParms[k]))
            .join('&');
    
            document.location.href = loginUrl + "&" +queryString;

        });

    } 

    signUp() {
        let method = 'B2C_1A_ZeissIdNormalChkEMailSignUp';
        this.getOpenidConfiguration(method).then(() => {
            let signUpUrl = this.OpenidConfiguration.authorization_endpoint;
            let loginParms = 
                {
                    "redirect_uri": this.uri + 'signup-oidc',
                    "response_type": "id_token",
                    "client_id":  this.clientId,
                    "scope": "openid",
                    "response_mode": "query"
                };
    
            let esc = encodeURIComponent;
            let queryString = Object.keys(loginParms)
            .map(k => esc(k) + '=' + esc(loginParms[k]))
            .join('&');
    
            document.location.href = signUpUrl + "&" +queryString;

        });

    } 

    logout() {
        let method = 'B2C_1A_ZeissIdNormalSignIn';
        this.getOpenidConfiguration(method).then(() => {
            let logoutUrl = this.OpenidConfiguration.end_session_endpoint;
            let params = 
                {
                    "post_logout_redirect_uri": this.uri + 'logout',
                };
    
            let esc = encodeURIComponent;
            let queryString = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
    
            document.location.href = logoutUrl + "&" +queryString;

        });

    } 

    verifyLoginToken(token, header) {
        return new Promise(resolve => {
            this.getOpenidConfiguration(this.loginMethod).then(() => {
                if(this.OpenidConfiguration.jwks_uri) {
                    axios.get(this.OpenidConfiguration.jwks_uri, {
                        withCredentials: false
                        }).then(response => {
                        if(response && response.data && response.data.keys) {
                            let key = response.data.keys.filter(key => key.kid === header.kid);
                            if( key.length > 0 ) {

                                let jwkToPem = require('jwk-to-pem');
                                let jwt = require('jsonwebtoken');
                                let pem = jwkToPem(key[0]);

                                jwt.verify(token, pem, { ignoreNotBefore: true }, (err, decoded) => {
                                    if(decoded && !err) {
                                        resolve(true);
                                    }
                                    else {
                                        console.error(err);
                                        resolve(false);                                        
                                    }                             
                                });
                            }
                            else {
                                resolve(false);
                            }
                        }
                        else {
                            resolve(false);
                        }
                    });
                }
                else {
                    resolve(false);
                }                
            });
        });
    }

    getOpenidConfiguration(method) {
        return new Promise(resolve => {
            const configUrl = config.zeissIdUrl + method;
            axios.get(configUrl, {
                withCredentials: false
                }).then(response => {
                if(response.data) {
                    this.OpenidConfiguration.issuer = response.data.issuer;
                    this.OpenidConfiguration.jwks_uri = response.data.jwks_uri;
                    this.OpenidConfiguration.authorization_endpoint = response.data.authorization_endpoint;
                    this.OpenidConfiguration.end_session_endpoint = response.data.end_session_endpoint;
                    resolve();
                }
                else {
                    resolve();
                }
            })
            .catch(err => {
                console.log(err);
                resolve();
            });
        });
        
    }

}
