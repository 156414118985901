import React from 'react';
import './ELearningMenuItem.css';

const ELearningMenuItem = props => (
    <div className={"el-menu-item " + ( (props.selectedMenuItem !== null && (props.selectedMenuItem.Category == props.item.Category)) ? 'el-menu-selected' : null)}
        onMouseEnter={ () => props.onHover(props.item)} onMouseLeave={ () => props.onHover(null)}>
            {props.item.Category}                     
    </div>
)

export default ELearningMenuItem;