import React, { Component } from 'react'
import { withRouter } from 'react-router';
import CartItem from '../../../components/CartItem/CartItem';
import { Button, Table, IconLink, notify, Modal, ModalBody, ModalHeader, ModalFooter,} from 'precise-ui/dist/es6';
import axios from '../../../axios';
import { connect } from 'react-redux';
import './Wishlist.css';

class Wishlist extends Component {
    state = {
        wishlist: [],
        openModal: false
    }

    tableColumns = {
        item: 'Item',
        action: ' '
    }

    quoteOptions = [
        'Samples',
        'Order',
        'Quotation',
        'Technical information',
        'Company details',
        'Product return',
        'Other'
    ]

    componentDidMount() {
        this.getUserWishlist();
    }

    componentDidUpdate(previousProps, previuosState, snapshot) {
        if (this.props.authUser !== previousProps.authUser) {
            this.getUserWishlist();
        }
    }

    getUserWishlist() {
        if (this.props.authUser != null && this.props.authUser.zeissId) {
            axios.post("/getUserWishlist", { zeissID: this.props.authUser.zeissId })
            .then(response => {
                if (response.data.type === 'success') {
                    this.setState({ wishlist: response.data.payload });
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    removeFromWishlist = recordId => {
        if (this.props.authUser != null && this.props.authUser.zeissId) {
            axios.post("/removeFromWishlist", { zeissID: this.props.authUser.zeissId, wishlistRecordID: recordId })
            .then(response => {
                if (response.data.type === 'success') {
                    const wishlist = this.state.wishlist.filter(i => i.ID !== recordId);
                    this.setState({ wishlist: wishlist });
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    cellTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'item':
                return (
                    <CartItem {...data.Product} />
                );
            case 'action':
                return (
                    <div className="text-right">
                        <IconLink icon="Delete" onClick={() => this.removeFromWishlist(data.ID)}/>
                    </div>
                );
            default:
                return value;
        }
    }

    toogleModal = () => {
        this.setState({
            ...this.state,
            openModal: !this.state.openModal
        });
    }  

    handleChangeSelect = (event) => {

        if(event.target.value === 'Other') {
            this.setState({
                ...this.state,
                showTextarea: true
            });           
        }
        else {
            this.setState({
                ...this.state,
                showTextarea: false
            });  
        }
    }

    sendRequest = () => {

        notify.success({ content: "Your request has been sent", options: { position: 'top-right', autoClose: 3000 } })

        this.setState({
            ...this.state,
            openModal: false
        });
    }


    render() {
        return (
            <div className="page-container">
                 <Modal open={this.state.openModal} onClose={this.toogleModal} className="render-fullscreen-modal">
                    <ModalHeader title="Request info" />
                    <ModalBody>
                        <p> Please choose a topic: </p>
                        <select className="quote-select" onChange={this.handleChangeSelect}>
                            { 
                                this.quoteOptions.map(option => { 
                                return <option value={option}>
                                    {option}</option>;
                                })
                            }
                        </select>
                        <p> Please specify your request: </p>
                        <textarea className="other-text-area"></textarea>                           
                    </ModalBody>                
                    <ModalFooter className="m-t-3">
                        <Button type="button" onClick={this.toogleModal}>Cancel</Button>
                        <Button type="submit" onClick={this.sendRequest}> Send request </Button>
                    </ModalFooter>
                </Modal>
                <div className="header-gray-line"> </div>
                <div className="m-b-30">
                    <Button onClick={this.props.history.goBack}>Back</Button>
                </div>
                <h1> Wishlist </h1>
                <Table
                    className="cart-table" 
                    data={this.state.wishlist} 
                    columns={this.tableColumns} 
                    cellRenderer={this.cellTableRender}
                    placeholder="No items in wishlist yet." 
                    borderless>
                </Table>
                <div style={{marginBottom: '2rem'}}></div>
                <Button className="quote-btn" buttonStyle="secondary" onClick={this.toogleModal}>Request info </Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authUser: state.auth.user,
    };
}

export default withRouter(connect(mapStateToProps, null)(Wishlist));