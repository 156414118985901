import React, { Component } from "react";
import './Product.css';
import axios from '../../../axios';

import NumberFormat from 'react-number-format';
import { withRouter } from "react-router";
import testPass from '../../../res/images/test-pass.png';
import testNotPass from '../../../res/images/test-not-pass.png';

import ProductImage from '../../../components/ProductImage/ProductImage';
import { Button, notify, Modal, ModalBody, ModalHeader, ModalFooter, Checkbox  } from "precise-ui/dist/es6";
import ProductRender from "../../../components/ProductRender/ProductRender";
import { connect } from "react-redux";
import ModalRenderInfo from '../../../components/ModalRenderInfo/ModalRenderInfo';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import * as actionCreators from '../../../store/actions/index';
import ModalProductQuantity from "../../../components/ModalProductQuantity/ModalProductQuantity";
import ModalRequestInfo from "../../../components/ModalRequestInfo/ModalRequestInfo";
import CartItem from "../../../components/CartItem/CartItem";
import imageNotFound from   '../../../res/images/Image-not-found.jpg';
import ReactGA from 'react-ga';
import RelatedProductsSlider from '../../../components/RelatedProductsSlider/RelatedProductsSlider';
import RelatedColorsSlider from '../../../components/RelatedColorsSlider/RelatedColorsSlider';

import stockIcon from '../../../res/images/stock_icon.png';
import cartIcon from '../../../res/images/cart.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ZEISSLogo from '../../../res/images/zeiss-logo.png';
import {config} from '../../../config';

const { Skeleton } = require('precise-ui');
const MAX_RELATED_COLORS_DISPLAYABLE = 8;

class Product extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    state = {
        detail : null,
        activeRender: null,
        showSkeletons: true,
        prevPath: null,
        openModal: false,
        openRenderModal: false,
        openModalQuantity: false,
        cartProduct: null,
        infoSubjects: [],
        infoSku: {
            subject: null,
            request: null
        },
        actionButtonsClasses: ["product-buttons", "fixed"],
        relatedColors: [],
        hasECommerce: false,
        showCartButton: false,
        cataloguePrevState: null,
        modalCartIcon: null
    }

    quoteOptions = [
        'Samples',
        'Order',
        'Quotation',
        'Technical information',
        'Company details',
        'Product return',
        'Other'
    ];

    componentDidMount () {
        document.addEventListener('scroll', this.trackScrolling);
        window.addEventListener('resize', e => this.trackScrolling(null));

        this.initActionButtonsClasses(() => this.savePreviousPath(this.checkRenderModalCookie));

        if (!this.props.match || !this.props.match.params.id) return;

        let priceList = this.getUserPricelist();
        this.getProductDetail();
        sessionStorage.removeItem('relatedColors'); // rimosso di default alla creazione

        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            if (!this.loadFromSessionStorage()) {
                this.savePreviousPath(this.getProductDetail);
            }
            if (this.props.location.state.from.indexOf('/product') === -1 || 
                (this.props.location.state.from.indexOf('/product') !== -1 && this.props.location.state.sameRoute)
            ) {
                sessionStorage.removeItem('relatedColors');
            }
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
        window.removeEventListener('resize', e => this.trackScrolling(null));
    }

    getProductDetail = () => {
        let hasECommerce = false;
        if(this.props.authUser.modulesEnabled !== null) {
            const modules = this.props.authUser.modulesEnabled.split(';');
            hasECommerce = modules.includes("E-commerce_Full") || modules.includes("E-commerce_Promo");
        }       
        
        let priceList = this.getUserPricelist();

        let params = {
            productId: this.props.match.params.id,
            priceList: priceList,
            ZeissID: this.props.authUser.zeissId,
            isStock: this.props.location?.state?.from === "/stock"
        }

        axios.post('/getProductDetail/', JSON.stringify(params))
        .then(response => {
            const productInCart = this.props.storedCart.find(product => product.ID === response.data.ID);
            const productInInventoryCart = this.props.storedInventoryCart.find(product => product.ID === response.data.ID);
            const detail = {
                ...response.data,
                InCart: productInCart != undefined,
                InInventoryCart: productInInventoryCart != undefined
            }
            this.setState(() => ({
                ...this.state,
                detail: detail,
                showSkeletons: false,
                hasECommerce: hasECommerce
            }), this.getRelatedProducts);
        })
        .catch(err => {
            console.log(err);
        });
    }

    savePreviousPath = (callback) => {
        if (this.props.location.state != null && this.props.location.state.from != null) {
            this.setState({
                ...this.state,
                prevPath: this.props.location.state.from,
                showCartButton: this.props.location.state.from === '/e-shop/products' || this.props.location.state.from.includes('/product/'),
                cataloguePrevState: this.props.location.state.catalogueState ?? null
            }, callback);
        }
    }

    setFirstImageActive = () => {
        const firstRender = this.state.detail.Renders.length > 0 ? this.state.detail.Renders[0] : null;
        this.setState({
            ...this.state,
            activeRender: firstRender
        }, this.getSubjectOptions);
    }

    getSubjectOptions = () => {
		axios.get("/getContactSubjectRecipient")
		.then(response => {
			if (response.data.type === "success") {
				this.setState({
					...this.state,
                    infoSubjects: response.data.payload,
                    infoSku: {
                        ...this.state.infoSku,
                        subject: response.data.payload[0].ID
                    }
				});
			}
		})
		.catch(error => {
			console.log(error);
		})
	}

    getTestResultImage = (value) => {
        if (value === "Pass") return testPass;
        else return testNotPass;
    }

    imageClickedHandler = render => {
        this.setState({
            ...this.state,
            activeRender: render
        });
    }

    historyBackHandler = () => {
        if (this.state.prevPath != null) {
            this.props.history.push(this.state.prevPath, { from: this.props.location.pathname, sameRoute: this.state.prevPath.includes('/product/'), cataloguePrevState: this.state.cataloguePrevState });
        } else {
            this.props.history.goBack();
        }
    }

    addToWishlistHandler = () => {
        if (this.props.authUser != null && this.props.authUser.zeissId) {
            axios.post("/addToWishlist", {zeissID: this.props.authUser.zeissId, productID: this.state.detail.ID})
            .then(response => {
                if (response.data.type === "success") {
                    this.setState({
                        ...this.state,
                        detail: {
                            ...this.state.detail,
                            WishlistID: response.data.payload.ID
                        }
                    });
                    notify.success({ content: "Product has been added to your wishlist.", options: { position: 'top-right', autoClose: 3000 } })
                } else if (response.data.type === "warning") {
                    if (response.data.message === "Duplicate entry") {
                        notify.warning({ content: "Product is already in your wishlist.", options: { position: 'top-right', autoClose: 3000 } })
                    }
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    removeFromWishlist = () => {
        if (this.props.authUser != null && this.props.authUser.zeissId) {
            axios.post("/removeFromWishlist", { zeissID: this.props.authUser.zeissId, wishlistRecordID: this.state.detail.WishlistID })
            .then(response => {
                if (response.data.type === 'success') {
                    this.setState({
                        ...this.state,
                        detail: {
                            ...this.state.detail,
                            WishlistID: null
                        }
                    });
                    notify.success({ content: "Product removed from your wishlist.", options: { position: 'top-right', autoClose: 3000 } })
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    toogleModal = () => {
        this.setState({
            ...this.state,
            openModal: !this.state.openModal
        });
    }  

    handleChange = (event) => {
        const { name, value } = event.target;
        let info = this.state.infoSku;
        info = {
            ...info,
            [name]: value
        }
        this.setState({
            ...this.state,
            infoSku: info
        });
    }

    sendRequest = () => {
        const data = {
            ...this.state.infoSku,
            productId: this.state.detail.ID,
            user: this.props.authUser
        }

        axios.post("/sendEmailSKUInfo", data)
        .then(response => {
            notify.success({ content: "Your request has been sent", options: { position: 'top-right', autoClose: 3000 } });
            this.setState({
                ...this.state,
                openModal: false
            });
        })
        .catch(error => {

        });
    }

    closeRenderInfoModalHandler = (dontShowMeAgain) => {
        const { cookies } = this.props;
        if (dontShowMeAgain) {
            let d = new Date();
            d.setTime(d.getTime() + 3600 * 1000 * 24 * 365);
            cookies.set('renderInfoShowed', 'shown', { path: '/', expires: d });
        }
        this.toggleRenderInfoModal();
    }

    toggleRenderInfoModal = () => {
        this.setState({ ...this.state, openRenderModal: !this.state.openRenderModal });
    }

    checkRenderModalCookie = () => {
        const { cookies } = this.props;
        const cookie = cookies.get('renderInfoShowed');
        if (!cookie) {
            this.toggleRenderInfoModal();
        }
    }

    toggleProductModal = () => {
        this.setState({
            ...this.state,
            cartProduct: null,
            openModalQuantity: !this.state.openModalQuantity
        });
    }

    changeProductQuantityHandler = event => {
        let product = Object.assign({}, this.state.cartProduct);
        product.Quantity = +event.target.value;
        this.setState({
            ...this.state,
            cartProduct: product
        });
    }

    addToCartHandler = () => {
        if (this.state.prevPath === "/stock"){
            this.props.onAddToInventoryCart(this.state.cartProduct, this.props.authUser.zeissId);
            notify.success({ content: "Product has been added to your inventory cart.", options: { position: 'top-right', autoClose: 3000 } });
            this.setState({
                ...this.state,
                detail: {
                    ...this.state.detail,
                    InInventoryCart: true
                }
            }, this.toggleProductModal);
        } else {
        //ANALYTICS
        /*ReactGA.plugin.require('ec', { debug: true });

        ReactGA.plugin.execute('ec', 'addProduct', {
            id: this.state.cartProduct.ID
        });
        ReactGA.plugin.execute('ec', 'setAction', 'add');

        ReactGA.ga('send', 'event', 'UX', 'click', 'add to cart',{
            hitCallback: () => {*/
                this.props.onAddToCart(this.state.cartProduct, this.props.authUser.zeissId);
                notify.success({ content: "Product has been added to your shopping cart.", options: { position: 'top-right', autoClose: 3000 } });
                this.setState({
                    ...this.state,
                    detail: {
                        ...this.state.detail,
                        InCart: true
                    }
                }, this.toggleProductModal);
            /*}
        });     */
    }
    }

    cartButtonHandler = () => {
        let cartProduct = this.props.storedCart.find(i => i.ID === this.state.detail.ID);
        if (!cartProduct) 
            cartProduct = Object.assign({Quantity: this.state.detail.OrderMinQty}, this.state.detail);
        this.setState({
            ...this.state,
            cartProduct: cartProduct,
            openModalQuantity: true,
            modalCartIcon: cartIcon
        })
    }

    inventoryCartButtonHandler = () => {
        let cartProduct = this.props.storedInventoryCart.find(i => i.ID === this.state.detail.ID);
        if (!cartProduct) 
            cartProduct = Object.assign({Quantity: this.state.detail.OrderMinQty}, this.state.detail);
        this.setState({
            ...this.state,
            cartProduct: cartProduct,
            openModalQuantity: true,
            modalCartIcon: stockIcon
        })
    }

    modalCellTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'item':
                return (
                    <CartItem {...data} />
                );
            default:
                return value;
        }
    }

    trackScrolling = (e) => {
        if (window.innerWidth <= 767) {
            const actionSpacer = document.getElementById("actionButtonsSpacer");
            const actionButtons = document.getElementById("actionButtons");
            if (actionSpacer && actionButtons) {
                let buttonsPos = actionButtons.getBoundingClientRect();
                let spacerPos = actionSpacer.getBoundingClientRect();

                const visiblePart = window.innerHeight - buttonsPos.height;
                const spacerBottomY = spacerPos.y + spacerPos.height;

                if (spacerBottomY < visiblePart) {
                    this.setState({
                        ...this.state,
                        actionButtonsClasses: this.state.actionButtonsClasses.filter(c => c !== 'fixed')
                    });
                } else {
                    let actionButtonsClasses = this.state.actionButtonsClasses;
                    if (!actionButtonsClasses.includes('fixed')) actionButtonsClasses.push('fixed');
                    this.setState({
                        ...this.state,
                        actionButtonsClasses: actionButtonsClasses
                    });
                }
            }
        } else {
            this.setState({
                ...this.state,
                actionButtonsClasses: this.state.actionButtonsClasses.filter(c => c !== 'fixed')
            });
        }
    }

    initActionButtonsClasses = (callback) => {
        if (window.innerWidth <= 767) {
            let actionButtonsClasses = this.state.actionButtonsClasses;
            if (!actionButtonsClasses.includes('fixed')) actionButtonsClasses.push('fixed');
            this.setState({
                ...this.state,
                actionButtonsClasses: actionButtonsClasses
            }, callback);
        } else {
            this.setState({
                ...this.state,
                actionButtonsClasses: this.state.actionButtonsClasses.filter(c => c !== 'fixed')
            }, callback);
        }
    }


    getRelatedProducts = () => {
        if (this.state.hasECommerce) {
            const params = {
                productId: this.props.match.params.id,
                maxRelatedColorsDisplayableOnFrontEnd: MAX_RELATED_COLORS_DISPLAYABLE
            };
            axios.post('/getRelatedProducts', params)
            .then(response => {
                this.setState({
                    ...this.state,
                    relatedColors: response.data.relatedColors
                }, this.setFirstImageActive)
            });
        } else {
            this.setFirstImageActive();
        }
    }

    onSelectRelatedProduct = (productID) => {
        sessionStorage.setItem('relatedColors', JSON.stringify(this.state));
        this.props.history.push("/product/"+productID, { from: this.props.location.pathname });
    }

    loadFromSessionStorage = () => {
        if (this.props.location.state != null && this.props.location.state.from.indexOf('/product') !== -1 && this.props.location.state.sameRoute) {
            const prevState = sessionStorage.getItem('relatedColors');
            if (prevState) {
                this.setState(JSON.parse(prevState));
                return true;
            } else return false;
        } else {
            return false;
        }
    }
    
    getUserPricelist = () => {
        let priceList = 32;
        if(this.props.authUser.priceList) {
            priceList = this.props.authUser.priceList;
        }        
        return priceList;
    }

    onExportPdfHandle = () => {
        const doc = new jsPDF();
        const defaultFont = doc.getFont();

        /*logo */
        doc.addImage(ZEISSLogo, 'PNG', 10, 7, 20,20);
        /* end logo */

        /* title */
        doc.setFontSize(16);
        doc.setFont(defaultFont.name, 'bold');
        doc.text('Product details', 10, 35);
        doc.setFont(defaultFont.name, 'normal');
        /* end title */

        if (!!this.state.detail) {
            let columnXStart = 120;
            let columnYStart = 45;
            /* left column details (images) */
            let tableBodyRenders = [];
            let detailRenders = [...this.state.detail.Renders];
            while (detailRenders.length > 0) {
                tableBodyRenders.push(detailRenders.splice(0, 2).map(r => r.Label));
            };
            
            doc.autoTable({
                columnStyles: {
                    0: {cellWidth: 50, minCellHeight: 50},
                    1: {cellWidth: 50, minCellHeight: 50}
                },
                theme: 'grid',
                margin: {left: 10, right: 10},
                startY: columnYStart,
                body: tableBodyRenders,
                bodyStyles: {
                    halign: 'center',
                    valign: 'bottom'
                },
                didDrawCell: async (data) => {
                    if (data.cell.section === 'body') {
                        let render = this.state.detail.Renders[data.row.index * 2 + data.column.index];
                        if(render) {
                            let photoIndex = this.state.detail.Photos.findIndex(p => p === render.Image);
                            if (photoIndex !== -1) {
                                doc.addImage(this.state.detail.PhotosBase64[photoIndex], 'jpeg', data.cell.x + 1, data.cell.y + 1, 48, 34.6);
                            }
                        }
                        /*let img = null;
                        switch (data.column.index) {
                            case 0:
                                img = this.getTestResultImage(this.state.detail.DriveSuiZEISS_ISO);
                                break;
                            case 1:
                                img = this.getTestResultImage(this.state.detail.DriveSuiZEISS_ANSI)
                                break;
                            case 2:
                                img = this.getTestResultImage(this.state.detail.DriveSuiZEISS_ASNZS)
                                break;
                            default:
                                break;
                        }
                        if (img) {
                            doc.addImage(img, 'PNG', data.cell.x + (data.cell.width / 2) - 3, data.cell.y + (data.cell.height / 2) - 3, 6, 6);
                        }*/
                    }
                }
            });
            /* end left column details (images) */


            /* right column details */
            if (this.state.detail.LensMaterial) {
                doc.setFontSize(12);
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Lens Material:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(this.state.detail.LensMaterial, columnXStart + 30, columnYStart);
                columnYStart += 9;
            }

            doc.setFontSize(11);
            if (this.state.detail.ColorCode) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Lens Color:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(`${this.state.detail.ColorCode} (${this.state.detail.LensColor})`, columnXStart + 23, columnYStart);
                columnYStart += 6;
            }

            if (this.state.detail.ColorType) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Color Type:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(this.state.detail.ColorType, columnXStart + 23, columnYStart);
                columnYStart += 6;
            }

            if (this.state.detail.Coatings.length > 0) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Coating name:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                this.state.detail.Coatings.forEach((c, idx) => {
                    let isLast = idx === this.state.detail.Coatings.length - 1;
                    doc.text(`${c}${!isLast ? ',' : ''}`, columnXStart + 28, columnYStart);
                    if (!isLast) {
                        columnYStart += 6;
                    }
                })
                columnYStart += 6;
            }

            if (this.state.detail.Size) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Size:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(this.state.detail.Size, columnXStart + 10, columnYStart);
                columnYStart += 6;
            }

            if (this.state.detail.Shape) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Shape Type:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(this.state.detail.Shape, columnXStart + 25, columnYStart);
                columnYStart += 9;
            }

            if (this.state.detail.FilterCategory) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Filter Category:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(this.state.detail.FilterCategory, columnXStart + 30, columnYStart);
                columnYStart += 6;
            }

            if (this.state.detail.LightTransmission) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Light Transmission:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(`${this.state.detail.LightTransmission}%`, columnXStart + 38, columnYStart);
                columnYStart += 6;
            }

            if (this.state.detail.InternalReflection) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Internal Reflection:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(`${this.state.detail.InternalReflection}%`, columnXStart + 37, columnYStart);
                columnYStart += 6;
            }
            if (this.state.detail.CustomMessage) {
                const availableWidthInMm = 76;
                const textLines = doc.splitTextToSize(this.state.detail.CustomMessage, availableWidthInMm);    
                doc.setFont(defaultFont.name, 'normal');
                doc.text(textLines, columnXStart, columnYStart);
                columnYStart += (textLines.length * 4.5)+1.5;
            }

            doc.autoTable({
                columnStyles: {
                    0: {cellWidth: 25, minCellHeight: 10},
                    1: {cellWidth: 25, minCellHeight: 10},
                    2: {cellWidth: 25, minCellHeight: 10}
                },
                theme: 'grid',
                margin: {left: columnXStart, right: 7},
                head: [
                    [
                        {
                            content: 'Driving suitability', 
                            colSpan: 3, 
                            styles: { halign: 'center', textColor: [77, 79, 83], fillColor: [226, 227, 224], fontStyle: 'bold' }
                        }
                    ],
                    [
                        {
                            content: 'ISO', 
                            colSpan: 1, 
                            styles: { halign: 'center', fillColor: [0, 139, 208], fontStyle: 'bold', fontSize: 10 }
                        },
                        {
                            content: 'ANSI', 
                            colSpan: 1, 
                            styles: { halign: 'center', fillColor: [0, 139, 208], fontStyle: 'bold', fontSize: 10 }
                        },
                        {
                            content: 'AS-NZ', 
                            colSpan: 1, 
                            styles: { halign: 'center', fillColor: [0, 139, 208], fontStyle: 'bold', fontSize: 10 }
                        }
                    ]
                ],
                headStyles: {
                    lineColor: 255,
                    lineWidth: .5
                },
                body: [['', '', '']],
                bodyStyles: {
                    fillColor: [247, 247, 247],
                    lineColor: 255,
                    lineWidth: .5
                },
                startY: columnYStart,
                rowPageBreak: 'avoid',
                didDrawCell: async (data) => {
                    if (data.cell.section === 'body') {
                        let img = null;
                        switch (data.column.index) {
                            case 0:
                                img = this.getTestResultImage(this.state.detail.DriveSuiZEISS_ISO);
                                break;
                            case 1:
                                img = this.getTestResultImage(this.state.detail.DriveSuiZEISS_ANSI)
                                break;
                            case 2:
                                img = this.getTestResultImage(this.state.detail.DriveSuiZEISS_ASNZS)
                                break;
                            default:
                                break;
                        }
                        if (img) {
                            doc.addImage(img, 'PNG', data.cell.x + (data.cell.width / 2) - 3, data.cell.y + (data.cell.height / 2) - 3, 6, 6);
                        }
                    }
                }
            });

            columnYStart = doc.lastAutoTable.finalY + 9;

            if (this.state.detail.MOQ) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Minimum Order Quantity:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(`${this.state.detail.MOQ}${this.state.detail.IsPromo ? '**' : ''}`, columnXStart + 48, columnYStart);
                columnYStart += 6;
            }

            if (this.state.detail.IsPromo) {
                doc.text(`*This price and conditions are applicable while stocks last.\n** In case of offline purchase or reorders, the MOQ could  be higher.`, columnXStart, columnYStart);
                columnYStart += 6;
            }

            if (this.state.detail.Brand) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Range:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(this.state.detail.Brand, columnXStart + 14, columnYStart);
                columnYStart += 6;
            }

            if (this.state.detail.Collection) {
                doc.setFont(defaultFont.name, 'bold');
                doc.text('Collection:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(this.state.detail.Collection, columnXStart + 21, columnYStart);
                columnYStart += 6;
            }

            if (this.state.detail.Price) {
                columnYStart += 3;
                doc.setFontSize(14);
                doc.setFont(defaultFont.name, 'bold');
                doc.text('SKU Price per piece:', columnXStart, columnYStart);
                doc.setFont(defaultFont.name, 'normal');
                doc.text(`€ ${this.state.detail.Price}${this.state.detail.IsPromo ? '*' : ''}`, columnXStart + 50, columnYStart);
                doc.setFontSize(11);
                columnYStart += 6;
            }
            /* end right column details */
        }

        let fileName = `ZEISS_${this.props.match.params.id}_Details.pdf`;
        doc.save(fileName);
    }

    render () {
        
        return (
            <div>
                <ModalRequestInfo 
                    open={this.state.openModal} 
                    close={this.toogleModal} 
                    handleChangeInput={this.handleChange}
                    infoSubjects={this.state.infoSubjects}
                    sendRequest={this.sendRequest}
                    tableColumns={{item: 'Item'}}
                    products={[this.state.detail]}
                    cellTableRender={this.modalCellTableRender}
                ></ModalRequestInfo>
                {   
                    (this.state.detail != null && this.state.showSkeletons == false) ?
                    (
                        <div>
                            <div className="page-container">
                            <div className="header-gray-line"> </div>
                            <div className="product-container">
                                <div className="product">
                                    <div className="half-width half-render">
                                        <p onClick={this.historyBackHandler} className="back-button d-block d-md-none" style={{marginTop: 0}}> &lt; Back </p>
                                        <div className="render-image-container">
                                            <ProductRender 
                                                render={this.state.activeRender} 
                                                shoWhislistIcon={this.state.prevPath !== '/e-shop/products'} 
                                                addedToWishList={this.state.detail.WishlistID !== null}  
                                                addToWishlistHandler={this.addToWishlistHandler}
                                                removeFromWishListHandler={this.removeFromWishlist}
                                            />
                                        </div>
                                        <div className="images-list">
                                            {this.state.detail.Renders.map(r => (
                                                <ProductImage
                                                    label={r.Label} 
                                                    src={r.Image !== null ? r.Image : imageNotFound} 
                                                    clicked={() => this.imageClickedHandler(r)} 
                                                    key={r.ID}
                                                    active={r === this.state.activeRender}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="half-width half-details">
                                        <p onClick={this.historyBackHandler} className="back-button d-none d-md-block" style={{marginTop: 0}}> &lt; Back </p>
                                        {
                                            this.state.detail.LensMaterial ? 
                                            (
                                                <div className="product-title">
                                                    <span className="text-bold">Lens material: </span>
                                                    {this.state.detail.LensMaterial}
                                                </div>
                                            ) 
                                            : null
                                        }
                                        {
                                            this.state.detail.ColorCode ? 
                                            (
                                                <div>
                                                    <br/>
                                                    <span className="text-bold">Lens Color: </span>
                                                    {this.state.detail.ColorCode} ({this.state.detail.LensColor})
                                                </div>
                                            ) 
                                            : null
                                        }
                                        {
                                            this.state.detail.ColorType ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Color Type: </span>
                                                    {this.state.detail.ColorType}
                                                </div>
                                            ) 
                                            : null
                                        }
                                        {
                                            this.state.detail.Coatings.length > 0 ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Coating name: </span>
                                                    {this.state.detail.Coatings.join(", ")}
                                                </div>
                                            ) 
                                            : null
                                        }
                                        {
                                            this.state.detail.Size ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Size: </span>
                                                    {this.state.detail.Size}
                                                </div>
                                            ) 
                                            : null
                                        } 
                                        {
                                            this.state.detail.Shape ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Shape Type: </span>
                                                    {this.state.detail.Shape}
                                                </div>
                                            ) 
                                            : null
                                        }                                                                               
                                        <br/>
                                        {
                                            this.state.detail.FilterCategory ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Filter Category: </span>
                                                    {this.state.detail.FilterCategory}
                                                </div>
                                            ) 
                                            : null
                                        }
                                        {
                                            this.state.detail.LightTransmission ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Light Transmission: </span>
                                                    {this.state.detail.LightTransmission}%
                                                </div>
                                            ) 
                                            : null
                                        }
                                        {
                                            this.state.detail.InternalReflection ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Internal Reflection: </span>
                                                    {this.state.detail.InternalReflection}%
                                                </div>
                                            ) 
                                            : null
                                        }
                                        {
                                            this.state.detail.CustomMessage ? 
                                            (
                                                <div>
                                                    {this.state.detail.CustomMessage}
                                                </div>
                                            ) 
                                            : null
                                        }
                                     
                                        <br/>
                                        <table className="table-drive-sui">
                                            <thead>
                                                <tr>
                                                    <th className="text-bold table-title" colSpan="3">Driving suitability</th>
                                                </tr>
                                                <tr>
                                                    <th className="column-title text-bold">ISO</th>
                                                    <th className="column-title text-bold">ANSI</th>
                                                    <th className="column-title text-bold">AS-NZ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><img src={this.getTestResultImage(this.state.detail.DriveSuiZEISS_ISO)} alt="test-result" /></td>
                                                    <td><img src={this.getTestResultImage(this.state.detail.DriveSuiZEISS_ANSI)} alt="test-result"/></td>
                                                    <td><img src={this.getTestResultImage(this.state.detail.DriveSuiZEISS_ASNZS)} alt="test-result"/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br/>
                                        {
                                            this.state.detail.MOQ ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Minimum Order Quantity: </span>
                                                    {this.state.detail.MOQ}
                                                    {this.state.detail.IsPromo ? 
                                                    (
                                                       
                                                        <span>**</span>
                                              
                                                    ) 
                                                    : null
                                                    }
                                                </div>
                                            ) 
                                            : null
                                        }  
                                        {this.state.detail.IsPromo ? 
                                                (
                                                    
                                                    <div>
                                                        *This price and conditions are applicable while stocks last.
                                                        <br/>                                                        
                                                        ** In case of offline purchase or reorders, the MOQ could  be higher.


                                                    </div>
                                            
                                                ) 
                                                : null
                                                }
                                        {
                                            this.state.detail.Brand ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Range: </span>
                                                    {this.state.detail.Brand}
                                                </div>
                                            ) 
                                            : null
                                        }
                                        {
                                            this.state.detail.Collection ? 
                                            (
                                                <div>
                                                    <span className="text-bold">Collection: </span>
                                                    {this.state.detail.Collection}
                                                </div>
                                            ) 
                                            : null
                                        }
                                        {
                                            this.state.detail.Price ? 
                                            (
                                                <div>
                                                    <br/>
                                                    <span className="text-bold price-text">SKU Price per piece: </span>
                                                    <NumberFormat 
                                                        className="price-text"
                                                        value={this.state.detail.Price} 
                                                        displayType={'text'} 
                                                        prefix={'€ '} 
                                                        decimalSeparator={'.'} 
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                    {this.state.detail.IsPromo ? 
                                                    (
                                                       
                                                        <span>*</span>
                                              
                                                    ) 
                                                    : null
                                                    }
                                                </div>
                                            ) 
                                            : null
                                        }
                                        <br id="actionButtonsSpacer"/>
                                        <div className={this.state.actionButtonsClasses.join(" ")} id="actionButtons">
                                            <Button type='button' className="product-action-button product-action-button-no-flex" buttonStyle="primary" icon="PDFFileOutline" onClick={this.onExportPdfHandle} theme={{ buttonIconPosition: 'left'}}>Export PDF</Button>
                                            {
                                                    // this.state.prevPath === '/e-shop/products' ?
                                                    this.state.showCartButton ?
                                                <Button  className="product-action-button product-action-button-no-flex" onClick={this.cartButtonHandler} disabled={this.state.detail.InCart}>
                                                    {
                                                        this.state.detail.InCart ?
                                                        "Added to cart"
                                                        : "Add to cart"
                                                    }
                                                </Button>
                                                : this.state.prevPath === '/stock' ?
                                                <Button className="product-action-button" onClick={this.inventoryCartButtonHandler} disabled={this.state.detail.InInventoryCart}>
                                                    {
                                                        this.state.detail.InInventoryCart ?
                                                        "Added to inventory cart"
                                                        : "Add to inventory cart"
                                                    }
                                                </Button>
                                                :
                                                null
                                            }
                                            <Button className="product-action-button product-action-button-no-flex" buttonStyle="secondary" onClick={this.toogleModal} >Request SKU info</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            {
                                this.state.relatedColors.length > 0 ?
                                (
                                    <div className="related-products-container">
                                        <div className="page-container">
                                            <h2 className="related-products-title">
                                                Matching colors on different thicknesses
                                                <div class="mouse">
                                                    <div class="mouse-wheel"></div>
                                                    <div class="arrows"></div>
                                                </div>
                                            </h2>
                                        </div>
                                        <div className="related-products">
                                            <div className="page-container">
                                                <div className="related-colors-carousel">
                                                    {
                                                        (this.state.relatedColors.length > MAX_RELATED_COLORS_DISPLAYABLE) ? 
                                                        (
                                                            <RelatedColorsSlider
                                                                colors={this.groupRelatedColors(this.state.relatedColors)}
                                                                onSelect={this.onSelectRelatedColor}
                                                            ></RelatedColorsSlider>
                                                        )
                                                        :
                                                        (
                                                            <RelatedProductsSlider 
                                                                products={this.state.relatedColors}
                                                                onSelect={this.onSelectRelatedProduct}
                                                                hidePrice={true}
                                                            ></RelatedProductsSlider>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : 
                                null                            
                            }
                        </div>
                    )
                    : 
                    <div className="page-container">
                        <div className="header-gray-line"> </div>
                        <div className="product-container">
                            <div className="product">
                                <div className="half-width half-render">
                                    <div className="render-image-container">
                                        <Skeleton width="100%" height="462px" />
                                    </div>
                                    <div className="images-list skeleton-image-list">
                                        <Skeleton width="110px" height="110px" />
                                        <Skeleton width="110px" height="110px" />
                                        <Skeleton width="110px" height="110px" />
                                    </div>
                                    <br/>
                                </div>
                                <div className="half-width half-details">
                                    <div className="product-title">
                                        <Skeleton width="300px" height="25px" />
                                    </div>
                                    <div>
                                        <br/>
                                        <Skeleton width="400px" height="25px" />
                                        <br/>
                                        <br/>
                                        <Skeleton width="300px" height="25px" /> <br/>
                                        <Skeleton width="300px" height="25px" />
                                        <br/>
                                        <br/>
                                        <Skeleton width="300px" height="25px" /> <br/>
                                        <Skeleton width="300px" height="25px" /> <br/>
                                        <Skeleton width="300px" height="25px" />
                                        <br/>
                                        <br/>
                                        <Skeleton width="300px" height="80px" />
                                        <br/>
                                        <br/>
                                        <Skeleton width="150px" height="25px" /> <br/>
                                        <Skeleton width="150px" height="25px" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                }
                <ModalRenderInfo open={this.state.openRenderModal} close={this.closeRenderInfoModalHandler} />
                <ModalProductQuantity 
                    open={this.state.openModalQuantity} 
                    toggle={this.toggleProductModal}
                    product={this.state.cartProduct}
                    changeQty={(event) => this.changeProductQuantityHandler(event)}
                    cartLength={this.state.modalCartIcon === stockIcon ? this.props.storedInventoryCart.length : this.props.storedCart.length}
                    addToCart={this.addToCartHandler}
                    icon={this.state.modalCartIcon}
                />
            </div>
        )
    }

    groupRelatedColors(){

        const groupedByColorsAndThickness = this.state.relatedColors.reduce((acc, obj) => {
            const reducedElement = { 
                            ColorCode: obj.ColorCode, 
                            ColorID: obj.ColorID, 
                            ColorImage: obj.ColorImage,
                            ThicknessID: obj.Thickness.ID,
                            ThicknessDescription: obj.Thickness.Description,
                            MaterialCategoryID: obj.MaterialCategoryID
                        };
            if (!acc.some(
                                item => 
                                    item.ColorCode === reducedElement.ColorCode &&
                                    item.ColorID === reducedElement.ColorID &&
                                    item.ColorImage === reducedElement.ColorImage &&
                                    item.ThicknessID === reducedElement.ThicknessID &&
                                    item.MaterialCategoryID === reducedElement.MaterialCategoryID 
                            )
                ) {
                acc.push(reducedElement);
            }
            return acc;
        }, []);

        return groupedByColorsAndThickness;
        
    }

    onSelectRelatedColor = (relatedColor) => {
        this.props.history.push(`/catalogue/${relatedColor.MaterialCategoryID}?color=${relatedColor.ColorID}&code=${relatedColor.ColorCode}&thickness=${relatedColor.ThicknessID}`);
    }
}

const mapStateToProps = state => {

    let user; 
    if(state.auth.user) {
        user = state.auth.user;
    }
    else {
        user = JSON.parse(localStorage.getItem('user'));
    }

    return {
        authUser: user,
        storedCart: state.eshop.cart,
        onlyBMQCatalogue: checkAccountVisibilites(user),
        storedInventoryCart: state.stock.inventoryCart
    };

    function checkAccountVisibilites(user) {
        if( user.accountType == 'Contact' ) {
            return true;
        }
        else {
            let modules = user.modulesEnabled.split(';');
            if(modules.includes("Catalogue_Full")) {
                return false;
            }
            return true;
        }        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddToCart: (product, idzeiss) => dispatch(actionCreators.addToCart(product, idzeiss)),
        onAddToInventoryCart: (product, idzeiss) => dispatch(actionCreators.addToInventoryCart(product, idzeiss))
    };
};

export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(Product)));