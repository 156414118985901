import React from 'react';
import { Carousel } from 'precise-ui/dist/es6';
import './CartItem.css';

const cartItem = props => (
    <div className="cart-item">
        {
            !props.hiddenImage ?
            <Carousel className="product-carousel" infinite>
                {
                    props.Photos.map((photo, i) => (
                        photo != null ?
                        <img key={i} src={photo} alt="lente" />
                        : null
                    ))
                }
            </Carousel>
            : null
        }
        <div>
            <p><span className="text-bold">Material</span>: {props.LensMaterial}</p>
            <p><span className="text-bold">Size</span>: {props.Size}</p>
            <p><span className="text-bold">Color</span>: {props.ColorCode}</p>
            {
                props.Coatings != null && props.Coatings.length > 0 ?
                <p><span className="text-bold">Coatings</span>: {props.Coatings.join(", ")}</p>
                : null
            }
        </div>
    </div>
);

export default cartItem;