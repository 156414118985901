import React, { Component } from 'react';
import axios from '../../../axios';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Table, Button, Icon } from 'precise-ui/dist/es6';

class MyOrders extends Component {

    state = {
        orders: []
    }

    orderTableColumns = {
        OrderReference: 'Order Reference',
        Status: 'Status',
        OrderDate: 'Order Date'
    }

    componentDidMount() {
        this.getUserOrders();
    }

    getUserOrders() {
        if (this.props.user != null && this.props.user.zeissId) {
            axios.post("/getUserOrders", { zeissID: this.props.user.zeissId })
            .then(response => {
                if (response.data.type === 'success') {
                   this.setState({ orders: response.data.payload });
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    cellOrderTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'orderreference':
                return (
                    <div>
                        {data.ID}
                    </div>
                    
                );
            case 'status':
                return (
                    this.getOrderStatus(data.Status)
                );
            case 'orderdate':
                return (
                    <div>
                        {data.Date}
                    </div>
                );
            default:
                return value;
        }
    }

    getOrderStatus(status){
        switch (status) {
            case 'Paid':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
                );
            case 'ReceivedAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
            );
            case 'SentAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
            );
            case 'InOrderAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
            );
            case 'PaymentError':
                return (
                    <div>
                        <div className="circle-icon red">
                            <span class="icon-close-delete"></span>
                        </div>                        
                        <span> Payment error </span>
                    </div>
                    
                );
            case 'CanceledAS400':
                return (
                    <div>
                        <div className="circle-icon"> </div>
                        <span> Order Canceled </span>
                    </div>
                    
                );
            case 'DeliveredAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-position-map"></span>
                        </div>
                        <span> Delivered </span>
                    </div>
                    
                );
            default:
        }
    }

    dataClickHandler = (event) => {
        this.props.history.push("/my-order/"+event.data.ID);
    }

    render() {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                <div className="back-shopping back-top"  onClick={() => this.props.history.goBack()} > <span class="icon-direction-back m-r-5"> </span> Back </div>
                <div className="m-b-30 ">
                    <h3> My Orders </h3>
                    <Table
                        className="orders-table" 
                        data={this.state.orders} 
                        columns={this.orderTableColumns} 
                        cellRenderer={this.cellOrderTableRender}
                        placeholder="No orders yet." 
                        onDataClick={this.dataClickHandler}
                        borderless>
                    </Table>
                </div>
                
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
}

export default withRouter(connect(mapStateToProps, null)(MyOrders));