import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import axios from '../../../axios';
import { ProgressBar, notify, Button, Accordion, AccordionTab } from "precise-ui/dist/es6";
import "./Configurator.css";
import ConfiguratorQuestion from "../../../components/ConfiguratorQuestion/ConfiguratorQuestion";
import ConfiguratorMaterialResult from "../../../components/ConfiguratorMaterialResult/ConfiguratorMaterialResult";

class Configurator extends Component {

  state = {
    questionList: [],
    userAnswerList: [],
    progressBarStops: [],
    currentQuestionIndex: 0,
    resultMaterialList: [],
    selectedResultMaterial: null,
    showResult: false
  };

  componentDidMount() {
		// todo: chiamata per scaricare la lista delle domande e delle risposte
    this.getConfiguratorQuestion();
		window.scrollTo(0,0);
	}

  getConfiguratorQuestion = () => {
    axios.get('/getConfiguratorQuestions')
    .then(response => {
      if (response && response.data && response.data.type == 'success') {
        let progressBarStops = this.calcProgressBarStops(response.data.payload.length);
        this.setState({
          ...this.state,
          questionList: response.data.payload,
          progressBarStops
        });
      } else {
        notify.warning({ content: "Server error. Try to reload the page.", options: { position: 'top-right', autoClose: 3000 } });
      }
    })
    .catch(error => {
      notify.warning({ content: "Server error. Try to reload the page.", options: { position: 'top-right', autoClose: 3000 } });
      console.log(error);
    });
  }

  calcProgressBarStops = (questionCount) => {
    let stopDistance = 100 / questionCount;
    return Array.from({length: questionCount}, (_, k) => (k + 1) * stopDistance);
  }

  onAnswerSelectedHandler = (questionIdx, answerId) => {
    let userAnswerList = [...this.state.userAnswerList];
    userAnswerList[questionIdx] = answerId;
    
    let answer = this.state.questionList[questionIdx].ConfiguratorAnswer.find(a => a.ID == answerId);
    if (answer && answer.GoToResult && (questionIdx+1) < userAnswerList.length) {
      // sbianco tutte le risposte alle domande successive
      userAnswerList.splice(questionIdx+1);
    }

    let currentQuestionIndex = this.state.currentQuestionIndex;
    if (questionIdx < this.state.questionList.length - 1 && (!answer || !answer.GoToResult)) {
      currentQuestionIndex++;
    }

    this.setState({
      ...this.state,
      userAnswerList: userAnswerList,
      currentQuestionIndex: currentQuestionIndex
    }, () => {
      if (questionIdx >= this.state.questionList.length - 1 || (answer && answer.GoToResult)) {
        this.getConfiguratorResult();
      }
    });
  }

  getConfiguratorResult = () => {
    axios.post('/getConfiguratorResult', { userAnswers: this.state.userAnswerList, userRole: this.props.user.catalogueRole, userType: this.props.user.accountType })
    .then(response => {
      if (response && response.data && response.data.type == 'success') {
        this.setState({
          ...this.state,
          resultMaterialList: response.data.payload,
          showResult: true
        });

        window.scrollTo(0, 0);
      } else {
        notify.warning({ content: "Server error. Try to reload the page.", options: { position: 'top-right', autoClose: 3000 } });
      }
    })
    .catch(error => {
      notify.warning({ content: "Server error. Try to reload the page.", options: { position: 'top-right', autoClose: 3000 } });
      console.log(error);
    });
  }

  onPreviousQuestion = () => {
    if (this.state.showResult) {
      this.setState({
        ...this.state,
        resultMaterialList: [],
        selectedResultMaterial: null,
        showResult: false
      });
    } else {
      let currentQuestionIndex = this.state.currentQuestionIndex;
      currentQuestionIndex--;
      this.setState({
        ...this.state,
        currentQuestionIndex: currentQuestionIndex
      });
    }
  }

  onStartAgain = () => {
    if (this.state.showResult) {
      this.setState({
        ...this.state,
        resultMaterialList: [],
        selectedResultMaterial: null,
        showResult: false,
        userAnswerList: [],
        currentQuestionIndex: 0
      });
    }
  }

  onResultMaterialClickHandler = (idMaterial) => {
    if (!this.state.selectedResultMaterial || this.state.selectedResultMaterial.ID !== idMaterial) {
      let materialSelected = this.state.resultMaterialList.find(m => m.ID == idMaterial);
      this.setState({
        ...this.state,
        selectedResultMaterial: materialSelected || null,
      });
    } else {
      this.setState({
        ...this.state,
        selectedResultMaterial: null,
      });
    }
  }

  onResultLinkClickHandler = (type) => {
    if (!this.state.selectedResultMaterial) return;
    switch(type) {
      case 'catalogue':
        this.props.history.push(`/catalogue/${this.state.selectedResultMaterial.ID}`);
        break;
      case 'vendors':
        this.props.history.push("/vendors-network");
        break;
      case 'elearning':
        let intermediateLevelUrl = null;
        let levelUrl = null;
        let eLearningUrl = this.props.user.accountType == 'Contact' ? '/leads-e-learning' : '/e-learning';

        if (this.state.selectedResultMaterial.ELearningIntermediateLevelTitle) {
          intermediateLevelUrl = this.sanitazeURL(this.state.selectedResultMaterial.ELearningIntermediateLevelTitle);
        }

        if (this.state.selectedResultMaterial.ELearningLevelTitle) {
          levelUrl = this.sanitazeURL(this.state.selectedResultMaterial.ELearningLevelTitle);
        }

        if (intermediateLevelUrl != null && levelUrl != null) {
          this.props.history.push(`${eLearningUrl}/${intermediateLevelUrl}/${levelUrl}`);
        } else if (levelUrl != null) {
          this.props.history.push(`${eLearningUrl}/${levelUrl}/${levelUrl}`);
        }
        break;
    }
  }

  sanitazeURL(value) {
    return value.toLowerCase().replace(/\s/g, '-').replace(/[^a-zA-Z0-9 -]/g, "")
  }

  getResultAnswerTitle = (answerId, index) => {
    const question = this.state.questionList[index];
    if (!question) return '';

    const answer = question.ConfiguratorAnswer.find(qAnswer => qAnswer.ID == answerId);
    if (!answer) return '';

    return answer.Title;
  }

  render() {
    return (
      <div className="personal-container">
        <div className="page-container">
          <div class="header-gray-line"> </div>
        </div>
        <div className="page-container">
          <h1 className="generic-page-title">
            {
              !this.state.showResult ?
                'Build Your Sunglasses'
              :
                'Recommended lenses materials'
            }
          </h1>
          <div>
            {
              !this.state.showResult ?
                <p>Identify the right sun lenses materials for your sunglasses project.</p>
              :
                <p>For your sunglasses project, we recommend you select some of the following materials</p>
            }
          </div>
          {
            !this.state.showResult ?
              <div>
                <div className="question-progress">
                  <ProgressBar value={100 / this.state.questionList.length * (this.state.currentQuestionIndex + 1)} stops={this.state.progressBarStops} animate></ProgressBar>
                </div>
                <ConfiguratorQuestion index={this.state.currentQuestionIndex} question={this.state.questionList[this.state.currentQuestionIndex] ?? null} userAnswer={this.state.userAnswerList[this.state.currentQuestionIndex]} onAnswerClick={this.onAnswerSelectedHandler}></ConfiguratorQuestion>
              </div>
            :
              <div>
                {
                  this.state.resultMaterialList.length == 0 ?
                   'No configuration founded.'
                  :
                  <div className="configurator-result-list">
                    {
                      this.state.resultMaterialList.map(material => (
                        <ConfiguratorMaterialResult key={material.ID} isAccount={this.props.user.accountType == 'Account'} material={material} isSelected={!this.state.selectedResultMaterial ? false : material.ID == this.state.selectedResultMaterial.ID} onSelect={this.onResultMaterialClickHandler} mediaClick={this.onResultLinkClickHandler}/>
                      ))
                    }
                  </div>
                }
                <p>Your selections:</p>
                <ol>
                  {
                    this.state.userAnswerList.map((answerId, index) => (
                      <li key={'result'+answerId}>{this.getResultAnswerTitle(answerId, index)}</li>   
                    ))
                  }
                </ol>
              </div>
          }
          {
            this.state.currentQuestionIndex > 0 || this.state.showResult ?
              <div>
                <Button buttonStyle="secondary" style={{width: '250px', marginTop: '30px' }} onClick={this.onPreviousQuestion}> BACK </Button>
                {
                  this.state.showResult ?
                  <Button buttonStyle="primary" style={{width: '250px', marginTop: '30px' }} onClick={this.onStartAgain}> START AGAIN </Button>
                  :
                    ''
                }
              </div>
            :
              ''
          }
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	}
}

export default withRouter(connect(mapStateToProps, null)(Configurator))