import React from 'react';

import './Personas.css';

const Personas = (props) => {
    
    return (
        <div className="personas-container">
            {
                props.personas.map((persona) => (
                    <div className="box-persona" onClick={ () => props.clicked(persona)}>
                        <img src={persona.Image} />
                        <p>{persona.Title} </p>
                        {
                            persona.Description ?
                            <div class="personas-description" dangerouslySetInnerHTML={{__html: persona.Description}}></div>
                            : null
                        }
                        
                    </div>
                ))   
            }
        </div>
    );
}

export default Personas;