import React from 'react';
import { connect } from 'react-redux';
import stockIcon from '../../../../res/images/stock_icon.png';


const stockHeader = props => {

    return (
        <div className="cart"  >
            <img src={stockIcon} alt="stock-icon" />
            {
                <span>{props.storedCart.length}</span>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        storedCart: state.stock.inventoryCart
    };
};

export default connect(mapStateToProps, null)(stockHeader);