import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    user: null
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        user: action.user
     } );
};

const authPrivacyChange = (state, action) => {
    return updateObject(state, {
        user: { ...state.user, privacy: action.privacy }
    });
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_CHANGE_PRIVACY: return authPrivacyChange(state, action);
        default:
            return state;
    }
};

export default reducer;