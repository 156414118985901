import React from 'react';
import './RelatedColorsSlider.css';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import {config} from '../../config';
import imageNotFound from   '../../res/images/Image-not-found.jpg';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3
    },
    mobileLarge: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ZEISSLeftArrow = ({ onClick }) => (
    <button className="zeiss-carousel-arrow arrow-left" onClick={() => onClick()}></button>
);

const ZEISSRightArrow = ({ onClick }) => (
    <button className="zeiss-carousel-arrow arrow-right" onClick={() => onClick()}></button>
);

const relatedColorsSlider = props => {

    return (
        <div>
            <Carousel arrows responsive={responsive} infinite={true} customLeftArrow={<ZEISSLeftArrow />} customRightArrow={<ZEISSRightArrow />}>
            {

                props.colors.map( (subitem) => {
                    return  <div key={subitem.toString()} className="product-single-block related-color carousel-color-card" onClick={ () => { props.onSelect(subitem) }}>
                                <div className="product-block-image"> 
                                    <img src={config.assets + "" + subitem.ColorImage} onError={(e)=>{e.target.onerror = null; e.target.src=imageNotFound}} />
                                </div>
                                <div className="product-block-details">
                                    <span>{subitem.ColorCode}</span>
                                    <span>{subitem.ThicknessDescription}</span>
                                </div>
                            </div>;
                })
            }
            </Carousel>
        </div>
    );
}

export default relatedColorsSlider;