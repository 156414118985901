import React, { Component } from "react";
import { withRouter } from 'react-router';
import {config} from '../../../config';

class StandardSalesCondition extends Component {

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render () {
        return (
            <div className="page-container cd-container">
                <div className="header-gray-line"> </div>
                <h1> Standard Sales Conditions </h1>
                <div className="cd-content">
                    <p> Please click here to download our <b> Standard sales conditions: </b> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/CZV-Sunlens-Sales-Conditions-January-2024-+-informative.pdf"} target="_blank"> Carl Zeiss Vision Italia SpA - ENG </a> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/CZV-Sunlens-Sales-Conditions-Asia-Pacific-Jan-2024.pdf"} target="_blank"> Carl Zeiss Vision Asia Pacific Limited - ENG </a> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/CZV-Sunlens-Condizioni-di-vendita-Gennaio-2024-+-informativa.pdf"} target="_blank" > Carl Zeiss Vision Italia SpA - ITA </a> </p>
                </div>
            </div>
        )
    }
}

export default withRouter(StandardSalesCondition);