import React, { Component } from 'react';
import './VendorNetwork.css';
import axios from '../../../axios';
import { withRouter } from 'react-router';
import { Button, notify, DropdownField, Checkbox, Icon } from 'precise-ui/dist/es6';
import { connect } from 'react-redux';
import ExternalLinkVendor from '../../../components/ExternalLinkVendor/ExternalLinkVendor';
import VendorNetworkCard from '../../../components/VendorNetworkCard/VendorNetworkCard';
import vendorIcon from '../../../res/images/icon_factory_02.png';

class VendorNetwork extends Component {
    state = {
        vendor: {},
        errors: {},
        mailBody: null,
        lists: {
            productionCountries: [],
            markets: [],
            frameMaterials: [],
            yearlyProductionCapicities: [],
            lensEdgings: [],
            companyTypes: [],
            manufacturingCompanyServices: []
        },
        dataReady: false,
        prevPath: null,
        previewVendor: {},
        showPreview: false
    };

    requiredDefaultProps = ["BusinessName","Address","ZipCode","City","Nation","Mail","CompanyTypes","Markets"];
    requiredManufactoringProps = ["FrameMaterials","ManufacturingCompanyServices","YearlyProduction"];

    isVendor = false;

    componentDidMount() {
        this.savePreviousPath(this.checkGetVendorNetwork);        
    }

    checkGetVendorNetwork = () => {
        if (this.props.authUser) {
            this.isVendor = this.props.authUser.modulesEnabled.split(';').includes("VendorNetwork");
            if (this.isVendor) {
                this.getListItems();
            } else {
                this.getVendorNetwork();
            }
        } else {
            this.getVendorNetwork();
        }
    }

    getListItems = () => {
        axios.get("/getVendorNetworkLists").then(response => {
            this.setState({
                ...this.state,
                lists: response.data.payload,
            }, this.getVendorNetwork)
        });
    }

    getVendorNetwork = () => {
        let dataToPost = {};
        if (this.props.match.params.id > 0) {
            dataToPost = {ID: this.props.match.params.id};
        } else {
            dataToPost = {sapCode: this.props.authUser.sapCode}
        }
        axios.post("/getVendorNetwork", dataToPost).then(response => {
            if (response.data.type === 'success') {
                let payload = response.data.payload;
                let previewVendor = {...response.data.payload};
                let frameMaterialsList = this.state.lists.frameMaterials;
                let showPreview = false;
                if (this.props.authUser && this.isVendor && payload) {
                    showPreview = true;
                    payload.Nation = payload.Nation ? [payload.Nation.ID] : null;
                    payload.Markets = payload.Markets ? payload.Markets.map(m => m.ID) : null;
                    payload.YearlyProduction = payload.YearlyProduction ? [payload.YearlyProduction.ID] : null;
                    payload.CompanyTypes = payload.CompanyTypes ? payload.CompanyTypes.map(m => m.ID) : null;
                    payload.LensEdging = payload.LensEdging ? [payload.LensEdging.ID] : null;
                    payload.ManufacturingCompanyServices = payload.ManufacturingCompanyServices ? payload.ManufacturingCompanyServices.map(m => m.ID) : null;
                    if (Array.isArray(payload.FrameMaterials)) {
                        payload.FrameMaterials.forEach(material => {
                            let listMaterialIdx = frameMaterialsList.findIndex(m => m.key === material.ID);
                            if (listMaterialIdx !== -1) frameMaterialsList[listMaterialIdx].selected = true;
                        })
                    }
                }
                this.setState({
                    ...this.state,
                    vendor: payload || {},
                    lists: {
                        ...this.state.lists,
                        frameMaterials: frameMaterialsList
                    },
                    dataReady: true,
                    previewVendor: this.isVendor ? previewVendor : null,
                    showPreview: showPreview
                })
            }
        })
    }

    changeTextareaHandler = event => {
        const target = event.target
		const { name, value } = target
		this.setState(
			{
				...this.state,
				[name]: value
			}
		)
    }

    sendVendorEmailHandler = () => {
        let data = {
            user: {
                zeissId: this.props.authUser.zeissId,
                name: this.props.authUser.name,
                surname: this.props.authUser.surname,
                email: this.props.authUser.email,
                country: this.props.authUser.country,
                company: this.props.authUser.company
            },
            vendorID: this.state.vendor.ID,
            mailBody: this.state.mailBody
        };
        axios.post("/sendVendorEmail", data).then(response => {
            if(response.data.type === 'success') {
                notify.success({ content: "Email sent.", options: { position: 'top-right', autoClose: 3000 } });
                this.setState({
                    ...this.state,
                    mailBody: ''
                });
            } else {
                notify.error({ content: response.data.msg, options: { position: 'top-right', autoClose: 3000 } })
            }
        });
    }

    backToVendorsList = () => {
        if (this.state.prevPath != null) {
            this.props.history.push(this.state.prevPath, { from: this.props.location.pathname });
        } else {
            this.props.history.goBack();
        }
    }


    formSubmit = (event) => {
        event.preventDefault();
        const valid = this.formValidation();
        if (valid) {
            const data = {
                sapCode: this.props.authUser.sapCode,
                ...this.state.vendor
            };
            axios.post("/saveVendorNetwork", data).then(response => {
                if(response.data.type === 'success') {
                    notify.success({ content: "Thank you. Your company profile will be published on MyZEISS soon.", options: { position: 'top-right', autoClose: 3000 } })
                    this.setState({
                        ...this.state,
                        vendor: {
                            ...this.state.vendor,
                            ID: response.data.payload
                        },
                        showPreview: true
                    }, () => window.scrollTo(0, 0));
                } else {
                    notify.error({ content: response.data.msg, options: { position: 'top-right', autoClose: 3000 } })
                }
            });
        } else {
            notify.error({ content: "Complete all required fields", options: { position: 'top-right', autoClose: 3000 } })
        }
    }

    formValidation = () => {
        let valid = true;
        let errors = {};
        if (this.state.vendor) {
            this.requiredDefaultProps.forEach(prop => {
                if (!this.state.vendor[prop]) {
                    valid = false;
                    errors[prop] = true;
                }
            });
            if (this.state.vendor && Array.isArray(this.state.vendor.CompanyTypes) && this.state.vendor.CompanyTypes.indexOf(3) !== -1 ) {
                this.requiredManufactoringProps.forEach(prop => {
                    if (!this.state.vendor[prop] || (Array.isArray(this.state.vendor[prop]) && this.state.vendor[prop].length === 0)) {
                        valid = false;
                        errors[prop] = true;
                    }
                });
            }
        } else {
            this.requiredDefaultProps.forEach(prop => {
                errors[prop] = true;
            });
            valid = false;
        }
        if (errors !== {}) {
            console.log(errors);
            this.setState({
                ...this.state,
                errors: errors
            })
        }
        return valid;
    }

    formFieldChangeHandler = (name, value) => {
        this.setState(
			{
				...this.state,
				vendor: {
					...this.state.vendor,
					[name]: value,
                },
                errors: {
                    ...this.state.errors,
                    [name]: false
                }
            },
            () => this.updatePreviewHandler(name, value)
        );
    }

    defaultFieldChangeHandler = (event) => {
        const target = event.target
        const { name, value } = target;
        this.formFieldChangeHandler(name, value);
    }

    frameMaterialCheckChangeHandler = (materialKey, value) => {
        let materialList = this.state.lists.frameMaterials
        const materialIdx = this.state.lists.frameMaterials.findIndex(m => m.key === materialKey);
        let vendorMaterials = this.state.vendor.FrameMaterials;
        if (materialIdx !== -1) {
            materialList[materialIdx].selected = value;

            if (value) {
                let material = {ID: materialList[materialIdx].key, Name: materialList[materialIdx].content, MOQ: 0};
                if (!Array.isArray(vendorMaterials)) vendorMaterials = [];
                vendorMaterials.push(material);
            } else {
                vendorMaterials = vendorMaterials.filter(m => m.ID !== materialKey);
            }
        }
        this.setState({
            ...this.state,
            vendor: {
                ...this.state.vendor,
                FrameMaterials: vendorMaterials
            },
            lists: {
                ...this.state.lists,
                frameMaterials: materialList
            },
            errors: {
                ...this.state.errors,
                FrameMaterials: false
            }
        }, () => this.updatePreviewHandler("FrameMaterials", {key: materialKey, status: value, moq: 0}));
    }

    frameMaterialMOQChangeHadnler = (materialKey, value) => {
        let vendorMaterials = this.state.vendor.FrameMaterials;
        const materialIdx = vendorMaterials.findIndex(m => m.ID === materialKey);
        if (materialIdx !== -1) {
            vendorMaterials[materialIdx].MOQ = value;
        }

        this.setState({
            ...this.state,
            vendor: {
                ...this.state.vendor,
                FrameMaterials: vendorMaterials
            }
        }, () => this.updatePreviewHandler("FrameMaterials", {key: materialKey, status: true, moq: value}));
    }

    beforeCompanyTypeChangeHandler = (name, value) => {
        if (this.state.vendor && Array.isArray(this.state.vendor.CompanyTypes)) {
            if (this.state.vendor.CompanyTypes.indexOf(3) !== -1 && value.indexOf(3) === -1) {
                // se viene tolto manufactoring pulisco i campi non necessari
                const manufactoringFields = this.requiredManufactoringProps;
                manufactoringFields.push("LensEdging","EcologicalManufactoringProcess");
                let vendor = this.state.vendor;
                let materialList = this.state.lists.frameMaterials;
                let previewVendor = this.state.previewVendor;
                for(let prop in vendor) {
                    if (this.requiredManufactoringProps.indexOf(prop) !== -1) {
                        if (Array.isArray(vendor[prop])) vendor[prop] = [];
                        else vendor[prop] = null;

                        for(let material in materialList) {
                            if (materialList[material].selected) materialList[material].selected = false;
                        }
                    }
                }
                for (let prop in previewVendor) {
                    if (this.requiredManufactoringProps.indexOf(prop) !== -1) {
                        if (Array.isArray(previewVendor[prop])) previewVendor[prop] = [];
                        else previewVendor[prop] = null;
                    }
                }
                this.setState({
                    ...this.state,
                    vendor: vendor,
                    lists: {
                        ...this.state.lists,
                        frameMaterials: materialList
                    }
                }, () => this.formFieldChangeHandler(name, value));
            } else {
                this.formFieldChangeHandler(name, value);
            }
        } else {
            this.formFieldChangeHandler(name, value);
        }
    }

    savePreviousPath = (callback) => {
        if (this.props.location.state != null && this.props.location.state.from != null) {
            this.setState({
                ...this.state,
                prevPath: this.props.location.state.from
            }, callback);
        } else {
            callback();
        }
    }

    updatePreviewHandler = (name, value) => {
        let previewVendor = {...this.state.previewVendor};
        if (Array.isArray(value) || name === "FrameMaterials") {
            previewVendor[name] = this.getPreviewDropdownValue(name, value);
        } else {
            previewVendor[name] = value;
        }
        this.setState({
            ...this.state,
            previewVendor: previewVendor
        })
    }

    getPreviewDropdownValue = (name, value) => {
        let previewValue = null;
        let findValue = null;
        switch (name) {
            case 'Nation':
                if (value.length > 0) {
                    findValue = this.state.lists.productionCountries.find(c => c.key === value[0]);
                    previewValue = {ID: findValue.key, Name: findValue.content};
                }
                break;
            case 'YearlyProduction':
                if (value.length > 0) {
                    findValue = this.state.lists.yearlyProductionCapicities.find(c => c.key === value[0]);
                    previewValue = {ID: findValue.key, Name: findValue.content};
                }
                break;
            case 'LensEdging':
                if (value.length > 0) {
                    findValue = this.state.lists.lensEdgings.find(c => c.key === value[0]);
                    previewValue = {ID: findValue.key, Name: findValue.content};
                }
                break;
            case 'EcologicalManufactoringProcess':
                if (value.length > 0) {
                    previewValue = value[0];
                }
                break;
            case 'CompanyTypes':
                previewValue = [];
                if (value.length > 0) {
                    value.forEach(key => {
                        findValue = this.state.lists.companyTypes.find(c => c.key === key);
                        previewValue.push({ID: findValue.key, Name: findValue.content});
                    })
                }
                break;
            case 'Markets':
                previewValue = [];
                if (value.length > 0) {
                    value.forEach(key => {
                        findValue = this.state.lists.markets.find(c => c.key === key);
                        previewValue.push({ID: findValue.key, Name: findValue.content});
                    })
                }
                break;
            case 'ManufacturingCompanyServices':
                previewValue = [];
                if (value.length > 0) {
                    value.forEach(key => {
                        findValue = this.state.lists.manufacturingCompanyServices.find(c => c.key === key);
                        previewValue.push({ID: findValue.key, Name: findValue.content});
                    })
                }
                break;
            case 'FrameMaterials':
                previewValue = this.state.previewVendor.FrameMaterials || [];
                previewValue = previewValue.filter(c => c.ID !== value.key);
                if (value.status) {
                    findValue = this.state.lists.frameMaterials.find(c => c.key === value.key);
                    previewValue.push({ID: findValue.key, Name: findValue.content, MOQ: value.moq});
                }
                break;
        
            default:
                return value;
        }
        return previewValue;
    }

    editVendorHandler = () => {
        this.setState({
            ...this.state,
            showPreview: false
        });
    }

    render() {
        let companyTypes = null;
        let markets = null;
        let manufacturingCompanyServices = null;

        if (this.state.vendor.CompanyTypes) {
            this.state.vendor.CompanyTypes.map(item => companyTypes ? companyTypes += ", " + item.Name : companyTypes = item.Name);
        }
        if (this.state.vendor.Markets) {
            this.state.vendor.Markets.map(item => markets ? markets += ", " + item.Name : markets = item.Name);
        }
        if (this.state.vendor.ManufacturingCompanyServices) {
            this.state.vendor.ManufacturingCompanyServices.map(item => manufacturingCompanyServices ? manufacturingCompanyServices += ", " + item.Name : manufacturingCompanyServices = item.Name);
        }

        return (
            <div className="page-container">
                <div className="header-gray-line"></div>
                {
                    !this.isVendor ?
                    <p class="back-button d-none d-md-block" onClick={this.backToVendorsList}> &lt; Back </p>
                    : null
                }
                <h2 className="vendor-title vendor-title-with-icon"><img className="m-r-5" src={vendorIcon} />Frame Factories</h2>
                {
                    this.state.dataReady ?
                        this.isVendor ?
                        <div className="vendor">
                            <div className="vendor-section full">
                                {
                                    (!this.state.showPreview || !this.state.vendor.ID || (!this.state.vendor.ID && this.state.showPreview)) ?
                                    <form name="vendorNetworkForm" onSubmit={this.formSubmit} noValidate>
                                        <h3 className="m-t-0">Contact data</h3>
                                        <div className={this.state.errors.BusinessName ? "form-group form-error" : "form-group"}>
                                            <label className="lablel-co" htmlFor="businessName">Business name</label>
                                            <input type="text" id="businessName" value={this.state.vendor.BusinessName} name="BusinessName" onChange={this.defaultFieldChangeHandler} />
                                        </div>
                                        <div className="form-row">
                                            <div className={this.state.errors.Address ? "form-group form-error" : "form-group"}>
                                                <label className="lablel-co" htmlFor="address">Address</label>
                                                <input type="text" id="address" value={this.state.vendor.Address} name="Address" onChange={this.defaultFieldChangeHandler} />
                                            </div>
                                            <div className={this.state.errors.ZipCode ? "form-group form-error" : "form-group"}>
                                                <label className="lablel-co" htmlFor="zipCode">Zip Code</label>
                                                <input type="text" id="zipCode" value={this.state.vendor.ZipCode} name="ZipCode" onChange={this.defaultFieldChangeHandler} />
                                            </div>
                                            <div className={this.state.errors.City ? "form-group form-error" : "form-group"}>
                                                <label className="lablel-co" htmlFor="city">City</label>
                                                <input type="text" id="city" value={this.state.vendor.City} name="City" onChange={this.defaultFieldChangeHandler} />
                                            </div>
                                        </div>
                                        <div className={this.state.errors.Nation ? "form-group dropdown form-error" : "form-group dropdown"}>
                                            <label className="lablel-co" htmlFor="nation">Production country</label>
                                            <DropdownField data={this.state.lists.productionCountries} value={this.state.vendor.Nation} onChange={event => this.formFieldChangeHandler('Nation', event.value)}  />
                                        </div>
                                        <div className="form-row">
                                            <div className={this.state.errors.Mail ? "form-group form-error" : "form-group"}>
                                                <label className="lablel-co" htmlFor="mail">Contact e-mail</label>
                                                <input type="email" id="mail" value={this.state.vendor.Mail} name="Mail" onChange={this.defaultFieldChangeHandler} />
                                            </div>
                                            <div className={this.state.errors.Website ? "form-group form-error" : "form-group"}>
                                                <label className="lablel-co" htmlFor="website">Website</label>
                                                <input type="text" id="website" value={this.state.vendor.Website} name="Website" onChange={this.defaultFieldChangeHandler} />
                                            </div>
                                        </div>
                                        <h3>Company details</h3>
                                        <div className="form-row">
                                            <div className={this.state.errors.CompanyTypes ? "form-group form-error dropdown" : "form-group dropdown"}>
                                                <label className="lablel-co" htmlFor="companyType">Company type</label>
                                                <DropdownField data={this.state.lists.companyTypes} value={this.state.vendor.CompanyTypes} onChange={event => this.beforeCompanyTypeChangeHandler('CompanyTypes', event.value)} multiple maxSelectedShown="3" />
                                            </div>
                                            <div className={this.state.errors.Markets ? "form-group form-error dropdown" : "form-group dropdown"}>
                                                <label className="lablel-co" htmlFor="markets">Product categories</label>
                                                <DropdownField data={this.state.lists.markets} value={this.state.vendor.Markets} onChange={event => this.formFieldChangeHandler('Markets', event.value)}  multiple maxSelectedShown="3" />
                                            </div>
                                        </div>
                                        {
                                            this.state.vendor.CompanyTypes && this.state.vendor.CompanyTypes.indexOf(3) !== -1 ?
                                            <div>
                                                <h4>Manufacturing company details</h4>
                                                <div className="form-row">
                                                    <div className={this.state.errors.ManufacturingCompanyServices ? "form-group form-error dropdown" : "form-group dropdown"}>
                                                        <label className="lablel-co" htmlFor="manufacturingCompanyServices">Manufacturing company services</label>
                                                        <DropdownField data={this.state.lists.manufacturingCompanyServices} value={this.state.vendor.ManufacturingCompanyServices} onChange={event => this.formFieldChangeHandler('ManufacturingCompanyServices', event.value)}  multiple maxSelectedShown="3" />
                                                    </div>
                                                    <div className={this.state.errors.YearlyProduction ? "form-group form-error dropdown" : "form-group dropdown"}>
                                                        <label className="lablel-co" htmlFor="yearlyProduction">Yearly eyewear production capacity</label>
                                                        <DropdownField data={this.state.lists.yearlyProductionCapicities} value={this.state.vendor.YearlyProduction} onChange={event => this.formFieldChangeHandler('YearlyProduction', event.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className={this.state.errors.CurrentFreeCapacity ? "form-group form-error" : "form-group"}>
                                                        <label className="lablel-co" htmlFor="currentFreeCapacity">Current free capacity</label>
                                                        <input type="text" id="currentFreeCapacity" value={this.state.vendor.CurrentFreeCapacity} name="CurrentFreeCapacity" onChange={this.defaultFieldChangeHandler} />
                                                    </div>
                                                    <div className={this.state.errors.LensEdging ? "form-group form-error dropdown" : "form-group dropdown"}>
                                                        <label className="lablel-co" htmlFor="lensEdging">Lens edging</label>
                                                        <DropdownField data={this.state.lists.lensEdgings} value={this.state.vendor.LensEdging} onChange={event => this.formFieldChangeHandler('LensEdging', event.value)} />
                                                    </div>
                                                    <div className={this.state.errors.EcologicalManufactoringProcess ? "form-group form-error dropdown" : "form-group dropdown"}>
                                                        <label className="lablel-co" htmlFor="ecologicalManufactoringProcess">Ecological manufacturing process</label>
                                                        <DropdownField data={[{key: "yes", content: "Yes"}, {key: "no", content: "No"}]} value={this.state.vendor.EcologicalManufactoringProcess} onChange={event => this.formFieldChangeHandler('EcologicalManufactoringProcess', event.value)} />
                                                    </div>
                                                </div>
                                                <p className={this.state.errors.FrameMaterials ? "frame-materials-title text-error" : "frame-materials-title"}>Frame materials</p>
                                                {
                                                    this.state.errors.FrameMaterials ?
                                                    <p className={this.state.errors.FrameMaterials ? "frame-materials-subtitle text-error" : null}><small >Select at least one material</small></p>
                                                    : null
                                                }
                                                <div className="frame-material-grid">
                                                    {
                                                        this.state.lists.frameMaterials.map(material => {
                                                            const vendorMaterial = Array.isArray(this.state.vendor.FrameMaterials) ? this.state.vendor.FrameMaterials.find(m => m.ID === material.key) : null;
                                                            const moqValue = vendorMaterial ? vendorMaterial.MOQ : null;
                                                            return (
                                                                <div className="form-row" key={material.key}>
                                                                    <div className={this.state.errors.FrameMaterials ? "form-group form-error" : "form-group"}>
                                                                        <Checkbox value={material.selected} onChange={event => this.frameMaterialCheckChangeHandler(material.key, event.value)}>{material.content}</Checkbox>
                                                                        {
                                                                            material.selected ?
                                                                            <div>
                                                                                <label className="lablel-co" htmlFor={"MOQ"+material.content}>Minimum order quantity / model</label>
                                                                                <input type="number" id={"MOQ"+material.content} value={moqValue} name={"MOQ"+material.content} onChange={event => this.frameMaterialMOQChangeHadnler(material.key, event.target.value)} disabled={!material.selected} />
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : null
                                        }
                                        <div className="form-submit">
                                            <Button
                                                className="btn-form-submit"
                                                type="submit"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </form>
                                    :
                                    <div>
                                        <h3 className="m-t-0">Your company profile</h3>
                                        <div style={{textAlign: 'right'}}>
                                            <Button onClick={this.editVendorHandler} icon="Edit" theme={{ buttonIconPosition: 'left'}} buttonStyle='secondary'>Edit</Button>
                                        </div>
                                        <div className="vendor-preview">
                                            <VendorNetworkCard onLearnMore={() => {}} hideLearnMore={true} {...this.state.previewVendor}/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className="vendor">
                            <div className="vendor-section">
                                <div className="summary-info main-info">
                                    <h2 className="m-tb-0 vendor-title">{this.state.vendor.BusinessName}</h2>
                                    <div className="vendor-address">
                                        <p>{this.state.vendor.Address}, {this.state.vendor.ZipCode} {this.state.vendor.City}, {this.state.vendor.Nation ? this.state.vendor.Nation.Name :  null}</p>
                                    </div>
                                    <div>
                                        <br/>
                                        {this.state.vendor.Website ? <ExternalLinkVendor link={this.state.vendor.Website}></ExternalLinkVendor> : "-"}
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <span className="text-bold">Company types: </span>
                                    {companyTypes ? companyTypes : "-"}
                                </div>
                                <div>
                                    <span className="text-bold">Markets: </span>
                                    {markets ? markets : "-"}
                                </div>
                                <div>
                                    <span className="text-bold">Manufacturing company services: </span>
                                    {manufacturingCompanyServices ? manufacturingCompanyServices : "-"}
                                </div>
                                <div>
                                    <span className="text-bold">Yearly eyewear production capacity: </span>
                                    {this.state.vendor.YearlyProduction ? this.state.vendor.YearlyProduction.Name : "-"}
                                </div>
                                <div>
                                    <span className="text-bold">Lens edging: </span>
                                    {this.state.vendor.LensEdging ? this.state.vendor.LensEdging.Name : "-"}
                                </div>
                                <div>
                                    <span className="text-bold">Ecological manufacturing process: </span>
                                    {this.state.vendor.EcologicalManufactoringProcess ? this.state.vendor.EcologicalManufactoringProcess : "-"}
                                </div>
                                <br/>
                                <table className="table-frame-material">
                                    <thead>
                                        <tr>
                                            <th className="text-bold table-title">Frame materials</th>
                                            <th className="table-title">MOQ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.vendor.FrameMaterials ?
                                            this.state.vendor.FrameMaterials.map(mat => (
                                                <tr>
                                                    <td className="table-header">{mat.Name}</td>
                                                    <td className="table-cell">{mat.MOQ}</td>
                                                </tr>
                                            ))
                                            : (
                                                <tr>
                                                    <td className="table-header"></td>
                                                    <td className="table-cell"></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="vendor-section vendor-contact">
                                <h3 className="m-t-0">Contact vendor</h3>
                                <div className="text-container">
                                    <textarea name="mailBody" value={this.state.mailBody} onChange={this.changeTextareaHandler}></textarea>
                                </div>
                                <div className="form-submit">
                                    <Button
                                        className="btn-form-submit"
                                        type="button"
                                        disabled={!this.state.mailBody}
                                        onClick={this.sendVendorEmailHandler}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </div>
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authUser: state.auth.user
    }
}

export default withRouter(connect(mapStateToProps,null)(VendorNetwork));