import React from 'react';
import './ConfiguratorAnswer.css';

const ConfiguratorAnswer = (props) => {

  return (
    <div className={`config-answer ${ props.userAnswer != null && props.userAnswer != props.answer.ID ? 'not-selected' : ''} ${props.answerCount < 3 ? 'rectangle' : ''}`} onClick={() => props.onAnswerClick(props.questionIdx, props.answer.ID)}>
      <div className='answer' style={{backgroundImage: props.answer.Image ? 'url('+props.answer.Image+')' : 'none'}}>
        <div className='description'>
          {props.answer.Title}
        </div>
      </div>
    </div>
  );

}

export default ConfiguratorAnswer;