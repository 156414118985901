import React from 'react';
import { Button, Modal } from "precise-ui/dist/es6";
import './ModalNewsletter.css';
import { ModalBody } from 'react-bootstrap';
import { config } from '../../config';
import newsletterImg from '../../res/images/newsletter-background.png';

const modalNewsletter = props => (
    <Modal open={props.open} onClose={() => props.close(false)} className="newsletter-modal">
        <ModalBody>
            <div className='content'>
                <p className='title text-bold'>STAY <br class="d-lg-block"/>IN TOUCH<br/>WITH US</p>
                <div class="d-block d-lg-none">
                    <img class="newsletter-image" src={newsletterImg} alt="newsletter" style={{maxWidth: '100%'}} />
                </div>
                {
                    props.accepted ?
                        <div>
                            <p>Thank you for subscribing</p>
                            <p className='policy-link'><a href="https://www.zeiss.com/data-protection/en/home.html" target="_blank">Privacy policy</a></p>
                            <Button className='full-width newsletter-button' onClick={() => props.close(true)}>CLOSE</Button>
                        </div>
                        :
                        <div>
                            <p className='policy-link'><a href="https://www.zeiss.com/data-protection/en/home.html" target="_blank">Privacy policy</a></p>
                            <Button className='full-width newsletter-button' buttonStyle='secondary' onClick={() => props.close(false)}>NO</Button>
                            <Button className='full-width newsletter-button' onClick={() => props.close(true)}>YES, LET'S DO IT!</Button>
                        </div>
                }
            </div>
        </ModalBody>
    </Modal>
);

export default modalNewsletter;