import React, {Component} from 'react';
import { connect } from 'react-redux';
import {withRouter, Link} from 'react-router-dom'


import './Header.css';
import logo from '../../res/images/zeiss-logo-tagline_rgb.svg';
import { NavLink } from 'react-router-dom';
import {ZeissIDUtils} from "../Auth/ZeissID";
import NavItem from './NavItem/NavItem';
import CartHeader from './NavigationHeader/CartHeader/CartHeader';
import StockHeader from './NavigationHeader/StockHeader/StockHeader';
import { includes } from 'lodash';


const { Button, IconLink, Sidebar, PaddedContainer, DropdownMenu,Icon } = require('precise-ui');


class Header extends Component {

    state = {
        searchValue: ''
    }

    showCart = () => {
        if(this.props.user != null) {
            if(this.props.user.accountType == 'Account') {
                if(this.props.user.modulesEnabled == null) {
                    return false;
                }
                else {
                    let modules = this.props.user.modulesEnabled.split(';');
                    if(modules.includes("E-commerce_Full")) {
                        return true;
                    }
                    else if(modules.includes("E-commerce_Promo")) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
            else{
                return false;
            }
        }
        else {
            return false;
        }
    }



    loginHandler = ( event ) => {
        let zeissID = new ZeissIDUtils();
        zeissID.login();
    }

    signupHandler = ( event ) => {
        let zeissID = new ZeissIDUtils();
        zeissID.signUp();
    }

    logoutHandler = ( event ) => {
        let zeissID = new ZeissIDUtils();
        zeissID.logout();
    }

    checkoutClickHandler = () => {
        let routeState = null;
        if (this.props.location.pathname.indexOf("/e-shop/products") !== -1) {
            routeState = { from: this.props.location.pathname };
        }
        this.props.history.push("/cart", routeState);
    }

    goHome = () => {
        this.props.history.push("/home");
    }

    goProfile = () => {
        this.props.history.push("/profile");
    }

    goToWishlist = () => {
        this.props.history.push("/my-personal-area");
    }

    goToMyDocuments = () => {
        this.props.history.push("/my-documents");
    }

    searchFieldHandler = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
            this.startSearch();

        }
    }

    startSearch = () => {
        this.props.history.push("/home");
        setTimeout(() => {
            this.props.history.push("/search-result", { searchValue: this.state.searchValue });
            this.setState({
                searchValue: ''
            });
        });
    }

    searchFieldOnChange = event => {
        this.setState({
            searchValue: event.target.value
        });
    };

    renderMenu = () =>  {

        let menuItems = [];

        if(this.props.menuItems.length > 0) {
            if(this.props.user !== null) {
                if(this.props.user.accountType == 'Contact') {
                    this.props.menuItems.map(menuItem => {
                        if(menuItem.visibility == 'Contact') {
                            menuItems.push(menuItem);
                        }

                    })
                }
                else if(this.props.user.accountType == 'Account' || this.props.user.accountType == 'Advanced') {
                    this.props.menuItems.map(menuItem => {
                        if(
                            (menuItem.visibility == 'Contact' && menuItem.URL !== '/leads-e-learning') ||
                            (menuItem.URL == '/pricelist' && this.props.user.priceListVisible) ||
                            (menuItem.URL == '/vendors-network' && this.props.user.vendorVisible) ||
						    (menuItem.URL == '/stock' && this.props.user.stockModuleVisible) ||
                            (menuItem.URL == '/e-shop' && this.props.user.accountType == 'Account') ||
                            (menuItem.URL === '/leadtime' && this.props.user.leadTimeModuleVisible) ||
                            (!['/pricelist', '/vendors-network', '/stock', '/e-shop', '/leadtime', '/catalogue'].includes(menuItem.URL) && menuItem.visibility == 'Account')
						) {
                            menuItems.push(menuItem);
                        } else if (menuItem.URL === '/catalogue' && menuItem.visibility == 'Account') {
                            menuItem.subPages = menuItem.subPages.filter(subPage => subPage.URLSegment !== 'coatings' || (subPage.URLSegment === 'coatings' && this.props.user.coatingsModuleVisible));
                            menuItems.push(menuItem);
                        }
                    })
                }
            }
            else {
                this.props.menuItems.map(menuItem => {
                    if(menuItem.visibility == 'All') {
                        menuItems.push(menuItem);
                    }
                })
            }
        }

        return <div className="navbar">
            {
                menuItems.map(menuItem => {
                    return <NavItem
                    key={menuItem.id}
                    title={menuItem.title}
                    url={menuItem.URL}
                    subPages={menuItem.subPages}
                    location={this.props.location.pathname}>
                    </NavItem>
                })
            }
        </div>
    }


    render() {

        return (
            <div className="Header">
                <div className="top-header page-container">
                    <div className="logo-header" onClick={this.goHome} >
                        <img src={logo} alt="Logo" />
                    </div>
                    <ul className="right-header d-none d-md-flex">
                        <li className="right-header-item">
                            <NavLink to='/contact-us' exact activeClassName="active-menu-item" > <span class="icon-contact-dialogue m-r-5"></span> Contact </NavLink>
                        </li>


                        {
                            this.props.user !== null ?

                            <li className="right-header-item profile-item">
                                <a href="javascript:void(0)">
                                    <span class="icon-person m-r-5"></span>
                                    {
                                        (this.props.user.name + " " + this.props.user.surname).length <= 25 ?
                                        this.props.user.name + " " + this.props.user.surname :
                                        (this.props.user.name + " " + this.props.user.surname).substr(0, 25) + "..."
                                    }
                                </a>
                                <ul className="dropdown-menu">
                                    <li onClick={this.goProfile} > Profile </li>
                                    <li onClick={this.goToWishlist} > My personal area </li>
                                    <li onClick={this.goToMyDocuments} > My documents </li>
                                    <li onClick={this.logoutHandler} > Logout </li>
                               </ul>
                            </li>

                            :
                            <div className="d-unset">
                                <li className="right-header-item"><Button buttonStyle="secondary" style={{width: '100px' }} onClick={this.loginHandler} >Login </Button></li>
                                <li className="right-header-item"> <Button  buttonStyle="primary" style={{width: '100px' }} onClick={this.signupHandler} >Sign up</Button> </li>
                            </div>
                        }
                        {
                            this.props.user !== null ?
                                <div className="search-button">
                                    <input className="search-input" value={this.state.searchValue} placeholder="Search for color code or coating in catalogue" onChange={this.searchFieldOnChange} onKeyDown={this.searchFieldHandler}></input>
                                    <span class="icon-search search-input-icon" onClick={this.startSearch}></span>
                                </div>
                            :
                            null
                        }


                    </ul>
                    <div className="zeiss-navbar d-flex d-md-none right-header m-l-20">
                        {
                            this.showCart() ?
                            <div className="navbar-item" onClick={this.checkoutClickHandler}>
                                <CartHeader mobile={true} />
                            </div>
                            : null
                        }
                        {
                            this.showCart() ?
                            <div className="navbar-item" onClick={this.checkoutClickHandler}>
                                <StockHeader mobile={true} />
                            </div>
                            : null
                        }
                        {
                             this.props.user !== null ?
                             null
                             :
                             <div className="navbar-item" onClick={this.checkoutClickHandler}>
                                <DropdownMenu icon="Person" text="" iconSize={1.5} items={[
                                {
                                    key: 'Login',
                                    content: <div onClick={this.loginHandler} >Login</div>,
                                },
                                {
                                    key: 'Sign up',
                                    content: <div onClick={this.signupHandler}>Sign up</div>,
                                },
                                ]} />
                            </div>
                        }


                        <Sidebar
                            position="right"
                            activator=
                                {
                                    <div style={{marginLeft: '10px'}}>
                                        <Icon size={2.5} name="Menu" />
                                    </div>
                                    // <IconLink size={3} className="navbar-item mobile-nav-menu m-t-m5" icon="Menu" />
                                }
                        >
                            <PaddedContainer gutter="xxxsmall">
                                <div className="mobile-navbar">
                                    {
                                        this.renderMenu()
                                    }
                                    <div className="user-bar">
                                        <div className="NavItem">
                                            <NavLink className="NavItem" to='/contact-us' exact activeClassName="active-menu-item" > Contact </NavLink>
                                        </div>
                                        {
                                            this.props.user !== null ?
                                            <div className="NavItem" onClick={this.goProfile}>{this.props.user.name + " " + this.props.user.surname}</div>
                                            : null
                                        }
                                        {
                                            this.props.user !== null ?
                                            <div className="NavItem logout-mobile-container" onClick={this.logoutHandler}>
                                                <Icon name="ExitToApp" color="#008bd0" size={1}/>
                                                <p className="logout-mobile">Logout </p>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </PaddedContainer>
                        </Sidebar>
                    </div>
                    {
                        this.props.user !== null ?
                            <div className="search-button right d-md-none">
                                <input className="search-input search-input-mobile" value={this.state.searchValue} placeholder="Search for color or coating..." onChange={this.searchFieldOnChange} onKeyDown={this.searchFieldHandler}></input>
                                <span class="icon-search search-input-icon" onClick={this.startSearch}></span>
                            </div>
                        :
                        null
                    }
                </div>
            </div>
        );
    };
}


const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
}

export default withRouter(connect(mapStateToProps, null)(Header));

