import React, { Component, Fragment } from "react";
import './LeadTime.css';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withCookies, Cookies } from 'react-cookie';
import axios from '../../../axios';
import { Button, notify } from "precise-ui";
import ReactLoaderSpinner from "react-loader-spinner";

class LeadTime extends Component {

    state = {
        leadTimeList: [],
        loadedLeadTime: null,
        selectedLeadTimeCode: 0,
        isLoading: false
    };

    componentDidMount() {
        if (this.props.leadTimeCodeList !== null && this.props.leadTimeCodeList.length > 0) {
            if (this.props.leadTimeCodeList.length === 1) {
                this.getLeadTimeDetails(this.props.leadTimeCodeList[0]);
            } else {
                this.getLeadTimeList(this.props.leadTimeCodeList);
            }
        }
        else {
            this.props.history.push("/home");
        }
    }

    getLeadTimeList = (leadTimeCodeList) => {
        this.updateLoadingStatus(true);
        axios.post('getLeadTimeList', JSON.stringify({ leadTimeCodeList }), {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.data.type === 'success') {
                this.setState({
                    ...this.state,
                    leadTimeList: response.data.payload,
                    selectedLeadTimeCode: response.data.payload.length > 0 ? response.data.payload[0].ID : 0
                }, () => this.updateLoadingStatus(false));
            } else {
                notify.error({ content: response.data.msg, options: { position: 'top-right', autoClose: 3000 } });
                this.updateLoadingStatus(false);
            }
        }).catch(error => {
            notify.error({ content: 'An not identify error occured', options: { position: 'top-right', autoClose: 3000 } });
            this.updateLoadingStatus(false);
            console.log(error);
        });
    }

    getLeadTimeDetails = (leadTimeCode) => {
        this.updateLoadingStatus(true);
        axios.get(`getLeadTimeDetails/${leadTimeCode}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.data.type === 'success') {
                this.setState({
                    ...this.state,
                    loadedLeadTime: response.data.payload
                }, () => this.updateLoadingStatus(false));
            } else {
                notify.error({ content: response.data.msg, options: { position: 'top-right', autoClose: 3000 } });
                this.updateLoadingStatus(false);
            }
        }).catch(error => {
            notify.error({ content: 'An not identify error occured', options: { position: 'top-right', autoClose: 3000 } });
            this.updateLoadingStatus(false);
            console.log(error);
        });
    }

    updateLoadingStatus = (isLoading) => {
        this.setState({
            ...this.state,
            isLoading
        });
    }

    changeLeadTimeHandler = (event) => {
        const { value } = event.target;
        this.setState({
            ...this.state,
            selectedLeadTimeCode: value
        });
    }

    selectClickHandler = () => {
        this.getLeadTimeDetails(this.state.selectedLeadTimeCode);
    }

    render() {
        return (
            <div className="page-container pricelist-container">
                <div className="header-gray-line"> </div>
                <h1 className="generic-page-title">Lead Time</h1>
                {
                    this.state.leadTimeList.length > 1 ?
                        <Fragment>
                            <p className="text-bold">Please select a Lead Time</p>
                            <div className="pricelist-select-container">
                                <select
                                    name="pricelist"
                                    className="pricelist-select"
                                    value={this.state.selectedLeadTimeCode}
                                    onChange={this.changeLeadTimeHandler}
                                >
                                    {
                                        this.state.leadTimeList.map(leadTime => (
                                            <option key={leadTime.ID} value={leadTime.ID}>
                                                {leadTime.Shipment}
                                            </option>
                                        ))
                                    }
                                </select>
                                <Button type="button" buttonStyle="secondary" className="pricelist-select-button" onClick={this.selectClickHandler}>Select</Button>
                            </div>
                        </Fragment>
                    :
                        null
                }
                <hr className="cookie" />
                {
                    this.state.loadedLeadTime !== null && !!this.state.loadedLeadTime.leadTime && !!this.state.loadedLeadTime.leadTimeDetails ?
                        <div>
                            <p className="text-bold text-center m-b-0">
                                LEAD TIMES<br />
                                synchrony (unbranded) and ZEISS brandend ITEMS<br />
                                {this.state.loadedLeadTime.leadTime.Shipment}
                            </p>
                            <p className="text-center">EDITED: {this.state.loadedLeadTime.leadTime.ValidityStartDate}</p>

                            <table className="lead-time">
                                <thead>
                                    <tr>
                                        <th style={{ width: '45%' }}>PRODUCT (unbranded or ZEISS branded with logo)</th>
                                        {
                                            this.state.loadedLeadTime.leadTime.Type === "HKSUN" ?
                                                <Fragment>
                                                    <th style={{ width: '15%' }}>WEEKS OF LEAD TIMES for Asian Production</th>
                                                    <th style={{ width: '15%' }}>WEEKS OF LEAD TIMES for Italian Production</th>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <th style={{ width: '15%' }}>WEEKS OF LEAD TIMES for Italian Production</th>
                                                    <th style={{ width: '15%' }}>WEEKS OF LEAD TIMES for Asian Production</th>
                                                </Fragment>
                                        }
                                        <th style={{ width: '25%' }}>Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.loadedLeadTime.leadTimeDetails.map(detail => (
                                            <tr>
                                                <td>{detail.MaterialDescription}</td>
                                                {
                                                    this.state.loadedLeadTime.leadTime.Type === "HKSUN" ?
                                                        <Fragment>
                                                            <td className="text-center">{detail.AsianLeadTime}</td>
                                                            <td className="text-center">{detail.ItalianLeadTime}</td>
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <td className="text-center">{detail.ItalianLeadTime}</td>
                                                            <td className="text-center">{detail.AsianLeadTime}</td>
                                                        </Fragment>
                                                }
                                                <td>{detail.Note}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            <p className="lead-time-note">{this.state.loadedLeadTime.leadTime.Remarks}</p>
                            <p className="lead-time-note">
                                The week of the order is considered week "ZERO".<br />
                                Lead time are tentative and subject to factory capacity saturation and/or specific capacity allocations agreed with consumers.<br />
                                Actual delivery dates are confirmed on firm orders with our order confirmation.
                            </p>
                        </div>
                        :
                        null
                }
                {
                    this.state.isLoading ?
                        <div>
                            <div className="black-layer">
                            </div>
                            <ReactLoaderSpinner
                                type="TailSpin"
                                color="#008bd0"
                                height={100}
                                width={100}
                                timeout={1500000}
                                className="spinner"
                            />
                        </div>

                        :
                        null
                }
            </div>
        );
    }

}

const mapStateToProps = state => {
    let user;
    if (state.auth.user) {
        user = state.auth.user;
    }
    else {
        user = JSON.parse(localStorage.getItem('user'));
    }

    return {
        leadTimeCodeList: user ? user.leadTimeCodeList : null
    };
}

export default withRouter(withCookies(connect(mapStateToProps, null)(LeadTime)));