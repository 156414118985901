import { RadioButton, RadioButtonGroup, Skeleton, StackItem, StackPanel } from 'precise-ui/dist/es6';
import React from 'react';
import ConfiguratorAnswer from '../ConfiguratorAnswer/ConfiguratorAnswer';
import './ConfiguratorQuestion.css';

const exampleWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
};

const skeletonWrapper = {
  flex: '0 1 24%',
  padding: '10px',
  alignSelf: 'flex-start',  
};

const ConfiguratorQuestion = (props) => {

  return (
    !!props.question ?
    <div>
      <p className="question-title">{props.index+1} - {props.question.Title}</p>
      {
        !!props.question.Description ?
        <p>{props.question.Description}</p>
        : null
      }
      <div className='config-answer-container'>
      {
        props.question.ConfiguratorAnswer && props.question.ConfiguratorAnswer.map((answer) => (
            <ConfiguratorAnswer key={answer.ID} answerCount={props.question.ConfiguratorAnswer.length} answer={answer} onAnswerClick={props.onAnswerClick} questionIdx={props.index} userAnswer={props.userAnswer} />
          )
        )
      }
      </div>
    </div>
    :
    <div>
      <p><Skeleton height={40} /></p>
      <div style={exampleWrapper}>
        <Skeleton height={200} count={4} style={skeletonWrapper} />
      </div>
    </div>
  );

}

export default ConfiguratorQuestion;