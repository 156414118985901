import { Button } from 'precise-ui/dist/es6';
import React from 'react';
import './SectionModule.css';



const sectionModule = (props) => {
    let disabled = false;
    let boxClasses = ["box"];
    let eshopMessage = '';
    let showAdvancedButton = false;
    let showFullButton = false;
    let moduleDescription = props.module.Content;
    const advancedRequestText = "REGISTER TO ACCESS";
    const fullRequestText = "SUBMIT REQUEST";

    if (props.module.AccountPrivileges && props.accountUser.accountType != 'Account' && (props.accountUser.accountType == 'Contact' || props.module.Title != 'Frame Factories')) {
        boxClasses.push("disabled-block");
        disabled = true;
        if(props.accountUser.accountType == 'Contact'){
            showAdvancedButton = !props.accountUser.fullPrivilegeRejected;
        } else if (props.accountUser.accountType == 'Advanced') {
            showFullButton = !props.accountUser.fullPrivilegeRejected;
        }
    }
    else if(props.accountUser.accountType == 'Account' && props.module.Title == 'My Shop') {
        disabled = false;
        if(props.accountUser.modulesEnabled == null) {
            eshopMessage = 'Sorry but we can’t serve you through this e-commerce. <br/> Contact us for more details.';
            boxClasses.push("disabled-block");
            boxClasses.push("e-shop-disabled");
            disabled = true;
        }
        else {
            let modules = props.accountUser.modulesEnabled.split(';');
            if(modules.includes("E-commerce_Full")) {
                eshopMessage = '';
            }
            else if(modules.includes("E-commerce_Promo")) {
                eshopMessage = '';
            }
            else {
                eshopMessage = 'Sorry but we can’t serve you through this e-commerce. <br/> Contact us for more details.';
                boxClasses.push("disabled-block");
                boxClasses.push("e-shop-disabled");
                disabled = true;
            }
        }
    }
    else if(['Account', 'Advanced'].includes(props.accountUser.accountType) && props.module.Title == 'Frame Factories') {
        let modules = props.accountUser.modulesEnabled.split(';');
        if(modules.includes('VendorNetwork')) {
            moduleDescription = 'Present your company and find your future partner';
        }
        else if (modules.includes('VendorNetworkConsultant')) {
            moduleDescription = props.module.Content;
        }
    }
    else if(props.accountUser.accountType == 'Account' && props.module.Title == 'Price List') {
        disabled = false;

        let modules = props.accountUser.modulesEnabled.split(';');
        if(!modules.includes('PriceList')) {
            disabled = false;
            boxClasses.push("disabled-block");
        }
    }
    else if (props.accountUser.accountType == 'Contact' && ['E-learning', 'Product Portfolio'].includes(props.module.Title)) {
        boxClasses.push("incomplete-block");
    }

    return (
        <div className="card-prospect f-left">   

            <div className={boxClasses.join(' ')} onClick={disabled ? undefined : props.clicked  }>
                <div className="box-image-prospect">
                    <img src={props.module.Image} alt="prospect" ></img>
                    <div class="box-image-no-access">
                        { showAdvancedButton ? advancedRequestText : fullRequestText }
                    </div>
                    {
                        (props.module.Title == 'My Shop') ? 
                        <div className="e-shop-tooltip" dangerouslySetInnerHTML={{__html: eshopMessage}}>  
                        </div>
                        :
                        null
                    }
                    <span className='box-status'>
                        {
                            boxClasses.includes('disabled-block') ?
                            'Locked'
                            :
                            boxClasses.includes('incomplete-block') ?
                            'Partially accessible'
                            :
                            'Accessible'
                        }
                    </span>
                </div>
                <div className="box-content">

                    <div className="box-headline" >
                        <span>{props.module.Title} </span>
                    </div>
                    <div className="box-text" dangerouslySetInnerHTML={{__html: moduleDescription}}></div>
                    {
                        showAdvancedButton || showFullButton ? 
                        <div className="fp-btn-container">
                            <Button onClick={ props.showFullPrivilegeModal} > {showAdvancedButton ? advancedRequestText : fullRequestText} </Button>
                        </div>
                        :
                        null
                    }

                </div>
            </div>
        </div>
    );
};

export default sectionModule;