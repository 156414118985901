import React, {Component} from 'react';
import './HomePageProspect.css';
import SlideShow from '../../../components/SlideShow/SlideShow';


import axios from '../../../axios';
import SectionModule from '../../../components/Section/SectionModule/SectionModule';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/index';

const { Icon } = require('precise-ui');


class HomePageProspect extends Component {
    state = {
        sections: [],
        slideShowImages: []
    }

    componentDidMount() {

        let path = this.props.match.path;
        axios.post('/getPageContentByUrl', {
            url: path.replace("/", '')
        }).then(response => {
            this.setState({
                ...this.state,
                sections: response.data.Section,
                slideShowImages: response.data.SlideShow
            });

            window.focus();
            window.scrollTo(0, 0);

        });

        this.getUserCart();
        this.getUserInventoryCart();
        if((typeof window.gtag !== 'undefined'))
        {
            window.gtag('config', 'AW-10905270588');
            window.gtag('event', 'conversion', {'send_to': 'AW-10905270588/IJqUCMWo6JUYELzyhNAo'});
        }
    }

    getUserCart = () => {
        if (this.props.user && this.props.user.accountType == 'Account' && this.props.user.zeissId) {
            axios.get('/getUserCart/'+this.props.user.zeissId+'/'+this.props.user.priceList)
            .then(response => {
                if (response.data.type === "success") {
                    this.props.updateCart(response.data.payload);
                } else {
                    console.log(response.data.error);
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

	 moduleClickHandler = (e, section) => {
	        if(!e.currentTarget.classList.contains("disabled-block")) {
	            let path = section.DestURL;

                if (path == '/e-learning' && this.props.user.accountType == 'Contact') {
                    path = '/leads-e-learning';
                }

	            const regAbsolutePath = new RegExp('^(?:[a-z]+:)?//', 'i');
	            if (!regAbsolutePath.test(path)) {
	                this.props.history.push(path);
	            } else {
	                window.open(path, '_blank');
	            }
	        }
	 }
	getUserInventoryCart = () => {
        if (this.props.user && this.props.user.accountType == 'Account' && this.props.user.zeissId) {
            axios.get('/getUserInventoryCart/'+this.props.user.zeissId+'/'+this.props.user.priceList)
            .then(response => {
                if (response.data.type === "success") {
                    this.props.updateInventoryCart(response.data.payload);
                } else {
                    console.log(response.data.error);
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    showFullPrivilegeModal = () => {
        this.props.toggleFullPrivilegeModal();
    }

    isModuleVisible = (section) => {
        if(this.props.user.accountType == 'Account' && section.Title == 'Frame Factories') {
             return this.props.user.vendorVisible;
        }

        if (this.props.user.accountType == 'Account' && section.Title == 'Price List') {
            return this.props.user.priceListVisible;
        }

        if(this.props.user.accountType == 'Account' && section.Title == 'Stock') {
            return this.props.user.stockModuleVisible;
        }
        else if(this.props.user.accountType != 'Account' && section.Title == 'Stock') {
            return false;
        }

        if(this.props.user.accountType == 'Account' && section.Title == 'Lead Time') {
            return this.props.user.leadTimeModuleVisible;
        }

        if(this.props.user.accountType == 'Account' && section.DestURL == '/coatings') {
            return this.props.user.coatingsModuleVisible;
        } else if(this.props.user.accountType != 'Account' && section.DestURL == '/coatings') {
            return false;
        }

        return true;
    }

    seeMoreClicked(e, image) {
        if(image.URL != null) {
            window.location.href = image.URL;
        }
    }

    render () {

        let sections = [];
        sections.push(this.state.sections.map((section, i) => {
            if(this.isModuleVisible(section)) {
                return <SectionModule key={section.ID} module={section} accountUser={this.props.user} clicked={(e) => this.moduleClickHandler(e,section)} showFullPrivilegeModal= {() => this.showFullPrivilegeModal() }/>
            }
        }));

        return (
            <div>
                <div className="header-blue-line"> </div>
                <div className="page-container prospect-container ">
                    <div> <h3 className="prospect-title"> Modules </h3> </div>
                    <div className="card-container">
                        {sections}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    };
}
const mapDispatchToProps = dispatch => {
    return {
        updateCart: (items) => dispatch(actions.updateCart(items)),
        toggleFullPrivilegeModal: () => dispatch(actions.toggleFullPrivilegeModal()),
        updateInventoryCart: (items) => dispatch(actions.updateInventoryCart(items))
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePageProspect));