import React from 'react';

import './ELearningMenu.css';
import ELearningMenuItem from './Item/ELearningMenuItem';
import ELearningSearchField from './SearchField/ELearningSearchField';
import { Sidebar, PaddedContainer,Skeleton } from 'precise-ui/dist/es6';

const ELearningMenu = (props) => {
    

    return (
        <div className="relative">
            <div className="elearning-menu-container d-none d-lg-flex">
                {
                    props.menu.length > 0 ?
                        <div className="elearning-menu-item-container">
                        {
                        
                            props.menu.map((menuItem) => (
                                <ELearningMenuItem item={menuItem} onHover={props.onHover} selectedMenuItem={props.selectedMenuItem} ></ELearningMenuItem>
                            ))   
            
                        }
                        </div>
                    :
                    <div className="elearning-menu-item-container">
                        <Skeleton width={75} height={24} style={{paddingBottom: '0', marginBottom:'50px'}}  className="el-menu-item" />
                        <Skeleton width={75} height={24} style={{paddingBottom: '0', marginBottom:'50px'}}  className="el-menu-item" />
                        <Skeleton width={75} height={24} style={{paddingBottom: '0', marginBottom:'50px'}}  className="el-menu-item" />
                        <Skeleton width={75} height={24} style={{paddingBottom: '0', marginBottom:'50px'}}  className="el-menu-item" />
                        <Skeleton width={75} height={24} style={{paddingBottom: '0', marginBottom:'50px'}}  className="el-menu-item" />
                        <Skeleton width={75} height={24} style={{paddingBottom: '0', marginBottom:'50px'}}  className="el-menu-item" />
                    </div>             
                }
                <div className="el-search-container">
                    <ELearningSearchField
                        change={props.changeSearchQuery}
                        keyDown={props.keyDown}
                    ></ELearningSearchField>
                </div>             
            </div>
            {
                    props.selectedMenuItem !== null ?
                    <div className="el-sub-menu-container d-none d-lg-block" onMouseEnter={ () => props.onHover(props.selectedMenuItem)} onMouseLeave={ () => props.onHover(null)}>
                        <p> EXPLORE </p>
                        {
                            props.selectedMenuItem.Levels.map((level) => (
                                <div onClick={ () => props.itemClicked(level)} >{level.Title}</div>
                            ))   
                        }
                    </div>
                    :
                    null
                }
            {/* ELearning mobile menu */}
            <Sidebar 
                position="left"
                activator=
                    {
                        <div class="d-block d-lg-none filter-sidebar-activator elearning">
                            Menu
                        </div>
                    }
            >
                <PaddedContainer gutter="small">
                    <div style={{marginTop: '50px'}}>
                        {
                            props.menu.map((menuItem) => (
                                <div>
                                    <ELearningMenuItem item={menuItem} clicked={props.clicked} selectedMenuItem={props.selectedMenuItem} ></ELearningMenuItem>
                                    {
                                        props.selectedMenuItem !== null && props.selectedMenuItem === menuItem ?
                                        <div className="el-sub-menu-container-mobile">
                                            {
                                                props.selectedMenuItem.Levels.map((level) => (
                                                    <div onClick={ () => props.itemClicked(level)} >{level.Title}</div>
                                                ))   
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            ))   
                        }
                    </div>
                </PaddedContainer>
            </Sidebar>
            <div className="d-block d-lg-none">
                <ELearningSearchField
                    change={props.changeSearchQuery}
                    keyDown={props.keyDown}
                ></ELearningSearchField>
            </div>
        </div>
        
    );
}

export default ELearningMenu;