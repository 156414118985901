import React, { Component } from "react";
import { withRouter } from 'react-router';
import axios from '../../../axios';
import imageNotFound from   '../../../res/images/Image-not-found.jpg';
import { Carousel } from 'precise-ui';
import {config} from '../../../config';
const { Skeleton } = require('precise-ui');
const { Button } = require('precise-ui');

class SearchResult extends Component {

    state = {
        showSkeletons: true,
        searchValue: '',
        items: [],
        numberOfItems: 20
    }

    componentDidMount () {
        if (this.props.location.state != null && this.props.location.state.searchValue != null) {
           this.setState(() => ({
            searchValue: this.props.location.state.searchValue
            }))        
           this.searchProducts(this.props.location.state.searchValue);
        }
        else if(sessionStorage.getItem('searchValueState')) {
            this.setState(() => ({
                ...JSON.parse(sessionStorage.getItem('searchValueState'))
            }))  
        }   
        else {
            this.props.history.push("/home");
        }      
        window.scrollTo(0, 0);
    }
    
    showSeeMore(){
        if(this.state.numberOfItems < this.state.items.length) {
            return true;
        }
        else {
            return false;
        }
    }

    searchProducts = (searchValue) => {
        const data = {
            search: searchValue,
            onlyBMQCatalogue: false //TODO
        }

        axios.post("/searchCatalogueProduct", data)
        .then(result => {
            let items = result.data.Items[0] ? result.data.Items[0] : [];

            this.setState(() => ({
                showSkeletons: false,
                items: items
            }))
        })
        .catch(error => {
            console.log(error);
        })
    }

    checkIfMoreThanOneIsNotNull(values){
        let i = 0;
        values.forEach(element => {
            if(element.ID > 0 ) {
                i++;
            }
        });

        if( i > 1 ) {
            return true;
        }
        else {
            return false;
        }
    }

    renderSkeletonItems() {
        return <div className="skeleton-items"> 
            <div className="products-container">
                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>


                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>


                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>
            </div>
        </div>
    }

    renderProductThirdLevel(subitem) {

        let itemDetails = [];
        subitem.ItemValues.forEach(itemValues => {
            itemValues.Values.forEach(val => {
                let typeValues = itemDetails.find(i => i.Type === val.Type);
                let detailIdx = itemDetails.indexOf(typeValues);
                if (typeValues && detailIdx !== -1) {
                    if(val.ID > 0) {
                        typeValues = {
                            ...typeValues,
                            Photo: [
                                ...typeValues.Photo,
                                {ID: val.ID, Photo: val.Photo}
                            ],
                            Code: [
                                ...typeValues.Code,
                                {ID: val.ID, Code: val.Code}
                            ]
                        };
                        itemDetails[detailIdx] = typeValues;
                    }                   
                } else {
                    if(val.ID > 0) {
                        itemDetails.push({
                            Type: val.Type,
                            Photo: [{ID: val.ID, Photo: val.Photo}],
                            Code: [{ID: val.ID, Code: val.Code}]
                        });
                    }
                }
            });
        });

        return (
            <div className="catalogue-product-container">
                <div className="catalogue-product-details">
                    {
                        itemDetails.map((detail) => {
                            return (

                                <div className="detail">
                                    <div className="img-container">
                                        {
                                            this.checkIfMoreThanOneIsNotNull(detail.Photo) ? 
                                            <Carousel >
                                                {                                
                                                    detail.Photo
                                                    .map((item, i) => (  
                                                        item.ID > 0 ?
                                                        <img key={i} src={item.Photo != null ? config.assets + "" +  item.Photo : imageNotFound} onError={(e)=>{e.target.onerror = null; e.target.src=imageNotFound}} alt="lente" />       
                                                        :
                                                        null
                                                    ))                           
                                                }
                                            </Carousel>
                                            :
                                            <div>
                                                <img src={config.assets + "" + detail.Photo[0].Photo} onError={(e)=>{e.target.onerror = null; e.target.src=imageNotFound}}></img>
                                                <div style={{width: '100%', height: '1.25rem'}}></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="product-block-details">
                                        {detail.Type}
                                    </div>
                                    <div className="product-block-extra-details black-block-labels">
                                    {
                                        detail.Code.map((value) => {
                                            return <p>{value.Code}</p>
                                        })  
                                    }
                                    </div>
                                </div>
                            );                  
                        })                    
                    }
                </div>
                <div className="select-block " onClick={ () => this.handleSelectProduct(subitem.ZeissProductID)}>
                    <p className="select-text"> Select </p>
                </div>                
            </div>
        );
    }

    handleSelectProduct(productId) {
        sessionStorage.setItem('searchValueState', JSON.stringify(this.state));
        this.props.history.push("/product/"+ productId, { from: this.props.location.pathname });
    }

    handleClickSeeMore = (e) => {
        e.preventDefault()  
        let numberOfItems = this.state.numberOfItems + 20;
        this.setState(() => ({
            numberOfItems: numberOfItems
        }))
    };

    render () {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                <h1> Search result for "{this.state.searchValue}" </h1> 
                <div>
                    { this.state.showSkeletons ?
                        this.renderSkeletonItems()
                        :                                               
                        <div >      
                            {
                                this.state.items.length > 0 ? 
                                <div className="result-container">
                                    {this.state.items.slice(0, this.state.numberOfItems).map((subitem) => (
                                        <div key={subitem.ID} className="product-block-second-level m-w-50"> 
                                            {this.renderProductThirdLevel(subitem)}
                                        </div>                            
                                    ))}
                                    {
                                        this.showSeeMore() ?
                                        <div className="see-more-sr">
                                            <Button className="see-more-button" buttonStyle="primary" style={{width: '120px' }}  onMouseDown ={ (e) => { this.handleClickSeeMore(e) }} >See more </Button>
                                        </div>
                                        : 
                                        null
                                    }
                                   
                                </div>
                                :
                                <div className="np-found">
                                    <p> No product found. <br/> Please try with another color code or another coating </p>
                                </div>

                            }                      
                           
                        </div>
                    }       
                </div>
            </div>
        )
    }
}

export default withRouter(SearchResult);