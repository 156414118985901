import React from 'react';
import ProductCard from '../../containers/Pages/EShop/ProductCard/ProductCard';
import './RelatedProductsSlider.css';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3
    },
    mobileLarge: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ZEISSLeftArrow = ({ onClick }) => (
    <button className="zeiss-carousel-arrow arrow-left" onClick={() => onClick()}></button>
);

const ZEISSRightArrow = ({ onClick }) => (
    <button className="zeiss-carousel-arrow arrow-right" onClick={() => onClick()}></button>
);

const relatedProductsSlider = props => {
    return (
        <div>
            <Carousel arrows responsive={responsive} infinite={true} customLeftArrow={<ZEISSLeftArrow />} customRightArrow={<ZEISSRightArrow />}>
            {
                props.products.map(product => (
                    <div className="carousel-product-card">
                        <ProductCard 
                            key={product.ID} 
                            product={product} 
                            clicked={() => props.onSelect(product.ID)}
                            hideAddToCartButton={true}
                            hidePrice={props.hidePrice}
                        ></ProductCard>
                    </div>
                ))
            }
            </Carousel>
        </div>
    );
}

export default relatedProductsSlider;