import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, IconLink, notify, ModalBody, Modal, ModalHeader } from 'precise-ui';
import './Cart.css';
import * as actionCreators from '../../../../store/actions/index';
import NumberFormat from 'react-number-format';
import CartItem from '../../../../components/CartItem/CartItem';
import QuantitySelector from '../../../../components/QuantitySelector/QuantitySelector';
import axios from '../../../../axios';
import Loader from 'react-loader-spinner'
import ReactGA from 'react-ga';
import {config} from '../../../../config';
const { Checkbox } = require('precise-ui');
const { Button } = require('precise-ui');
const { Icon } = require('precise-ui');

class Cart extends Component {

    state = {
        prevPath: null,
        xPayParams: { url: null, requestParams: []},
        showFinalCheckout: false,
        totalCheckOut: { shippingTotal: 0, productsTotal: 0, finalTotal: 0},
        checkDifferentShippingAddress: false,
        differentShippingAddress: -1,
        privacyAccepted: false,
        showBillingPanel: true,
        notes: '',
        coupon: '',
        privacyError: false,
        deliveryPointError: false,
        isLoading: false,
        couponState: 'not-verified',
        openShopClosedModal: false,
        shopClosedEndDate: ''
    }

    tableColumns = {
        item: 'Item',
        Quantity: 'Pieces',
        Price: {
            header: 'Price / piece',
            footer: 'Subtotal:',
            sortable: false
        },
        TotalPrice: 'Total Price',
    };

    tableCheckOutColumns = {
        item: 'Item',
        Quantity: 'Pieces',
        TotalPrice: 'Total Price',
    };
   
    total = 0;

    componentDidMount () {
        this.savePreviousPath();
        window.scrollTo(0, 0);

        this.isEShopOpened();
    }

    isEShopOpened() {
        
         let params = {
            section: 'ESHOP'
        }
        
        axios.post('/isModuleClosed', JSON.stringify(params), {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then(response => {
            if(response.data) {
                if(response.data.payload.isClosed){
                    this.setState({openShopClosedModal: true, shopClosedEndDate: response.data.payload.dateEnd});    
                }
                
            }
        })
        .catch(err => {
            console.log(err);
        });
        
    }

    savePreviousPath = () => {
        if (this.props.location.state != null && this.props.location.state.from != null) {
            this.setState({
                ...this.state,
                prevPath: this.props.location.state.from
            });
        }
    }

    headerCellRender = headerCell => {
        const key = headerCell.key;
        const value = headerCell.value;

        if (key.toLowerCase() === 'price' || key.toLowerCase() === 'totalprice') {
            return (
                <div className="text-right">
                    {value}
                </div>
            );
        } else {
            return value;
        }

    }

    cellTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'totalprice':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price * data.Quantity} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'price':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'item':
                return (
                    <CartItem {...data} />
                );
            case 'quantity':
                return (
                    <div>
                        <div>
                            <QuantitySelector 
                                value={value}
                                change={(event) => this.props.onEditQuantity(data.ID, event.target.value)}
                                start={data.OrderMinQty} 
                                end={data.StockQty} 
                                step={data.MinReorder} />
                            <IconLink className="m-l-10" icon="Delete" size={1} onClick={() => this.props.onRemoveFromCart(data.ID, this.props.user.zeissId)}/>
                        </div>
                        {
                            !data.Available ?
                            <p className="text-bold text-error">Item not available.</p>
                            : null
                        }
                    </div>
                );
            default:
                return value;
        }
    }

    cellCheckOutTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'totalprice':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price * data.Quantity} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'price':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'item':
                return (
                    <CartItem {...data} />
                );
            case 'quantity':
                return (
                    <div>{value}</div>
                );
            default:
                return value;
        }
    }

    footerCellRenderer = footerCell => {        
        const key = footerCell.key;
        const value = footerCell.value;

        if (key.toLowerCase() === 'totalprice') {
            return (
                <div className="text-right">
                    <NumberFormat 
                        value={this.getTotal()} 
                        displayType={'text'} 
                        prefix={'€ '} 
                        decimalSeparator={'.'} 
                        decimalScale={2}
                        fixedDecimalScale={true} />
                </div>
            );
        } else if (key.toLowerCase() === 'price') {
            return (
                <div className="text-right">
                    {value}
                </div>
            );
        } else {
            return value;
        }
    }

    getTotal() {
        let total = 0;
        this.props.storedCart.forEach(
            element => {
                total = total + (element.Price * element.Quantity);
            }
        );

        return total;
    }

    historyBackHandler = () => {
        if (this.state.prevPath != null) {
            this.props.history.push(this.state.prevPath, { from: this.props.location.pathname });
        } else {
            this.props.history.goBack();
        }
    }

    proceedOrder = () => {
        this.setState({
            showFinalCheckout: true
        });
              
    }

    handleChangeShippingCheckBox = () => {
        let checked = this.state.checkDifferentShippingAddress;

        this.setState({
            checkDifferentShippingAddress: !checked
        });
    }


    handlePrivacy= () => {
        let privacy = this.state.privacyAccepted;

        this.setState({
            privacyAccepted: !privacy,
            privacyError: false
        });

    }

    handleChangeSelectDelivery = (event) => {
        this.setState({
            differentShippingAddress: event.target.value
        });
    }

    handleChangeNotes = (event) => {
        this.setState({notes: event.target.value});
    }

    confirmBilling = () => {

        let privacyError = false;
        if(!this.state.privacyAccepted){
            privacyError = true;
        }
        
        let deliveryPointError = false;
        if (this.props.user.isDeliveryPointMandatory && this.state.differentShippingAddress == -1){
            deliveryPointError = true;
        }

        if(!privacyError && !deliveryPointError){            
            //ANALYTICS
            /*ReactGA.plugin.require('ec', { debug: true });
            this.props.storedCart.forEach( element => {
                ReactGA.plugin.execute('ec', 'addProduct', {
                    id: element.ID
                });
            });

            ReactGA.plugin.execute('ec', 'setAction', 'checkout');

            ReactGA.pageview(window.location.pathname + window.location.search);*/

            window.scrollTo(0, 0);
            this.setState({
                isLoading: true
            });

            let differentShippingAddress = { code: null, adress: null, ivaAS400: null, extraAS400: null };

            let useDifferentShippingAddress = false;
            
            if(this.state.checkDifferentShippingAddress || this.props.user.isDeliveryPointMandatory){
                useDifferentShippingAddress = true;
                differentShippingAddress.code = this.state.differentShippingAddress;
                differentShippingAddress.adress = this.props.user.deliveries.filter(function(item) { return item.Delivery_Point_Number__c === differentShippingAddress.code; })[0].Address__c;
                differentShippingAddress.ivaAS400 = this.props.user.deliveries.filter(function(item) { return item.Delivery_Point_Number__c === differentShippingAddress.code; })[0].BtoB_Portal_IVA_AS400__c;
                differentShippingAddress.extraAS400 = this.props.user.deliveries.filter(function(item) { return item.Delivery_Point_Number__c === differentShippingAddress.code; })[0].BtoB_Portal_spese_accessorie_AS400__c;
            }
    
            let order = {
                user: this.props.user,
                country: this.props.user.billingAddress.countryCode,
                note: this.state.notes,
                useDifferentShippingAddress: useDifferentShippingAddress,
                differentShippingAddress: differentShippingAddress,            
                products: this.props.storedCart.filter(i => i.Available !== false),
                coupon: this.state.coupon
            }
        
            axios.post('/checkOut', JSON.stringify(order), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                if (response.data.error) {
                    notify.error({ content: response.data.error, options: { position: 'top-right', autoClose: 3000 } });
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                } else {
                    let totalCheckOut = { 
                        shippingNoVAT: response.data.ShippingPriceNoVAT,
                        productsTotalNoVat: response.data.ProductsPriceNoVAT, 
                        subTotal: response.data.SubTotal,
                        vat: response.data.VAT,
                        extraVat: response.data.ExtraVAT,
                        finalTotal: response.data.FinalTotalPrice,
                        VATDescription: response.data.VATDescription,
                        adminCosts: response.data.AdminCosts,
                        discount: response.data.Discount
                    }
    
                    let xPayParams = {
                        url: response.data.XpayParams.redirectUrl,
                        requestParams: response.data.XpayParams.requestParams
                    }  
    
                    this.setState({
                        showFinalCheckout: true,
                        totalCheckOut: totalCheckOut,
                        xPayParams: xPayParams,
                        showBillingPanel: false,
                        isLoading: false
                    });
                }
            })
            .catch(err => {
                notify.error({ content: 'There was an error handling your request. Please contact support for assistance', options: { position: 'top-right', autoClose: 4000 } });
                this.setState({
                    ...this.state,
                    isLoading: false
                });
            });
        }
        else {
            this.setState({
                privacyError: privacyError,
                deliveryPointError: deliveryPointError
            });
            
        }
        
    }

    
    backToCart = () => {
        this.setState({
            showFinalCheckout: false
        });
        window.scrollTo(0, 0);
    }

    backToBilling = () => {
        this.setState({
            showFinalCheckout: true,
            showBillingPanel: true,
            privacyError: false,
            deliveryPointError: false,
            privacyAccepted: false
        });
        window.scrollTo(0, 0);
    }

    escape = (value) => {
        var replace = value.replace(/&#39;/g, "'");
        return replace;
    }

    handleChangeCouponCode = (event) => {
        this.setState({coupon: event.target.value, couponState: 'not-verified'});
    }

    isConfirmButtonDisabled = () => {
        if(this.state.coupon != '' && (this.state.couponState == 'not-verified' || this.state.couponState == 'invalid') ) {
            return true;
        }
        else if(this.state.coupon != '' && !this.state.couponState == 'valid') {
            return false;
        }
        else if(this.state.coupon == '') {
            return false;
        }        
    }

    verifyCoupon = () => {

        let coupon = {
            coupon: this.state.coupon,
            company: this.props.user.company
        }

        axios.post('/verifyCoupon', JSON.stringify(coupon), {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if(response.data) {
                if(response.data.payload) {
                    this.setState({
                        ...this.state,
                        couponState: 'valid'
                    });
                }
                else{
                    this.setState({
                        ...this.state,
                        couponState: 'invalid'
                    });
                }
            }
        })
        .catch(err => {
            notify.error({ content: 'There was an error handling your request. Please contact support for assistance', options: { position: 'top-right', autoClose: 4000 } });
            this.setState({
                ...this.state,
                isLoading: false
            });
        });
    }

    handleCloseShopLocked() {
        this.setState({openShopClosedModal: false});
        this.props.history.push("/home");
    }

    render() {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                {
                    this.state.showFinalCheckout ?
                    <div>
                        <h1> <span class="icon-checkmark m-r-5 checkout-icon" ></span>  Check out </h1>

                            <div className="checkout-container">
                                {
                                    this.state.showBillingPanel ? 
                                        <div className="checkout-billing">
                                        <h3>{ !this.state.checkDifferentShippingAddress ? "Billing and Shipping Address" : "Billing Address" }</h3>
                                        <div className="billing-details">  
                                            <div className="left-billing">
                                                <label className="lablel-co">
                                                    Company Name: 
                                                    <input type="text" value= {this.props.user.company} disabled/>
                                                </label>   

                                                <label className="lablel-co">
                                                    Country: 
                                                    <input type="text" value=  {this.props.user.billingAddress.country} disabled/>
                                                </label>   

                                                <label className="lablel-co">
                                                    City: 
                                                    <input type="text" value= {this.props.user.billingAddress.city} disabled/>
                                                </label>   
                                            </div>

                                            <div className="right-billing">
                                                <label className="lablel-co">
                                                    Postal Code: 
                                                    <input type="text" value= {this.props.user.billingAddress.postalCode} disabled/>
                                                </label>   

                                                <label className="lablel-co">
                                                    Address: 
                                                    <input type="text" value= {this.props.user.billingAddress.street} disabled/>
                                                </label>     
                                            </div>                                     

                                                                                                                            
                                        </div>
                                        {
                                            (this.props.user.deliveries != null && this.props.user.deliveries.length > 0 && !this.props.user.isDeliveryPointMandatory) ? 
                                            <Checkbox className="checkout-check" onChange={this.handleChangeShippingCheckBox}> Use a different shipping address </Checkbox>     
                                            :
                                            null
                                        }                                   
                                        {
                                            this.state.checkDifferentShippingAddress || this.props.user.isDeliveryPointMandatory ?
                                            <div className="checkout-step-cnt">
                                                <h3> Shipping Address</h3>                               

                                                <div className="content-co">
                                                    <p> Please select a shipping address: </p>                                                   
                                                        <select className="shipping-select" onChange={this.handleChangeSelectDelivery}>
                                                        <option key={-1} value={-1}> Select </option>
                                                        { 
                                                            this.props.user.deliveries.map( (item) => {
                                                                return <option value={item.Delivery_Point_Number__c}>
                                                                        { this.escape(item.Address__c)}
                                                                    </option>;
                                                            })
                                                        }                                
                                                        </select>
                                                        {
                                                        this.state.deliveryPointError ? 
                                                            <p className="required-field"> 
                                                                Field required 
                                                            </p>
                                                            :
                                                            null
                                                        }                                                    
                                                </div>                                    
                                            </div>    
                                            :
                                            null
                                        }                                  

                                        <div>
                                            <div className="note-container">
                                                <div className="note-content">
                                                    <p className="lablel-co">Note:</p>
                                                    <textarea class="m-b-30" maxLength="300" value={this.state.notes} onChange={this.handleChangeNotes}></textarea>
                                                </div>
                                                <div className="note-ws"></div>
                                            </div>
                                            <br/>
                                            <Checkbox  style={{float: 'left'}}  className="checkout-check" onChange={this.handlePrivacy}> I have read and agree with ZEISS </Checkbox>
                                            <a href={config.backend + "assets/Uploads/ZEISS_SalesCondition.pdf"} target="_blank" className="sales-anchor"> Sales Condition </a>
                                            {
                                            this.state.privacyError ? 
                                                <p className="required-field cb"> 
                                                    Field required 
                                                </p>
                                                :
                                                null
                                            }
                                            <br/>
                                            <h3 className="coupon-title"> Coupon </h3>
                                            <div className="note-container">
                                                <div className="note-content">
                                                    <label className="label-coupon">
                                                        Coupon code: 
                                                    </label>
                                                    <input type="text" className="coupon-input" value={this.state.coupon} onChange={this.handleChangeCouponCode}/>
                                                    {
                                                        this.state.coupon != '' ?
                                                        <div>
                                                            {
                                                                this.state.couponState != 'not-verified' ?
                                                                <div>
                                                                    {
                                                                        this.state.couponState == 'valid' ?
                                                                        <div className="verified-coupon">
                                                                            <Icon name="Done" size={1.5} style={{float: 'left'}} />
                                                                            <p> Valid coupon </p>
                                                                        </div>
                                                                        :
                                                                        <div className="invalid-coupon">
                                                                            <Icon name="Close" size={1.5}  style={{float: 'left'}}/>
                                                                            <p> Invalid coupon </p>
                                                                        </div>
                                                                    }
                                                                    
                                                                </div>
                                                                :
                                                                <Button  buttonStyle="secondary" style={{width: '140px', float: 'left', marginTop: 0, marginLeft: 50 }} onClick={this.verifyCoupon} > Verify coupon </Button> 
                                                            }                                                        
                                                        </div>
                                                        :
                                                        null
                                                    }                                                  
                                                    
                                                </div>
                                                <div className="note-ws"></div>
                                            </div>

                                            <div className="checkout-btn-container">
                                                <div className="back-shopping"  onClick={this.backToCart}> <span class="icon-direction-back m-r-5"> </span> Back </div>
                                                <Button disabled={this.isConfirmButtonDisabled()} buttonStyle="primary" icon="Check" style={{width: '150px', float: 'right' }} onClick={this.confirmBilling} > Confirm </Button> 
                                            </div>
                                        </div>                                    
                                        
                                    </div>    
                                    :
                                    <div className="checkout-summary-full cart-table-container">
                                        <h3> Order summary </h3>
                                        <Table
                                            className="cart-table" 
                                            syle= {{'max-width' : '1200px' }}
                                            data={this.props.storedCart} 
                                            columns={this.tableCheckOutColumns} 
                                            headerCellRenderer={this.headerCellRender}
                                            cellRenderer={this.cellCheckOutTableRender} 
                                            placeholder="No item in cart yet." 
                                            borderless>
                                        </Table>
                                        <div className="total-container">
                                            <div className="total-summary">
                                                <div className="clear">
                                                    <span> € {this.state.totalCheckOut.productsTotalNoVat} </span> <span>Products price: </span>
                                                </div>
                                                {
                                                    (this.state.totalCheckOut.discount.Type == 'Product' && this.state.totalCheckOut.discount.Value > 0) ?
                                                    <div className="clear">
                                                        <span> - €{this.state.totalCheckOut.discount.Value} </span> 
                                                        <span> Products discount: </span>                                                      
                                                    </div>
                                                    :
                                                    null
                                                }

                                                <div className="clear">
                                                    <span> € {this.state.totalCheckOut.shippingNoVAT} </span> <span> Shipping price: </span> 
                                                </div>
                                                <div className="clear">
                                                    <span> € {this.state.totalCheckOut.adminCosts} </span> <span> Administrative Costs: </span> 
                                                </div>
                                                {
                                                    (this.state.totalCheckOut.discount.Type == 'Delivery' && this.state.totalCheckOut.discount.Value > 0) ?
                                                    <div className="clear">
                                                        <span> - €{this.state.totalCheckOut.discount.Value} </span> 
                                                        <span> Shipping discount: </span>                                                      
                                                    </div>
                                                    :
                                                    null
                                                }   
                                                <div className="clear m-b-20">
                                                    <span className="final-subtotal-value"> € {this.state.totalCheckOut.subTotal} </span> <span className="final-subtotal-lbl">Subtotal: </span>
                                                </div>
                                                <div className="clear">
                                                    <span> € {this.state.totalCheckOut.vat} </span> <span> VAT: </span> 
                                                </div>
                                                <div className="clear">
                                                   <span className="vat-desc"> {this.state.totalCheckOut.VATDescription} </span> 
                                                </div>
                                                <div className="clear">
                                                    <span> € {this.state.totalCheckOut.extraVat} </span> <span> Stamp Tax: </span> 
                                                </div>
                                                                                       
                                                <div  className="clear">                                                
                                                    <span className="final-total-value"> € {this.state.totalCheckOut.finalTotal} </span> <span className="final-total-lbl ">Total: </span> 
                                                </div>
                                            </div>                                  
                                        </div>
        
                                        <div className="checkout-btn-container">
                                            <div className="back-shopping"  onClick={this.backToBilling}> <span class="icon-direction-back m-r-5"> </span> Back </div>
                                            <form method='POST' action={this.state.xPayParams.url}>
                                                {
                                                    Object.keys(this.state.xPayParams.requestParams).map((key) => {
                                                        return <input type='hidden' name={key} value={this.state.xPayParams.requestParams[key]} />
                                                    })
                                                }                    
                                                
                                                <input type="submit" value="Checkout" className="checkout-btn" />                                               
                                                
                                            </form> 
                                        </div>
                                 
                                    
                                    </div> 
                                }

                                
                            </div>
                                                                              
                    </div>
                    : 
                    <div class="cart-table-container">
                        <h1>  <span class="icon-online-shop m-r-5"></span>   Your shopping cart </h1>
                        <Table
                        className="cart-table" 
                        data={this.props.storedCart} 
                        columns={this.tableColumns} 
                        headerCellRenderer={this.headerCellRender}
                        cellRenderer={this.cellTableRender} 
                        footerCellRenderer={this.footerCellRenderer}
                        placeholder="No items in cart yet." 
                        borderless>
                        </Table>
                        
                            <div className="footer-checkout">
                                <div className="back-shopping" onClick={this.historyBackHandler}> <span class="icon-direction-back m-r-5"> </span>Continue Shopping </div>
                                {
                                   this.props.storedCart.length > 0 ?                             
                                    <Button  buttonStyle="primary"  icon="KeyboardArrowRight" style={{width: '220px', float: 'right' }} onClick={this.proceedOrder} > Go to the Checkout </Button> 
                                        :
                                    null                                                    
                                }
                            </div>                           
                        
                    </div>
                }

                {
                    this.state.isLoading ?      
                    <div>
                        <div className="black-layer">
                        </div>   
                        <Loader
                            type="TailSpin"
                            color="#008bd0"
                            height={100}
                            width={100}
                            timeout={1500000}                 
                            className="spinner"
                        />
                    </div>       
                      
                    :
                    null

                }

                {/* ecommerce suspended */}
                <Modal open={this.state.openShopClosedModal} onClose={this.handleCloseShopLocked.bind(this)}>
                    <ModalHeader title="E-shop is temporarily suspended"/>
                    <ModalBody>
                        The e-shop service is temporarily suspended. It will be available again on <b> {this.state.shopClosedEndDate} </b>.
                    </ModalBody>
                </Modal>
          
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        storedCart: state.eshop.cart,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditQuantity: (productId, quantity) => dispatch(actionCreators.editQuantity(productId, +quantity)),
        onRemoveFromCart: (productId, idzeiss) => dispatch(actionCreators.removeFromCart(productId, idzeiss))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);