import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { Table, IconLink, Button, notify, Icon, Pagination, SearchField } from 'precise-ui/dist/es6';
import axiosAPI from '../../../axiosAPI';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import './MyDocuments.css';
import 'jspdf-autotable';

class MyDocuments extends Component {
 
    
    imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; 
    pdfExtensions = ['pdf']; 
    archiveExtension = ['rar','zip','gz']; 
    imageIcon = "Page";
    pdfIcon = "PDFFile";
    archiveIcon = "Unarchive";
    typeFilterValues = ['image', 'archive', 'pdf', 'file'];

    state = {
        userPrivateDocuments: [],
        userCompanyDocuments: [] 
    }

    downloadedDocumentsModel = {...this.state};

    activeDocumentsFilters = {
        userPrivateDocuments: {
            name : false,
            lastModified : false,
            type : false
        },
        userCompanyDocuments: {
            name : false,
            lastModified : false,
            type : false
        }
    }
    
    documentsTableColumns = {
        name: { header: "Name", sortable: true },
        lastModified: { header: "Last Modified", sortable: true },
        type: { header: "Type", sortable: true },
        action: { header: "", sortable: false }
    }


    componentDidMount() {       
        this.getDocuments();
        window.scrollTo(0, 0);
    }

    factoryGetMyDocuments(companySapCode, userZeissID){
        

        if(companySapCode && userZeissID){
            return `/companies/${companySapCode}/users/${userZeissID}/documents`;

        }else if (companySapCode && !userZeissID){
            return `/companies/${companySapCode}/documents`;
        }else if(!companySapCode && userZeissID){
            return `/users/${userZeissID}/documents`;
        }else{
            throw new Error("Not implemented.")
        }

    }


    factoryDistributeDocuments(documentsList) {
        const companyDocumentKey = "Zeiss\\B2BPortal\\CompanyDocument";
        const userDocumentKey = "Zeiss\\B2BPortal\\UserDocument";

        const userCompanyDocuments = documentsList.filter(document => document.ClassName == companyDocumentKey);
        const userPrivateDocuments = documentsList.filter(document => document.ClassName == userDocumentKey);

        const formattedUserPrivateDocuments = this.formatDocumentsForTable(userPrivateDocuments);
        const formattedUserCompanyDocuments = this.formatDocumentsForTable(userCompanyDocuments);
        this.setState({ 
            userPrivateDocuments: formattedUserPrivateDocuments,
            userCompanyDocuments: formattedUserCompanyDocuments   
        });

        this.downloadedDocumentsModel = {...this.state};
 
    }

    formatData(dataString){
        if(!dataString || dataString == "") return null;

        return format(new Date(dataString), 'dd/MM/yyyy');
    }


    getFileIcon(fileName){

        const fileExtension = fileName.split('.').pop();
        
        if(this.imageExtensions.includes(fileExtension)){
            return this.imageIcon;
        }else if(this.pdfExtensions.includes(fileExtension)){
            return this.pdfIcon;
        }else if(this.archiveExtension.includes(fileExtension)){
            return this.archiveIcon;
        }else{
            return this.fileIcon;
        }
    }


    formatDocumentForTable(document){
        return {
            id: document.ID,
            fileID: document.File?.ID,
            name : document.File?.Name,
            lastModified : this.formatData(document.LastEdited),
            type : this.getFileIcon(document.File?.Name),
            action : "download",
        };
    }
   
    formatDocumentsForTable(documents){
        return documents.map(document => ( this.formatDocumentForTable(document) ) );
    }
  
    getDocuments() {
        if(!this.props.user) return;

        const companySapCode =  this.props.user.sapCode;
        const userZeissID = this.props.user.zeissId;

        const endPoint = this.factoryGetMyDocuments(companySapCode, userZeissID);

        axiosAPI.get(endPoint)
        .then(response => {
            if (response.status && response.status === 200) {
               this.factoryDistributeDocuments(response.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    downlaodDocument(downloadURL, filename){

    
        axiosAPI.get(
            downloadURL, {
            responseType: 'blob'
        })
        .then(response => {
            if (response.status && response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    cellUserDocumentTableRender = (cell) => {

        const data = cell.data;
        const documentID = data.id;
        const userZeissID = this.props.user.zeissId;
        const url = `/users/${userZeissID}/documents/${documentID}/file`;
        return this.cellDocumentTableRender(cell, data, url);
        

    }
    cellCompanyDocumentTableRender = (cell) => {

        const data = cell.data;
        const documentID = data.id;
        const companySapCode =  this.props.user.sapCode;
        const url = `/companies/${companySapCode}/documents/${documentID}/file`;
        return this.cellDocumentTableRender(cell, data, url);

    }

  
  
    cellDocumentTableRender = (cell, data, downloadURL) => {

        const key = cell.key;
        const value = cell.value;
        const filename = data.name;

        switch (key.toLowerCase()) {
            case 'action':
                return ( <div><u class="download-file-link" onClick={() => this.downlaodDocument(downloadURL, filename)} >Download</u></div>);
            case 'type':
                return ( <div><Icon name={value} size={1.5} /></div>);
            default:
                return value;
        }
    }

    getOrderStatus(status){
        switch (status) {
            case 'Paid':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
                );
            case 'ReceivedAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
            );
            case 'SentAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
            );
            case 'InOrderAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
            );
            case 'PaymentError':
                return (
                    <div>
                        <div className="circle-icon red">
                            <span class="icon-close-delete"></span>
                        </div>                        
                        <span> Payment error </span>
                    </div>
                    
                );
            case 'CanceledAS400':
                return (
                    <div>
                        <div className="circle-icon"> </div>
                        <span> Order Canceled </span>
                    </div>
                    
                );
            case 'DeliveredAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-position-map"></span>
                        </div>
                        <span> Delivered </span>
                    </div>
                    
                );
            case 'Sent':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Sent </span>
                    </div>
                );
            default:
        }
    }


    updateTableFilters(documentsTableToConsider, propertyName, filterObj ){

        const value = filterObj?.query.trim();
        this.activeDocumentsFilters[documentsTableToConsider][propertyName] =  (value && value != "") ? value : false;
        const result = this.applyFilters(documentsTableToConsider, this.activeDocumentsFilters);

        this.setState({ 
            [documentsTableToConsider] : result 
        });
    }

    filterModelByName(model, value){
        const filtered =  model.filter(elem => 
            elem.name.toLowerCase().includes(value.toLowerCase())
        );

        return filtered;
    }

    filterModelByLastModified(model, value){
        const filtered =  model.filter(elem => 
            elem.lastModified.includes(value)
        );

        return filtered;
    }

    filterModelByType(model, value){
       
        let mappedType = "";
        switch(value){
            case "image":
                mappedType = "Page";
                break;
            case "archive":
                mappedType = "Unarchive";
                break;
            case "pdf":
                mappedType = "PDFFile";
                break;
            case "file":
                mappedType = "Page";
                break;
            default:
                return model;
    
        }    
        
        const filtered =  model.filter(elem => 
            elem.type == mappedType
        );


        return filtered;
    }

    applyFilters(documentsTableToConsider, filters){

        let updatedModel = this.downloadedDocumentsModel[documentsTableToConsider];

        if(filters[documentsTableToConsider].name) updatedModel = this.filterModelByName(updatedModel, filters[documentsTableToConsider].name);
        if(filters[documentsTableToConsider].lastModified) updatedModel = this.filterModelByLastModified(updatedModel, filters[documentsTableToConsider].lastModified);
        if(filters[documentsTableToConsider].type) updatedModel = this.filterModelByType(updatedModel, filters[documentsTableToConsider].type);

        return updatedModel;

    }


    render() {
        return (
            <div >
                <div className="page-container">
                    <div className="header-gray-line"> </div>
                
                    <div className="personal-container">
                        <h2> Hello <span> {this.props.user.name + " " + this.props.user.surname} </span> </h2>

                        <div className="bg-light">
                            <div className="personal-block">
                                <div className="personal-block-left">
                                        <div className="personal-orders no-margin">
                                            <span class="table-section-title">
                                                <Icon  name='Description' size={1.5} ></Icon>
                                                <h3>  My documents </h3>
                                            </span>
                                            <div class="filters-row">
                                                <SearchField className="name-filter" label="Name" onSearch={valueObj => this.updateTableFilters("userPrivateDocuments", "name", valueObj )} />
                                                <SearchField className="last-modified-filter" label="Last Modified" onSearch={valueObj => this.updateTableFilters("userPrivateDocuments", "lastModified", valueObj )} />
                                                <SearchField className="type-filter" label="Type" suggestions={this.typeFilterValues} onSearch={valueObj => this.updateTableFilters("userPrivateDocuments", "type", valueObj )} />
                                            </div>
                                            <Table
                                                className="orders-table documents-table" 
                                                data={this.state.userPrivateDocuments} 
                                                columns={this.documentsTableColumns} 
                                                cellRenderer={this.cellUserDocumentTableRender}
                                                placeholder="No documents found."
                                                borderless
                                                bodyRenderer={paginate}
                                            >
                                            </Table>                          
                                        </div>
                                    </div>
                                    <div className="personal-block-right">
                                        <div className="personal-orders no-margin">
                                            <span class="table-section-title">
                                                <Icon  name='Description' size={1.5} ></Icon>
                                                <h3>  My company documents </h3>
                                            </span>
                                            <div class="filters-row">
                                            <SearchField className="name-filter" label="Name" onSearch={valueObj => this.updateTableFilters("userCompanyDocuments", "name", valueObj )} />
                                                <SearchField className="last-modified-filter" label="Last Modified" onSearch={valueObj => this.updateTableFilters("userCompanyDocuments", "lastModified", valueObj )} />
                                                <SearchField className="type-filter" label="Type" suggestions={this.typeFilterValues} onSearch={valueObj => this.updateTableFilters("userCompanyDocuments", "type", valueObj )} />
                                            </div>
                                            <Table
                                                className="orders-table documents-table" 
                                                data={this.state.userCompanyDocuments} 
                                                columns={this.documentsTableColumns} 
                                                cellRenderer={this.cellCompanyDocumentTableRender}
                                                placeholder="No company documents found."
                                                borderless
                                                bodyRenderer={paginate}
                                                >
                                            </Table>                          
                                        </div>
                                    </div>                         
                            </div>
                        </div>                   
                    </div>
                </div>
            </div>            
        );
    }
}

const paginationLimit = 5;
const paginate = ({ table, rows, props }) => <Pagination host={table} size={paginationLimit} {...props}>{rows}</Pagination>;

const mapStateToProps = state => {

    let user; 
    if(state.auth.user) {
        user = state.auth.user;
    }
    else {
        user = JSON.parse(localStorage.getItem('user'));
    }

    return {
        user: user
    };
}

export default withRouter(connect(mapStateToProps)(MyDocuments));