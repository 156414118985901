
import * as actionTypes from './actionTypes';

export const authSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user: user
    };
};

export const authChangePrivacy = (privacy) => {
    return {
        type: actionTypes.AUTH_CHANGE_PRIVACY,
        privacy: privacy
    }
}
