import React from 'react'
import './VendorNetworkCard.css'
import { Icon } from 'precise-ui/dist/es6'
import { Link } from 'react-router-dom';
import ExternalLinkVendor from '../ExternalLinkVendor/ExternalLinkVendor';

const vendorNetworkCard = props => {
    let companyTypes = null;
    let markets = null;
    let manufactoringServices = null;
    if (props.CompanyTypes && Array.isArray(props.CompanyTypes)) {
        props.CompanyTypes.map(item => companyTypes ? companyTypes += ", " + item.Name : companyTypes = item.Name);
    }
    if (props.Markets && Array.isArray(props.Markets)) {
        props.Markets.map(item => markets ? markets += ", " + item.Name : markets = item.Name);
    }
    if (props.ManufacturingCompanyServices && Array.isArray(props.ManufacturingCompanyServices)) {
        props.ManufacturingCompanyServices.map(item => manufactoringServices ? manufactoringServices += ", " + item.Name : manufactoringServices = item.Name);
    }

    return (
        <div className="vendor-network">
            <div class="summary-info main-info">
                <div className="section">
                    <p className="business-name">{props.BusinessName}</p>
                    <p>{props.Address}, {props.ZipCode} {props.City}, {props.Nation ? props.Nation.Name :  null}</p>
                    <br/>
                    {
                        props.Website ?
                        <p><ExternalLinkVendor link={props.Website}></ExternalLinkVendor></p>
                        : null
                    }
                </div>
            </div>
            <hr className="divider"/>
            <div class="summary-info" style={{flex: "1"}}>
                <div className="section">
                    <p><span className="text-bold">Company types</span>: {companyTypes ? companyTypes : "-"}</p>
                    <p><span className="text-bold">Markets</span>: {markets ? markets : "-"}</p>
                    {
                        props.CompanyTypes && props.CompanyTypes.findIndex(c => c.ID === 3) !== -1 ?
                        <div>
                            {
                                manufactoringServices ?
                                <p><span className="text-bold">Services</span>: {manufactoringServices}</p>
                                : null
                            }
                            {
                                props.YearlyProduction ?
                                <p><span className="text-bold">Yearly production</span>: {props.YearlyProduction.Name}</p>
                                : null
                            }
                            {
                                props.LensEdging ?
                                <p><span className="text-bold">Lens edging</span>: {props.LensEdging.Name}</p>
                                : null
                            }
                            {
                                props.EcologicalManufactoringProcess ?
                                <p><span className="text-bold">Ecological process</span>: {props.EcologicalManufactoringProcess}</p>
                                : null
                            }
                            <br/>
                            {
                                props.FrameMaterials ?
                                <table className="table-frame-material vendor-card">
                                    <thead>
                                        <tr>
                                            <th className="text-bold table-title">Frame materials</th>
                                            <th className="table-title">MOQ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.FrameMaterials.map(mat => (
                                                <tr>
                                                    <td className="table-header">{mat.Name}</td>
                                                    <td className="table-cell">{mat.MOQ}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                : null
                            }
                        </div>
                        : null
                    }
                </div>
            </div>
            {
                props.hideLearnMore ?
                null :
                <div>
                    <hr className="divider"/>
                    <div className="vendor-learn-more">
                        <p onClick={() => props.onLearnMore(props.ID)} className="learn-more">Learn more &#8594;</p>
                    </div>
                </div>
            }
        </div>
    );
}

export default vendorNetworkCard;