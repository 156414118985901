import React from 'react';
import { Modal, Button } from 'precise-ui/dist/es6';

const modalFullPrivilegeReminder = props => {
   
    return (
        <Modal open={props.open} onClose={() => props.close()} className="full-privilege-modal">
            <div className="fp-header">
                <h2> Do you want to see the whole range? </h2>
                <p> To see the entire product portfolio range, please fill in your full company information. </p>
                <Button buttonStyle="primary" style={{'margin-top': '20px' }} onClick={props.openModalFullPrivilege} > Register to access </Button> 
            </div>

            
        </Modal>
    );
};

export default modalFullPrivilegeReminder;