import React from 'react';

const LevelExtraText = (props) => {
    
    return (
        <div className="abs-text-container" dangerouslySetInnerHTML={{__html: props.HTML}} >                               
        </div>
    );
}

export default LevelExtraText;