import React, { Component } from 'react';
import './ProductCard.css';
import NumberFormat from 'react-number-format';
import imageNotFound from   '../../../../res/images/Image-not-found.jpg';
import { Button, Carousel } from 'precise-ui';
import cartIcon from '../../../../res/images/cart.png';
import stockIcon from '../../../../res/images/stock_icon.png';
import cartIconHover from '../../../../res/images/cart_hover.png';
import stockIconHover from '../../../../res/images/stock_icon_hover.png';
import { connect } from 'react-redux';

class ProductCard extends Component {
    state = {
        buttonCartIcon: cartIcon,
        buttonStockIcon: stockIcon
    }


    onStockButtonEnterHandler = e => {
        this.setState({...this.state, buttonStockIcon: stockIconHover});
    }

    onStockButtonLeaveHandler = e => {
        this.setState({...this.state, buttonStockIcon: stockIcon});
    }

    render() {
        return (
            <div className="product-single-block" >
                <div className="product-block-carousel"> 
                    <Carousel className="product-carousel" infinite>
                        {
                            this.props.product.Photos.length > 0 ?
                            (this.props.product.Photos.map((photo, i) => (
                                photo != null ?
                                <img key={i} src={photo} onError={(e)=>{e.target.onerror = null; e.target.src=imageNotFound}} alt="lente" />
                                : null
                            )))
                            : <img src={imageNotFound} alt="lente" />
                        }
                    </Carousel>
                </div>
                <div className="product-block-info" >
                    <div>
                        <p><span className="text-bold">Material</span>: {this.props.product.LensMaterial}</p>
                        <p><span className="text-bold">Size</span>: {this.props.product.Size}</p>
                        <p><span className="text-bold">Color</span>: {this.props.product.ColorCode}</p>
                        {
                            this.props.product.Coatings.length > 0 ?
                            <p><span className="text-bold">Coatings</span>: {this.props.product.Coatings.join(", ")}</p>
                            : null
                        }
                        {
                            this.props.type === 'Stock' ?
                            <p><span className="text-bold">Stock qty</span>: {this.props.product.StockQty}</p>
                            : null
                        }
                    </div>
                    {
                        !this.props.hidePrice ?
                        <div>
                            <NumberFormat 
                                className="product-price text-bold" 
                                value={this.props.product.Price} 
                                displayType={'text'} 
                                prefix={'€ '} 
                                decimalSeparator={'.'} 
                                decimalScale={2}
                                fixedDecimalScale={true} />
                            {
                                this.props.product.StockQty == 0 ?
                                <p className="product-not-available-alert text-bold">Item not available.</p>
                                : null
                            }
                        </div>
                        :
                        null
                    }
                    
                    <div>
                    <Button buttonStyle="primary" style={{width: '100%', marginTop: '10px',  marginBottom: '10px', height: '45px' }} onClick={ this.props.clicked }> FIND OUT MORE </Button> 
                    
                     {
                        !this.props.hideAddToCartButton ?
                        <div>
                            {
                                this.props.type == 'EShop' ?
                                    <Button 
                                            buttonStyle="secondary" style={{width: '100%', margin: 0, height: '45px'  }} 
                                            onClick={ this.props.cartClicked }
                                            disabled={this.props.product.StockQty <= 0 || this.props.inCart}
                                        >                    
                                        {
                                        this.props.inCart ?
                                        "ADDED TO CART"
                                        :
                                        "ADD TO CART "
                                        }                    
                                    </Button> 
                                :
                                   <Button 
                                            buttonStyle="secondary" style={{width: '100%', margin: 0, height: '45px'  }} 
                                            onClick={ this.props.cartClicked }
                                            disabled={this.props.product.StockQty <= 0 || this.props.inCart}
                                        >                    
                                        {
                                            this.props.inCart ?
                                            "ADDED TO INVENTORY CART"
                                            :
                                            "ADD TO INVENTORY CART"
                                        }                    
                                    </Button> 
                            }
                        </div>
                        :
                        null
                    }                  
                </div>
            </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        storedCart: state.eshop.cart
    };
}

export default connect(mapStateToProps)(ProductCard);