import React from 'react';
import './ConfiguratorMaterialResult.css';
import catalogueIcon from '../../res/images/catalogue-icon.png';
import productInfoIcon from '../../res/images/product-info-icon.png';
import vendorsIcon from '../../res/images/vendors-network-icon.png';

const ConfiguratorMaterialResult = (props) => {
  return (
    <div className={props.isSelected ? 'material-result-box open' : 'material-result-box'}>
      <div className='header' onClick={() => props.onSelect(props.material.ID)}>
        { props.material.Value }
      </div>
      <div className='body'>
        <div className='abs-text-container'>
          <div className="half-content">
            <img src={ props.material.LeftImage } />
          </div>
          <div className="half-content second-half" dangerouslySetInnerHTML={{__html: props.material.RightText}}>
          </div>
        </div>
        <div className="level-media-container configurator">
          { /* catalogue box */ }
          <div className="level-media-box" onClick={() => props.mediaClick('catalogue')}>
            <div className="level-icon-container">
              <img className="level-media-icon media-fix-height-conf" src={catalogueIcon} alt="video-icon" />
            </div>
            <div className="level-label-container">
              <div class="level-label"><span>See lenses</span></div>                        
            </div>
          </div>

          { /* product information box -> e-learning */ }
          {
            props.material.ELearningIntermediateLevelTitle != null || props.material.ELearningLevelTitle != null ?
              <div className="level-media-box" onClick={() => props.mediaClick('elearning')}>
                <div className="level-icon-container">
                  <img className="level-media-icon media-fix-height-conf" src={productInfoIcon} alt="video-icon" />
                </div>
                <div className="level-label-container">
                  <div class="level-label"><span>Product information</span></div>                        
                </div>
              </div>
            : 
              ''
          }

          { /* vendors network box */ }
          {
            props.isAccount ?
              <div className="level-media-box" onClick={() => props.mediaClick('vendors')}>
                <div className="level-icon-container">
                  <img className="level-media-icon media-fix-height-conf" src={vendorsIcon} alt="video-icon" />
                </div>
                <div className="level-label-container">
                  <div class="level-label"><span>Frame Factories</span></div>                        
                </div>
              </div>
            :
              ''
          }
        </div>
      </div>
      
    </div>
  );
};

export default ConfiguratorMaterialResult;