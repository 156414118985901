import React from 'react';
import './ELearningSearchField.css';

const ELearningSearchField = props => (
    <div className="search-button">
        <input className="el-search-input" placeholder="Search in e-learning section"  
            onChange={ (event) => props.change(event)} 
            onKeyDown={ (event) => props.keyDown(event)}>            
        </input>
        <span class="icon-search el-search-input-icon"></span>
    </div>
)

export default ELearningSearchField;