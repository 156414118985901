import React, { Component } from "react";
import { withRouter } from 'react-router';
import './CodeOfConduct.css';
import {config} from '../../../config';

class CodeOfConduct extends Component {

    render () {
        return (
            <div className="page-container cd-container">
                <div className="header-gray-line"> </div>
                <h1>Code Of Conduct </h1> 
                <div className="cd-content">
                    <p> Please click here to download our <b> Code Of Conduct: </b> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/Code-of-Conduct-2020_A5_EN_90_010_0018III.pdf"} target="_blank"> In English </a> </p>
                    <p> <a className="cd-link" href={config.backend +  "assets/Uploads/codice etico di gruppo Ott2020.pdf"} target="_blank"> In Italian </a> </p>
                </div>

                <h1>Organization Model </h1> 
                <div className="cd-content">
                    <p> Please click here to download our <a className="cd-link"href={config.backend +  "assets/Uploads/Carl Zeiss Vision Italia SpA - Modello Organizzativo ESTRATTO.pdf"} > Organization Model </a> </p>
                </div>
            </div>
        )
    }
}

export default withRouter(CodeOfConduct);