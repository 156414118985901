import React, {Component} from 'react';
import { connect } from 'react-redux';
import './Catalogue.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Cookies from 'universal-cookie';

import * as actions from '../../../store/actions/index';

import imageNotFound from   '../../../res/images/Image-not-found.jpg';
import check from   '../../../res/images/check.png';
import axios from '../../../axios';
import { withRouter } from "react-router-dom";
import { Icon, Sidebar, PaddedContainer, Carousel, Slider } from 'precise-ui';
import {config} from '../../../config';

const { Skeleton } = require('precise-ui');
const { Button } = require('precise-ui');

class Catalogue extends Component {

    timeout = null;   
    sliderFilterTimer = null;

    state = {
        selectedMaterial: {id: 0, value: ''},
        selectedItem : 0,
        materials: [],
        items : [], 
        filters : [],
        showMaterialSkeleton: true,
        showFiltersItemSkeleton: true,
        activeFilters: [],
        selectedItems: [],
        hiddenFilters: [],
        isScrolling: false,
        comingFromELearning: false
    }

    sortSelection = 0;

    componentDidMount () {
        if(this.props.isPageVisible) {
            this.props.onMountCheckFPCookie();
            if (!this.loadFromHistoryState()) {

                if(this.props.match.params.materialId){
                    let idMaterial = this.props.match.params.materialId;
                    let idCollection = this.props.match.params.collectionId ? this.props.match.params.collectionId : 0;
                    const queryParams = new URLSearchParams(this.props.location.search);
                    let prefilteredColorId = null;
                    let prefilteredColorCode = null;
                    let prefilteredThickness = null;
                    if(queryParams.size && queryParams.size == 3){
                        prefilteredColorId =queryParams.get("color") ?? null;
                        prefilteredColorCode =queryParams.get("code") ?? null;
                        prefilteredThickness =queryParams.get("thickness") ?? null;
                    }
                    this.manageMaterialSelected(idMaterial, idCollection, prefilteredColorId, prefilteredColorCode, prefilteredThickness);
                }
                else {
                    this.getMaterials();
                }               
            }

            window.addEventListener("scroll", this.onScroll);
            window.scrollTo(0, 0);
        }
        else {
            this.props.history.push("/home");
        }      
    }
       
    componentDidUpdate(prevProps) {
        if (prevProps.user?.catalogueRole != this.props.user?.catalogueRole) {
            this.getMaterials();
        }
		if (this.props.match.params.materialId !== prevProps.match.params.materialId) {
            if (this.props.match.params.materialId) {
                this.manageMaterialSelected(this.props.match.params.materialId, 0);
            } else {
                this.setState({
                    ...this.state,
                    selectedMaterial: {id: 0, value: ''},
                    selectedItem : 0,
                    materials: [],
                    items : [], 
                    filters : [],
                    showMaterialSkeleton: true,
                    showFiltersItemSkeleton: true,
                    activeFilters: [],
                    selectedItems: [],
                    hiddenFilters: [],
                    isScrolling: false,
                    comingFromELearning: false
                }, this.getMaterials)
            }
        }
    }

    manageMaterialSelected(idMaterial, idCollection, colorId = null, colorCode = null, thicknessId = null) {
        let data = {
            materialId: idMaterial
        }

        axios.post('/getMaterialById', JSON.stringify(data),{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if(response.data){
                let material = response.data;
                this.setState(() => ({
                    selectedMaterial: {id: material.ID, value: material.Value},
                    comingFromELearning: true
                }))
                
                this.getHomePagePromise(this.state.selectedMaterial.id).then(
                    bool => {

                        if(idCollection < 1 && ( colorId == null || colorCode == null || thicknessId == null ) ){
                            this.setState(() => ({
                                showFiltersItemSkeleton: false,
                            })) ;
                        }

                        if(idCollection > 0 ) {
                            let collectionFilter = this.state.filters.filter(e => e.FilterCategory === 'Collection')[0];
                            let collection = collectionFilter.Values.filter(e => e.ID === parseInt(idCollection))[0];
                            this.handleChangeFilter('Collection', collection.ID, collection.Value); 
                        }                        

                      
                        if(colorId != null && colorCode != null && thicknessId != null ) {
                            try{
                                this.handlePrefilteredPage(colorId, colorCode, thicknessId);
                            }catch{
                                window.location.href = window.location.pathname;
                            }
                        }                     
                           
                    }                   
                )
            }                        
        })
        .catch(err => {
            console.log(err);
        });
    }

    getMaterials() {
        let data = {
            role: this.props.user.catalogueRole
        }

        axios.post('/getMaterials', JSON.stringify(data),{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {

            if( response && response.data && typeof response.data.Values !== "undefined" ) {
                this.setState(() => ({
                    materials: response.data.Values,
                    showMaterialSkeleton: false
                }))
            }
        
        })
        .catch(err => {
            console.log(err);
        });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
        if (this.sliderFilterTimer) {
            clearTimeout(this.sliderFilterTimer)
        }
    }

    onScroll = () => {
        this.setState({ isScrolling: true });
    };

    loadFromHistoryState = () => {
        if (this.props.location.state != null && !!this.props.location.state.from && this.props.location.state.from.indexOf('/product') !== -1) {
            let prevState = this.props.location.state.cataloguePrevState ?? null;
            if (prevState!= null) {
                this.setState(prevState);
                return true;
            } else return false;
        } else {
            return false;
        }
    }

    handleChangeFilter(filterCategory, filterId, filterValue) {

        this.setState(() => ({
            ...this.state,
            showFiltersItemSkeleton: true
        }))

        this.sortSelection = this.sortSelection + 1;
        let fCategory = this.state.activeFilters.filter(e => e.category === filterCategory)[0];
        if (fCategory.category === 'VLT %') {
            if (fCategory.sortSelection === 999 && Array.isArray(fCategory.values) && fCategory.values.length > 0) {
                fCategory.sortSelection = this.sortSelection;
            }
            else { /* non deve fare niente */ }
        }
        else if (fCategory.category === 'Driving Suitability') {
            fCategory.sortSelection = this.sortSelection;
            let fValue = fCategory.values.filter(e => e.value === filterId);
            if (fValue.length > 0) {
                let index = fCategory.values.indexOf(fValue[0]);
                fCategory.values.splice(index, 1);
                if (filterId !== 'ISO_ANSI_ASNZS') {
                    let allStandardSelectedIndex = fCategory.values.findIndex(e => e.value === 'ISO_ANSI_ASNZS');
                    if (allStandardSelectedIndex !== -1) {
                        fCategory.values.splice(allStandardSelectedIndex, 1);
                    }
                }
            }
            else {
                fCategory.values.push({code: filterValue, value: filterId});
                if (filterId === 'ISO_ANSI_ASNZS') {
                    let drivingSuitabilityFilter = this.state.filters.find(f => f.FilterCategory === 'Driving Suitability');
                    if (!!drivingSuitabilityFilter && drivingSuitabilityFilter.Values.length > 0) {
                        let notSelectedSuitability = drivingSuitabilityFilter.Values.filter(v => !fCategory.values.find(fv => fv.value === v.ID) && v.ID !== filterId);
                        notSelectedSuitability.forEach(filterToAdd => fCategory.values.push({code: filterToAdd.Value, value: filterToAdd.ID}));
                    }
                }
            }
        }
        else if( fCategory.sortSelection === 999 ) {
            fCategory.sortSelection = this.sortSelection;
            fCategory.values.push({code: filterValue, value: filterId});
        }
        else if(fCategory.category === 'Coating Type' || fCategory.category === 'Coating Color') {
            let fValue = fCategory.values.filter(e => e.value === filterId);
            if(fValue.length > 0) {
                let index = fCategory.values.indexOf(fValue[0]);
                fCategory.values.splice(index, 1);
            }
            else {
                fCategory.values.push({code: filterValue, value: filterId});  
            }
        }
        else {
            fCategory.sortSelection = 999;
            fCategory.values.pop();
        }
        this.startFilterItems();
    }

    startFilterItems() {
        if(this.state.selectedItems.length > 0 ) {
            this.filterBySelectedItem(this.state.selectedItems);
        }
        else {
            this.getFiltersAndItems();
        }
    }

    getActiveFiltersCount() {
        let activeFiltersCount = 0;
        this.state.activeFilters.forEach(element => {
            if(element.values.length > 0 ){
                activeFiltersCount++;
            }
        });

        return activeFiltersCount;
    }

    
    getActiveFiltersAndSelectionCount() {
        let activeFiltersCount = this.getActiveFiltersCount();
        return activeFiltersCount + this.state.selectedItems.length;
    }
    

    getFiltersAndItems() {

       let activeFiltersCount = this.getActiveFiltersCount();

        if(activeFiltersCount === 0 ) {
            this.getHomePage(this.state.selectedMaterial.id);
        }
        else {
            let activeFilters = {
                filters: this.state.activeFilters,
                selectedMaterial: this.state.selectedMaterial.id,
                role: this.props.user.catalogueRole
            }

            axios.post('/getFiltersAndProduct', JSON.stringify(activeFilters),{
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                this.setState(() => ({
                    ...this.state,
                    items: response.data.Items,
                    filters: response.data.Filters,
                    showFiltersItemSkeleton: false
                }))
                window.scrollTo(0, 0);               
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    handleClickMaterial(material) {
        this.props.history.push("/catalogue/"+material.ID);
        /*this.setState(() => ({
            selectedMaterial: {id: material.ID, value: material.Value},
        }))
        
        this.getHomePage(material.ID);     */
       
    }


    getHomePage(materialID) {
    
        let materialId = {
            id: materialID,
            role: this.props.user.catalogueRole
        }

        axios.post('/getFiltersByMaterial', JSON.stringify(materialId),{
            headers: {
                'Content-Type': 'application/json',
            }
        }
        )
        .then(response => {

            let filters = response.data.Filters;
            let activeFilters = this.getActiveFilters(filters);

            this.setState(() => ({
                items: response.data.Items,
                filters: filters,
                showFiltersItemSkeleton: false,
                activeFilters: activeFilters,
                hiddenFilters: activeFilters.filter(f => f.category !== 'Lens Color').map(f => f.category)
            }));

            window.scrollTo(0, 0);
        })
        .catch(err => {
            console.log(err);
        });
    }

    getHomePagePromise(materialID) {
    
        return new Promise((resolve, reject) => {
            let materialId = {
                id: materialID,
                role: this.props.user.catalogueRole
            }

            axios.post('/getFiltersByMaterial', JSON.stringify(materialId),{
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            )
            .then(response => {

                let filters = response.data.Filters;
                let activeFilters = this.getActiveFilters(filters);

                this.setState(() => ({
                    items: response.data.Items,
                    filters: filters,
                    activeFilters: activeFilters,
                    hiddenFilters: activeFilters.filter(f => f.category !== 'Lens Color').map(f => f.category)
                }));
                window.scrollTo(0, 0);
                resolve('ok');
            })
            .catch(err => {
                console.log(err);
                resolve(err);
            });
        });
    }

    getActiveFilters(filters) {
        let arr = [];
        filters.forEach(element => {
            let filterCatObj = { category: element.FilterCategory, values: [], sortSelection: 999};
            arr.push(filterCatObj);
        });
        return arr;
    }

    checkedFilter(item, filtrValue){
        let filterValues = this.state.activeFilters.filter(e => e.category === item.FilterCategory)[0];
        if(filterValues.values.length > 0 ) {
            return filterValues.values.find((f) => f.value === filtrValue)
        }
        return false;
    }

    isHiddenFilter(item){
        let hiddenFilters = this.state.hiddenFilters;
        let hiddenFilterIndex = hiddenFilters.findIndex(e => e === item.FilterCategory);
        if(hiddenFilterIndex >= 0 ) {
            return true;
        }
        else {
            return false;
        }
    }

    handleHideFilter(item){
        let hiddenFilters = this.state.hiddenFilters;
        let hiddenFilterIndex = hiddenFilters.findIndex(e => e === item.FilterCategory);
        if(hiddenFilterIndex >= 0 ) {
            hiddenFilters.splice(hiddenFilterIndex, 1);
        }
        else {
            hiddenFilters.push(item.FilterCategory);
        }

        this.setState(() => ({
            hiddenFilters: hiddenFilters
        }))
    }

    changeSliderHandler = (filterCategory, event) => {
        let {value, index} = event;
        let activeFilterIndex = this.state.activeFilters.findIndex(f => f.category === filterCategory);
        let activeFilters = [...this.state.activeFilters];

        if (activeFilterIndex > -1) {
            if(!Array.isArray(activeFilters[activeFilterIndex].values)) {
                activeFilters[activeFilterIndex].values = [];
            }

            if (index === 0) {
                if (!activeFilters[activeFilterIndex].values[1]) {
                    activeFilters[activeFilterIndex].values[1] = {
                        code: 100,
                        value: 1
                    };
                }
            } else if (index === 1) {
                if (!activeFilters[activeFilterIndex].values[0]) {
                    activeFilters[activeFilterIndex].values[0] = {
                        code: 0,
                        value: 0
                    };
                }
            }

            if (!activeFilters[activeFilterIndex].values[index]) {
                activeFilters[activeFilterIndex].values[index] = {
                    code: 0,
                    value: 0
                };
            }

            value = parseFloat((Math.round(value*100)/100).toFixed(2));

            activeFilters[activeFilterIndex].values[index].code = value;
            activeFilters[activeFilterIndex].values[index].value = value/100;
            this.setState({
                ...this.state,
                activeFilters
            });
        }

        if (this.sliderFilterTimer) {
            clearTimeout(this.sliderFilterTimer);
        }
        this.sliderFilterTimer = setTimeout(() => this.handleChangeFilter(filterCategory, index, value), 1200);
    }

    renderFilters(item) {
        if ( item.FilterCategory === 'Lens Color') {
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container-color' : 'filter-item-container-color'}>
            {
                item.Values.map((filterItem) => (
                    <div className="lens-color-container tooltip-on-hover" >
                        <div className="filter-item filter-item-color " key={filterItem.ID} style={{ backgroundColor: filterItem.Value }} onClick={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Extra)}>
                            {
                                this.checkedFilter(item, filterItem.ID) ?
                                <img src={check} className="selected-color-filter"/>
                                :
                                null
                            }
                        </div>       
                        <div className="tooltip">{filterItem.Extra} </div>                                
                    </div>
                ))   
            }
            </div>  
        }
        else if( item.FilterCategory === 'Coating Color') {
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container-color' : 'filter-item-container-color'}>
            {
                    item.Values.map((filterItem) => (
                        <div className="lens-color-container tooltip-on-hover" >
                            <div className="filter-item f-item-coating-color" key={filterItem.ID} 
                                style={{ background: "linear-gradient(90deg, " + filterItem.Extra.PrimaryHexCode + " 25%, " + filterItem.Extra.SecondaryHexCode + " 50%, " + filterItem.Extra.PrimaryHexCode + " 75%"}} onClick={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Value)}>
                            {
                                this.checkedFilter(item, filterItem.ID) ?
                                <img src={check} className="selected-color-filter"/>
                                :
                                null
                            }                            
                            </div>
                            <div className="tooltip">{filterItem.Value} </div>         
                        </div>
                    ))   
            }
            </div>  
        }
        else if (item.FilterCategory === 'VLT %') {
            let activeVltFilter = this.state.activeFilters.find(f => f.category === 'VLT %');
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container-color slider-item' : 'filter-item-container-color slider-item'}>
                <Slider value={[(+activeVltFilter?.values[0]?.code || 0), (+activeVltFilter?.values[1]?.code || 100)]} minimum={0} maximum={100} step={0.01} showTooltip onChange={(event) => this.changeSliderHandler(item.FilterCategory, event)}></Slider>
                <div className="slider-minmax">
                    <span>0</span>
                    <span>100</span>
                </div>
            </div>
        }
        else {
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container' : 'filter-item-container'}>
            {
                    item.Values.map((filterItem) => (
                        <div className="filter-item" key={filterItem.ID}>
                            <label className="filter-checkbox-label">
                                <input type="checkbox" value={filterItem.ID} checked={this.checkedFilter(item, filterItem.ID)}  onChange={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Value)} name="produktart" className="filter-checkbox" />
                                <span className="btn-checkbox-style">&nbsp;</span>
                                <span className="checkbox-label">{filterItem.Value} </span>
                                
                            </label>
                        </div>   
                    ))   
            }
            </div>  
        }   
        
    }

    renderActiveSeclections() {
        return <div> 
            {
                this.state.selectedItems.map((selectedItem) => (                 
                    <p className="selected-filter-block" onClick={ () => this.removeSelectedItem(selectedItem)}>
                        {selectedItem.type}: {selectedItem.code}
                    </p>                  
                ))

            }
            </div>  
    }

    renderActiveFilters() {
        return <div> 
            {
                this.state.activeFilters.map((activeFilter) => (          
                    activeFilter.sortSelection != 999 ?
                        activeFilter.category !== 'VLT %' ?
                            activeFilter.values.map((activeFilterItem) => ( 
                                <p key={"active_filter"+activeFilter.category} className="selected-filter-block" onClick={ () => this.removeSelectedFilter(activeFilter, activeFilterItem)}>
                                    {activeFilter.category}: {activeFilterItem.code}
                                </p>
                            ))
                        :
                            <p key={"active_filter"+activeFilter.category} className="selected-filter-block" onClick={ () => this.removeSelectedSliderFilter(activeFilter)}>
                                {activeFilter.category}: 
                                {
                                    activeFilter.values.map((activeFilterItem, index) => (
                                        index === 0 ?
                                            " from " + activeFilterItem.code
                                        :
                                            " to " + activeFilterItem.code
                                    ))
                                }
                            </p>
                    :
                    null
                ))

            }
            </div>
    }

    renderRemoveAllFilters() {
        return  this.getActiveFiltersAndSelectionCount() > 1 ?
            <div class="clear-filters">
                <Button icon="Close" buttonStyle="secondary" style={{width: '180px' }}  onClick={ () => { this.handleClickRemoveAllFilters() }} > Clear all filters </Button>
            </div>               
            :
            <div></div>            
    }

    handleClickRemoveAllFilters() {
        this.setState(() => ({
            ...this.state,
            showFiltersItemSkeleton: true
        }))

        let arr = [];
        this.state.filters.forEach(element => {
            let filterCatObj = { category: element.FilterCategory, values: [], sortSelection: 999};
            arr.push(filterCatObj);
        });

        this.setState(() => ({
            activeFilters: arr,
            selectedItems: []
        }), this.getHomePage(this.state.selectedMaterial.id))              
    }

    removeSelectedFilter (activeFilter, activeFilterItem) {
        this.handleChangeFilter(activeFilter.category,activeFilterItem.value, activeFilterItem.code);       
    }

    removeSelectedSliderFilter (activeFilter) {
        let fCategory = this.state.activeFilters.find(e => e.category === activeFilter.category);
        if(fCategory) {
            fCategory.sortSelection = 999;
            fCategory.values = [];
        }
        this.handleChangeFilter(activeFilter.category, null, null);
    }

    removeSelectedItem(selectedItem) {
        let selectedItems = this.state.selectedItems;
        selectedItems = selectedItems.filter(function( obj ) {
            return obj !== selectedItem;
        });
        
        this.setState(() => ({
            ...this.state,
            showFiltersItemSkeleton: true,
            selectedItems: selectedItems
        }))

        if (selectedItems.length > 0) {
            this.filterBySelectedItem(selectedItems);  
        }
        else {
            this.getFiltersAndItems();
        }                  
    }

    handleSelectProduct(productId) {
        const currentLocation = this.props.history.location;
        this.props.history.replace(currentLocation.pathname, {...currentLocation.state, from: `/product/${productId}`, cataloguePrevState: {...this.state}});
        this.props.history.push("/product/"+ productId, { from: this.props.location.pathname, catalogueState: {...this.state} });
    }

    handleBackMaterial() {
        this.setState(() => ({
            selectedMaterial: {id: 0, value: ''},
            selectedItem : 0,
            items : [], 
            filters : [],
            showFiltersItemSkeleton: true,
            activeFilters: [],
            selectedItems: [],
            hiddenFilters: [],
            showFiltersItemSkeleton: true
        })) 

        if(this.state.comingFromELearning) {
            this.props.history.push("/catalogue/");    
            window.location.reload();         
        }        
    }

    handleClickProductBlock(subitem) {
        let selectedItem = [{ id: subitem.ID, type: subitem.Type, code: subitem.Value }];

        this.setState(() => ({
            ...this.state,
            showFiltersItemSkeleton: true,
            selectedItems: selectedItem
        }))

        this.filterBySelectedItem(selectedItem);        
    }

    filterBySelectedItem(selectedItem) {

        let activeFilters = {
            filters: this.state.activeFilters,
            selectedMaterial: this.state.selectedMaterial.id,
            selectedItems: selectedItem,
            role: this.props.user.catalogueRole
        }

        axios.post('/getFiltersBySelectedItem', JSON.stringify(activeFilters),{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            this.setState(() => ({
                ...this.state,
                items: response.data.Items,
                filters: response.data.Filters,
                showFiltersItemSkeleton: false
            }))

            
            window.scrollTo(0, 0);
        })
        .catch(err => {
            console.log(err);
        });
    }

    handleClickProductBlockSecondLevel(subitem) {
        let selectedItem = [];
        subitem.ItemValues.forEach(element => {
            selectedItem.push({ id: element.ID, type: element.Type, code: element.Code })
        });

        this.setState(() => ({
            ...this.state,
            showFiltersItemSkeleton: true,
            selectedItems: selectedItem
        }))

        this.filterBySelectedItem(selectedItem);       
    }

    handleClickSeeMore(item){

        let request = {
            category: item.Title,
            items: item.Values,
            selectedMaterial: this.state.selectedMaterial.id,
            role: this.props.user.catalogueRole
        }

        axios.post('/getMoreHomeCatalogue', JSON.stringify(request),{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            let index;

            if(item.Title == 'Solid Tint') {
                index = 0;
            }
            else if(item.Title == 'Gradient') {
                index = 1;
            }
            else if(item.Title == 'Double Gradient') {
                index = 2;
            }
            else if(item.Title == 'Coatings') {
                index = 3;
            }


            let items = this.state.items;
            items.Items[index].ShowSeeMore = response.data.ShowSeeMore;
            items.Items[index].Values = items.Items[index].Values.concat(response.data.Values);

            this.setState(() => ({
                items: items
            }))    
         
        })
        .catch(err => {
            console.log(err);
        });
    }

    renderSkeletonMaterial() {
        return <div className="skeleton-material">
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />

            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />

            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />

            
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
        </div>
    }

    renderSkeletonFilters() {
        return <div className="skeleton-catalogue">
            <div className="skeleton-filters">
                <div className="filter-block">
                    <p className="filter-title"> Lens Color </p>
                    <div className="sk-filter-cat-color">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />                   
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                    </div>                     
                </div>  
                <div className="filter-block">
                    <p className="filter-title"> Color Type </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>                     
                </div>        
                <div className="filter-block">
                    <p className="filter-title"> Filter Category </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>    
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>                     
                </div>                
            </div>
           
        </div>
    }

    renderSkeletonItems() {
        return <div className="skeleton-items"> 
            <div className="products-container">
                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>


                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>


                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">             
                    <div className="product-block-image"> 
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>
            </div>
        </div>
    }

    renderProductSecondLevel(subitem) {

        return (
            <div className="catalogue-product-container">
                <div className="catalogue-product-details">
                    {
                        subitem.ItemValues.map((item) => {
                            if(item.ID > 0) {
                                return (
                                    <div className="detail">
                                        <div className="img-container">
                                            <img src={config.assets + "" + item.Photo} onError={(e)=>{e.target.onerror = null; e.target.src=imageNotFound}}></img>
                                        </div>
                                        <div className="product-block-details">
                                            {item.Type}
                                        </div>
                                        <div className="product-block-extra-details black-block-labels">
                                            {item.Code}
                                        </div>
                                    </div>
                                );
                            }                    
                        })                    
                    }
                </div>
                {
                    this.state.items.ShowSelectButton ?
                    <div className="select-block " onClick={ () => this.handleSelectProduct(subitem.ZeissProductID)}>
                        <p className="select-text"> Select </p>
                    </div>
                    :
                    null
                }
            </div>
        );
    }

    checkIfMoreThanOneIsNotNull(values){
        let i = 0;
        values.forEach(element => {
            if(element.ID > 0 ) {
                i++;
            }
        });

        if( i > 1 ) {
            return true;
        }
        else {
            return false;
        }

    }


    renderProductThirdLevel(subitem) {
        let itemDetails = [];
        subitem.ItemValues.forEach(itemValues => {
            itemValues.Values.forEach(val => {
                let typeValues = itemDetails.find(i => i.Type === val.Type);
                let detailIdx = itemDetails.indexOf(typeValues);
                if (typeValues && detailIdx !== -1) {
                    if(val.ID > 0) {
                        typeValues = {
                            ...typeValues,
                            Photo: [
                                ...typeValues.Photo,
                                {ID: val.ID, Photo: val.Photo}
                            ],
                            Code: [
                                ...typeValues.Code,
                                {ID: val.ID, Code: val.Code}
                            ]
                        };
                        itemDetails[detailIdx] = typeValues;
                    }                   
                } else {
                    if(val.ID > 0) {
                        itemDetails.push({
                            Type: val.Type,
                            Photo: [{ID: val.ID, Photo: val.Photo}],
                            Code: [{ID: val.ID, Code: val.Code}]
                        });
                    }
                }
            });
        });



        return (
            <div className="catalogue-product-container">
                <div className="catalogue-product-details">
                    {
                        itemDetails.map((detail) => {
                            return (

                                <div className="detail">
                                    <div className="img-container">
                                        {
                                            this.checkIfMoreThanOneIsNotNull(detail.Photo) ? 
                                            <Carousel >
                                                {                                
                                                    detail.Photo
                                                    .map((item, i) => (  
                                                        item.ID > 0 ?
                                                        <img key={i} src={item.Photo != null ? config.assets + "" +  item.Photo : imageNotFound} onError={(e)=>{e.target.onerror = null; e.target.src=imageNotFound}} alt="lente" />       
                                                        :
                                                        null
                                                    ))                           
                                                }
                                            </Carousel>
                                            :
                                            <div>
                                                <img src={config.assets + "" + detail.Photo[0].Photo} onError={(e)=>{e.target.onerror = null; e.target.src=imageNotFound}}></img>
                                                <div style={{width: '100%', height: '1.25rem'}}></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="product-block-details">
                                        {detail.Type}
                                    </div>
                                    <div className="product-block-extra-details black-block-labels">
                                    {
                                        detail.Code.map((value) => {
                                            return <p>{value.Code}</p>
                                        })  
                                    }
                                    </div>
                                </div>
                            );                  
                        })                    
                    }
                </div>
                {
                    this.state.items.ShowSelectButton ?
                    <div className="select-block " onClick={ () => this.handleSelectProduct(subitem.ZeissProductID)}>
                        <p className="select-text"> Select </p>
                    </div>
                    :
                    null
                }
            </div>
        );
    }

    renderItems() {
        let containerClassList = ["products-container"];
        if (this.state.items.FilterLevel === 3) {
            containerClassList.push("products-container-third-level");
        } else {
            containerClassList.push("products-container-second-level");
        }
        return <div>
                {(this.state.items.FilterLevel && this.state.items.FilterLevel > 1) ?
                               
                    <div>
                    {
                        this.state.items.Items && this.state.items.Items.length > 0 ?
                            this.state.items.Items && this.state.items.Items.map((item) => (
                                <div key={item.ID} className="catalogue-right-container"> 
                                    <div className={containerClassList.join(' ')}>
                                        {item.Values.map((subitem) => (
                                            this.state.items.FilterLevel === 3 ?
                                            <div key={subitem.ID}  className={`product-block-second-level ${this.state.items.ShowSelectButton ? "no-pointer" : ""}`}> 
                                                {this.renderProductThirdLevel(subitem)}
                                            </div>
                                            :
                                            <div key={subitem.ID}  className={`product-block-second-level ${this.state.items.ShowSelectButton ? "no-pointer" : ""}`} onClick={ () => { this.handleClickProductBlockSecondLevel(subitem)}}> 
                                                {this.renderProductSecondLevel(subitem)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))
                        : <p>No products found with this filters.</p>
                    }
                    </div>
                :
                <div>
                    {this.state.items.Items && this.state.items.Items.map((item) => (
                        item.Values.length > 0 ?
                        <div key={item.ID} className="catalogue-right-container"> 
                            <h3 className="group-title"> {item.Title} </h3>
                            <div className="products-container">
                                {item.Values.map((subitem) => (
                                    <div className="product-single-block" onClick={ () => { this.handleClickProductBlock(subitem) }}>
                                        <div key={subitem.ID} className="product-block-image"> 
                                            <img src={config.assets + "" + subitem.Photo} onError={(e)=>{e.target.onerror = null; e.target.src=imageNotFound}} />
                                        </div>
                
                                        <div className="product-block-details">
                                            {subitem.Value}
                                        </div>
                                    </div>
                                ))}
                                {
                                    (this.getActiveFiltersCount() == 0 && item.ShowSeeMore)  ?                                
                                    <div className="see-more-container">
                                        <Button className="see-more-button" buttonStyle="primary" style={{width: '120px' }}  onClick={ () => { this.handleClickSeeMore(item) }} >See more </Button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>                                    
                        :
                        null
                    ))}
                </div>
            }
            </div>
    }

    renderFilterMenu = displayClasses => {
        const classes = "filter-menu " + displayClasses;
        return (
            <div className={classes}>
                {
                    this.state.showFiltersItemSkeleton ?
                        this.renderSkeletonFilters()
                    :
                    <div>
                        {
                            this.state.filters.map((item) => (
                                item.Values.length > 0 || item.FilterCategory === 'VLT %' ?
                                <div className={item.FilterCategory === 'Collection' ? "filter-block filter-inverted" : "filter-block" } >
                                    <p className={this.isHiddenFilter(item) ? ' filter-title hidden-filter' : 'filter-title'} onClick={ () => { this.handleHideFilter(item) }}> 
                                        {
                                            item.FilterCategory === 'Collection' ? 
                                            <span class="icon-highlights-specials collection-star"></span>
                                            : null
                                        }
                                        {item.FilterCategory}
                                    </p>
                                    {
                                        this.renderFilters(item)
                                    }
                                </div>
                                :
                                null
                            ))
                        }
                    </div>
                   
                }                             
            </div>
        );
    }

    renderSidebar = () => (
        <Sidebar 
            position="left"
            activator=
                {
                    <div class="d-block d-md-none filter-sidebar-activator">
                        Filters ({this.state.selectedItems.length + this.getActiveFiltersCount()})
                    </div>
                }
        >
            <PaddedContainer gutter="small">
                <div style={{marginTop: '15px'}}>
                    <div>
                        {this.renderActiveSeclections()}
                        {this.renderActiveFilters()}
                    </div>
                    {this.renderFilterMenu()}
                </div>
            </PaddedContainer>
        </Sidebar>
    );

    renderMobileBackButton = () => (
        <div class="back-material-button" onClick={ () => this.handleBackMaterial()}>
            Back to Material
        </div>
    );

    backToTop = () => {
        window.scrollTo(0, 0);

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.setState({ isScrolling: false });
        }, 300);        
    }
    
    render () {
    
        return (
            <div className="page-container"> 
            	
                <div className="header-gray-line"> </div>
                {
                    this.state.selectedMaterial.id == 0 ?
                
                        <div className="catalogue-material-content">
                            {
                                this.props.user.accountType == 'Contact' && !this.props.user.fullPrivilegeRejected ?
                                <div className="banner-full" onClick={() => {  this.props.toggleFullPrivilegeModal() }} >
                                    <span class="icon-hint-warning m-r-10"></span>
                                    <span>To access the full product portfolio, please fill in your company information.</span>    
                                    <Icon name="KeyboardArrowRight" size={2} />
                                </div>
                                :
                                null
                            }
                          
                            <h2> Product Portfolio </h2>
                            <h3 className="intro-materials"> Please select a material </h3>
                            <div className="material-container">
                                {
                                    this.state.showMaterialSkeleton ?
                                        this.renderSkeletonMaterial()
                                    :
                                    this.state.materials.map((material, index) => (

                                        <div key={material.ID} className="product-card pc-small" onClick={ () => { this.handleClickMaterial(material) }}>
                                            <div className="box" >
                                                <div className="box-content-material">
                                                    <div className="material-box-name">
                                                        <p> {material.Value} </p>    
                                                    </div>
                                                    <div className="material-desc">
                                                        <ul className="material-desc-list">
                                                        {
                                                            material.ExtDescription.map( (materialDesc) => (
                                                                <li>
                                                                    {materialDesc}
                                                                </li>
                                                            ))
                                                        }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                : 
                    <div className="catalogue-container">
                        {
                            this.state.isScrolling ?
                            <div className="arrow-up" onClick={ () => this.backToTop()}>
                                <Icon name='KeyboardArrowUp' style={{color: '#fff'}}  size={3} />
                            </div>
                            :
                            null
                        }                       
                        {this.renderFilterMenu('d-none d-md-flex')}
                        <div>  
                            <div className="catalogue-content">
                                <div className="d-block d-md-none">
                                    {this.renderMobileBackButton()}
                                </div>
                                {this.renderSidebar()}
                                <div className="d-none d-md-block">
                                    <div className="back-material-button back-material-button-full" onClick={ () => this.handleBackMaterial()}>Back to Materials </div>
                                    <div className="header-catalogue">
                                        <div class="selected-material">
                                            Selected Material: <span> {this.state.selectedMaterial.value} </span>
                                        </div> 
                                    </div>
                                   {this.renderRemoveAllFilters()}
                                    <div className="d-flex">
                                        {this.renderActiveSeclections()}
                                        {this.renderActiveFilters()}                                    
                                    </div>
                                </div>                              
                                <div className="product-list">
                                    { this.state.showFiltersItemSkeleton ?
                                            this.renderSkeletonItems()
                                        :                                               
                                            this.renderItems()
                                    }                                        
                                </div>
                            </div>                        
                        </div>
                    </div>
                }
            
            </div>
        )
    }


    
    handlePrefilteredPage(colorId, colorCode, thicknessId){

        let fakeColorSubitem = { ID: colorId, Type: "Color", Value: colorCode };
        let selectedItem = [{ id: fakeColorSubitem.ID, type: fakeColorSubitem.Type, code: fakeColorSubitem.Value }];

        this.setState(() => ({
            ...this.state,
            showFiltersItemSkeleton: true,
            selectedItems: selectedItem
        }));

        let activeFilters = {
            filters: this.state.activeFilters,
            selectedMaterial: this.state.selectedMaterial.id,
            selectedItems: selectedItem,
            role: this.props.user.catalogueRole
        }

        axios.post('/getFiltersBySelectedItem', JSON.stringify(activeFilters),{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            this.setState(() => ({
                ...this.state,
                items: response.data.Items,
                filters: response.data.Filters
            }));

            let thicknessFilter = this.state.filters.filter(e => e.FilterCategory === 'Thickness')[0];
            let thickness = thicknessFilter.Values.filter(e => e.ID === parseInt(thicknessId))[0];

            this.handleChangeFilter('Thickness', thickness.ID, thickness.Value);    
            
            window.scrollTo(0, 0);
        })
        .catch(err => {
            console.log(err);
            window.location.href = window.location.pathname;
        });

    }
}

const mapStateToProps = state => {

    let user; 
    if(state.auth.user) {
        user = state.auth.user;
    }
    else {
        user = JSON.parse(localStorage.getItem('user'));        
    }

    return {
        isPageVisible: checkPageVisibilites(user),
        user: user
    };


    function checkPageVisibilites(user) {
        if(user) {
            if(user.accountType == 'Contact' ) {
                return true;
            }
            else if(user.modulesEnabled == null) {
                return false;
            }
            else {
                let modules = user.modulesEnabled.split(';');
                if(modules.includes("Catalogue_Full")) {
                    return true;
                }
                else if(modules.includes("Catalogue_BMQ")) {
                    return true;
                }
                else {
                    return false;
                }
            }       
        }
        else
            return false;        
    }
    
}


const mapDispatchToProps = dispatch => {
    return {
        getCatalogue: () => dispatch( actions.getCatalogue()),
        toggleFullPrivilegeModal: () => dispatch(actions.toggleFullPrivilegeModal())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Catalogue));