import React, {Component} from 'react';

import './NavigationHeader.css';
import { connect } from 'react-redux';
import NavItem from '../NavItem/NavItem';
import { withRouter } from 'react-router';
import CartHeader from './CartHeader/CartHeader';
import StockHeader from './StockHeader/StockHeader';


class NavigationHeader extends Component {


    showCart = () => {
        if(this.props.user != null) {
            if(this.props.user.accountType == 'Account') {
                if(this.props.user.modulesEnabled == null) {
                    return false;
                }
                else {
                    let modules = this.props.user.modulesEnabled.split(';');
                    if(modules.includes("E-commerce_Full")) {
                        return true;
                    }
                    else if(modules.includes("E-commerce_Promo")) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
            else{
                return false;
            }
        }
        else {
            return false;
        }
    }

    showInventoryCart = () => {
        if(this.props.user != null) {
            if(this.props.user.accountType == 'Account') {
                if(this.props.user.stockModuleVisible){
                    return true;
                }
                return false;
            }
            else{
                return false;
            }
        }
        else {
            return false;
        }
    }


    checkoutClickHandler = () => {
        let routeState = null;
        if (this.props.location.pathname.indexOf("/e-shop/products") !== -1) {
            routeState = { from: this.props.location.pathname };
        }
        this.props.history.push("/cart", routeState);
    }

    checkoutInventoryClickHandler = () => {
        let routeState = null;
        routeState = { from: this.props.location.pathname };
        this.props.history.push("/inventory-cart", routeState);
    }

    renderMenu = () =>  {
        let menuItems = [];

        if(this.props.menuItems.length > 0) {
            if(this.props.user !== null) {
                if(this.props.user.accountType == 'Contact') {
                    this.props.menuItems.map(menuItem => {
                        if(menuItem.visibility == 'Contact') {
                            menuItems.push(menuItem);
                        }

                    })
                }
                else if(this.props.user.accountType == 'Account' || this.props.user.accountType == 'Advanced') {
                    this.props.menuItems.map(menuItem => {
                        if((menuItem.visibility == 'Contact' && menuItem.URL != '/leads-e-learning') || menuItem.visibility == 'Account') {
                            if( menuItem.URL == '/vendors-network' && (this.props.user.vendorVisible) ) {
                                menuItems.push(menuItem);
                            }
                            else if(menuItem.URL == '/pricelist' && this.props.user.priceListVisible) {
                                menuItems.push(menuItem);
                            }
							else if( menuItem.URL == '/stock' && (this.props.user.stockModuleVisible)) {
								menuItems.push(menuItem);
							}
                            else if (menuItem.URL == '/e-shop' && this.props.user.accountType == 'Account') {
                                menuItems.push(menuItem);
                            }
                            else if (menuItem.URL === '/leadtime' && this.props.user.leadTimeModuleVisible) {
                                menuItems.push(menuItem);
                            }
                            else if (menuItem.URL === '/catalogue') {
                                menuItem.subPages = menuItem.subPages.filter(subPage => subPage.URLSegment !== 'coatings' || (subPage.URLSegment === 'coatings' && this.props.user.coatingsModuleVisible));
                                menuItems.push(menuItem);
                            }
                            else if(menuItem.URL != '/vendors-network' && menuItem.URL != '/pricelist' && menuItem.URL != '/stock' && menuItem.URL != '/e-shop' && menuItem.URL !== '/leadtime' && menuItem.URL !== '/coatings') {
                                menuItems.push(menuItem);
                            }
                        }

                    })
                }
            }
            else {
                this.props.menuItems.map(menuItem => {
                    if(menuItem.visibility == 'All') {
                        menuItems.push(menuItem);
                    }
                })
            }
        }


        return <div className="menu">
        {
            menuItems.map(menuItem => {
                return <NavItem
                key={menuItem.id}
                title={menuItem.title}
                url={menuItem.URL}
                subPages={menuItem.subPages}
                location={this.props.location.pathname}>
                </NavItem>
            })
        }
        </div>
    }


    render() {
        return (
            <div>
                <div className="NavigationHeader page-container d-none d-md-flex">
                    {
                        this.renderMenu()
                    }
                    <div className="carts-header">
                    {
                        this.showCart() ?
                            <div className="cart-icon-container" onClick={this.checkoutClickHandler}><CartHeader /></div>
                        : null
                    }
                        {
                            this.showInventoryCart() ?
                            <div className="cart-icon-container" onClick={this.checkoutInventoryClickHandler}><StockHeader /></div>
                            : null
                        }
                </div>
                </div>
                <div style={{marginTop: '100px'}} className="d-block d-md-none"></div>
            </div>
        );
    };
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
}

export default withRouter(connect(mapStateToProps, null)(NavigationHeader));