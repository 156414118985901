import React, {Component} from 'react';
import jwt_decode from 'jwt-decode';
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import axios from '../../axios';
import {ZeissIDUtils} from "../Auth/ZeissID";
import { instanceOf } from 'prop-types';
import Cookies from 'universal-cookie';
import * as actions from '../../store/actions/index';

class CallbackSignUp extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    componentDidMount () {
        const cookies = new Cookies();
        cookies.set('newsletter_seen', false, { path: '/', expires: new Date('Thu, 01 Jan 1970 00:00:00 UTC') });
        let zeissID = new ZeissIDUtils();
        zeissID.login();
    }

    render () {
        return (
            <div className="callback-container">
                <div className="black-layer">
                </div>

                <Loader
                    type="TailSpin"
                    color="#008bd0"
                    height={100}
                    width={100}
                    timeout={1500000}
                    className="spinner"
                />

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( user) => dispatch( actions.authSuccess(user) )
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CallbackSignUp));
