import { API, GET_CATALOGUE, SET_ARTICLE_DETAILS } from "./actionTypes";

export function getCatalogue() {
  return apiAction({
    url: "/getCatalogue",
    onSuccess: () => setArticleDetails,
    onFailure: () => console.log("Error occured loading articles"),
    label: GET_CATALOGUE
  });
}

function setArticleDetails(data) {
  return {
    type: SET_ARTICLE_DETAILS,
    payload: data
  };
}

function apiAction({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  headersOverride = null
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride
    }
  };
}