import React, { Component } from 'react';
import './MyStockRequest.css';
import { withRouter } from 'react-router';
import axios from '../../../axios';
import { Table, Button } from 'precise-ui/dist/es6';
import NumberFormat from 'react-number-format';
import CartItem from '../../../components/CartItem/CartItem';

class MyStockRequest extends Component {

    state = {
        stockRequest: null,
        showSkeletons: true
    }

    orderItemColumns = {
        item: 'Product',
        Quantity: 'Quantity',
        Price: 'Price'
    };

    componentDidMount () {
        if (!this.props.match || !this.props.match.params.id) return;

        axios.get('/getStockRequest/'+this.props.match.params.id)
        .then(response => {
            if (response.data.type === "success") {
                const request = {...response.data.payload};
                const products = request.Items.filter(i => i.Product != null);
                this.setState(() => ({
                    ...this.state,
                    stockRequest: {
                        ...request,
                        Products: products
                    },
                    showSkeletons: false
                }));
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    headerCellRender = headerCell => {
        console.log(headerCell);
        const key = headerCell.key;
        const value = headerCell.value;

        if (key.toLowerCase() === 'price') {
            return (
                <div className="text-right">
                    {value}
                </div>
            );
        } else {
            return value;
        }

    }

    cellTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'price':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'item':
                if (data.Product) {
                    return (
                        <CartItem {...data.Product} />
                    );
                }
                return null;
            default:
                return value;
        }
    }

    render() {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                <div className="back-shopping back-top"  onClick={this.props.history.goBack} > <span class="icon-direction-back m-r-5"> </span> Back </div>
                {
                    this.state.stockRequest ?
                    <div className="order-container">
                        <h3>Stock Request details</h3>
                        <div className="order-block">
                            <div className="order-header d-sm-flex">
                                <div>
                                    <h4>Stock Request date: {this.state.stockRequest.CreationDate}&nbsp;{this.state.stockRequest.CreationTime}</h4>                                    
                                </div>                                
                            </div>
                            <div className="order-body">
                                <div>
                                    <Table
                                        className="cart-table" 
                                        data={this.state.stockRequest.Products} 
                                        columns={this.orderItemColumns} 
                                        placeholder="No items yet."
                                        headerCellRenderer={this.headerCellRender}
                                        cellRenderer={this.cellTableRender}  
                                        borderless>
                                    </Table>
                                </div>
                                <div>
                                    <table className="totalTable">
                                        <thead>
                                            <tr>
                                                <th style={{width: '50%'}}></th>
                                                <th style={{width: '50%'}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><h3>Products Price:</h3></td>
                                                <td className="text-right total important-total">
                                                    <NumberFormat 
                                                        value={this.state.stockRequest.ProductsPrice} 
                                                        displayType={'text'} 
                                                        prefix={'€ '} 
                                                        decimalSeparator={'.'} 
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null //skeleton
                }
            </div>
        );
    }

}

export default withRouter(MyStockRequest);