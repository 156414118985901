import React from 'react';
import './PricelistCoatingTable.css';

const pricelistCoatingTable = props => {

    let getShapePrice = (family, coating, shape) => {
        if(props.coatings[family][coating][shape]) {
            if(props.coatings[family][coating][shape]['price']) {
                let zeissExcl = '';
                if(props.coatings[family][coating][shape]['zeissExcl'] && props.coatings[family][coating][shape]['zeissExcl'] == 1) {
                    zeissExcl = ' *';
                }
                return props.sign + props.coatings[family][coating][shape]['price'].toFixed(2) + zeissExcl
            }
        }
        return 'NA';
    }

    let getPrice = (family, coating) => {
        if(props.coatings[family][coating]) {
            if(props.coatings[family][coating]['price']) {
                let zeissExcl = '';
                if(props.coatings[family][coating]['zeissExcl'] && props.coatings[family][coating]['zeissExcl'] == 1) {
                    zeissExcl = ' *';
                }
                return props.sign + props.coatings[family][coating]['price'].toFixed(2) + zeissExcl
            }
        }
        return 'NA';
    }


    return (
        <div className="responsive-table">
           <table className="pricelist-table full-width">
                <colgroup>
                    <col className="family-column"></col>
                    <col className="coating-column"></col>
                    {
                        props.allShapes && props.allShapes.length > 0 ?
                            props.allShapes.map(shape => (
                                <col key={"coatings_"+props.material+"_"+shape} className={(props.allShapes.length === 1 && props.allShapes[0] === ' ') ? "price-column width-auto" : "price-column"}></col>
                            ))
                        :
                            <col className="price-column width-auto"></col>
                    }
                </colgroup>
                {/* <thead>
                    <tr className="highlight-row">
                        <th className="family-column">family</th>
                        <th className="coating-column">COATINGS ON {props.material}</th>
                        {
                            props.allShapes && props.allShapes.length > 0 ?
                                props.allShapes.map(shape => (
                                    <th key={"coatings_"+props.material+"_"+shape} className={(props.allShapes.length === 1 && props.allShapes[0] === ' ') ? "price-column width-auto" : "price-column text-center"}>{shape}</th>
                                ))
                            :
                                <th className="ghost-cell bordered-bottom"></th>
                        }
                    </tr>
                </thead> */}
               <tbody>
                   <tr className="highlight-row">
                       <td>family</td>
                       <td>COATINGS ON {props.material}</td>
                       {
                            props.allShapes && props.allShapes.length > 0 ?
                                props.allShapes.map(shape => (
                                    <td key={"coatings_"+props.material+"_"+shape} className="text-center">{shape}</td>
                                ))
                            :
                                <td className="ghost-cell bordered-bottom"></td>
                       }
                   </tr>
                   {
                       Object.keys(props.coatings).map(family => (
                            Object.keys(props.coatings[family]).map(coating => (
                                props.allShapes && props.allShapes.length > 0 ?
                                    <tr key={"coatings_"+props.material+"_"+family+"_"+coating} className="data-row">
                                        <td>{family}</td>
                                        <td>{coating}</td>
                                        {
                                            props.allShapes.map(shape => (
                                                <td key={"coatings_"+props.material+"_"+family+"_"+coating+"_"+shape} className="text-center"
                                                dangerouslySetInnerHTML={{__html: getShapePrice(family,coating,shape)}}>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                :
                                    <tr key={"coatings_"+props.material+"_"+family+"_"+coating} className="data-row">
                                        <td>{family}</td>
                                        <td>{coating}</td>
                                        <td className="text-center" dangerouslySetInnerHTML={{__html: getPrice(family,coating)}}>
                                        </td>
                                    </tr>
                            ))
                        ))
                   }
               </tbody>
            </table>
        </div>
    )
};

export default pricelistCoatingTable;