import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, IconLink, notify, ModalBody, Modal, ModalHeader } from 'precise-ui';
import './InventoryCart.css';
import * as actionCreators from '../../../../store/actions/index';
import NumberFormat from 'react-number-format';
import CartItem from '../../../../components/CartItem/CartItem';
import QuantitySelector from '../../../../components/QuantitySelector/QuantitySelector';
import axios from '../../../../axios';
import Loader from 'react-loader-spinner'
import ReactGA from 'react-ga';
import {config} from '../../../../config';
import stockIcon from '../../../../res/images/stock_icon.png';
const { Checkbox } = require('precise-ui');
const { Button } = require('precise-ui');

class InventoryCart extends Component {

    state = {
        prevPath: null,
        xPayParams: { url: null, requestParams: []},
        showFinalCheckout: false,
        totalCheckOut: { productsTotalPrice: 0, idStockRequest: -1},
        checkDifferentShippingAddress: false,
        differentShippingAddress: -1,
        privacyAccepted: false,
        showBillingPanel: true,
        notes: '',
        privacyError: false,
        deliveryPointError: false,
        isLoading: false
    }

    tableColumns = {
        item: 'Item',
        Quantity: 'Pieces',
        Price: {
            header: 'Price / piece',
            footer: 'Subtotal:',
            sortable: false
        },
        TotalPrice: 'Total Price',
    };

    tableCheckOutColumns = {
        item: 'Item',
        Quantity: 'Pieces',
        TotalPrice: 'Total Price',
    };
   
    total = 0;

    componentDidMount () {
        this.savePreviousPath();
        window.scrollTo(0, 0);
    }

    savePreviousPath = () => {
        if (this.props.location.state != null && this.props.location.state.from != null) {
            this.setState({
                ...this.state,
                prevPath: this.props.location.state.from
            });
        }
    }

    headerCellRender = headerCell => {
        const key = headerCell.key;
        const value = headerCell.value;

        if (key.toLowerCase() === 'price' || key.toLowerCase() === 'totalprice') {
            return (
                <div className="text-right">
                    {value}
                </div>
            );
        } else {
            return value;
        }

    }

    cellTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'totalprice':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price * data.Quantity} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'price':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'item':
                return (
                    <CartItem {...data} />
                );
            case 'quantity':
                return (
                    <div>
                        <div>
                            <QuantitySelector 
                                value={value}
                                change={(event) => this.props.onEditQuantity(data.ID, event.target.value, this.props.user.zeissId)}
                                start={data.OrderMinQty} 
                                end={data.StockQty} 
                                step={data.MinReorder} />
                            <IconLink className="m-l-10" icon="Delete" size={1} onClick={() => this.props.onRemoveFromCart(data.ID, this.props.user.zeissId)}/>
                        </div>
                        {
                            !data.Available ?
                            <p className="text-bold text-error">Item not available.</p>
                            : null
                        }
                    </div>
                );
            default:
                return value;
        }
    }

    cellCheckOutTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'totalprice':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price * data.Quantity} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'price':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'item':
                return (
                    <CartItem {...data} />
                );
            case 'quantity':
                return (
                    <div>{value}</div>
                );
            default:
                return value;
        }
    }

    footerCellRenderer = footerCell => {        
        const key = footerCell.key;
        const value = footerCell.value;

        if (key.toLowerCase() === 'totalprice') {
            return (
                <div className="text-right">
                    <NumberFormat 
                        value={this.getTotal()} 
                        displayType={'text'} 
                        prefix={'€ '} 
                        decimalSeparator={'.'} 
                        decimalScale={2}
                        fixedDecimalScale={true} />
                </div>
            );
        } else if (key.toLowerCase() === 'price') {
            return (
                <div className="text-right">
                    {value}
                </div>
            );
        } else {
            return value;
        }
    }

    getTotal() {
        let total = 0;
        this.props.inventoryCart.forEach(
            element => {
                total = total + (element.Price * element.Quantity);
            }
        );

        return total;
    }

    historyBackHandler = () => {
        if (this.state.prevPath != null) {
            this.props.history.push(this.state.prevPath, { from: this.props.location.pathname });
        } else {
            this.props.history.goBack();
        }
    }

    proceedOrder = () => {
        this.setState({
            showFinalCheckout: true
        });
              
    }

    
    sendRequest = () => {

        if(this.state.totalCheckOut.idStockRequest > 0 ) {

            let stockRequest = {
                idStockRequest: this.state.totalCheckOut.idStockRequest,
                user: this.props.user
            }
    
            axios.post('/sendStockRequest', JSON.stringify(stockRequest), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                if (response.data.error) {
                    notify.error({ content: response.data.error, options: { position: 'top-right', autoClose: 3000 } });
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                } else {

                    this.props.emptyInventoryCart(this.props.user.zeissId);
                    this.setState({
                        isLoading: false
                    });

                    notify.success(
                        { content: 'Your request has been sent successfully', options: { position: 'top-right', autoClose: 3000 }},
                    );
                    this.props.history.push("/stock")
                }
            })
            .catch(err => {
                notify.error({ content: 'There was an error handling your request. Please contact support for assistance', options: { position: 'top-right', autoClose: 4000 } });
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                console.log(err);
            });      
        }
       
    }


    handleChangeShippingCheckBox = () => {
        let checked = this.state.checkDifferentShippingAddress;

        this.setState({
            checkDifferentShippingAddress: !checked
        });
    }


    handlePrivacy= () => {
        let privacy = this.state.privacyAccepted;

        this.setState({
            privacyAccepted: !privacy,
            privacyError: false
        });

    }

    handleChangeSelectDelivery = (event) => {
        this.setState({
            differentShippingAddress: event.target.value
        });
    }

    handleChangeNotes = (event) => {
        this.setState({notes: event.target.value});
    }

    confirmBilling = () => {

        let privacyError = false;
        if(!this.state.privacyAccepted){
            privacyError = true;
        }
        
        let deliveryPointError = false;
        if (this.props.user.isDeliveryPointMandatory && this.state.differentShippingAddress == -1){
            deliveryPointError = true;
        }

        if(!privacyError && !deliveryPointError){            
            //ANALYTICS
            /*ReactGA.plugin.require('ec', { debug: true });
            this.props.inventoryCart.forEach( element => {
                ReactGA.plugin.execute('ec', 'addProduct', {
                    id: element.ID
                });
            });

            ReactGA.plugin.execute('ec', 'setAction', 'checkout');

            ReactGA.pageview(window.location.pathname + window.location.search);*/

            window.scrollTo(0, 0);
            this.setState({
                isLoading: true
            });

            let differentShippingAddress = { code: null, adress: null, ivaAS400: null, extraAS400: null };

            let useDifferentShippingAddress = false;
            
            if(this.state.checkDifferentShippingAddress || this.props.user.isDeliveryPointMandatory){
                useDifferentShippingAddress = true;
                differentShippingAddress.code = this.state.differentShippingAddress;
                differentShippingAddress.adress = this.props.user.deliveries.filter(function(item) { return item.Delivery_Point_Number__c === differentShippingAddress.code; })[0].Address__c;
                differentShippingAddress.ivaAS400 = this.props.user.deliveries.filter(function(item) { return item.Delivery_Point_Number__c === differentShippingAddress.code; })[0].BtoB_Portal_IVA_AS400__c;
                differentShippingAddress.extraAS400 = this.props.user.deliveries.filter(function(item) { return item.Delivery_Point_Number__c === differentShippingAddress.code; })[0].BtoB_Portal_spese_accessorie_AS400__c;
            }
    
            let order = {
                user: this.props.user,
                country: this.props.user.billingAddress.countryCode,
                note: this.state.notes,
                useDifferentShippingAddress: useDifferentShippingAddress,
                differentShippingAddress: differentShippingAddress,            
                products: this.props.inventoryCart.filter(i => i.Available !== false)
            }
        
            axios.post('/addStockRequest', JSON.stringify(order), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                if (response.data.error) {
                    notify.error({ content: response.data.error, options: { position: 'top-right', autoClose: 3000 } });
                    this.setState({
                        ...this.state,
                        isLoading: false
                    });
                } else {
                    let totalCheckOut = { 
                        productsTotalPrice: response.data.ProductsTotalPrice,
                        idStockRequest: response.data.IDStockRequest
                    }
    
                    this.setState({
                        showFinalCheckout: true,
                        totalCheckOut: totalCheckOut,
                        showBillingPanel: false,
                        isLoading: false
                    });
                }
            })
            .catch(err => {
                notify.error({ content: 'There was an error handling your request. Please contact support for assistance', options: { position: 'top-right', autoClose: 4000 } });
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                console.log(err);
            });
        }
        else {
            this.setState({
                privacyError: privacyError,
                deliveryPointError: deliveryPointError
            });
            
        }
        
    }

    
    backToCart = () => {
        this.setState({
            showFinalCheckout: false
        });
        window.scrollTo(0, 0);
    }

    backToBilling = () => {
        this.setState({
            showFinalCheckout: true,
            showBillingPanel: true,
            privacyError: false,
            deliveryPointError: false,
            privacyAccepted: false
        });
        window.scrollTo(0, 0);
    }

    escape = (value) => {
        var replace = value.replace(/&#39;/g, "'");
        return replace;
    }


    render() {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                {
                    this.state.showFinalCheckout ?
                    <div>
                        <h1> <span class="icon-checkmark m-r-5 checkout-icon" ></span>  Check out </h1>

                            <div className="checkout-container">
                                {
                                    this.state.showBillingPanel ? 
                                        <div className="checkout-billing">
                                        <h3> Billing Address </h3>
                                        <div className="billing-details">  
                                            <div className="left-billing">
                                                <label className="lablel-co">
                                                    Company Name: 
                                                    <input type="text" value= {this.props.user.company} disabled/>
                                                </label>   

                                                <label className="lablel-co">
                                                    Country: 
                                                    <input type="text" value=  {this.props.user.billingAddress.country} disabled/>
                                                </label>   

                                                <label className="lablel-co">
                                                    City: 
                                                    <input type="text" value= {this.props.user.billingAddress.city} disabled/>
                                                </label>   
                                            </div>

                                            <div className="right-billing">
                                                <label className="lablel-co">
                                                    Postal Code: 
                                                    <input type="text" value= {this.props.user.billingAddress.postalCode} disabled/>
                                                </label>   

                                                <label className="lablel-co">
                                                    Address: 
                                                    <input type="text" value= {this.props.user.billingAddress.street} disabled/>
                                                </label>     
                                            </div>                                     

                                                                                                                            
                                        </div>
                                        {
                                            (this.props.user.deliveries != null && this.props.user.deliveries.length > 0 && !this.props.user.isDeliveryPointMandatory) ? 
                                            <Checkbox className="checkout-check" onChange={this.handleChangeShippingCheckBox}> Use a different shipping address </Checkbox>     
                                            :
                                            null
                                        }                                   
                                        {
                                            this.state.checkDifferentShippingAddress || this.props.user.isDeliveryPointMandatory ?
                                            <div className="checkout-step-cnt">
                                                <h3> Shipping Address</h3>                               

                                                <div className="content-co">
                                                    <p> Please select a shipping address: </p>                                                   
                                                        <select className="shipping-select" onChange={this.handleChangeSelectDelivery}>
                                                        <option key={-1} value={-1}> Select </option>
                                                        { 
                                                            this.props.user.deliveries.map( (item) => {
                                                                return <option value={item.Delivery_Point_Number__c}>
                                                                        { this.escape(item.Address__c)}
                                                                    </option>;
                                                            })
                                                        }                                
                                                        </select>
                                                        {
                                                        this.state.deliveryPointError ? 
                                                            <p className="required-field"> 
                                                                Field required 
                                                            </p>
                                                            :
                                                            null
                                                        }                                                    
                                                </div>                                    
                                            </div>    
                                            :
                                            null
                                        }                                  

                                        <div>
                                            <div className="note-container">
                                                <div className="note-content">
                                                    <p className="lablel-co">Note:</p>
                                                    <textarea class="m-b-30" maxLength="300" value={this.state.notes} onChange={this.handleChangeNotes}></textarea>
                                                </div>
                                                <div className="note-ws"></div>
                                            </div>
                                            <br/>
                                            <Checkbox  style={{float: 'left'}}  className="checkout-check" onChange={this.handlePrivacy}> I have read and agree with ZEISS </Checkbox>
                                            <a href={config.backend + "assets/Uploads/ZEISS_SalesCondition.pdf"} target="_blank" className="sales-anchor"> Sales Condition </a>
                                            {
                                            this.state.privacyError ? 
                                                <p className="required-field cb"> 
                                                    Field required 
                                                </p>
                                                :
                                                null
                                            }
                                            <div className="checkout-btn-container">
                                                <div className="back-shopping"  onClick={this.backToCart}> <span class="icon-direction-back m-r-5"> </span> Back </div>
                                                <Button  buttonStyle="primary" icon="Check" style={{width: '150px', float: 'right' }} onClick={this.confirmBilling} > Confirm </Button> 
                                            </div>
                                        </div>                                    
                                        
                                    </div>    
                                    :
                                    <div className="checkout-summary-full cart-table-container">
                                        <h3> Order summary </h3>
                                        <Table
                                            className="cart-table" 
                                            syle= {{'max-width' : '1200px' }}
                                            data={this.props.inventoryCart} 
                                            columns={this.tableCheckOutColumns} 
                                            headerCellRenderer={this.headerCellRender}
                                            cellRenderer={this.cellCheckOutTableRender} 
                                            placeholder="No item in cart yet." 
                                            borderless>
                                        </Table>
                                        <div className="total-container">
                                            <div className="total-summary">
                                                                
                                                <div  className="clear">                                                
                                                    <span className="final-total-value"> € {this.state.totalCheckOut.productsTotalPrice} </span> <span className="final-total-lbl ">Total: </span> 
                                                </div>
                                            </div>                                  
                                        </div>
        
                                        <div className="checkout-btn-container">
                                            <div className="back-shopping"  onClick={this.backToBilling}> <span class="icon-direction-back m-r-5"> </span> Back </div>
                                            <Button  buttonStyle="primary"  icon="Check" style={{width: '180px', float: 'right' }} onClick={this.sendRequest} > Send request </Button> 
                                        </div>
                                 
                                    
                                    </div> 
                                }

                                
                            </div>
                                                                              
                    </div>
                    : 
                    <div class="cart-table-container">
                        <h1>  <img src={stockIcon} className="m-r-5" style={{width: '24px'}} alt="inventory_icon" />  Your inventory cart </h1>
                        <Table
                        className="cart-table" 
                        data={this.props.inventoryCart} 
                        columns={this.tableColumns} 
                        headerCellRenderer={this.headerCellRender}
                        cellRenderer={this.cellTableRender} 
                        footerCellRenderer={this.footerCellRenderer}
                        placeholder="No items in cart yet." 
                        borderless>
                        </Table>
                        
                            <div className="footer-checkout">
                                <div className="back-shopping" onClick={this.historyBackHandler}> <span class="icon-direction-back m-r-5"> </span>Continue Shopping </div>
                                {
                                   this.props.inventoryCart.length > 0 ?                             
                                    <Button  buttonStyle="primary"  icon="KeyboardArrowRight" style={{width: '220px', float: 'right' }} onClick={this.proceedOrder} > Go to the Checkout </Button> 
                                        :
                                    null                                                    
                                }
                            </div>                           
                        
                    </div>
                }

                {
                    this.state.isLoading ?      
                    <div>
                        <div className="black-layer">
                        </div>   
                        <Loader
                            type="TailSpin"
                            color="#008bd0"
                            height={100}
                            width={100}
                            timeout={1500000}                 
                            className="spinner"
                        />
                    </div>       
                      
                    :
                    null

                }
          
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        inventoryCart: state.stock.inventoryCart,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditQuantity: (productId, quantity, idzeiss) => dispatch(actionCreators.editQuantityInventoryCart(productId, +quantity, idzeiss)),
        onRemoveFromCart: (productId, idzeiss) => dispatch(actionCreators.removeFromInventoryCart(productId, idzeiss)),
        emptyInventoryCart: (idzeiss) => dispatch(actionCreators.emptyInventoryCart(idzeiss))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCart);