import React, { Component } from 'react';
import './ModalFullPrivilege.css';
import {config} from '../../../src/config';
import { connect } from 'react-redux';
import axios from '../../axios';
import { Modal, Button, Checkbox, notify, DropdownField } from 'precise-ui/dist/es6';
import Cookies from 'universal-cookie';
import * as actions from '../../store/actions/index';


class ModalFullPrivilege extends Component {

    state = {
        inputs: {
            companyName: '',
            billingCountry: '',
            billingCity: '',
            billingPostalCode: '',
            billingStreet: '',
            shippingCountry: '',
            shippingCity: '',
            shippingPostalCode: '',
            shippingStreet: '',
            production: '',
            vat: '',
            phone: '',
            department: '',
            businessType: '',
            extraInfo: '',
            website: '',
            PEC: '',
            privacy: false,
            inBusinessSince: '',
            industry: [],
            averageRetailPrices: []

        },
        errors: {
            companyName: false,
            billingCountry: false,
            billingCity: false,
            billingPostalCode: false,
            billingStreet: false,
            production: false,
            vat: false,
            phone: false,
            department: false,
            production: false,
            businessType: false,
            inBusinessSince: false,
            industry: false,
            averageRetailPrices: false
        },
        showRequestFullPrivilege: true,
        differentShippingAddress: false,
        isFullPrivilegRequestFunctionEnabled: true,
        fullPrivilegeClosedEndDate: '',
        isAdvancedPrivilegeRequest: true,
        advancedRequestSucceeded: false,
        fullRequestSucceded: false
    }

    departments = [
        {id: 1, value: "Brand Management"},
        {id: 2, value: "Product Management"},
        {id: 3, value: "Design"},
        {id: 4, value: "Operations"},
        {id: 5, value: "Engineering"},
        {id: 6, value: "Procurement"},
        {id: 7, value: "Marketing"},
        {id: 8, value: "PR-Communication"},
        {id: 9, value: "R&D"},
        {id: 10, value:  "Sales"},
        {id: 11, value: "Quality"},
        {id: 12, value: "Retail"},
        {id: 13, value: "IT"},
        {id: 14, value: "Management"},
        {id: 15, value: "Owner"},
        {id: 16, value: "HR"},
        {id: 17, value: "Legal"},
        {id: 18, value: "Finance"}
    ];

    countries = [
        {"name": "Afghanistan", "code": "AF"},
        {"name": "Åland Islands", "code": "AX"},
        {"name": "Albania", "code": "AL"},
        {"name": "Algeria", "code": "DZ"},
        {"name": "American Samoa", "code": "AS"},
        {"name": "AndorrA", "code": "AD"},
        {"name": "Angola", "code": "AO"},
        {"name": "Anguilla", "code": "AI"},
        {"name": "Antarctica", "code": "AQ"},
        {"name": "Antigua and Barbuda", "code": "AG"},
        {"name": "Argentina", "code": "AR"},
        {"name": "Armenia", "code": "AM"},
        {"name": "Aruba", "code": "AW"},
        {"name": "Australia", "code": "AU"},
        {"name": "Austria", "code": "AT"},
        {"name": "Azerbaijan", "code": "AZ"},
        {"name": "Bahamas", "code": "BS"},
        {"name": "Bahrain", "code": "BH"},
        {"name": "Bangladesh", "code": "BD"},
        {"name": "Barbados", "code": "BB"},
        {"name": "Belarus", "code": "BY"},
        {"name": "Belgium", "code": "BE"},
        {"name": "Belize", "code": "BZ"},
        {"name": "Benin", "code": "BJ"},
        {"name": "Bermuda", "code": "BM"},
        {"name": "Bhutan", "code": "BT"},
        {"name": "Bolivia", "code": "BO"},
        {"name": "Bosnia and Herzegovina", "code": "BA"},
        {"name": "Botswana", "code": "BW"},
        {"name": "Bouvet Island", "code": "BV"},
        {"name": "Brazil", "code": "BR"},
        {"name": "British Indian Ocean Territory", "code": "IO"},
        {"name": "Brunei Darussalam", "code": "BN"},
        {"name": "Bulgaria", "code": "BG"},
        {"name": "Burkina Faso", "code": "BF"},
        {"name": "Burundi", "code": "BI"},
        {"name": "Cambodia", "code": "KH"},
        {"name": "Cameroon", "code": "CM"},
        {"name": "Canada", "code": "CA"},
        {"name": "Cape Verde", "code": "CV"},
        {"name": "Cayman Islands", "code": "KY"},
        {"name": "Central African Republic", "code": "CF"},
        {"name": "Chad", "code": "TD"},
        {"name": "Chile", "code": "CL"},
        {"name": "China", "code": "CN"},
        {"name": "Christmas Island", "code": "CX"},
        {"name": "Cocos (Keeling) Islands", "code": "CC"},
        {"name": "Colombia", "code": "CO"},
        {"name": "Comoros", "code": "KM"},
        {"name": "Congo", "code": "CG"},
        {"name": "Congo, The Democratic Republic of the", "code": "CD"},
        {"name": "Cook Islands", "code": "CK"},
        {"name": "Costa Rica", "code": "CR"},
        {"name": "Cote D'Ivoire", "code": "CI"},
        {"name": "Croatia", "code": "HR"},
        {"name": "Cuba", "code": "CU"},
        {"name": "Cyprus", "code": "CY"},
        {"name": "Czech Republic", "code": "CZ"},
        {"name": "Denmark", "code": "DK"},
        {"name": "Djibouti", "code": "DJ"},
        {"name": "Dominica", "code": "DM"},
        {"name": "Dominican Republic", "code": "DO"},
        {"name": "Ecuador", "code": "EC"},
        {"name": "Egypt", "code": "EG"},
        {"name": "El Salvador", "code": "SV"},
        {"name": "Equatorial Guinea", "code": "GQ"},
        {"name": "Eritrea", "code": "ER"},
        {"name": "Estonia", "code": "EE"},
        {"name": "Ethiopia", "code": "ET"},
        {"name": "Falkland Islands (Malvinas)", "code": "FK"},
        {"name": "Faroe Islands", "code": "FO"},
        {"name": "Fiji", "code": "FJ"},
        {"name": "Finland", "code": "FI"},
        {"name": "France", "code": "FR"},
        {"name": "French Guiana", "code": "GF"},
        {"name": "French Polynesia", "code": "PF"},
        {"name": "French Southern Territories", "code": "TF"},
        {"name": "Gabon", "code": "GA"},
        {"name": "Gambia", "code": "GM"},
        {"name": "Georgia", "code": "GE"},
        {"name": "Germany", "code": "DE"},
        {"name": "Ghana", "code": "GH"},
        {"name": "Gibraltar", "code": "GI"},
        {"name": "Greece", "code": "GR"},
        {"name": "Greenland", "code": "GL"},
        {"name": "Grenada", "code": "GD"},
        {"name": "Guadeloupe", "code": "GP"},
        {"name": "Guam", "code": "GU"},
        {"name": "Guatemala", "code": "GT"},
        {"name": "Guernsey", "code": "GG"},
        {"name": "Guinea", "code": "GN"},
        {"name": "Guinea-Bissau", "code": "GW"},
        {"name": "Guyana", "code": "GY"},
        {"name": "Haiti", "code": "HT"},
        {"name": "Heard Island and Mcdonald Islands", "code": "HM"},
        {"name": "Holy See (Vatican City State)", "code": "VA"},
        {"name": "Honduras", "code": "HN"},
        {"name": "Hong Kong", "code": "HK"},
        {"name": "Hungary", "code": "HU"},
        {"name": "Iceland", "code": "IS"},
        {"name": "India", "code": "IN"},
        {"name": "Indonesia", "code": "ID"},
        {"name": "Iran, Islamic Republic Of", "code": "IR"},
        {"name": "Iraq", "code": "IQ"},
        {"name": "Ireland", "code": "IE"},
        {"name": "Isle of Man", "code": "IM"},
        {"name": "Israel", "code": "IL"},
        {"name": "Italy", "code": "IT"},
        {"name": "Jamaica", "code": "JM"},
        {"name": "Japan", "code": "JP"},
        {"name": "Jersey", "code": "JE"},
        {"name": "Jordan", "code": "JO"},
        {"name": "Kazakhstan", "code": "KZ"},
        {"name": "Kenya", "code": "KE"},
        {"name": "Kiribati", "code": "KI"},
        {"name": "Korea, Democratic People'S Republic of", "code": "KP"},
        {"name": "Korea, Republic of", "code": "KR"},
        {"name": "Kuwait", "code": "KW"},
        {"name": "Kyrgyzstan", "code": "KG"},
        {"name": "Lao People'S Democratic Republic", "code": "LA"},
        {"name": "Latvia", "code": "LV"},
        {"name": "Lebanon", "code": "LB"},
        {"name": "Lesotho", "code": "LS"},
        {"name": "Liberia", "code": "LR"},
        {"name": "Libyan Arab Jamahiriya", "code": "LY"},
        {"name": "Liechtenstein", "code": "LI"},
        {"name": "Lithuania", "code": "LT"},
        {"name": "Luxembourg", "code": "LU"},
        {"name": "Macao", "code": "MO"},
        {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"},
        {"name": "Madagascar", "code": "MG"},
        {"name": "Malawi", "code": "MW"},
        {"name": "Malaysia", "code": "MY"},
        {"name": "Maldives", "code": "MV"},
        {"name": "Mali", "code": "ML"},
        {"name": "Malta", "code": "MT"},
        {"name": "Marshall Islands", "code": "MH"},
        {"name": "Martinique", "code": "MQ"},
        {"name": "Mauritania", "code": "MR"},
        {"name": "Mauritius", "code": "MU"},
        {"name": "Mayotte", "code": "YT"},
        {"name": "Mexico", "code": "MX"},
        {"name": "Micronesia, Federated States of", "code": "FM"},
        {"name": "Moldova, Republic of", "code": "MD"},
        {"name": "Monaco", "code": "MC"},
        {"name": "Mongolia", "code": "MN"},
        {"name": "Montserrat", "code": "MS"},
        {"name": "Morocco", "code": "MA"},
        {"name": "Mozambique", "code": "MZ"},
        {"name": "Myanmar", "code": "MM"},
        {"name": "Namibia", "code": "NA"},
        {"name": "Nauru", "code": "NR"},
        {"name": "Nepal", "code": "NP"},
        {"name": "Netherlands", "code": "NL"},
        {"name": "Netherlands Antilles", "code": "AN"},
        {"name": "New Caledonia", "code": "NC"},
        {"name": "New Zealand", "code": "NZ"},
        {"name": "Nicaragua", "code": "NI"},
        {"name": "Niger", "code": "NE"},
        {"name": "Nigeria", "code": "NG"},
        {"name": "Niue", "code": "NU"},
        {"name": "Norfolk Island", "code": "NF"},
        {"name": "Northern Mariana Islands", "code": "MP"},
        {"name": "Norway", "code": "NO"},
        {"name": "Oman", "code": "OM"},
        {"name": "Pakistan", "code": "PK"},
        {"name": "Palau", "code": "PW"},
        {"name": "Palestinian Territory, Occupied", "code": "PS"},
        {"name": "Panama", "code": "PA"},
        {"name": "Papua New Guinea", "code": "PG"},
        {"name": "Paraguay", "code": "PY"},
        {"name": "Peru", "code": "PE"},
        {"name": "Philippines", "code": "PH"},
        {"name": "Pitcairn", "code": "PN"},
        {"name": "Poland", "code": "PL"},
        {"name": "Portugal", "code": "PT"},
        {"name": "Puerto Rico", "code": "PR"},
        {"name": "Qatar", "code": "QA"},
        {"name": "Reunion", "code": "RE"},
        {"name": "Romania", "code": "RO"},
        {"name": "Russian Federation", "code": "RU"},
        {"name": "RWANDA", "code": "RW"},
        {"name": "Saint Helena", "code": "SH"},
        {"name": "Saint Kitts and Nevis", "code": "KN"},
        {"name": "Saint Lucia", "code": "LC"},
        {"name": "Saint Pierre and Miquelon", "code": "PM"},
        {"name": "Saint Vincent and the Grenadines", "code": "VC"},
        {"name": "Samoa", "code": "WS"},
        {"name": "San Marino", "code": "SM"},
        {"name": "Sao Tome and Principe", "code": "ST"},
        {"name": "Saudi Arabia", "code": "SA"},
        {"name": "Senegal", "code": "SN"},
        {"name": "Serbia and Montenegro", "code": "CS"},
        {"name": "Seychelles", "code": "SC"},
        {"name": "Sierra Leone", "code": "SL"},
        {"name": "Singapore", "code": "SG"},
        {"name": "Slovakia", "code": "SK"},
        {"name": "Slovenia", "code": "SI"},
        {"name": "Solomon Islands", "code": "SB"},
        {"name": "Somalia", "code": "SO"},
        {"name": "South Africa", "code": "ZA"},
        {"name": "South Georgia and the South Sandwich Islands", "code": "GS"},
        {"name": "Spain", "code": "ES"},
        {"name": "Sri Lanka", "code": "LK"},
        {"name": "Sudan", "code": "SD"},
        {"name": "Suriname", "code": "SR"},
        {"name": "Svalbard and Jan Mayen", "code": "SJ"},
        {"name": "Swaziland", "code": "SZ"},
        {"name": "Sweden", "code": "SE"},
        {"name": "Switzerland", "code": "CH"},
        {"name": "Syrian Arab Republic", "code": "SY"},
        {"name": "Taiwan, Province of China", "code": "TW"},
        {"name": "Tajikistan", "code": "TJ"},
        {"name": "Tanzania, United Republic of", "code": "TZ"},
        {"name": "Thailand", "code": "TH"},
        {"name": "Timor-Leste", "code": "TL"},
        {"name": "Togo", "code": "TG"},
        {"name": "Tokelau", "code": "TK"},
        {"name": "Tonga", "code": "TO"},
        {"name": "Trinidad and Tobago", "code": "TT"},
        {"name": "Tunisia", "code": "TN"},
        {"name": "Turkey", "code": "TR"},
        {"name": "Turkmenistan", "code": "TM"},
        {"name": "Turks and Caicos Islands", "code": "TC"},
        {"name": "Tuvalu", "code": "TV"},
        {"name": "Uganda", "code": "UG"},
        {"name": "Ukraine", "code": "UA"},
        {"name": "United Arab Emirates", "code": "AE"},
        {"name": "United Kingdom", "code": "GB"},
        {"name": "United States", "code": "US"},
        {"name": "United States Minor Outlying Islands", "code": "UM"},
        {"name": "Uruguay", "code": "UY"},
        {"name": "Uzbekistan", "code": "UZ"},
        {"name": "Vanuatu", "code": "VU"},
        {"name": "Venezuela", "code": "VE"},
        {"name": "Viet Nam", "code": "VN"},
        {"name": "Virgin Islands, British", "code": "VG"},
        {"name": "Virgin Islands, U.S.", "code": "VI"},
        {"name": "Wallis and Futuna", "code": "WF"},
        {"name": "Western Sahara", "code": "EH"},
        {"name": "Yemen", "code": "YE"},
        {"name": "Zambia", "code": "ZM"},
        {"name": "Zimbabwe", "code": "ZW"}
    ];

    euList =
        [
        {name:'Austria',code:'AT',vat:20},
        {name:'Belgium',code:'BE',vat:21},
        {name:'Bulgaria',code:'BG',vat:20},
        {name:'Croatia',code:'HR',vat:25},
        {name:'Cyprus',code:'CY',vat:19},
        {name:'Czech Republic',code:'CZ',vat:21},
        {name:'Denmark',code:'DK',vat:25},
        {name:'Estonia',code:'EE',vat:20},
        {name:'Finland',code:'FI',vat:24},
        {name:'France',code:'FR',vat:20},
        {name:'Germany',code:'DE',vat:19},
        {name:'Greece',code:'GR',vat:24},
        {name:'Hungary',code:'HU',vat:27},
        {name:'Ireland',code:'IE',vat:23},
        {name:'Italy',code:'IT',vat:22},
        {name:'Latvia',code:'LV',vat:21},
        {name:'Lithuania',code:'LT',vat:21},
        {name:'Luxembourg',code:'LU',vat:17},
        {name:'Malta',code:'MT',vat:18},
        {name:'Netherlands',code:'NL',vat:21},
        {name:'Poland',code:'PL',vat:23},
        {name:'Portugal',code:'PT',vat:23},
        {name:'Romania',code:'RO',vat:20},
        {name:'Slovakia',code:'SK',vat:20},
        {name:'Slovenia',code:'SI',vat:22},
        {name:'Spain',code:'ES',vat:21},
        {name:'Sweden',code:'SE',vat:25},
        {name:'United Kingdom',code:'GB',vat:20},
    ];

    businessTypes = [
        {id: 1, value: "Eyewear manufacturing"},
        {id: 2, value: "Eyewear brand"},
        {id: 3, value: "Design"},
        {id: 4, value: "Optician"},
        {id: 5, value: "Other"}
    ];

    industries = [
        'Lifestyle',
        'Design',
        'Fashion',
        'Luxury',
        'Snow goggles',
        'Sport',
        'Military and Safety',
        'ECP Service',
        'Kids eyewear'
    ];

    averageRetailPrices = [
        '< 50$',
        '50-100$',
        '100-150$',
        '150-200$',
        '200-300$',
        '> 300$'
    ];

    yearSinces = [];


    componentDidMount () {
        if(this.props.user) {
            this.setState({
                inputs: {
                    ...this.state.inputs,
                    companyName: this.props.user.company,
                    billingCountry: this.props.user.country,
                    billingCity: this.props.user.city,
                },
                isAdvancedPrivilegeRequest: !this.props.user.fullPrivilegeId || this.props.user.fullPrivilegeId == 0
            });
        }
        this.chekFPRequestCookie();
        this.isFullPrivilegRequestFunctionEnabled();
        this.popuplateYearSinceList();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.user && !!this.props.user) {
            this.setState({
                inputs: {
                    ...this.state.inputs,
                    companyName: this.props.user.company,
                    billingCountry: this.props.user.country,
                    billingCity: this.props.user.city,
                },
                isAdvancedPrivilegeRequest: !this.props.user.fullPrivilegeId || this.props.user.fullPrivilegeId == 0
            }, this.chekFPRequestCookie);
        }
    }

    isFullPrivilegRequestFunctionEnabled() {

         let params = {
            section: 'FULL_PRIVILEGE'
        }

        axios.post('/isModuleClosed', JSON.stringify(params), {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then(response => {
            if(response.data) {
                if(response.data.payload.isClosed){
                    this.setState({isFullPrivilegRequestFunctionEnabled: false, fullPrivilegeClosedEndDate: response.data.payload.dateEnd});
                }

            }
        })
        .catch(err => {
            console.log(err);
        });

    }

    checkIfIsCountryInUe = () => {
        let isInUE = this.euList.some(item => item.code === this.state.inputs['billingCountry']);
        return isInUE;
    }

    handleDifferentShippingCheckbox = () => {
        let showDifferenShipping = this.state.differentShippingAddress;

        this.setState({
            differentShippingAddress: !showDifferenShipping
        });

    }

    handleSubmit = event => {

        event.preventDefault();
        const { inputs, errors } = this.state;

        let notRequiredFields = [
            'shippingCity',
            'shippingCountry',
            'shippingPostalCode',
            'shippingStreet',
            'website',
            'PEC',
            'extraInfo',
            'privacy',
            'inBusinessSince',
            'industry',
            'averageRetailPrices'
        ];

        let isInUE = this.checkIfIsCountryInUe();

        if(!isInUE){
            notRequiredFields.push('vat');
        }

        let newErrorsObj = Object.entries(inputs)
          .filter(([key, value]) => {
            return (value.length === 0 || !value) && !notRequiredFields.includes(key);
          })
          .reduce((obj, [key, value]) => {
            if ( (value.length === 0 || !value)) {
              obj[key] = true;
            } else {
              obj[key] = false;
            }
            return obj;
          }, {});

        if (Object.keys(newErrorsObj).length > 0) {
          this.setState({
            errors: newErrorsObj
          });
        }
        else {

            let validVat = true;
            /*if(isInUE){
                let validate = require('validate-vat');
                validate( this.state.inputs.billingCountry,  this.state.inputs.vat,  (err, validationInfo) => {
                    if(validationInfo.valid) {
                        validVat = true;
                    }
                    else {
                        notify.error({
							content: "The VAT provided is not valid",
							options: { position: "top-right", autoClose: 3000 },
						})
                    }
                });
            }*/

            if(validVat) {
                let values = {
                    zeissId: this.props.user.zeissId,
                    name: this.props.user.name,
                    surname: this.props.user.surname,
                    email: this.props.user.email,
                    companyName:  this.state.inputs.companyName,
                    billingCountry: this.state.inputs.billingCountry,
                    billingCity: this.state.inputs.billingCity,
                    billingPostalCode: this.state.inputs.billingPostalCode,
                    billingStreet: this.state.inputs.billingStreet,
                    useDifferentShippingAddress: this.state.differentShippingAddress,
                    shippingCountry: this.state.inputs.shippingCountry,
                    shippingCity: this.state.inputs.shippingCity,
                    shippingPostalCode: this.state.inputs.shippingPostalCode,
                    shippingStreet: this.state.inputs.shippingStreet,
                    production: this.state.inputs.production,
                    vat: this.state.inputs.vat,
                    phone: this.state.inputs.phone,
                    department: this.state.inputs.department,
                    privacy: this.state.inputs.privacy !== null ? this.state.inputs.privacy : false,
                    businessType: this.state.inputs.businessType,
                    extraInfo: this.state.inputs.extraInfo,
                    website: this.state.inputs.website,
                    PEC: this.state.inputs.PEC,
                    inBusinessSince: this.state.inputs.inBusinessSince,
                    industry: this.state.inputs.industry,
                    averageRetailPrices: this.state.inputs.averageRetailPrices
                }

                axios.post('/requestAdvancedAccount', JSON.stringify(values),{
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(response => {
                    if(response.data) {
                        if(response.data.emailSent == 1) {
                            notify.success({ content: "The request was sent successfully", options: { position: 'top-right', autoClose: 3000 } });
                            this.setState({
                                inputs: {
                                    companyName: '',
                                    billingCountry: '',
                                    billingCity: '',
                                    billingPostalCode: '',
                                    billingStreet: '',
                                    shippingCountry: '',
                                    shippingCity: '',
                                    shippingPostalCode: '',
                                    shippingStreet: '',
                                    production: '',
                                    vat: '',
                                    phone: '',
                                    department: '',
                                    privacy: false,
                                    businessType: '',
                                    extraInfo: '',
                                    website: '',
                                    PEC: ''
                                },
                                errors: {
                                    companyName: false,
                                    billingCountry: false,
                                    billingCity: false,
                                    billingPostalCode: false,
                                    billingStreet: false,
                                    production: false,
                                    vat: false,
                                    phone: false,
                                    department: false,
                                    production: false,
                                    extraInfo: false,
                                    website: false,
                                    PEC: false
                                },
                                showRequestFullPrivilege: true,
                                isAdvancedPrivilegeRequest: false,
                                advancedRequestSucceeded: true
                            });

                            this.setAdvancedUser(response.data.idRequest, values.companyName);
                            // this.props.toggleFullPrivilegeModal();
                        }
                        else {
                            notify.success({ content: "There has been an error with your request. Please try again", options: { position: 'top-right', autoClose: 3000 } });
                        }
                    }
                    else {
                        notify.success({ content: "There has been an error with your request. Please try again", options: { position: 'top-right', autoClose: 3000 } });
                    }

                })
                .catch(err => {
                    console.log(err);
                });
            }
        }
    }

    handleRequestFullAccess = () => {

        if (this.props.user?.fullPrivilegeId > 0) {
            let values = {
                'requestId': this.props.user.fullPrivilegeId,
                'zeissId': this.props.user.zeissId,
                'name': this.props.user.name,
                'surname': this.props.user.surname,
                'email': this.props.user.email,
                'companyName':  this.props.user.company
            };

            axios.post('/requestFullAccount', JSON.stringify(values),{
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                if(response.data) {
                    if(response.data == 1) {
                        notify.success({ content: "The request was sent successfully", options: { position: 'top-right', autoClose: 3000 } });
                        this.setState({
                            ...this.state,
                            showRequestFullPrivilege: false,
                            fullRequestSucceded: true
                        });

                        this.setFullUser();
                        this.setFPRequesteCookie();
                    }
                    else {
                        notify.success({ content: "There has been an error with your request. Please try again", options: { position: 'top-right', autoClose: 3000 } });
                    }
                }
                else {
                    notify.success({ content: "There has been an error with your request. Please try again", options: { position: 'top-right', autoClose: 3000 } });
                }

            })
            .catch(err => {
                console.log(err);
            });
        }

    }

    setFPRequesteCookie = () => {
        const cookies = new Cookies();
        let d = new Date();
        d.setMonth(d.getMonth() + 1);
        cookies.set('fp-requested', 'Y', { path: '/', expires: d});
    }

    chekFPRequestCookie = () => {
        const cookies = new Cookies();
        if( cookies.get('fp-requested') || this.props.user?.fullAccessRequested){
            this.setState({
                showRequestFullPrivilege: false
            });
        }
        else {
            this.setState({
                showRequestFullPrivilege: true
            });
        }
    }


    handleOnChange = event => {
        const { name, value } = event.target;

        this.setState({
        inputs: {
            ...this.state.inputs,
            [name]: value
        },
        errors: {
            ...this.state.errors,
            [name]: (name == "privacy" && value == false) ? true : false
        }
        });

      };

    handleOnChangeSelect = event => {
        const { name, value } = event.target;

        this.setState({
            ...this.state,
            inputs: {
                ...this.state.inputs,
                [name]: event.target.value
            },
            errors: {
                ...this.state.errors,
                [name]: false,
                ['vat']: (this.checkIfIsCountryInUe() && this.state.inputs['vat'].length == 0) ? true : false
            }
        });
    };

    handleOnChangeMultiSelect = (event, name) => {
        const { value } = event;

        this.setState({
            ...this.state,
            inputs: {
                ...this.state.inputs,
                [name]: value
            },
            errors: {
                ...this.state.errors,
                [name]: false,
                ['vat']: (this.checkIfIsCountryInUe() && this.state.inputs['vat'].length == 0) ? true : false
            }
        });
    }

    handleOnBlur = event => {
        const inputs = this.state.inputs;
        if (inputs[event.target.name].length === 0) {
            let insertError = false;
            if((event.target.name == 'vat' && inputs['billingCountry'].length !== 0) || (event.target.name == 'production')) {
                insertError =  this.checkIfIsCountryInUe();
            }

            if(insertError) {
                this.setState({
                    errors: {
                    ...this.state.errors,
                    [event.target.name]: true
                    }
                });
            }
        }
    };

    setAdvancedUser = (requestId, companyName) => {
        if (this.props.user) {
            let user = {...this.props.user};
            user.accountType = 'Advanced';
            user.company = companyName;
            user.fullPrivilegeId = requestId;
            user.fullAccessRequested = false;
            user.fullPrivilegeRejected = false;

            let modules = user.modulesEnabled?.split(';') || [];
            if (!modules.includes("Catalogue_Full")) {
                // rimuove "Catalogue_BMQ" se presente in quanto deve essere sovrascritto con "Catalogue_Full"
                let i = modules.indexOf("Catalogue_BMQ");
                if (i > -1) {
                    modules.splice(i, 1);
                }
                modules.push("Catalogue_Full");
                user.catalogueRole = 'Full';
                user.modulesEnabled = modules.join(";");
            }

            if (!modules.includes("VendorNetworkConsultant")) {
                user.vendorVisible = true;
                modules.push("VendorNetworkConsultant");
                user.modulesEnabled = modules.join(";");
            }

            localStorage.setItem('user', JSON.stringify(user));
            this.props.onAuth(user);
        }
    }

    setFullUser = () => {
        if (this.props.user) {
            let user = {...this.props.user};
            user.fullAccessRequested = true;

            localStorage.setItem('user', JSON.stringify(user));
            this.props.onAuth(user);
        }
    }

    popuplateYearSinceList = () => {
        if (this.yearSinces.length == 0) {
            let startYear = new Date().getUTCFullYear();
            while(startYear >= 1900 ) {
                this.yearSinces.push(startYear);
                startYear--;
            }
        }
    }

    handleCloseModal = () => {
        if (this.state.advancedRequestSucceeded) {
            this.setState({
                ...this.state,
                advancedRequestSucceeded: false,
            }, () => this.props.toggleFullPrivilegeModal());
        } else {
            this.setState({
                ...this.state,
                fullRequestSucceded: false
            }, () => this.props.toggleFullPrivilegeModal());
        }
    }

    render() {
        return (

            <Modal open={this.props.opened}  onClose={() => this.handleCloseModal() } className="full-privilege-modal">
                {

                    !this.state.showRequestFullPrivilege ?
                    <div className="fp-header">
                        {
                            this.state.fullRequestSucceded ?
                            <p>
                                Thank you for applying for the pricelist and e-shop functionalities of MyZEISS. Our sales representative will contact you shortly.<br/>
                                We will automatically grant you access if you are an existing customer and if your email is already in our system.
                            </p>
                            :
                            <p>
                                Your request is being processed. You will be contacted shortly by our sales representative.
                            </p>
                        }
                        {
                            !this.state.isFullPrivilegRequestFunctionEnabled ?
                            <p className='fp-closed-office'> Our offices are closed you may not receive a reply before the {this.state.fullPrivilegeClosedEndDate} </p>
                            :
                            <div></div>
                        }
                    </div>
                    :
                    (
                        this.state.advancedRequestSucceeded ?
                        <div className="fp-header">
                            <p>
                                You can now navigate through our entire product portfolio, e-learning and frame factories sections.<br/>
                                We will automatically grant you access to all the remaining modules shortly if you are an existing customer and if your email is already in our system.
                            </p>
                            {
                                !this.state.isFullPrivilegRequestFunctionEnabled ?
                                <p className='fp-closed-office'> Our offices are closed you may not receive a reply before the {this.state.fullPrivilegeClosedEndDate} </p>
                                :
                                <div></div>
                            }
                        </div>
                        :
                        (
                            this.state.isAdvancedPrivilegeRequest ?
                            <div>
                                <div className="fp-header">
                                    <h2> Do you want to access advanced functionalities? </h2>
                                    <p> To see the full product catalogue and restricted areas, please fill in the following company information. </p>
                                    {
                                        !this.state.isFullPrivilegRequestFunctionEnabled ?
                                        <p className='fp-closed-office'> Our offices are closed you may not receive a reply before the {this.state.fullPrivilegeClosedEndDate} </p>
                                        :
                                        <div></div>
                                    }
                                </div>
                                <div className="signup-container" >
                                    <div className="signup-grid">
                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Company Name <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <input type="text" className={this.state.errors.companyName ? "form-input-fail" : ""}
                                                name='companyName' value={this.state.inputs.companyName} onChange={this.handleOnChange} onBlur={this.handleOnBlur}></input>
                                                {
                                                    this.state.errors.companyName ?
                                                    <p className="required-field">
                                                        Field required
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                VAT or Tax Number <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <input type="text" className={this.state.errors.vat ? "form-input-fail" : ""}
                                                name='vat' value={this.state.inputs.vat} onChange={this.handleOnChange} onBlur={this.handleOnBlur}></input>
                                                {
                                                    this.state.errors.vat ?
                                                    <p className="required-field">
                                                        Field required
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Department <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <select name="department" value={this.state.inputs.department} className={this.state.errors.department ? "form-input-fail" : ""} onChange={this.handleOnChangeSelect}>
                                                        <option value=''> Select a value </option>
                                                        {
                                                            this.departments.map( (item) => {
                                                                return <option key={item.value} value={item.value}>
                                                                        {item.value}
                                                                    </option>;
                                                            })
                                                        }
                                                </select>
                                                {
                                                    this.state.errors.department ?
                                                    <p className="required-field">
                                                        Field required
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Telephone number <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <input type="text" className={this.state.errors.phone ? "form-input-fail" : ""}
                                                name='phone' value={this.state.inputs.phone} onChange={this.handleOnChange} onBlur={this.handleOnBlur}></input>
                                                {
                                                    this.state.errors.phone ?
                                                    <p className="required-field">
                                                        Field required
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Yearly sunglasses production (volume) <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <input type="number" className={this.state.errors.production ? "form-input-fail" : ""} name='production' value={this.state.inputs.production} onChange={this.handleOnChange} onBlur={this.handleOnBlur} />
                                                {
                                                    this.state.errors.production ?
                                                    <p className="required-field">
                                                            Field required
                                                        </p>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Describe the nature of your business <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <select name="businessType" value={this.state.inputs.businessType} className={this.state.errors.businessType ? "form-input-fail" : ""} onChange={this.handleOnChangeSelect}>
                                                        <option value=''> Select a value </option>
                                                        {
                                                            this.businessTypes.map( (item) => {
                                                                return <option key={item.value} value={item.value}>
                                                                        {item.value}
                                                                    </option>;
                                                            })
                                                        }
                                                </select>
                                                {
                                                    this.state.errors.businessType ?
                                                    <p className="required-field">
                                                        Field required
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Additional company information
                                            </div>
                                            <div className="signup-tf-content">
                                                <textarea name='extraInfo' value={this.state.inputs.extraInfo} onChange={this.handleOnChange} ></textarea>
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Web Site
                                            </div>
                                            <div className="signup-tf-content">
                                                <input type="text"
                                                name='website' value={this.state.inputs.website} onChange={this.handleOnChange} ></input>
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                PEC email – for Italian companies only
                                            </div>
                                            <div className="signup-tf-content">
                                                <input type="text"
                                                name='PEC' value={this.state.inputs.PEC} onChange={this.handleOnChange} ></input>
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                In business since
                                            </div>
                                            <div className="signup-tf-content">
                                                <select name="inBusinessSince" value={this.state.inputs.inBusinessSince} className={this.state.errors.inBusinessSince ? "form-input-fail" : ""} onChange={this.handleOnChangeSelect}>
                                                        <option value=''> Select a value </option>
                                                        {
                                                            this.yearSinces.map( (item) => {
                                                                return <option key={item} value={item}>
                                                                        {item}
                                                                    </option>;
                                                            })
                                                        }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Industry
                                            </div>
                                            <div className={this.state.errors.industry ? "signup-tf-content zeiss-dropdown form-input-fail" : "signup-tf-content zeiss-dropdown"}>
                                                <DropdownField multiple data={this.industries} placeholder="Select a value" value={this.state.inputs.industry} onChange={(event) => this.handleOnChangeMultiSelect(event, "industry")} />
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Average retail price of your sunglasses (in US $)
                                            </div>
                                            <div className={this.state.errors.averageRetailPrices ? "signup-tf-content zeiss-dropdown form-input-fail" : "signup-tf-content zeiss-dropdown"}>
                                                <DropdownField multiple data={this.averageRetailPrices} placeholder="Select a value" value={this.state.inputs.averageRetailPrices} onChange={(event) => this.handleOnChangeMultiSelect(event, "averageRetailPrices")} />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="divider-row" ></div>
                                    <h3> Biling address </h3>
                                    <div className="signup-grid">
                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Country <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <select name="billingCountry" value={this.state.inputs.billingCountry} className={this.state.errors.billingCountry ? "form-input-fail" : ""} onChange={this.handleOnChangeSelect}>
                                                        <option value=''> Select a value </option>
                                                        {
                                                            this.countries.map( (item) => {
                                                                return <option key={item.code} value={item.code}>
                                                                        {item.name}
                                                                    </option>;
                                                            })
                                                        }
                                                </select>
                                                {
                                                    this.state.errors.billingCountry ?
                                                    <p className="required-field">
                                                        Field required
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                City <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <input type="text" className={this.state.errors.billingCity ? "form-input-fail" : ""}
                                                name='billingCity' value={this.state.inputs.billingCity} onChange={this.handleOnChange} onBlur={this.handleOnBlur}></input>
                                                {
                                                    this.state.errors.billingCity ?
                                                    <p className="required-field">
                                                        Field required
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Postal Code <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <input type="text" className={this.state.errors.billingPostalCode ? "form-input-fail" : ""}
                                                name='billingPostalCode' value={this.state.inputs.billingPostalCode} onChange={this.handleOnChange} onBlur={this.handleOnBlur}></input>
                                                {
                                                    this.state.errors.billingPostalCode ?
                                                    <p className="required-field">
                                                        Field required
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                        <div className="signup-tf-container">
                                            <div className="signup-tf-label">
                                                Street <span class="required-field">*</span>
                                            </div>
                                            <div className="signup-tf-content">
                                                <input type="text" className={this.state.errors.billingStreet ? "form-input-fail" : ""}
                                                name='billingStreet' value={this.state.inputs.billingStreet}  onChange={this.handleOnChange} onBlur={this.handleOnBlur}></input>
                                                {
                                                    this.state.errors.billingStreet ?
                                                    <p className="required-field">
                                                        Field required
                                                    </p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="signup-tf-container" >
                                        <div className="signup-tf-content checkbox-form-prospect ">
                                            <Checkbox
                                                style={{float: 'left'}}
                                                onChange={() => this.handleDifferentShippingCheckbox()}
                                            > Use different shipping address </Checkbox>
                                        </div>
                                    </div>
                                    {
                                        this.state.differentShippingAddress ?
                                        <div>
                                            <div className="divider-row"></div>
                                            <h3> Shipping address </h3>
                                        </div>
                                        :
                                        null
                                    }


                                        {this.state.differentShippingAddress ?
                                        <div className="signup-grid">
                                            <div className="signup-tf-container">
                                                <div className="signup-tf-label">
                                                    Country
                                                </div>
                                                <div className="signup-tf-content">
                                                    <select name="shippingCountry" value={this.state.inputs.shippingCountry} onChange={this.handleOnChangeSelect}>
                                                            <option value=''> Select a value </option>
                                                            {
                                                                this.countries.map( (item) => {
                                                                    return <option key={item.code} value={item.code}>
                                                                            {item.name}
                                                                        </option>;
                                                                })
                                                            }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="signup-tf-container">
                                                <div className="signup-tf-label">
                                                    City
                                                </div>
                                                <div className="signup-tf-content">
                                                    <input type="text" name='shippingCity' value={this.state.inputs.shippingCity} onChange={this.handleOnChange} onBlur={this.handleOnBlur}></input>
                                                </div>
                                            </div>

                                            <div className="signup-tf-container">
                                                <div className="signup-tf-label">
                                                    Postal Code
                                                </div>
                                                <div className="signup-tf-content">
                                                    <input type="text" name='shippingPostalCode' value={this.state.inputs.shippingPostalCode}  onChange={this.handleOnChange} onBlur={this.handleOnBlur}></input>

                                                </div>
                                            </div>

                                            <div className="signup-tf-container">
                                                <div className="signup-tf-label">
                                                    Street
                                                </div>
                                                <div className="signup-tf-content">
                                                    <input type="text" name='shippingStreet' value={this.state.inputs.shippingStreet} onChange={this.handleOnChange} onBlur={this.handleOnBlur}></input>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                        }
                                        <div className="signup-tf-container">
                                            <div className="signup-tf-content">
                                                <div className="checkbox-form-prospect">
                                                    <Checkbox
                                                        onChange={(checkbox) => this.handleOnChange({target: {name: 'privacy', value: checkbox.value}})}
                                                    >I want to receive news and offers from ZEISS </Checkbox>
                                                </div>
                                                <a href="https://www.zeiss.com/data-protection/en/home.html" target="_blank" className="privacy-anchor privacy-fp"> Privacy Policy </a>
                                            </div>
                                        </div>
                                        <div>
                                            <span class="required-field">* Field required</span>
                                        </div>
                                        <div className="button-singup-prospect-cont">
                                            <Button  buttonStyle="primary" style={{width: '150px', marginBottom: '40px' }} onClick={ this.handleSubmit }> Submit </Button>
                                        </div>
                                    </div>
                            </div>
                            :
                            <div>
                                <div className="fp-header">
                                    <h2> Access the pricelist and the e-shop </h2>
                                    <Button  buttonStyle="primary" style={{width: '250px', marginBottom: '40px' }} onClick={ this.handleRequestFullAccess }> REQUEST FULL ACCESS </Button>
                                </div>
                            </div>
                        )
                    )
                }
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        opened: state.fullPrivilegeModal.open
    };
}

const mapDispatchToProps = dispatch => {
    return {
        toggleFullPrivilegeModal: () => dispatch(actions.toggleFullPrivilegeModal()),
        onAuth: (user) => dispatch( actions.authSuccess(user) )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFullPrivilege);