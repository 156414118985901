import React, { Component } from 'react';
import './MyOrder.css';
import { withRouter } from 'react-router';
import axios from '../../../axios';
import { Table, Button } from 'precise-ui/dist/es6';
import NumberFormat from 'react-number-format';
import CartItem from '../../../components/CartItem/CartItem';

class MyOrder extends Component {

    state = {
        order: null,
        showSkeletons: true
    }

    orderItemColumns = {
        item: 'Product',
        Quantity: 'Quantity',
        Price: 'Price'
    };

    componentDidMount () {
        if (!this.props.match || !this.props.match.params.id) return;

        axios.get('/getOrder/'+this.props.match.params.id)
        .then(response => {
            if (response.data.type === "success") {
                const order = {...response.data.payload};
                const products = order.Items.filter(i => i.Product != null);
                const shippingItems = order.Items.filter(i => i.Product ==  null);
                this.setState(() => ({
                    ...this.state,
                    order: {
                        ...order,
                        Products: products,
                        ShippingItems: shippingItems
                    },
                    showSkeletons: false
                }));
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    headerCellRender = headerCell => {
        console.log(headerCell);
        const key = headerCell.key;
        const value = headerCell.value;

        if (key.toLowerCase() === 'price') {
            return (
                <div className="text-right">
                    {value}
                </div>
            );
        } else {
            return value;
        }

    }

    cellTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'price':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'item':
                if (data.Product) {
                    return (
                        <CartItem {...data.Product} />
                    );
                }
                return null;
            default:
                return value;
        }
    }

    trackOrder = () => {
        window.open(this.state.order.TrackingURL, '_blank');
    }

    render() {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                <div className="back-shopping back-top"  onClick={this.props.history.goBack} > <span class="icon-direction-back m-r-5"> </span> Back </div>
                {
                    this.state.order ?
                    <div className="order-container">
                        <h3>Order details</h3>
                        <div className="order-block">
                            <div className="order-header d-sm-flex">
                                <div>
                                    <h4>Order date: {this.state.order.CreationDate}&nbsp;{this.state.order.CreationTime}</h4>
                                </div>
                                {
                                    this.state.order.TrackingCode ?
                                    <div className="tracking-container" style={{float: "right"}}>
                                        <span> Tracking Code: </span> <span id="tracking-code"> {this.state.order.TrackingCode} </span>
                                        <Button  buttonStyle="secondary"  onClick={this.trackOrder}>Track your order</Button>
                                    </div>
                                        
                                    :
                                    null
                                }
                                
                            </div>
                            <div className="order-body">
                                <div>
                                    <Table
                                        className="cart-table" 
                                        data={this.state.order.Products} 
                                        columns={this.orderItemColumns} 
                                        placeholder="No items yet."
                                        headerCellRenderer={this.headerCellRender}
                                        cellRenderer={this.cellTableRender}  
                                        borderless>
                                    </Table>
                                </div>
                                <div>
                                    <table className="totalTable">
                                        <thead>
                                            <tr>
                                                <th style={{width: '50%'}}></th>
                                                <th style={{width: '50%'}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><p className="not-important-total">Products Price:</p></td>
                                                <td className="text-right not-important-total">
                                                    <NumberFormat 
                                                        value={this.state.order.ProductsPrice} 
                                                        displayType={'text'} 
                                                        prefix={'€ '} 
                                                        decimalSeparator={'.'} 
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p className="not-important-total">Shipping Price:</p></td>
                                                <td className="text-right not-important-total">
                                                    <NumberFormat 
                                                        value={this.state.order.ShippingPrice} 
                                                        displayType={'text'} 
                                                        prefix={'€ '} 
                                                        decimalSeparator={'.'} 
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p className="not-important-total">Admin Costs:</p></td>
                                                <td className="text-right not-important-total">
                                                    <NumberFormat 
                                                        value={this.state.order.AdminCosts} 
                                                        displayType={'text'} 
                                                        prefix={'€ '} 
                                                        decimalSeparator={'.'} 
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><h4>SubTotal:</h4></td>
                                                <td className="text-right important-total">
                                                    <NumberFormat 
                                                        value={this.state.order.SubTotal} 
                                                        displayType={'text'} 
                                                        prefix={'€ '} 
                                                        decimalSeparator={'.'} 
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p className="not-important-total">VAT:</p></td>
                                                <td className="text-right not-important-total">
                                                    <NumberFormat 
                                                        value={this.state.order.VAT} 
                                                        displayType={'text'} 
                                                        prefix={'€ '} 
                                                        decimalSeparator={'.'} 
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="text-right" ><p className="not-important-total">{this.state.order.VATDescription} </p></td>
                                            </tr>
                                            <tr>
                                                <td><p className="not-important-total">Stamp Tax:</p></td>
                                                <td className="text-right not-important-total">
                                                    <NumberFormat 
                                                        value={this.state.order.StampTax} 
                                                        displayType={'text'} 
                                                        prefix={'€ '} 
                                                        decimalSeparator={'.'} 
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><h3>Total:</h3></td>
                                                <td className="text-right total important-total">
                                                    <NumberFormat 
                                                        value={this.state.order.FinalTotal} 
                                                        displayType={'text'} 
                                                        prefix={'€ '} 
                                                        decimalSeparator={'.'} 
                                                        decimalScale={2}
                                                        fixedDecimalScale={true} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null //skeleton
                }
            </div>
        );
    }

}

export default withRouter(MyOrder);