import React from 'react';
import './NavItem.css';
import { NavLink } from 'react-router-dom';

const NavItem = (props) => {
        return  (
        <div className='NavItem'>
            {
                ( props.subPages.length > 0 && props.subPages.some(subPage => subPage.MantainPreviousUrlSegment === 1) ) ?
                <NavLink to={props.url} activeClassName="active-menu-item" onClick={ (event) => event.preventDefault() }> {props.title } </NavLink>
                :
                <NavLink to={props.url} activeClassName="active-menu-item" > {props.title } </NavLink>
            }
           
           {
                typeof props.subPages !== undefined && props.subPages.length > 0 ?                            
                    <ul className="dropdown-main-menu">
                        {
                        props.subPages.map(subPage => {
                            let link = props.url +'/'+ subPage.URLSegment;
                            if(subPage.MantainPreviousUrlSegment === 0 ) link = '/'+subPage.URLSegment;
                            return  <NavLink className="sub-link" to={link} activeClassName="active-menu-item" > {subPage.Title} </NavLink>
                        })                   
                    }                   
                </ul>
                :
                null
            }
        </div>       
    )
}

export default NavItem;