import React from 'react';
import './QuantitySelector.css';
import _ from 'lodash';

const quantitySelector = props => {
    let options = [0];
    if (props.start > 0 && props.end > 0 && props.step > 0) {
        options = _.range(props.start, props.end, props.step);
        options.push(props.end);
    }

    return (
        <select className="qty-selector" value={props.value} onChange={props.change}>
            {
                options.map((qty, i) => (
                    <option key={i} value={qty}>{qty + " pcs."}</option>
                ))
            }
        </select>
    );
};

export default quantitySelector;