import React, { Component } from 'react';
import axios from '../../../axios';
import RightSection from '../../../components/Section/RightSection/RightSection';
import LeftSection from '../../../components/Section/LeftSection/LeftSection';
import './Returns.css';

class Returns extends Component {

    state = {
        sections: []
    }

    componentDidMount() {
        let path = this.props.match.path;
        path = path.substring(path.lastIndexOf("/"), path.length);
        
        axios.post('/getPageContentByUrl', {
            url: path.replace("/", '')
        }).then(response => {
            this.setState({
                ...this.state,
                sections: response.data.Section
            });
        });
        window.scrollTo(0, 0);
    }

    render() {
        let sections = [];
        sections.push(this.state.sections.map((section, i) => {
            if (i % 2 === 0) {
                return <RightSection key={section.ID} section={section} />
            } else {
                return <LeftSection key={section.ID} section={section} />
            }
        }));

        return (
            <div>
                <div className="header-blue-line"> </div>
                <div className="page-container">
                    <h1 className="generic-page-title"> Returns </h1>
                    <p>In order to make our e-commerce process easy and quick for you, we rely on lenses renders rather than physical samples.</p>
                </div>       
                <div className="returns" >
                    {sections}
                </div>         
                
            </div>
        );
    }
}

export default Returns;