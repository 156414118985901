import React, { useState, useEffect } from 'react';

import './Category.css';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const Category = (props) => {
    let [carouselValue, setCarouselValue] = useState(0);

    useEffect(() => {
        let el = document.getElementById('persona-categories-mobile');
        if (el) el.scrollIntoView({behavior: 'smooth', block: 'center'});
    })
    
    let firstRow = props.category.Levels.filter(function (element, index, array) {
        let halfIndex = Math.ceil(props.category.Levels.length / 2);
        if (index < halfIndex) {
            return element;
        }
    });

    let secondRow = props.category.Levels.filter(function (element, index, array) {
        let halfIndex = Math.ceil(props.category.Levels.length / 2);
        if (array.indexOf(element) >= halfIndex) {
            return element;

        }
    });

    return (

        <div className="personas-container no-wrap">
            <div className="box-persona-cat opacity-box" onClick={ () => props.clickedBackPersona()}>
                <img src={props.persona.Image} />
                <p>{props.persona.Title} </p>
            </div>
            <div className="box-selected-category opacity-box" onClick={ () => props.clickedBackCategory()}>
                <div className="img-container">
                    <img src={props.category.Icon} />
                    <p>{props.category.Title} </p>
                </div>
            </div>
            <div className="category-levels d-none d-md-inline-block">
                <div>
                    {
                        firstRow.map((level) => (
                            <div className="box-category box-int-level " onClick={ () => props.clicked(level)}>                                
                                <img src={level.Image} />
                                <p>{level.Title} </p>                                
                            </div>
                        ))   
                    }
                </div>
                <div>
                    {
                        secondRow.map((level) => (
                            <div className="box-category box-int-level " onClick={ () => props.clicked(level)}>                                
                                <img src={level.Image} />
                                <p>{level.Title} </p>                                
                            </div>
                        ))   
                    }
                </div>
            </div>
            <div className="persona-categories-mobile d-block d-md-none" id="persona-categories-mobile">
                <Carousel  
                    value={carouselValue}
                    onChange={value => setCarouselValue(carouselValue = value)}
                    centered
                    slidesPerPage={2}
                >
                    {
                        props.category.Levels.map((level) => (
                            <div className="box-category box-int-level " onClick={ () => props.clicked(level)}>                                
                                <img src={level.Image} />
                                <p>{level.Title} </p>                                
                            </div>
                        ))
                    }
                </Carousel>
                <Dots className="ZEISS_dots_carousel" value={carouselValue} onChange={value => setCarouselValue(value)} number={props.category.Levels.length} />
            </div>
        </div>
    );
}

export default Category;