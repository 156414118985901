import React from 'react';
import './SectionImage.css';

const sectionImage = props => {
    const classList = Array.isArray(props.classList) ? props.classList.slice() : [];
    return (
        <div className={classList.join(' ')}>
            <img src={props.url} className='block-image'  alt={props.alt} />
        </div>
    );
}

export default sectionImage;