import React from 'react';
import './ProductImage.css';
import imageNotFound from   '../../res/images/Image-not-found.jpg';

const productImage = props => {
    let classes = ["product-image"];
    if (props.active) classes.push("active");
    return (
        <div className={classes.join(' ')} onClick={props.clicked}>
            <img src={props.src.replaceAll(' ', '%20')} alt={props.label} onError={(e)=>{e.target.onerror = null; e.target.src=imageNotFound}}/>
            <p>{props.label}</p>
        </div>
    );
};

export default productImage;