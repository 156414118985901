import React from 'react';
import './SlideShow.css';
import { Carousel} from 'precise-ui';
import { Button } from 'precise-ui/dist/es6';


const SlideShow = (props) => {
    return  (
        <div className="SlideShow">
            <div className="mobile-layer"></div>
            <Carousel infinite autoplay={8000}>
                { 
                    props.images.map((image) => (
                        <div className="slider-img" style={{  width: '100%', background: `linear-gradient(90deg, rgba(255,255,255,0.7) 10%, rgba(255,255,255,0) 70%), url(${image.Image})`, backgroundSize: 'cover', backgroundPosition: 'center',  'margin-bottom': '20px'}}>

                        <div className="page-container">
                            <div className="slider-text-container">
                                <h2 className="slider-title"> {image.Text} </h2>   
                                {
                                    image.URL != null ?
                                        <Button className="big-cta" href={image.URL}>{!!image.CTALabel ? image.CTALabel : 'See more'}</Button>    
                                    :
                                    null
                                }
                            </div>
                        </div>
                        </div>
                        ))
                }

            </Carousel>
        </div>
    )
}

export default SlideShow;