import React, { Component } from 'react';
import './ProductRender.css';
import renderIcon from '../../res/images/render-icon-360.png';
import { Icon, Modal, ModalBody, Tooltip} from 'precise-ui/dist/es6';
import imageNotFound from   '../../res/images/Image-not-found.jpg';

class ProductRender extends Component {
    state = {
        openModal: false
    }
    
    fullscreenHandler = () => {
        this.toogleModal();
    }

    toogleModal = () => {
        this.setState({
            ...this.state,
            openModal: !this.state.openModal
        });
    }

    render() {
        const iframe = this.props.render && this.props.render.Render ? (
            <iframe 
                key={this.props.render.ID+"_render"}
                src={this.props.render.Render}
                allowFullScreen
                className="render"
                frameBorder="0"
                scrolling="no"
                title="product render 3d">
            </iframe>
        ) : <img src={imageNotFound} className="render" style={{width: '100%'}} alt="Render not found"></img>;
        return (
            <div className="render-main">
                <div className="render-container">
                    {iframe}
                </div>
                <img className="render-icon" src={renderIcon} alt="render-icon" />
                {
                    this.props.shoWhislistIcon ?                    
                    <div className="add-to-wish-icon">
                        {
                            this.props.addedToWishList ? 
                            <Tooltip className="add-to-wish-icon" content="Add to whishlist">
                                <Icon  name='Favorite' size={3} color="#008bd0" onClick={this.props.removeFromWishListHandler} />
                            </Tooltip>
                            :
                            <Tooltip className="add-to-wish-icon" content="Add to whishlist">
                                <Icon name='FavoriteBorder' size={3} color="#008bd0" onClick={this.props.addToWishlistHandler}/>
                            </Tooltip>
                        }
                    </div>                    
                    :
                    null
                }
                <Icon className="render-fullscreen-icon" name="Fullscreen" size={2} onClick={this.fullscreenHandler}/>
                
                <Modal open={this.state.openModal} onClose={this.toogleModal} className="render-fullscreen-modal">
                    <ModalBody>
                        <div className="render-main">
                            <div className="render-container">
                                {iframe}
                            </div>
                            <img className="render-icon" src={renderIcon} alt="render-icon" />
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ProductRender;