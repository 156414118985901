export const config = {
    backend: 'https://backend.sunlens-myzeiss.com/',
    assets: 'https://backend.sunlens-myzeiss.com/assets/',
    salesForceENV: 'Prod',
    clientId: 'd41cc26c-b77c-4483-91bb-c6270ca7b2ca',
    frontend: 'https://sunlens-myzeiss.com/',
    zeissIdUrl: 'https://id-ip.zeiss.com/CD/OAuth/OpenidConfiguration?p=',
    analytics: 'UA-180178029-1',
    cookiebotDomainGroupId: 'bb78e6f4-483c-4ad8-a6e7-2e5770c8fce4',
    activeCampaignAccount: '477950277',
    gtag: 'AW-10905270588',
    recaptchaKey: '6LeSo3QnAAAAAGuC5hs4pubQWNWQZSeMt9BPoCHC',
    gtm: 'GTM-KJQZT2DW',
    xApiKey: "6yG4UcpXx0lioIL8Qz757dAfkYUj105d",
    clientID: "eEuf4ryzlnkiLIOaErU3H8q4APg0vEtu"
};