import React, { Component } from 'react';
import './VendorsNetwork.css';
import { connect } from 'react-redux';
import axios from '../../../axios';
import VendorNetworkCard from '../../../components/VendorNetworkCard/VendorNetworkCard';
import { DropdownField, Slider, Button } from 'precise-ui/dist/es6';
import vendorIcon from '../../../res/images/icon_factory_02.png';


class VendorsNetwork extends Component {
    state = {
        vendorsNetwork: [],
        filters: {
            productionCountry: null,
            companyTypes: null,
            markets: null,
            manufacturingCompanyServices: null,
            yearlyProductionCapacity: null,
            lensEdging: null,
            ecologicalManufacturingProcess: null,
            frameMaterials: null,
            moq: {min: 0, max: 0},
        },
        filterItems: {
            productionCountry: [],
            companyTypes: [],
            markets: [],
            manufactoringCompanyServices: [],
            yearlyProductionCapacity: [],
            lensEdging: [],
            ecologicalManufacturingProcess: [],
            frameMaterials: [],
            moq: []
        },
        slider: {min: 0, max: 0},
        filterVisible: false
    };

    moqChangeTimer = null;

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.modulesEnabled.split(';').includes("VendorNetwork")) {
            this.props.history.push("/vendors-network/0");
        } else {
            if (!this.loadFromSessionStorage()) {
                this.callGetVendorsNetwork(this.callGetVendorsNetworkFilters);
            }
        }
        sessionStorage.removeItem('vendorsNetwork');
    }

    componentWillUnmount() {
        sessionStorage.setItem('vendorsNetwork', JSON.stringify(this.state));
    }

    callGetVendorsNetwork = (callbak = null) => {
        axios.post("/getVendorsNetwork", {filters: this.state.filters}).then(response => {
            if (response.data.type === 'success') {
                this.setState({
                    ...this.state,
                    vendorsNetwork: response.data.payload
                }, callbak)
            }
        })
    }

    callGetVendorsNetworkFilters = () => {
        axios.get("/getVendorsNetworkFilters").then(response => {
            if (response.data.type === 'success') {
                let slider = this.state.slider;
                let filtersMoq = this.state.filters.moq;
                if (response.data.payload.moq && response.data.payload.moq.length > 0) {
                    slider.min = filtersMoq.min = 0;
                    slider.max = filtersMoq.max = response.data.payload.moq[response.data.payload.moq.length-1];
                }
                this.setState({
                    ...this.state,
                    filters: {
                        ...this.state.filters,
                        moq: filtersMoq
                    },
                    filterItems: response.data.payload,
                    slider: slider
                })
            }
        })
    }

    filterChangeHandler = (event, filterName) => {
        if (filterName) {
            this.setState({
                ...this.state,
                filters: {
                    ...this.state.filters,
                    [filterName]: event.value
                }
            }, this.callGetVendorsNetwork)
        }
    }

    changeSliderHandler = (event) => {
        let {value, index} = event;
        let moq = this.state.filters.moq;
        this.clearMoqChangeTimeout();
        if (index === 0) {
            if (value > moq.max) value = moq.max-1;
            moq.min = value;
        } else {
            if (value < moq.min) value = moq.min+1;
            moq.max = value;
        }
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                moq: moq
            }
        }, this.setMoqChangeTimeout);
    }

    setMoqChangeTimeout = () => {
        this.moqChangeTimer = setTimeout(() => {
            this.callGetVendorsNetwork();
        }, 1000);
    }

    clearMoqChangeTimeout = () => {
        if (this.moqChangeTimer) clearTimeout(this.moqChangeTimer);
    }

    learnMoreVendorHandler = (vendorId) => {
        this.props.history.push("/vendors-network/"+vendorId, { from: this.props.location.pathname });
    }

    loadFromSessionStorage = () => {
        const routeState = this.props.location.state;
        if (routeState != null && (routeState.from.indexOf('/vendors-network') !== -1)) {
            const prevState = sessionStorage.getItem('vendorsNetwork');
            if (prevState) {
                this.setState(JSON.parse(prevState));
                return true;
            } else return false;
        } else {
            return false;
        }
    }

    resetFilterHandler = () => {
        let moqFilter = {min: 0, max: 0};
        if (Array.isArray(this.state.filterItems.moq) && this.state.filterItems.moq.length > 0) {
            moqFilter.max = this.state.filterItems.moq[this.state.filterItems.moq.length-1];
        }

        this.setState({
            ...this.state,
            filters: {
                productionCountry: null,
                companyTypes: null,
                markets: null,
                manufacturingCompanyServices: null,
                yearlyProductionCapacity: null,
                lensEdging: null,
                ecologicalManufacturingProcess: null,
                frameMaterials: null,
                moq: moqFilter,
            }
        }, this.callGetVendorsNetwork);
    }

    render() {
        const { min, max } = this.state.filters.moq;
        return (
            <div>
                <div className="page-container">
                    <div className="header-gray-line"></div>
                </div>
                <div className="page-container">
                    <h1 className="generic-page-title vendor-title-with-icon"><img className="m-r-5" src={vendorIcon} style={{height: '35px'}} />Frame Factories</h1>
                </div>
                <div>
                    <div className="page-container">
                        <div className={this.state.filterVisible ? "vendor-filter-bar open" : "vendor-filter-bar"} onClick={ () => this.setState({ ...this.state, filterVisible: !this.state.filterVisible})}>
                            <h3>Filters</h3>
                        </div>
                        {
                            this.state.filterVisible ?
                            <div className="vendors-network-filters">
                                <div className="filters-container">
                                    <div className="filter dropdown">
                                        <label htmlFor="production-contry-filter">Production country</label>
                                        <DropdownField id="production-contry-filter" name="production-contry-filter" onChange={event => this.filterChangeHandler(event, 'productionCountry')} data={this.state.filterItems.productionCountry} value={this.state.filters.productionCountry}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="company-types-filter">Company type</label>
                                        <DropdownField id="company-types-filter" name="company-types-filter" onChange={event => this.filterChangeHandler(event, 'companyTypes')} data={this.state.filterItems.companyTypes} multiple maxSelectedShown="2" value={this.state.filters.companyTypes}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="markets-filter">Markets</label>
                                        <DropdownField id="markets-filter" name="markets-filter" onChange={event => this.filterChangeHandler(event, 'markets')} data={this.state.filterItems.markets} multiple maxSelectedShown="2" value={this.state.filters.markets}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="manufacturing-services-filter">Manufacturing services</label>
                                        <DropdownField id="manufacturing-services-filter" name="manufacturing-services-filter" onChange={event => this.filterChangeHandler(event, 'manufacturingCompanyServices')} data={this.state.filterItems.manufacturingCompanyServices} multiple maxSelectedShown="2" value={this.state.filters.manufacturingCompanyServices}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="yearly-prod-filter">Yearly production capacity</label>
                                        <DropdownField id="yearly-prod-filter" name="yearly-prod-filter" onChange={event => this.filterChangeHandler(event, 'yearlyProductionCapacity')} data={this.state.filterItems.yearlyProductionCapacity} value={this.state.filters.yearlyProductionCapacity}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="lens-edging-filter">Lens edging</label>
                                        <DropdownField id="lens-edging-filter" name="lens-edging-filter" onChange={event => this.filterChangeHandler(event, 'lensEdging')} data={this.state.filterItems.lensEdging} value={this.state.filters.lensEdging}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="ecological-manufaturing-process-filter">Ecological Manufactoring process</label>
                                        <DropdownField id="ecological-manufaturing-process-filter" name="ecological-manufaturing-process-filter" onChange={event => this.filterChangeHandler(event, 'ecologicalManufacturingProcess')} data={this.state.filterItems.ecologicalManufacturingProcess} value={this.state.filters.ecologicalManufacturingProcess}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="frame-materials-filter">Frame materials</label>
                                        <DropdownField id="frame-materials-filter" name="frame-materials-filter" onChange={event => this.filterChangeHandler(event, 'frameMaterials')} data={this.state.filterItems.frameMaterials} multiple maxSelectedShown="2" value={this.state.filters.frameMaterials}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="min-order-qta-filter">Minimum order quantity</label>
                                        <Slider value={[min, max]} minimum={0} maximum={this.state.slider.max} margin={1} showTooltip onChange={this.changeSliderHandler} step={1} />
                                        <div className="slider-minmax">
                                            <span>0</span>
                                            <span>{this.state.slider.max}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="filters-action">
                                    <Button onClick={this.resetFilterHandler}>Clear</Button>
                                </div>
                            </div>
                            : 
                            null
                        }
                        

                    </div>
                </div>
                <div className="page-container">
                    <h2 className="vendor-list-title">Vendors list</h2>
                    <div className="vendors-network-grid">
                        {
                            this.state.vendorsNetwork.length > 0 ?
                            this.state.vendorsNetwork.map(vendor => (
                                <VendorNetworkCard key={vendor.ID} onLearnMore={this.learnMoreVendorHandler} {...vendor} />
                            ))
                            : <p>No results</p>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authUser: state.auth.user
    }
}

export default connect(mapStateToProps, null)(VendorsNetwork);