import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import axios from '../../../axios';
import { Checkbox, Button, ModalBody, Modal, ModalFooter, ModalHeader } from "precise-ui/dist/es6";
import {config} from '../../../config';
import Loader from 'react-loader-spinner'
import './Profile.css';
import * as actions from '../../../store/actions/index';

class Profile extends Component {

    state = {
        privacy: this.props.user.privacy,
        openModal: false,
        isLoading: false
    }

    toogleModal = () => {
        this.setState({
            ...this.state,
            openModal: !this.state.openModal
        });
    }

    deleteAccount = () => {
        console.log('cancella');
    }


    componentDidMount() {
        window.scrollTo(0, 0);
     }

    handleChangeMarketingAcceptance = () => {
        this.setState({ isLoading: true });
        let privacy = !this.state.privacy;

        let params = {
            environment: config.salesForceENV,
            zeissid:  this.props.user.zeissId,
            email: this.props.user.email,
            lastname:  this.props.user.surname,
            privacy: privacy
        };

        axios.post('/salesforceUpdate', JSON.stringify(params),
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then(response => {
            if(response.data) {
                let userStored = localStorage.getItem('user');
                if (userStored) {
                    let user = JSON.parse(userStored);
                    user.privacy = privacy;
                    localStorage.setItem('user', JSON.stringify(user));
                    this.props.onChangePrivacy(privacy);
                }

                this.setState({
                    privacy: privacy,
                    isLoading: false
                });
            }
            else {
                this.setState({
                    isLoading: false
                });
            }

        })
        .catch(err => {
            console.log(err);
            this.setState({ isLoading: false });
        });
    }


    render() {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                <h1> My Profile </h1>

                <div className="profile-container">

                    <div className="profile-left">
                        <label className="lablel-co">
                            Firstname:
                            <input type="text" value= {this.props.user.name} disabled/>
                        </label>

                        <label className="lablel-co">
                            Lastname:
                            <input type="text" value= {this.props.user.surname} disabled/>
                        </label>

                        <label className="lablel-co">
                            Email:
                            <input type="text" value= {this.props.user.email} disabled/>
                        </label>
                        <label className="lablel-co">
                            Company Name:
                            <input type="text" value= {this.props.user.company} disabled/>
                        </label>

                        {
                            (this.props.user.deliveries != null && this.props.user.deliveries.length > 0) ?
                                this.props.user.deliveries.map((item, index) => (
                                    index % 2 === 0 ?
                                        <label className="lablel-co" key={item.Delivery_Point_Number__c}>
                                            Shipping Address{this.props.user.deliveries.length > 1 ? (" "+(index+1)) : ""}:
                                            <input type="text" value={item.Address__c}  disabled/>
                                        </label>
                                    : null
                                ))
                            : null
                        }


                        <label className="lablel-co">
                            <Checkbox
                                value={this.state.privacy}
                                style={{float: 'left'}}
                                onChange={() => this.handleChangeMarketingAcceptance()}
                            > I want to receive news and offers from ZEISS </Checkbox>
                             <a class="profile-privacy-policy-link" href="https://www.zeiss.com/data-protection/en/home.html" target="_blank"> Privacy Policy</a>
                        </label>





                       {/*<Button icon="Close" theme={{ buttonIconPosition: 'left'}}  onClick={this.toogleModal} buttonStyle='secondary'>Delete account</Button>*/}
                    </div>

                    <div className="profile-right">

                        <label className="lablel-co">
                            Country:
                            <input type="text" value=  { this.props.user.billingAddress != null && this.props.user.billingAddress.country !== undefined ? this.props.user.billingAddress.country : ''  } disabled/>
                        </label>

                        <label className="lablel-co">
                            City:
                            <input type="text" value={this.props.user.billingAddress != null &&  this.props.user.billingAddress.city !== undefined ? this.props.user.billingAddress.city : ''  } disabled/>
                        </label>

                        <label className="lablel-co">
                            Postal Code:
                            <input type="text" value= { this.props.user.billingAddress != null &&  this.props.user.billingAddress.postalCode !== undefined ? this.props.user.billingAddress.postalCode : ''  }  disabled/>
                        </label>

                        <label className="lablel-co">
                            {
                                this.props.user.deliveries === null || this.props.user.deliveries.length === 0
                                ? 'Billing and Shipping Address:'
                                : 'Billing Address:'
                            }

                            Address:
                            <input type="text" value= {this.props.user.billingAddress != null &&  this.props.user.billingAddress.street !== undefined ? this.props.user.billingAddress.street : ''  }  disabled/>
                        </label>

                        {
                            (this.props.user.deliveries != null && this.props.user.deliveries.length > 0) ?
                                this.props.user.deliveries.map((item, index) => (
                                    index % 2 !== 0 ?
                                        <label className="lablel-co" key={item.Delivery_Point_Number__c}>
                                            Shipping Address {index+1}:
                                            <input type="text" value={item.Address__c}  disabled/>
                                        </label>
                                    : null
                                ))
                            : null
                        }

                    </div>
                </div>
                <Modal open={this.state.openModal} onClose={this.toogleModal} className="render-fullscreen-modal">
                    <ModalHeader title="Deleting account"/>
                    <ModalBody>
                        <div>
                           <p> Are you sure you want to delete your account? </p>
                        </div>
                    </ModalBody>
                    <ModalFooter className="m-t-3">
                        <Button type="button" onClick={this.toogleModal}>No</Button>
                        <Button type="submit" onClick={this.deleteAccount}> Yes </Button>
                    </ModalFooter>
                </Modal>

                {
                    this.state.isLoading ?
                        <Loader
                        type="TailSpin"
                        color="#008bd0"
                        height={100}
                        width={100}
                        timeout={1500000}
                        className="spinner"
                    />:
                    null
                }

               {
                    this.state.isLoading ?
                    <div className="black-layer">
                    </div>
                    :
                    null
                }



            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onChangePrivacy: (privacy) => dispatch(actions.authChangePrivacy(privacy))
    };
  };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));