import React from 'react';

const HiglightText = (props) => {
    
    return (
        <p dangerouslySetInnerHTML={{__html: props.HTML}} >                               
        </p>
    );
}

export default HiglightText;