import React from 'react'
import SectionContent from '../SectionContent/SectionContent';
import SectionImage from '../SectionImage/SectionImage';
import SectionLink from '../SectionLink/SectionLink';

import './RightSection.css';

const rightSection = (props) => {
    const postfix = props.classPostfix || '';
    const imgClassName = ['block-right-image'+postfix];
    let link = null;

    if (props.section.DestURL) {
        link = <SectionLink path={props.section.DestURL} label={props.section.CTALabel} asButton={props.section.ShowCTAAsButton} />
    }

    return (
        <div className="block-right-w-image block-container">
            <div className="page-container">
                <div className="half-width">
                    <div className="block-left-content">
                        <h4>{props.section.Title}</h4>
                        <SectionContent content={props.section.Content} />
                        {link}
                    </div>
                </div>
                <div className="half-width image-container">
                    <SectionImage classList={imgClassName} url={props.section.Image} alt={props.section.Title} />
                </div>
            </div>
        </div>
    );
}

export default rightSection;