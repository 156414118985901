import React from 'react';
import SectionContent from '../SectionContent/SectionContent';
import SectionImage from '../SectionImage/SectionImage';
import SectionLink from '../SectionLink/SectionLink';

import './LeftSection.css';

const leftSection = (props) => {
    const postfix = props.classPostfix || '';
    const imgClassName = ['block-left-image'+postfix, 'half-width'];
    const blockClassName = ['block-left-w-image'+postfix, 'full-width-row', 'block-container']
    let link = null;

    if (props.section.DestURL) {
        link = <SectionLink path={props.section.DestURL} label={props.section.CTALabel} asButton={props.section.ShowCTAAsButton} />
    }

    return (
        <div className={blockClassName.join(' ')} style={{backgroundImage: 'url('+props.section.BackgroundImage+')'}}>
            <div className="page-container" >
                <div className="half-width image-container">
                    <SectionImage classList={imgClassName} url={props.section.Image} alt={props.section.Title} />
                </div>
                <div className="half-width">
                    <div className="block-right-content">
                        <h4>{props.section.Title}</h4>
                        <SectionContent content={props.section.Content} />
                        {link}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default leftSection;