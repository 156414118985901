import React, {Component} from 'react';
import jwt_decode from 'jwt-decode';
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import axios from '../../axios';
import {config} from '../../config';
import Cookies from 'universal-cookie';
import {ZeissIDUtils} from "../Auth/ZeissID";

import * as actions from '../../store/actions/index';

class Callback extends Component {

    componentDidMount () {

        const search = window.location.search;
        const token = search.substring(search.indexOf('id_token=') + 9);

        if(token) {
            let decodedToken = jwt_decode(token);
            let decodedHeader = jwt_decode(token, { header: true });

            let zeissID = new ZeissIDUtils();
            zeissID.verifyLoginToken(token, decodedHeader).then((response) => {
                if(response) {
                    let params = {ztoken: token};

                    axios.post('/setJWTSecureCookie', JSON.stringify(params), {
                        headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    )
                    .then(response => {
                       this.successLogin(decodedToken);
                    })
                    .catch(err => {
                        this.props.history.push("/home")
                    });

                }
                else {
                    this.props.history.push("/home")
                }
            });
        }
        else {
            this.props.history.push("/home")
        }
    }

    successLogin(token) {
        
        let accountData = JSON.parse(token.ZeissIdBase);
        let organizationData = JSON.parse(token.ZeissIdOrganisation);
        let contactData =  JSON.parse(token.ZeissIdContact);
        let expirationDate = token.exp;

        let sapCode = '';

        this.manageSAPCode(organizationData).then((result) => {

            sapCode = result;
            if(isNaN(sapCode)){
                sapCode = '';
            }

            let params = {
                environment: config.salesForceENV,
                sapcode: sapCode,
                zeissid: accountData.accountId
            };

            axios.post('/salesforceLogin', JSON.stringify(params),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            )
            .then(response => {
                if(response.data && response.data.SALESFORCE_LOGIN && response.data.SALESFORCE_LOGIN[0].RESULT !== undefined ) {

                    if(response.data.SALESFORCE_LOGIN[0].RESULT == 0) {

                        let accountResult = response.data.SALESFORCE_LOGIN[0].ACCOUNT_RESULT;
                        let fullPrivilegeRequest = response.data.FULL_REQUEST;

                        // todo: popolazione di array LeadTime proveniente da SalesForce. In attesa del nome della variabile
                        let user = {
                            zeissId: accountData.accountId,
                            name: accountData.firstName,
                            surname: accountData.lastName,
                            email: accountData.eMail,
                            country: contactData.country,
                            city: contactData.city,
                            priceList: accountResult.AS400_Pricelist_Code__c,
                            company: organizationData.name,
                            billingAddress: accountResult.BillingAddress,
                            salesforceId: accountResult.Id,
                            sapCode: accountResult.SAPCode__c,
                            modulesEnabled: accountResult.BtoB_Portal_Modules__c,
                            expirationDate: expirationDate,
                            accountType: accountResult.attributes.type,
                            deliveries: accountResult.Deliveries__r == null ? null : accountResult.Deliveries__r.records,
                            as400Code: accountResult.AS400Code__c,
                            customerServiceOperator: accountResult.Customer_Service_Operator__c == null ? null : accountResult.Customer_Service_Operator__c ,
                            customerServiceOperatorITSUN: accountResult.Customer_Service_Operator_ITSUN__c == null ? null : accountResult.Customer_Service_Operator_ITSUN__c ,
                            customerServiceOperatorSamples: accountResult.Customer_Service_Samples__c == null ? null : accountResult.Customer_Service_Samples__c,
                            customerServiceOperatorSamplesITSUN: accountResult.Customer_Service_Samples_ITSUN__c  == null ? null : accountResult.Customer_Service_Samples_ITSUN__c,
                            ivaAS400: accountResult.BtoB_Portal_IVA_AS400__c  == null ? null : accountResult.BtoB_Portal_IVA_AS400__c,
                            extraAS400: accountResult.BtoB_Portal_spese_accessorie_AS400__c  == null ? null : accountResult.BtoB_Portal_spese_accessorie_AS400__c,
                            isDeliveryPointMandatory: accountResult.BtoB_Portal_Parameter__c ? this.isDeliveryPointMandatory(accountResult.BtoB_Portal_Parameter__c) : false,
                            privacy: accountResult.Contacts.records[0].Privacy_Agreement__c == 'Yes' ? true : false,
                            catalogueRole: this.getCatalogueRole(accountResult),
                            vendorVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, ['VendorNetwork','VendorNetworkConsultant']),
                            priceLists: this.getPricelist(accountResult.BtoB_Portal_Parameter__c),
                            priceListVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, 'PriceList'),
                        	stockModuleVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, 'Stock'),
                            leadTimeModuleVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, 'LeadTime'),
                            coatingsModuleVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, 'Coatings'),
                            leadTimeCodeList: this.getLeadTimeCodeList(accountResult.BtoB_Portal_Parameter__c)
                        }

                        const cookies = new Cookies();
                        this.setFullPrivilegeRequestData(user, fullPrivilegeRequest);
                        localStorage.setItem('user', JSON.stringify(user));

                        if(user.accountType == 'Contact'){
                            this.insertFullPrivilegeReminderCookie(cookies);
                        }
                        else {
                            this.removeFullPrivilegeReminderCookie(cookies);
                        }

                        this.props.onAuth( user );
                        this.props.history.push("/home-prospect");
                        window.location.reload();


                    }
                    else if(response.data.SALESFORCE_LOGIN[0].ERR_CODE ==  'EMPTY_INPUT_SAPCODE'){
                        //REGISTRAZIONE IN SALESFORCE
                        this.salesForceRegistration(token);
                    }
                    else if(response.data.SALESFORCE_LOGIN[0].BASE_MODULE == true) {
                        let fullPrivilegeRequest = response.data.FULL_REQUEST;
                        let user = {
                            zeissId: accountData.accountId,
                            name: accountData.firstName,
                            surname: accountData.lastName,
                            email: accountData.eMail,
                            country: contactData.country,
                            city: contactData.city,
                            company: organizationData.name,
                            priceList: null,
                            billingAddress:null,
                            salesforceId: null,
                            sapCode: null,
                            modulesEnabled: null,
                            expirationDate: expirationDate,
                            accountType: 'Contact',
                            deliveries: null,
                            as400Code: null,
                            customerServiceOperator: null,
                            customerServiceOperatorITSUN: null,
                            customerServiceOperatorSamples: null,
                            customerServiceOperatorSamplesITSUN: null,
                            ivaAS400: null,
                            extraAS400: null,
                            isDeliveryPointMandatory: false,
                            privacy: false,
                            catalogueRole: 'Base',
                            vendorVisible: true,
                            priceLists: null,
                            priceListVisible: false,
                        	stockModuleVisible: false,
                            leadTimeModuleVisible: false,
                            coatingsModuleVisible: false,
                            leadTimeCodeList: null
                        }

                        const cookies = new Cookies();
                        this.setFullPrivilegeRequestData(user, fullPrivilegeRequest);
                        localStorage.setItem('user', JSON.stringify(user));
                        this.insertFullPrivilegeReminderCookie(cookies);
                        this.props.onAuth( user );
                        this.props.history.push("/home-prospect");
                        window.location.reload();


                    }
                    else {
                        this.props.history.push("/home")
                    }
                }
                else {
                    this.props.history.push("/home");
                }

            })
            .catch(err => {
                console.log(err);
                this.props.history.push("/home");
            });
        });
    }

    removeValue(list, value) {
        const separator = ';';
        var values = list.split(separator);
        for(var i = 0 ; i < values.length ; i++) {
          if(values[i] == value) {
            values.splice(i, 1);
            return values.join(separator);
          }
        }
        return list;
    }

    isDeliveryPointMandatory(list){
        const separator = ';';
        var values = list.split(separator);
        for(var i = 0 ; i < values.length ; i++) {
          if(values[i] == 'PC') {
            return true;
          }
        }
        return false;

    }

    isVendorModuleVisible(in_modules) {
        let modules = in_modules.split(';');
        if(modules.includes('VendorNetwork') ||  (modules.includes('VendorNetworkConsultant'))) {
            return true;
        }
        return false;
    }


    getPricelist(list) {
        if(list) {
            const separator = ';';
            var values = list.split(separator);
            if(values[1]) {
                return values[1].split('-');
            }
        }

        return null;
    }

    isModuleVisible(in_modules, moduleTitles){

        let titles = moduleTitles;
        if (typeof moduleTitles === 'string') { titles = [moduleTitles] }

        if(in_modules) {
            let modules = in_modules.split(';');
            if( titles.some(title => modules.includes(title)) ) {
                return true;
            }
        }
        return false;
    }


    getLeadTimeCodeList(list) {
        if(list) {
            const separator = ';';
            var values = list.split(separator);
            if(values[2]) {
                return values[2].split('-');
            }
        }

        return null;
    }

    salesForceRegistration(zeissData) {

        let accountData = JSON.parse(zeissData.ZeissIdBase);
        let organizationData = JSON.parse(zeissData.ZeissIdOrganisation);
        let contactData =  JSON.parse(zeissData.ZeissIdContact);
        let expirationDate = zeissData.exp;

        let params = {
            environment: config.salesForceENV,
            zeissid: accountData.accountId,
            email: accountData.eMail,
            firstname: accountData.firstName,
            lastname: accountData.lastName,
            company: organizationData.name,
            countryCode: contactData.country,
            city: contactData.city,
            mobile: contactData.cellPhone
        };

        axios.post('/salesforceNewRegistration', JSON.stringify(params),
        {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {

            let sapCode = '';

            this.manageSAPCode(organizationData).then((result) => {

                sapCode = result;
                if(isNaN(sapCode)){
                    sapCode = '';
                }

                    let params = {
                        environment: config.salesForceENV,
                        sapcode: sapCode,
                        zeissid: accountData.accountId
                    };

                    axios.post('/salesforceLogin', JSON.stringify(params),
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    )
                    .then(response => {
                        if(response.data.SALESFORCE_LOGIN[0].RESULT == 0) {

                            let accountResult =  response.data.SALESFORCE_LOGIN[0].ACCOUNT_RESULT;
                            let fullPrivilegeRequest = response.data.FULL_REQUEST;

                            let user = {
                                zeissId: accountData.accountId,
                                name: accountData.firstName,
                                surname: accountData.lastName,
                                email: accountData.eMail,
                                country: contactData.country,
                                city: contactData.city,
                                priceList: accountResult.AS400_Pricelist_Code__c,
                                company: organizationData.name,
                                billingAddress: accountResult.BillingAddress,
                                salesforceId: accountResult.Id,
                                sapCode: accountResult.SAPCode__c,
                                modulesEnabled: accountResult.BtoB_Portal_Modules__c,
                                expirationDate: expirationDate,
                                accountType: accountResult.attributes.type,
                                deliveries: accountResult.Deliveries__r == null ? null : accountResult.Deliveries__r.records,
                                as400Code: accountResult.AS400Code__c,
                                customerServiceOperator: accountResult.Customer_Service_Operator__c == null ? null : accountResult.Customer_Service_Operator__c ,
                                customerServiceOperatorITSUN: accountResult.Customer_Service_Operator_ITSUN__c == null ? null : accountResult.Customer_Service_Operator_ITSUN__c ,
                                customerServiceOperatorSamples: accountResult.Customer_Service_Samples__c == null ? null : accountResult.Customer_Service_Samples__c,
                                customerServiceOperatorSamplesITSUN: accountResult.Customer_Service_Samples_ITSUN__c  == null ? null : accountResult.Customer_Service_Samples_ITSUN__c,
                                ivaAS400: accountResult.BtoB_Portal_IVA_AS400__c  == null ? null : accountResult.BtoB_Portal_IVA_AS400__c,
                                extraAS400: accountResult.BtoB_Portal_spese_accessorie_AS400__c  == null ? null : accountResult.BtoB_Portal_spese_accessorie_AS400__c,
                                isDeliveryPointMandatory: accountResult.BtoB_Portal_Parameter__c ? this.isDeliveryPointMandatory(accountResult.BtoB_Portal_Parameter__c) : false,
                                privacy: accountResult.Contacts.records[0].Privacy_Agreement__c == 'Yes' ? true : false,
                                catalogueRole: this.getCatalogueRole(accountResult),
                                vendorVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, ['VendorNetwork','VendorNetworkConsultant']),
                                priceLists: this.getPricelist(accountResult.BtoB_Portal_Parameter__c),
                                priceListVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, 'PriceList'),
		                        stockModuleVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, 'Stock'),
                                leadTimeModuleVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, 'LeadTime'),
                                coatingsModuleVisible: this.isModuleVisible(accountResult.BtoB_Portal_Modules__c, 'Coatings'),
                                leadTimeCodeList: this.getLeadTimeCodeList(accountResult.BtoB_Portal_Parameter__c)
                            }

                            const cookies = new Cookies();
                            this.setFullPrivilegeRequestData(user, fullPrivilegeRequest);
                            localStorage.setItem('user', JSON.stringify(user));
                            if(user.accountType == 'Contact'){
                                this.insertFullPrivilegeReminderCookie(cookies);
                            }
                            else {
                                this.removeFullPrivilegeReminderCookie(cookies);
                            }
                            this.props.onAuth( user );
                            this.props.history.push("/home-prospect");
                            window.location.reload();
                        }
                        else if(response.data.SALESFORCE_LOGIN[0].BASE_MODULE == true) {

                            let fullPrivilegeRequest = response.data.FULL_REQUEST;

                            let user = {
                                zeissId: accountData.accountId,
                                name: accountData.firstName,
                                surname: accountData.lastName,
                                email: accountData.eMail,
                                country: contactData.country,
                                city: contactData.city,
                                company: organizationData.name,
                                priceList: null,
                                billingAddress:null,
                                salesforceId: null,
                                sapCode: null,
                                modulesEnabled: null,
                                expirationDate: expirationDate,
                                accountType: 'Contact',
                                deliveries: null,
                                as400Code: null,
                                customerServiceOperator: null,
                                customerServiceOperatorITSUN: null,
                                customerServiceOperatorSamples: null,
                                customerServiceOperatorSamplesITSUN: null,
                                ivaAS400: null,
                                extraAS400: null,
                                isDeliveryPointMandatory: null,
                                privacy: null,
                                catalogueRole: 'Base',
                                vendorVisible: true,
                                priceLists: null,
                                priceListVisible: false,
                                stockModuleVisible: false,
                                leadTimeModuleVisible: false,
                                leadTimeCodeList: null,
                                coatingsModuleVisible: false
                            }

                            const cookies = new Cookies();
                            this.setFullPrivilegeRequestData(user, fullPrivilegeRequest);
                            localStorage.setItem('user', JSON.stringify(user));
                            this.insertFullPrivilegeReminderCookie(cookies);
                            this.props.onAuth( user );
                            this.props.history.push("/home-prospect");
                            window.location.reload();

                        }
                        else {
                            this.props.history.push("/home")
                        }
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        });

    }

    insertFullPrivilegeReminderCookie (cookies) {
        if(!cookies.get('fp-requested')) {
            if(!cookies.get('fp-reminder')){
                let dateIn3Days = new Date();
                dateIn3Days.setDate(dateIn3Days.getDate() + 3);
                cookies.set('fp-reminder', dateIn3Days, { path: '/'});
            }
        }

    }

    removeFullPrivilegeReminderCookie (cookies) {
        cookies.remove('fp-reminder');
    }

    getCatalogueRole(account) {
        if(account.SAPCode__c && account.SAPCode__c !== ''){
            if(account.BtoB_Portal_Modules__c.includes("Catalogue_Full")) {
                return 'Full';
            }
            else if(account.BtoB_Portal_Modules__c.includes("Catalogue_BMQ")) {
                return 'BMQ';
            }
        }

        return 'Base';
    }

    getSAPCode(SAPCode) {
        return new Promise((resolve) => {
            let params = {
                SAPCode: SAPCode,
            };

            axios.post('/getSAPCode', JSON.stringify(params),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            )
            .then(response => {
            if(response && response.data) {
                    if(response.data.SAPCode) {
                        resolve(response.data.SAPCode);
                    }
                    else {
                        resolve('');
                    }
                }
                else {
                    resolve('');
                }

            })
            .catch(err => {
                console.log(err);
                resolve('');
            });
        });
    }

    manageSAPCode(organizationData) {

        return new Promise(resolve => {
            if(organizationData.organisationVerification == 1) {
                if(typeof organizationData.zeissCustomerNo != 'undefined' && organizationData.zeissCustomerNo && organizationData.zeissCustomerNo != '-') {
                    if(organizationData.zeiss3rdPartySystem != 'P10') {
                        this.getSAPCode(parseInt(organizationData.zeissCustomerNo, 10)).then((result) => {
                            resolve(result);
                        });
                    }
                    else{
                        let sapCode = parseInt(organizationData.zeissCustomerNo, 10)
                        resolve(sapCode);
                    }
                }
            }
            else
                resolve('');
        });
    }

    setFullPrivilegeRequestData(user, fullPrivilegeRequest) {
        if (fullPrivilegeRequest) {
            if (user.accountType == 'Contact' && fullPrivilegeRequest.requested && !fullPrivilegeRequest.isRejected) {
                user.accountType = 'Advanced';

                let modules = user.modulesEnabled?.split(';') || [];
                if (!modules.includes("Catalogue_Full")) {
                    // rimuove "Catalogue_BMQ" se presente in quanto deve essere sovrascritto con "Catalogue_Full"
                    let i = modules.indexOf("Catalogue_BMQ");
                    if (i > -1) {
                        modules.splice(i, 1);
                    }
                    modules.push("Catalogue_Full");
                    user.catalogueRole = 'Full';
                    user.modulesEnabled = modules.join(";");
                }

                if (!modules.includes("VendorNetworkConsultant")) {
                    user.vendorVisible = true;
                    modules.push("VendorNetworkConsultant");
                    user.modulesEnabled = modules.join(";");
                }
            }
            user.fullPrivilegeId = fullPrivilegeRequest.idRequest;
            user.fullAccessRequested = fullPrivilegeRequest.fullAccessRequested;
            user.fullPrivilegeRejected = fullPrivilegeRequest.isRejected;
        } else {
            user.fullPrivilegeId = 0;
            user.fullAccessRequested = false;
            user.fullPrivilegeRejected = false;
        }
    }



    render () {
        return (
            <div className="callback-container">
                <div className="black-layer">
                </div>

                <Loader
                    type="TailSpin"
                    color="#008bd0"
                    height={100}
                    width={100}
                    timeout={1500000}
                    className="spinner"
                />

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( user) => dispatch( actions.authSuccess(user) )
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Callback));
