import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { Table, IconLink, Button, notify, Icon } from 'precise-ui/dist/es6';
import axios from '../../../axios';
import { Link } from 'react-router-dom';
import CartItem from '../../../components/CartItem/CartItem';
import { connect } from 'react-redux';
import './PersonalArea.css';
import ModalRequestInfo from '../../../components/ModalRequestInfo/ModalRequestInfo';
import NumberFormat from 'react-number-format';
import Cookies from 'universal-cookie';
import * as actionCreators from '../../../store/actions/index';
import stockIcon from '../../../res/images/stock_icon.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ZEISSLogo from '../../../res/images/zeiss-logo.png';

class PersonalArea extends Component {
    
    state = {
        wishlist: [],
        orders: [],
        stockRequests: [],
        openModal: false,
        infoSubjects: [],
        wishlistInfo: {
            subject: null,
            request: null
        }
    }
    
    tableColumns = {
        item: 'Item',
        action: ' '
    }

    orderTableColumns = {
        OrderReference: 'Order Reference',
        Status: 'Status',
        OrderDate: 'Order Date'
    }

    stockRequestTableColumns = {
        RequestReference: 'Request Reference',
        Status: 'Status',
        RequestDate: 'Request Date'
    }

    cartTableColumns = {
        item: {
            header: 'Item',
            footer: 'Subtotal:',
            sortable: false
        },
        Quantity: 'Pieces',
        TotalPrice: 'Total Price',
    };

    componentDidMount() {
        this.getUserWishlist();
        this.getUserOrders();
        if (this.props.user.stockModuleVisible) {
            this.getUserStockRequests();
        }
        this.getSubjectOptions();
        window.scrollTo(0, 0);
    }



    getUserWishlist() {
        if (this.props.user != null && this.props.user.zeissId) {
            axios.post("/getUserWishlist", { zeissID: this.props.user.zeissId })
            .then(response => {
                if (response.data.type === 'success') {
                    this.setState({ wishlist: response.data.payload });
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    getUserOrders() {
        if (this.props.user != null && this.props.user.zeissId) {
            axios.post("/getUserOrders", { zeissID: this.props.user.zeissId, limitTo: 5 })
            .then(response => {
                if (response.data.type === 'success') {
                   this.setState({ orders: response.data.payload });
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    removeFromWishlist = recordId => {
        if (this.props.user != null && this.props.user.zeissId) {
            axios.post("/removeFromWishlist", { zeissID: this.props.user.zeissId, wishlistRecordID: recordId })
            .then(response => {
                if (response.data.type === 'success') {
                    const wishlist = this.state.wishlist.filter(i => i.ID !== recordId);
                    this.setState({ wishlist: wishlist });
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    cellTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'item':
                return (
                    <CartItem {...data.Product} />
                );
            case 'action':
                return (
                    <div className="text-right">
                        <IconLink icon="Delete" onClick={() => this.removeFromWishlist(data.ID)}/>
                    </div>
                );
            default:
                return value;
        }
    }

    cellOrderTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'orderreference':
            case 'requestreference':
                return (
                    <div>
                        {data.ID}
                    </div>
                    
                );
            case 'status':
                return (
                    this.getOrderStatus(data.Status)
                );
            case 'orderdate':
            case 'requestdate':
                return (
                    <div>
                        {data.Date}
                    </div>
                );
            default:
                return value;
        }
    }

    getOrderStatus(status){
        switch (status) {
            case 'Paid':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
                );
            case 'ReceivedAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
            );
            case 'SentAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
            );
            case 'InOrderAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Paid </span>
                    </div>
                    
            );
            case 'PaymentError':
                return (
                    <div>
                        <div className="circle-icon red">
                            <span class="icon-close-delete"></span>
                        </div>                        
                        <span> Payment error </span>
                    </div>
                    
                );
            case 'CanceledAS400':
                return (
                    <div>
                        <div className="circle-icon"> </div>
                        <span> Order Canceled </span>
                    </div>
                    
                );
            case 'DeliveredAS400':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-position-map"></span>
                        </div>
                        <span> Delivered </span>
                    </div>
                    
                );
            case 'Sent':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Sent </span>
                    </div>
                );
            default:
        }
    }

    checkOut = () => {
        this.props.history.push("/cart");
    }

    
    toogleModal = () => {
        this.setState({
            ...this.state,
            openModal: !this.state.openModal
        });
    }

    orderClickHandler = (event) => {
        this.props.history.push("/my-order/"+event.data.ID);
    }

    sendRequest = () => {
        let productIds = [];
        this.state.wishlist.forEach(wishProduct => productIds.push(wishProduct.Product.ID));
        const data = {
            ...this.state.wishlistInfo,
            productIds: productIds,
            user: this.props.user
        }

        axios.post("/sendEmailWishlistInfo", data)
        .then(response => {
            notify.success({ content: "Your request has been sent", options: { position: 'top-right', autoClose: 3000 } });
            this.setState({
                ...this.state,
                openModal: false
            });
        })
        .catch(error => {

        });
    }

    getSubjectOptions = () => {
		axios.get("/getContactSubjectRecipient")
		.then(response => {
			if (response.data.type === "success") {
				this.setState({
                    infoSubjects: response.data.payload,
                    wishlistInfo: {
                        ...this.state.wishlistInfo,
                        subject: response.data.payload[0].ID
                    }
				});
			}
		})
		.catch(error => {
			console.log(error);
		})
    }
    
    handleChange = (event) => {
        const { name, value } = event.target;
        let info = this.state.wishlistInfo;
        info = {
            ...info,
            [name]: value
        }
        this.setState({
            ...this.state,
            wishlistInfo: info
        });
    }

    cartHeaderCellRender = headerCell => {
        const key = headerCell.key;
        const value = headerCell.value;

        if (key.toLowerCase() === 'price' || key.toLowerCase() === 'totalprice') {
            return (
                <div className="text-right">
                    {value}
                </div>
            );
        } else {
            return value;
        }

    }

    cartCellTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'totalprice':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price * data.Quantity} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                        <IconLink className="m-l-10" icon="Delete" size={1} onClick={() => this.props.onRemoveFromCart(data.ID, this.props.user.zeissId)}/>
                    </div>
                );
            case 'price':
                return (
                    <div className="text-right">
                        <NumberFormat 
                            value={data.Price} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>
                );
            case 'item':
                return (
                    <CartItem {...data} hiddenImage={true} />
                );
            case 'quantity':
                return (
                    <div>
                        <div>
                            {value} pcs.
                        </div>
                        {
                            !data.Available ?
                            <p className="text-bold text-error">Item not available.</p>
                            : null
                        }
                    </div>
                );
            default:
                return value;
        }
    }

    cartFooterCellRenderer = footerCell => {        
        const key = footerCell.key;
        const value = footerCell.value;

        if (key.toLowerCase() === 'totalprice') {
            return (
                <div className="text-right">
                    <NumberFormat 
                        value={this.getTotal()} 
                        displayType={'text'} 
                        prefix={'€ '} 
                        decimalSeparator={'.'} 
                        decimalScale={2}
                        fixedDecimalScale={true} />
                </div>
            );
        } else if (key.toLowerCase() === 'price') {
            return (
                <div className="text-right">
                    {value}
                </div>
            );
        } else {
            return value;
        }
    }

    getTotal() {
        let total = 0;
        this.props.storedCart.forEach(
            element => {
                total = total + (element.Price * element.Quantity);
            }
        );

        return total;
    }


    canSeeShoppingCart() {
        if(this.props.user != null) {
            if(this.props.user.accountType == 'Account') {
                if(this.props.user.modulesEnabled == null) {
                    return false;
                }
                else {
                    let modules = this.props.user.modulesEnabled.split(';');
                    if(modules.includes("E-commerce_Full")) {
                        return true;
                    }
                    else if(modules.includes("E-commerce_Promo")) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }       
            }
            else{
                return false;
            }
        }
        else {
            return false;
        }       
    }

    getUserStockRequests() {
        if (this.props.user != null && this.props.user.zeissId) {
            axios.post("/getUserStockRequests", { zeissID: this.props.user.zeissId, limitTo: 5 })
            .then(response => {
                if (response.data.type === 'success') {
                   this.setState({ stockRequests: response.data.payload });
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    stockRequestClickHandler = (event) => {
        this.props.history.push("/my-stock-request/"+event.data.ID);
    }

    exportPdfWishlistHandler = () => {
        const doc = new jsPDF();
        doc.setFontSize(14);

        /*logo */
        doc.addImage(ZEISSLogo, 'PNG', 7, 15, 20,20);
        /* end logo */

        /* title */
        doc.text('My Wishlist', 7, 43);
        /* end title */

        /* wishlist table */
        let tableBody = [];
        if (this.state.wishlist) {
            let labelText = "";
            let itemDetails = "";
            this.state.wishlist.forEach((item) => {
                labelText = `Material:\nSize:\nColor:`;
                itemDetails = `${item.Product.LensMaterial}\n${item.Product.Size}\n${item.Product.ColorCode}`;

                if (!!item.Product.Coatings && item.Product.Coatings.length > 0) {
                    labelText += `\nCoatings:`;
                    itemDetails += `\n${item.Product.Coatings.join(", ")}`;
                }

                tableBody.push([
                    '', 
                    {
                        content: labelText,
                        styles: { fontStyle: 'bold', valign: 'middle' }
                    },
                    {
                        content: itemDetails,
                        styles: { valign: 'middle' }
                    }
                ]);
            });
        } else {
            tableBody.push(['No items in wishlist yet.']);
        }
        doc.autoTable({
            columnStyles: {
                0: {cellWidth: 44, minCellHeight: 34},
                1: {cellWidth: 20}
            },
            head: [[{content: 'Item', colSpan: 3}]],
            theme: 'grid',
            margin: {left: 7},
            body: tableBody,
            headStyles : {
                fillColor: [0, 139, 208]
            },
            startY: 53,
            rowPageBreak: 'avoid',
            didDrawCell: async (data) => {
                if (data.column.index === 0 && data.cell.section === 'body') {
                    let item = this.state.wishlist?.at(data.row.index);
                    let imageUrl = item?.Product.PhotosBase64 && item?.Product.PhotosBase64.length > 0 ? item.Product.PhotosBase64[0] : null;
                    if (imageUrl) {
                        doc.addImage(imageUrl, 'JPEG', data.cell.x + 2, data.cell.y + 2, 40, 30);
                    }
                }
            }
        });
        /* end wishlist table */

        let fileName = 'ZEISS_MyWishlist.pdf';
        doc.save(fileName);
    }

    render() {
        return (
            <div >
                <div className="page-container">
                    <div className="header-gray-line"> </div>
                    <ModalRequestInfo 
                        open={this.state.openModal} 
                        close={this.toogleModal} 
                        handleChangeInput={this.handleChange}
                        infoSubjects={this.state.infoSubjects}
                        sendRequest={this.sendRequest}
                        tableColumns={{item: 'Item'}}
                        products={this.state.wishlist}
                        cellTableRender={this.cellTableRender}
                    ></ModalRequestInfo>
                    <div className="personal-container">
                        <h2> Hello <span> {this.props.user.name + " " + this.props.user.surname} </span> </h2>

                    {
                        this.canSeeShoppingCart() ?
                        <div className="bg-light">
                            <div className="personal-block">
                                    <div className="personal-block-right">
                                        <div className="personal-whish">
                                            <h3>  <Icon name='Favorite' size={1} />  My Wishlist </h3>
                                            <div className="whishlist-widget">
                                                <Table id="mywishlist-table"
                                                    className="personal-cart-table" 
                                                    data={this.state.wishlist} 
                                                    columns={this.tableColumns} 
                                                    cellRenderer={this.cellTableRender}
                                                    placeholder="No items in wishlist yet." 
                                                    borderless
                                                    mode='table'>
                                                </Table>
                                            </div>
                                            <div style={{marginTop: '2rem', display: 'flex', justifyContent: 'flex-end'}}>
                                                <Button type='button' className="quote-btn" buttonStyle="primary" icon="PDFFileOutline" theme={{ buttonIconPosition: 'left'}} onClick={this.exportPdfWishlistHandler} disabled={!this.state.wishlist || this.state.wishlist.length === 0}>Export PDF</Button>
                                                <Button className="quote-btn" buttonStyle="primary" onClick={this.toogleModal}>Request info </Button>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div className="personal-block-left">
                                        <div className="personal-cart">
                                            <h3> <span class="icon-online-shop m-r-5"></span> Your shopping cart </h3>
                                            <div className="cart-widget">
                                                <Table
                                                className="personal-cart-table shopping-cart-table" 
                                                data={this.props.storedCart} 
                                                columns={this.cartTableColumns}
                                                headerCellRenderer={this.cartHeaderCellRender}
                                                cellRenderer={this.cartCellTableRender} 
                                                footerCellRenderer={this.cartFooterCellRenderer}
                                                placeholder="No items in cart yet." 
                                                borderless>
                                                </Table>
                                            </div>
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <Link to="/cart" className="learn-more">View details &#8594;</Link> 
                                                <Button  buttonStyle="primary" icon="KeyboardArrowRight" onClick={this.checkOut}>Checkout </Button> 
                                            </div>
                                        </div>
                                    </div>
                         
                            </div>

                                
                            <div className="personal-block">
                                <div className="personal-block-right">
                                    <div className="personal-orders">
                                        <h3> <span class="product-stock m-r-5"></span> My Orders </h3>
                                        <Table
                                            className="orders-table" 
                                            data={this.state.orders} 
                                            columns={this.orderTableColumns} 
                                            cellRenderer={this.cellOrderTableRender}
                                            placeholder="No orders yet."
                                            onDataClick={this.orderClickHandler} 
                                            borderless>
                                        </Table>
                                        <div className="learn-container">
                                            <Link to="/my-orders" className="learn-more">View all orders &#8594;</Link> 
                                        </div>                            
                                    </div>
                                </div>
                                <div className="personal-block-left">
                                    <div className="personal-account">
                                        <h3> My Account </h3>
                                        <div>
                                            <p> <span className="lablel-co"> Name: </span> {this.props.user.name} </p>
                                            <p> <span className="lablel-co"> Surname: </span> { this.props.user.surname} </p>
                                            <p> <span className="lablel-co"> Email: </span> { this.props.user.email} </p>
                                            <p> <span className="lablel-co"> Company: </span> { this.props.user.company} </p>
                    
                                            <div className="learn-container">
                                                <Link to="/profile" className="learn-more white-link">View details &#8594;</Link> 
                                            </div>
                                        </div>
                                        <Icon className="background-icon" name="Person" size={8}/>
                                    </div>
                                </div>
                            </div>
                            
                            {
                                this.props.user.stockModuleVisible ? 
                                <div className="personal-block">
                                    <div className="personal-block-right">
                                        <div className="personal-orders">
                                            <h3> <img src={stockIcon} className="m-r-5" style={{width: '24px', height:'24px'}} /> My Stock Requests </h3>
                                            <Table
                                                className="orders-table" 
                                                data={this.state.stockRequests} 
                                                columns={this.stockRequestTableColumns} 
                                                cellRenderer={this.cellOrderTableRender}
                                                placeholder="No stock requests yet."
                                                onDataClick={this.stockRequestClickHandler} 
                                                borderless>
                                            </Table>
                                            <div className="learn-container">
                                                <Link to="/my-stock-requests" className="learn-more">View all stock requests &#8594;</Link> 
                                            </div>                            
                                        </div>
                                    </div>
                                    <div className="personal-block-left">
                                    </div>
                                </div>
                                : null
                            }
                        </div>

                        :
                        <div className="personal-block">
                            <div className="personal-block-right">
                                <div className="personal-whish">
                                    <h3>  <span class="icon-highlights-specials m-r-5"></span>  My Wishlist </h3>
                                    <div className="whishlist-widget">
                                        <Table
                                            className="personal-cart-table" 
                                            data={this.state.wishlist} 
                                            columns={this.tableColumns} 
                                            cellRenderer={this.cellTableRender}
                                            placeholder="No items in wishlist yet." 
                                            borderless
                                            mode='table'>
                                        </Table>
                                    </div>                               
                                    <div style={{marginTop: '2rem', display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button type='button' className="quote-btn" buttonStyle="primary" icon="PDFFileOutline" theme={{ buttonIconPosition: 'left'}} onClick={this.exportPdfWishlistHandler} disabled={!this.state.wishlist || this.state.wishlist.length === 0}>Export PDF</Button>
                                        <Button className="quote-btn" buttonStyle="primary" onClick={this.toogleModal}>Request info </Button>
                                    </div>
                                </div>
                            </div>
        
                            <div className="personal-block-left">
                                <div className="personal-account">
                                    <h3> My Account </h3>
                                    <div>
                                        <p> <span className="lablel-co"> Name: </span> {this.props.user.name} </p>
                                        <p> <span className="lablel-co"> Surname: </span> { this.props.user.surname} </p>
                                        <p> <span className="lablel-co"> Email: </span> { this.props.user.email} </p>
                                        <p> <span className="lablel-co"> Company: </span> { this.props.user.company} </p>
                
                                        <div className="learn-container">
                                            <Link to="/profile" className="learn-more white-link">View details &#8594;</Link> 
                                        </div>
                                    </div>
                                    <Icon className="background-icon" name="Person" size={8}/>
                                </div>
                            </div>            
                        </div>
                    }
                   
                    </div>
                </div>
            </div>            
        );
    }
}

const mapStateToProps = state => {

    let user; 
    if(state.auth.user) {
        user = state.auth.user;
    }
    else {
        const cookies = new Cookies();
        let cookieUser = cookies.get('z-user');
        if(cookieUser){
            user = JSON.parse(localStorage.getItem('user'));
        }
    }

    return {
        user: user,
        storedCart: state.eshop.cart
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onRemoveFromCart: (productId, idzeiss) => dispatch(actionCreators.removeFromCart(productId, idzeiss))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalArea));