import React, {Component} from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import axios from '../../axios';

import * as actions from '../../store/actions/index';

class CallbackSignUp extends Component {
    
    componentDidMount () {
        axios.get('/deleteJWTSecureCookie')
        .then(response => {
            localStorage.setItem('user', null); 
            localStorage.removeItem('user');
            this.props.onAuth(null);
            localStorage.removeItem('user');
            this.props.history.push("/home");
        })
        .catch(err => {
            localStorage.setItem('user', null); 
            localStorage.removeItem('user');
            this.props.onAuth(null);
            this.props.history.push("/home");
        });
    }


    render () {
        return (
            <div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( user) => dispatch( actions.authSuccess(user) )
    };
  };
  


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CallbackSignUp));
