import * as actionTypes from "./actionTypes";
import axios from '../../axios';
import { notify } from "precise-ui/dist/es6";

const addToSessionInventoryCart = (product) => {
    product.Available = true;
    return {
        type: actionTypes.ADD_TO_INVENTORY_CART,
        product: product
    };
};

const removeFromSessionInventoryCart = productId => {
    return {
        type: actionTypes.REMOVE_FROM_INVENTORY_CART,
        productId: productId
    };
};

const editQuantitySessionInventoryCart = (productId, quantity) => {
    return {
        type: actionTypes.EDIT_QUANTITY_INVENTORY_CART,
        productId: productId,
        quantity: quantity
    }
}

const restoreQuantitySessionInventoryCart = (productId) => {
    return {
        type: actionTypes.RESTORE_QUANTITY_INVENTORY_CART,
        productId: productId
    }
}

const restoreLastProductRemovedInventoryCart = () => {
    return {
        type: actionTypes.RESTORE_LAST_PRODUCT_INVENTORY_CART
    };
}

export const addToInventoryCart = (product, idzeiss) => {
    return dispatch => {
        dispatch(addToSessionInventoryCart(product));
        const data = {
            'ZeissProductID': product.ID,
            'Quantity': product.Quantity,
            'IDZeiss': idzeiss
        };
        axios.post('/addToInventoryCart', data)
        .then(result => {
            if (result.data.type === "error") {
                dispatch(removeFromSessionInventoryCart(product.ID));
                notify.error({ content: result.data.msg, options: { position: 'top-right', autoClose: 3000 } });
            }
        })
        .catch(error => {
            dispatch(removeFromSessionInventoryCart(product.ID));
            console.log(error);
        });
    }
}

export const updateInventoryCart = items => {
    return {
        type: actionTypes.UPDATE_INVENTORY_CART,
        items: items
    };
}

export const editQuantityInventoryCart = (productId, quantity, idzeiss) => {
    return dispatch => {        
        dispatch(editQuantitySessionInventoryCart(productId, quantity));
        const data = {
            'ZeissProductID': productId,
            'Quantity': quantity,
            'IDZeiss': idzeiss
        };
        axios.post('/updateInventoryCart', data)
        .then(result => {
            if (result.data.type === "error") {
                dispatch(restoreQuantitySessionInventoryCart(productId));
                notify.error({ content: result.data.msg, options: { position: 'top-right', autoClose: 3000 } });
            }
        })
        .catch(error => {
            dispatch(restoreQuantitySessionInventoryCart(productId));
            console.log(error);
        });
    }
}

export const removeFromInventoryCart = (productId, idzeiss) => {
    return dispatch => {
        dispatch(removeFromSessionInventoryCart(productId));
        const data = {
            'ZeissProductID': productId,
            'IDZeiss': idzeiss
        };
        axios.post('/removeFromInventoryCart', data)
        .then(result => {
            if (result.data.type === "error") {
                notify.error({ content: result.data.msg, options: { position: 'top-right', autoClose: 3000 } });
                if (result.data.msg !== 'Product not found.') dispatch(restoreLastProductRemovedInventoryCart());
            }
        })
        .catch(error => {
            dispatch(restoreLastProductRemovedInventoryCart());
            console.log(error);
        });
    }
}

const emptySessionInventoryCart = () => {
    return {
        type: actionTypes.EMPTY_INVENTORY_CART
    };
}

export const emptyInventoryCart = (idzeiss) => {
    return dispatch => {
        const data = {
            'IDZeiss': idzeiss
        };
        axios.post('/emptyInventoryCart', data)
        .then(result =>  {
            if (result.data.type === "error") {
                notify.error({ content: result.data.msg, options: { position: 'top-right', autoClose: 3000 } });
            } else {
                dispatch(emptySessionInventoryCart());
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
}