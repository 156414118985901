import React from 'react';

import './IntermediateLevel.css';

const IntermediateLevel = (props) => {
    
    return (
        <div className="int-level-container">
            <div className={"header-w-img " + (props.level.HeaderImage ? '' : 'blue-header')}  >
                {
                    props.level.HeaderImage ?
                        <img src={props.level.HeaderImage} />
                        :
                        null
                }                
                <div>
                    <p> {props.level.Title} </p>
                </div>                
           </div>
           {
               props.level.LastEdited ?
               <p class="last-edit-info">Last edit: { props.level.LastEdited }</p>
               : 
               null
           }
           <div className="abs-text-container" dangerouslySetInnerHTML={{__html: props.level.Text}}>
           </div>
           <div className="final-levels-container">
                {
                    props.level.Levels.map((level) => (
                        <div className="box-level" onClick={ () => props.clicked(level)}>
                            <div className="level-img">
                                {
                                    level.Image ? 
                                    <img src={level.Image} />
                                    :
                                    <p>{level.Title} </p>
                                }                                
                            </div>
                            <div class="box-content">
                                <div class="box-headline"><span>{level.Title}</span></div>
                            </div>
                                  
                        </div>
                    ))   
                }
           </div>
        </div>
    );
}

export default IntermediateLevel;