import React, { Component } from 'react';
import axios from '../../../axios';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Table } from 'precise-ui/dist/es6';

class MyStockRequests extends Component {

    state = {
        stockRequests: []
    }

    requestTableColumns = {
        RequestReference: 'Request Reference',
        Status: 'Status',
        RequestDate: 'Request Date'
    }

    componentDidMount() {
        this.getUserStockRequests();
    }

    getUserStockRequests() {
        if (this.props.user != null && this.props.user.zeissId) {
            axios.post("/getUserStockRequests", { zeissID: this.props.user.zeissId })
            .then(response => {
                if (response.data.type === 'success') {
                   this.setState({ stockRequests: response.data.payload });
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    cellOrderTableRender = (cell) => {
        const key = cell.key;
        const value = cell.value;
        const data = cell.data;

        switch (key.toLowerCase()) {
            case 'requestreference':
                return (
                    <div>
                        {data.ID}
                    </div>
                    
                );
            case 'status':
                return (
                    this.getOrderStatus(data.Status)
                );
            case 'requestdate':
                return (
                    <div>
                        {data.Date}
                    </div>
                );
            default:
                return value;
        }
    }

    getOrderStatus(status){
        switch (status) {
            case 'Sent':
                return (
                    <div>
                        <div className="circle-icon green"> 
                            <span class="icon-checkmark"></span>
                        </div>
                        <span> Sent </span>
                    </div>
                    
                );
            default:
        }
    }

    dataClickHandler = (event) => {
        this.props.history.push("/my-stock-request/"+event.data.ID);
    }

    render() {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                <div className="back-shopping back-top"  onClick={() => this.props.history.goBack()} > <span class="icon-direction-back m-r-5"> </span> Back </div>
                <div className="m-b-30 ">
                    <h3> My Stock Requests </h3>
                    <Table
                        className="orders-table" 
                        data={this.state.stockRequests} 
                        columns={this.requestTableColumns} 
                        cellRenderer={this.cellOrderTableRender}
                        placeholder="No stock requests yet." 
                        onDataClick={this.dataClickHandler}
                        borderless>
                    </Table>
                </div>
                
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
}

export default withRouter(connect(mapStateToProps, null)(MyStockRequests));