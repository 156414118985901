import React from 'react';
import './ModalProductQuantity.css';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'precise-ui/dist/es6';
import NumberFormat from 'react-number-format';
import QuantitySelector from '../QuantitySelector/QuantitySelector';


const modalProductQuantity = props => {
    const headerTitle = (
        <div className="cart" style={{fontSize: '1rem', paddingLeft: '2rem'}} >
            <img src={props.icon} alt="cart-icon" />
            <span>{props.cartLength} {props.cartLength == 1 ? 'Item' : 'Items'}</span>
        </div>
    );

    return (
        <Modal open={props.open} onClose={props.toggle} className="modal-product-quantity">
            <ModalHeader className="modal-product-quantity-header" title={headerTitle}/>
            {
                props.product ?
                <div>
                    <ModalBody className="modal-product-quantity-body">
                        <p><span className="text-bold">Material</span>: {props.product.LensMaterial}</p>
                        <p><span className="text-bold">Size</span>: {props.product.Size}</p>
                        <p><span className="text-bold">Color</span>: {props.product.ColorCode}</p>
                        {
                            props.product.Coatings.length > 0 ?
                            <p><span className="text-bold">Coatings</span>: {props.product.Coatings.join(", ")}</p>
                            : null
                        }
                        <p><span className="text-bold">Stock qty</span>: {props.product.StockQty}</p>
                        <div className="product-price text-bold">
                            <NumberFormat 
                            value={props.product.Price} 
                            displayType={'text'} 
                            prefix={'€ '} 
                            decimalSeparator={'.'} 
                            decimalScale={2}
                            fixedDecimalScale={true} />
                            &nbsp;*&nbsp;
                            <QuantitySelector 
                                value={props.product.Quantity}
                                change={props.changeQty}
                                start={props.product.OrderMinQty} 
                                end={props.product.StockQty} 
                                step={props.product.MinReorder} />
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-product-quantity-footer">
                        <Button type="button" onClick={props.addToCart} disabled={props.product.Quantity <= 0}>Add to cart</Button>
                    </ModalFooter>
                </div>
                : null
            }
        </Modal>
    );
};

export default modalProductQuantity;