import React from 'react';
import videoIcon from '../../../../res/images/video-icon.png';
import learnMoreIcon from '../../../../res/images/learn-more-icon.png';
import catalogueIcon from '../../../../res/images/catalogue-icon.png';
import pdfIcon from '../../../../res/images/pdf-icon.png';
import LevelExtraText from './LevelExtraText';
import './Level.css';

const Level = (props) => {

    let visibleCatalogueLink = false;
    if (props.user) { 
        props.level.Media.forEach(element => {
           if(element.Type == 'CatalogueLink' && element.Value !== null) {

                if(props.user.catalogueRole == 'Full') {
                    visibleCatalogueLink = true;
                }
                else if(props.user.catalogueRole == 'BMQ') {
                    if(element.Value.Role == 'BMQ' || element.Value.Role == 'Base') {
                        visibleCatalogueLink = true;
                    }
                } 
                else if(props.user.catalogueRole == 'Base') {
                    if(element.Value.Role == 'Base') {
                        visibleCatalogueLink = true;
                    }
                }
           }
        });      
    }
    
    return (
        <div className="int-level-container">
            <div className={"header-w-img " + (props.level.HeaderImage ? '' : 'blue-header')} >
               
                {
                    props.level.HeaderImage ?
                        <img src={props.level.HeaderImage} />
                        :
                        null
                }       
                <div>
                    <p> {props.level.Title} </p>
                    {
                        props.level.SubTitle ?
                        <p class="level-subtitle"> {props.level.SubTitle} </p>
                        : null
                    }
                </div>
           </div>
           {
               props.level.LastEdited ?
               <p class="last-edit-info">Last edit: { props.level.LastEdited }</p>
               : 
               null
           }
           {
               props.level.ContentLeftImage ? 
               <div className="abs-text-container" >
                    <div className="half-content">
                        <img src={props.level.ContentLeftImage} />
                    </div>                
                    <div className="half-content second-half" dangerouslySetInnerHTML={{__html: props.level.Text}}>

                    </div>
                </div>
                :
                null
           }
           {
               props.level.ExtraText.length > 0 ? 
               <LevelExtraText  HTML={ props.level.ExtraText} />               
                :
                null
           }
          
           <div className="level-media-container">
                {
                    props.level.Media.map((media) => {
                        if(media.Value) {
                            if(media.Type == 'CatalogueLink') {
                                if(visibleCatalogueLink) {
                                    return <div className="level-media-box" onClick={ () => props.clicked(media)}>                         
                                    <div className="level-icon-container">
                                        {
                                            media.Type == 'Video' ||  media.Type == 'YouTubeVideo' ?
                                                <img className="level-media-icon" src={videoIcon} alt="video-icon" />
                                            :
                                            media.Type == 'PDF' ?
                                                <img className="level-media-icon" src={pdfIcon} alt="video-icon" />
                                            :
                                            media.Type == 'LearnMoreLink' ?
                                                <img className="level-media-icon" src={learnMoreIcon} alt="video-icon" />
                                            :
                                            media.Type == 'CatalogueLink' ?
                                                <img className="level-media-icon" src={catalogueIcon} alt="video-icon" />
                                            :
                                            null
                                        }                                
                                    </div>
                                    <div className="level-label-container">
                                        <div class="level-label"><span>{media.Label}</span></div>                        
                                    </div>                            
                                </div>    
                                }
                            }    
                            else {
                                return <div className="level-media-box" onClick={ () => props.clicked(media)}>                         
                                <div className="level-icon-container">
                                    {
                                        media.Type == 'Video' ||  media.Type == 'YouTubeVideo' ?
                                            <img className="level-media-icon" src={videoIcon} alt="video-icon" />
                                        :
                                        media.Type == 'PDF' ?
                                            <img className="level-media-icon" src={pdfIcon} alt="video-icon" />
                                        :
                                        media.Type == 'LearnMoreLink' ?
                                            <img className="level-media-icon" src={learnMoreIcon} alt="video-icon" />
                                        :
                                        media.Type == 'CatalogueLink' ?
                                            <img className="level-media-icon" src={catalogueIcon} alt="video-icon" />
                                        :
                                        null
                                    }                                
                                </div>
                                <div className="level-label-container">
                                    <div class="level-label"><span>{media.Label}</span></div>                        
                                </div>                            
                            </div>    
                            }                        
                        }                                   
                    })   
                }
           </div>
        </div>
    );
}

export default Level;