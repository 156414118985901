import { Button, Checkbox, colorCycle, DropdownField, notify } from 'precise-ui/dist/es6';
import React, { Component } from 'react';
import axios from '../../../axios';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import PricelistCoatingTable from '../../../components/PricelistCoatingTable/PricelistCoatingTable';
import PricelistLensTable from '../../../components/PricelistLensTable/PricelistLensTable';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Cookies from 'universal-cookie';
import ZEISSLogo from '../../../res/images/zeiss-logo.png';
import Loader from 'react-loader-spinner'
import './Pricelist.css';

class Pricelist extends Component {

    state = {
        pricelists: [],
        selectedPricelistID: 0,
        pricelist: null,
        filterVisible: true,
        filtersList: {},
        filters: {
            material: [],
            shape: [],
            size: [],
            coatingfamily: [],
            brand: [],
            showCoatings: true,
            bmq: false,
            wasBmqActive: false
        },
        isLoading: false,
        pdfSkeleton: null,
        uniqueMaterialList: []
    }

    componentDidMount() {
        if(this.props.user.priceLists.length > 0 ) {
            this.getPricelists();
            this.getPDFSKeleton();
        }
        else {
            this.props.history.push("/home");
        }
      
    }

    getPricelists = () => {

        let data = { priceLists: this.props.user.priceLists };

        axios.post('getPLPricelists', JSON.stringify(data),{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.data.type === "success") {
                this.setState({
                    ...this.state,
                    pricelists: response.data.payload,
                    selectedPricelistID: response.data.payload.length > 0 ? response.data.payload[0].ID : 0
                });
            } else if (response.data.type === "error") {
                notify.error({ content: response.data.msg, options: { position: 'top-right', autoClose: 3000 } })
            }
        }).catch(error => {
            console.log(error);
        })
    }

    
    getPDFSKeleton = () => {
        axios.get("getPLPDFSKeleton").then(response => {
            if (response.data.type === "success") {
                this.state.pdfSkeleton = response.data.payload;
            } else if (response.data.type === "error") {
                notify.error({ content: response.data.msg, options: { position: 'top-right', autoClose: 3000 } })
            }
        }).catch(error => {
            console.log(error);
        })
    }

    changePricelistHandler = (event) => {
        const {name, value} = event.target;
        this.setState({
            ...this.state,
            selectedPricelistID: value
        });
    }

    selectClickHandler = () => {
        this.setState({
            ...this.state,
            isLoading: true   
        }, this.getPricelistFilters);
    }

    getPricelist = () => {
        axios.post('getPLPricelist/'+this.state.selectedPricelistID, this.state.filters).then(response => {
            if (response.data.type === "success") {
                const payload = response.data.payload;
                const uMaterials = this.getUniqueMaterialPricelist(payload);
                this.setState({
                    ...this.state,
                    pricelist: response.data.payload,
                    uniqueMaterialList: uMaterials,
                    isLoading: false  
                });
            } else if (response.data.type === "error") {
                notify.error({ content: response.data.msg, options: { position: 'top-right', autoClose: 3000 } })
            }
        }).catch(error => {
            console.log(error);
        })
    }

    getPricelistFilters = () => {
        const data = {
            'PricelistID' : this.state.selectedPricelistID,
            'filters': this.state.filters
        };
        axios.post('getPLPricelistFilters/', data).then(response => {
            if (response.data.type === "success") {
                this.setState({
                    ...this.state,
                    filtersList: response.data.payload.list,
                    filters:  response.data.payload.filters
                }, this.getPricelist);
            } else if (response.data.type === "error") {
                notify.error({ content: response.data.msg, options: { position: 'top-right', autoClose: 3000 } })
            }
        }).catch(error => {
            console.log(error);
        })
    }

    resetFilters = () => {
        this.setState({
            ...this.state,
            filters: {
                material: [],
                shape: [],
                size: [],
                coatingfamily: [],
                brand: [],
                showCoatings: true,
                bmq: false,
                wasBmqActive: false
            }
        }, this.selectClickHandler);
    }

    filterChangeHandler = (event, filterName) => {
        if (filterName) {
            let newState = {
                ...this.state,
                filters: {
                    ...this.state.filters,
                    [filterName]: event.value,
                }
            };

            if(filterName === 'material') {           

                const OM15 = 1;
                const OM15_POLARIZED = 10;
                const ANTI_SCRATCH_COATING = 6;

                if(event.value.indexOf(OM15) >= 0 || event.value.indexOf(OM15_POLARIZED) >= 0) {
                     if(newState.filters.coatingfamily.indexOf(ANTI_SCRATCH_COATING) === -1) {
                        newState.filters.coatingfamily.push(ANTI_SCRATCH_COATING);
                    }
                }

                if(newState.filters.material.indexOf(OM15) === -1 && newState.filters.material.indexOf(OM15_POLARIZED) === -1) {
                    if(newState.filters.coatingfamily.indexOf(ANTI_SCRATCH_COATING) >= 0) {
                        newState.filters.coatingfamily.splice(newState.filters.coatingfamily.indexOf(ANTI_SCRATCH_COATING), 1);
                    }
                }

            } else if (filterName === 'bmq' && this.state.filters.bmq === true) {
                newState.filters.wasBmqActive = false;
            }

            if (filterName !== 'bmq' && this.state.filters.bmq === true) {
                newState.filters.wasBmqActive = true;
            }

            this.setState(newState, this.selectClickHandler)
        }
    }

    exportPDF = () => {

        if(this.state.pdfSkeleton !== null ) {
            let index = 0;
            const doc = new jsPDF();
            
            /* logo */
            if(this.state.pdfSkeleton.Logo) {
                //doc.addImage(this.state.pdfSkeleton.Logo, 'PNG', 7, 15, 20,20);
                doc.addImage(ZEISSLogo, 'PNG', 7, 15, 20,20);
            }
            /* end logo */

            /* title */
            if(this.state.pdfSkeleton.Title) {
                doc.setFontSize(14);
                doc.text(
                    this.state.pdfSkeleton.Title,
                    7,
                    43
                );
            }
            /* end title */
                   
            let startX = 7;
            let startY = 53;
            doc.setFontSize(5);                
        
            /* header */
            if( this.state.pdfSkeleton.IntroText) {
                startY = this.printIntroFooterText(doc, this.state.pdfSkeleton.IntroText, startY, startX);
            }
            /* end header */

            /* body */
    
            doc.setFontSize(7);

            if(this.state.pricelist.hasZeissExcl) {
                doc.text(
                    "* exclusive ZEISS BRANDED",
                    7,
                    startY + 2
                );    
            }          
    
            this.state.uniqueMaterialList.map(material => {          
                if(this.state.pricelist.PLLenses[material]) {
                    Object.keys(this.state.pricelist.PLLenses[material]).map(shape => {
                    
                        let tablesStartY =  (index === 0) ? startY + 8 : doc.lastAutoTable.finalY + 10;
                        let columns = this.buildPDFShapeTableColumn(material, shape);
                        let header = this.buildPDFShapeTableHeader(material, shape);
                        let body = this.buildPDFShapeTableBody(material, shape);
                        let content = header.concat(body);
    
                        if(index >= 0) 
                        
                        doc.autoTable(
                            {
                                columnStyles:columns,
                                theme: 'grid',
                                margin: {left: 7},
                                styles: { fontSize: 5, cellPadding: {top: 2, right: 1, bottom: 2, left: 1} },
                                body: content,
                                startY: tablesStartY,      
                                bodyStyles: {lineColor: [226,226,226]},
                                pageBreak: 'avoid'

                            }                                                        
                        )
                        index++;
                    });
                    
                    if(this.state.pricelist.PLCoatings[material]) {
                        let tablesStartY =  (index === 0) ?  startY + 8 : doc.lastAutoTable.finalY + 10;
                        let columns = this.buildPDFCoatingTableColumn(material);
                        let coatingsHeader = this.buildPDFCoatingTableHeader(material);
                        let coatingsBody = this.buildPDFCoatingTableBody(material);
                        let coatingsContent = coatingsHeader.concat(coatingsBody);
    
                        doc.autoTable(
                            {
                                columnStyles:columns,
                                theme: 'grid',
                                margin: {left: 7},
                                styles: { fontSize: 5,cellPadding : 1 },
                                body: coatingsContent,
                                startY: tablesStartY,         
                                bodyStyles: {lineColor: [226,226,226]}, 
                                pageBreak: 'avoid'                                
                           }
                        )
                        index++;
                    }       
                }              
            });
    
            /* end  body */

            startY =  doc.lastAutoTable.finalY + 10;
            doc.setFontSize(5); 
            startX = 7;   
         

            /* footer */
            let editedDate = this.formatDate(new Date());
            let editedDateText = '<br/> <p>Edited: ' + editedDate + '</p>';
            let footerText = this.state.pdfSkeleton.FooterText + editedDateText;

            if( this.state.pdfSkeleton.FooterText) {
                startY = this.adjustFooterStart(doc, footerText, startY);
                startY = this.printIntroFooterText(doc, footerText, startY, startX);
            }

            

            /* end footer */
            let fileName = this.state.pricelist.Name + '.pdf';
            doc.save(fileName);
        }
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('/');
    }

    adjustFooterStart = (doc, text, startY) => {
        let pText = text.replaceAll('</p>', '');
        let pArray = pText.split('<p>');
        
        let textHeight = pArray.length * 10;
        let newHeight = startY + textHeight;
        if(newHeight > 297) {
            doc.addPage();
            return 10;
        }
    
        return startY;
    }

    printIntroFooterText = (doc, text, startY, startX) => {
        let pText = text.replaceAll('</p>', '');        
        let pArray = pText.split('<p>');
        
        let finalP = [];

        pArray.forEach(paragraph => {
            let newLineSplit = paragraph.split('<br>');
            newLineSplit.forEach(nlP => {
                if(nlP === '') {
                    finalP.push('\n');
                }
                else {
                    finalP.push(nlP);
                }
            });
        });

        const parser = new DOMParser();            
        
        finalP.forEach(textLine => {
            if(textLine !== '') {
                
                let regExp = new RegExp('<strong>(.*?)<\/strong>');
                if(regExp.test(textLine)) {
                    let newTextLine = textLine.split(new RegExp('(<strong>.*?<\/strong>)'));
                    newTextLine.forEach( strongTextLine => {
                        doc.setFont(doc.getFont().fontName, 'normal');
                        if(regExp.test(strongTextLine)) {                        
                            doc.setFont(doc.getFont().fontName, 'bold');
                        }
                        let text = parser.parseFromString(strongTextLine, 'text/html').body.textContent;
                        doc.text(text, startX, startY,{
                            maxWidth: 190,
                            align: 'justify'
                        });
                        startX = startX + doc.getStringUnitWidth(text) * 1.8;
                    });
                    startY = startY + 5;
                    startX = 7;
                }
                else{
                    startX = 7;
                    doc.setFont(doc.getFont().fontName, 'normal');
                    let text = parser.parseFromString(textLine, 'text/html').body.textContent;
                    doc.text(text, startX, startY,{
                        maxWidth: 190,
                        align: 'justify'
                    });
                    startY = startY + 5;
                }                           
            }
            else if(textLine === '\n'){
                startX = 7;
                doc.setFont(doc.getFont().fontName, 'normal');
                doc.text('', startX, startY,{
                    maxWidth: 190,
                    align: 'justify'
                });
                startY = startY + 5;
            }
        });  
        
        return startY;
    }

    buildPDFCoatingTableColumn = (material) => {

        let colObj = {
            0: {cellWidth: 20},
            1: {cellWidth: 35}
        };

        if( this.state.pricelist.PLCoatings[material]['allShapes']) {
            let count = this.state.pricelist.PLCoatings[material]['allShapes'].length + 2;

            for(let i = 2; i < count; i++) {
                colObj[i] =  {cellWidth: 8};
            }            
        }
        else {
            colObj[2] =  {cellWidth: 30};
        }

        return colObj;        
    }


    buildPDFCoatingTableHeader = (material) => {
        let header = [
            [
                {
                    content: 'family',
                    styles: {  fillColor: [0,139,208], textColor: [255,255,255], lineWidth: 0 }
                },
                {
                    content: 'COATINGS ON ' + material,
                    styles: {  fillColor: [0,139,208], textColor: [255,255,255], lineWidth: 0 }
                }
            ]
        ];

        if( this.state.pricelist.PLCoatings[material]['allShapes'] && this.state.pricelist.PLCoatings[material]['allShapes'].length > 0) {
            
            let count = this.state.pricelist.PLCoatings[material]['allShapes'].length;

            for(let i = 0; i < count; i++) {
                header[0].push({content: this.state.pricelist.PLCoatings[material]['allShapes'][i], styles: {  fillColor: [0,139,208], textColor: [255,255,255], lineWidth: 0, halign: 'center'}});
            }                 
        }
        else{
            header[0].push({content: '', styles: {  fillColor: [255,255,255], lineWidth: 0 }});
        }

        return header;
    }

    
    buildPDFCoatingTableBody = (material) => {

        let body = [];

        if( this.state.pricelist.PLCoatings[material].coatings) {
           
            Object.keys( this.state.pricelist.PLCoatings[material].coatings).map(family => {   
                Object.keys(this.state.pricelist.PLCoatings[material].coatings[family]).map(coating => {

                    let arr = [
                        {
                            content: family,
                            styles: {lineWidth: {'bottom' : 0.2}}
                        }, 
                        {
                            content: coating,
                            styles: {lineWidth: {'bottom' : 0.2}}
                        }
                    ];
           
                    if( this.state.pricelist.PLCoatings[material]['allShapes'] && this.state.pricelist.PLCoatings[material]['allShapes'].length > 0) {
                        
                        let count = this.state.pricelist.PLCoatings[material]['allShapes'].length;

                        for(let i = 0; i < count; i++) {
                            let shape = this.state.pricelist.PLCoatings[material]['allShapes'][i];
                            if(this.state.pricelist.PLCoatings[material].coatings[family][coating][shape]) {
                                let zeissExcl = '';
                                if(this.state.pricelist.PLCoatings[material].coatings[family][coating][shape]['zeissExcl'] && this.state.pricelist.PLCoatings[material].coatings[family][coating][shape]['zeissExcl'] == 1) {
                                    zeissExcl = ' *';
                                }
                                let value = this.state.pricelist.PLCoatings[material].coatings[family][coating][shape]['price'];
                                arr.push({content:  value ? (this.htmlDecode(this.state.pricelist.CurrencySign) + ' '+ value.toFixed(2) + zeissExcl) : 'NA', styles: {lineWidth: {'bottom' : 0.2}, halign: 'center', valign: 'center'}});
                            }
                            else {
                                arr.push({content: 'NA',  styles: {lineWidth: {'bottom' : 0.2},halign: 'center', valign: 'center'}});
                            }

                        }                 
                    } 
                    else {
                        if(this.state.pricelist.PLCoatings[material].coatings[family][coating]) {
                            let zeissExcl = '';
                            if(this.state.pricelist.PLCoatings[material].coatings[family][coating]['zeissExcl'] && this.state.pricelist.PLCoatings[material].coatings[family][coating]['zeissExcl'] == 1) {
                                zeissExcl = ' *';
                            }
                            let value = this.state.pricelist.PLCoatings[material].coatings[family][coating]['price'];
                            arr.push({content:  value ? (this.htmlDecode(this.state.pricelist.CurrencySign) + ' '+ value.toFixed(2) + zeissExcl) : 'NA', styles: {lineWidth: {'bottom' : 0.2},halign: 'center', valign: 'center'}});
                        }            
                        else {
                            arr.push({content: 'NA', styles: {lineWidth: {'bottom' : 0.2},halign: 'center', valign: 'center'}});           
                        }            
                    }                                  

                    body.push(arr); 
                })
              
            })               
                    
        }
     
        return body;
    }

    buildPDFShapeTableColumn = (material, shape) => {

        let colObj = {
            0: {cellWidth: 20},
            1: {cellWidth: 30}
        };

        if( this.state.pricelist.PLLenses[material][shape]['allSizes'] !== 'undefined') {
            let count = this.state.pricelist.PLLenses[material][shape]['allSizes'].length + 2;

            for(let i = 2; i < count; i++) {
                colObj[i] =  {cellWidth: 10};
            }            
        }
        return colObj;        
    }

    buildPDFShapeTableHeader = (material, shape) => {
        let header = [
            [
                {
                    content: 'Material',
                    styles: {  fontStyle: 'bold' , lineWidth: 0, textColor: [112,112,112], cellPadding: {top: 2, right: 1, bottom: 2, left: 2} },
                },
                {
                    content: material,
                    styles: { fontStyle: 'bold',  lineWidth: 0, textColor: [112,112,112] }
                }
            ],
            [  
                {
                    content: 'Shape',
                    styles: { fillColor: [0,139,208], textColor: [255,255,255], lineWidth: 0, cellPadding: {top: 2, right: 1, bottom: 2, left: 2} }
                },   {
                    content: shape,
                    styles: { fillColor: [0,139,208], textColor: [255,255,255], lineWidth: 0 }
                }
            ]
        ];

        let brandShapeRow = [
            {
                content: 'Range', 
                styles: { valign: 'bottom', lineWidth: 0, cellPadding: {top: 2, right: 1, bottom: 2, left: 2}, fillColor: [247,247,247] },
                
            },{
                content: 'Color type',
                styles: { valign: 'bottom', lineWidth: 0, fillColor: [247,247,247] },
            }
        ]

    
        if( this.state.pricelist.PLLenses[material][shape]['allSizes'] !== 'undefined') {
            
            let count = this.state.pricelist.PLLenses[material][shape]['allSizes'].length;

            for(let i = 0; i < count; i++) {
                let text = this.state.pricelist.PLLenses[material][shape]['allSizes'][i];
                text = text.replaceAll('<br>', '\n');                
                text = text.replaceAll('<strong>', ''); 
                text = text.replaceAll('</strong>', ''); 

                brandShapeRow.push({
                    content: text,
                    styles: { valign: 'middle', halign: 'center', lineWidth: 0, fillColor: [247,247,247] },
                })
                header[0].push({content: '', styles: {  fillColor: [255,255,255], lineWidth: 0 }});
            }     
            
            header[1].push({content: '', colSpan: count, styles: { halign: 'center',  lineWidth: 0 }});
        }

        header.push(brandShapeRow);

        return header;
    }

    buildPDFShapeTableBody = (material, shape) => {
        
        let body = []

        let brandsCount = this.state.pricelist.PLLenses[material][shape].allBrand.length;
        
        let elementsAdded = 0;
        for (let i = 0; i < brandsCount; i++) {         
           let currentBrand = this.state.pricelist.PLLenses[material][shape].allBrand[i];

           let arrColorTypes = [];
           Object.keys(this.state.pricelist.PLLenses[material][shape].lenses[currentBrand]).map(colorType => {
                arrColorTypes.push({content: colorType, styles: { lineWidth: 0 } });                
           })

           let j = 0;
           arrColorTypes.forEach(colorType => {
            if(j === 0 ) {
                body.push( [{
                    rowSpan: arrColorTypes.length,
                    content: currentBrand,
                    styles: { valign: 'middle', lineWidth: {'bottom' : 0.2}, cellPadding: {top: 2, right: 1, bottom: 2, left: 2}}
                }, {content: colorType.content, styles: { lineWidth: {'bottom' : 0.2} }}]);
            }
            else {
                body.push([{
                    content: colorType.content,
                    styles: { lineWidth: {'bottom' : 0.2}}
                }])                                 
            }    

            if( this.state.pricelist.PLLenses[material][shape]['allSizes'] !== 'undefined') {
                let sizeCount = this.state.pricelist.PLLenses[material][shape]['allSizes'].length;        
                for(let z = 0; z < sizeCount; z++) {
                    let currentSize = this.state.pricelist.PLLenses[material][shape]['allSizes'][z];
                    let value = this.state.pricelist.PLLenses[material][shape].lenses[currentBrand][colorType.content][currentSize];
                    body[elementsAdded].push({ content: value ? this.htmlDecode(this.state.pricelist.CurrencySign) + ' '+ value.toFixed(2) : 'NA' , styles: { halign: 'center',  lineWidth: {'bottom' : 0.2} } });                   
                }  
            }
            elementsAdded++;
            j++;
           });
        }
 
        return body;
     
    }

    buildKey = (material, shape) => {
        let key = 'lens_'+this.state.pricelist.ID+material+'_'+shape;
        return key.replace(/\s+/g, '_').replace(/\./g, "");      
    }

    htmlDecode(input) {
        let doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    getUniqueMaterialPricelist = (pricelistData) => {
        let uMaterials = [];
        if (pricelistData.PLLenses) {
            uMaterials = Object.keys(pricelistData.PLLenses);
        }

        return uMaterials;
    }

    render() {
        return (
            <div className="page-container pricelist-container">
                <div className="header-gray-line"> </div>
                <h1 className="generic-page-title">Pricelist</h1>
                <p className="text-bold">Please select a pricelist</p>
                <div className="pricelist-select-container">
                    <select
                        name="pricelist"
                        className="pricelist-select"
                        value={this.state.selectedPricelistID}
                        onChange={this.changePricelistHandler}
                    >
                        {
                            this.state.pricelists.map(pricelist => (
                                <option key={pricelist.ID} value={pricelist.ID}>
                                    {pricelist.Name}
                                </option>
                            ))
                        }
                    </select>
                    <Button type="button" buttonStyle="secondary" className="pricelist-select-button" onClick={this.selectClickHandler}>Select</Button>
                </div>
                <hr className="cookie"/>
                {
                    this.state.pricelist === null ?
                    null
                    :
                    <div className="page-container">
                        <div className={this.state.filterVisible ? "vendor-filter-bar pricelist-filter open" : "vendor-filter-bar pricelist-filter"} onClick={ () => this.setState({ ...this.state, filterVisible: !this.state.filterVisible})}>
                            <h3>Filters</h3>
                        </div>
                        {
                            this.state.filterVisible ?
                            <div className="vendors-network-filters">
                                <div className="filters-container">
                                    <div className="filter dropdown">
                                        <label htmlFor="material-filter">Material</label>
                                        <DropdownField id="material-filter" name="material-filter" onChange={event => this.filterChangeHandler(event, 'material')} data={this.state.filtersList.Materials} multiple maxSelectedShown="2" value={this.state.filters.material}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="shape-filter">Shape</label>
                                        <DropdownField id="shape-filter" name="shape-filter" onChange={event => this.filterChangeHandler(event, 'shape')} data={this.state.filtersList.Shapes} multiple maxSelectedShown="2" value={this.state.filters.shape}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="size-filter">Size</label>
                                        <DropdownField id="size-filter" name="size-filter" onChange={event => this.filterChangeHandler(event, 'size')} data={this.state.filtersList.Sizes} multiple maxSelectedShown="2" value={this.state.filters.size}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="coating-family-filter">Coating Family</label>
                                        <DropdownField id="coating-family-filter" name="coating-family-filter" onChange={event => this.filterChangeHandler(event, 'coatingfamily')} data={this.state.filtersList.CoatingFamilies} multiple maxSelectedShown="2" value={this.state.filters.coatingfamily}/>
                                    </div>
                                    <div className="filter dropdown">
                                        <label htmlFor="brand-filter">Brand</label>
                                        <DropdownField id="brand-filter" name="brand-filter" onChange={event => this.filterChangeHandler(event, 'brand')} data={this.state.filtersList.Brands} multiple maxSelectedShown="2" value={this.state.filters.brand}/>
                                    </div>
                                    <div className="filter dropdown checkbox">
                                        <Checkbox id="show-coatings-filter" name="show-coatings-filter" value={this.state.filters.showCoatings} onChange={event => this.filterChangeHandler(event, 'showCoatings')}>
                                            <label htmlFor="show-coatings-filter">Show coatings</label>
                                        </Checkbox>
                                    </div>
                                    {/* <div className="filter dropdown checkbox">
                                        <Checkbox id="show-coatings-filter" name="show-coatings-filter" value={this.state.filters.bmq} onChange={event => this.filterChangeHandler(event, 'bmq')}>
                                            <label htmlFor="show-coatings-filter">BMQ and BMQ Sport Pricelist</label>
                                        </Checkbox>
                                    </div> */}
                                </div>
                                <div className="filters-action">
                                    <Button icon="Close" theme={{ buttonIconPosition: 'left'}}  buttonStyle='secondary' onClick={this.resetFilters}> Reset filters </Button>
                                </div>
                            </div>
                            : null
                        }
                        <div className="pricelist-actions">
                            <Button icon="PDFFileOutline" theme={{ buttonIconPosition: 'left'}} buttonStyle='primary' onClick={this.exportPDF}>Export PDF</Button>
                        </div>
                        <div className="pricelist-tables-container">
                            {
                                this.state.filtersList.Materials ?
                                <div>
                                     {
                                        this.state.pdfSkeleton && this.state.pdfSkeleton.Title?
                                        <h2 className="pl-title">{this.state.pdfSkeleton.Title}</h2>
                                        :
                                        null
                                    }      
                                    {
                                        this.state.pdfSkeleton && this.state.pdfSkeleton.IntroText?
                                        <div className="pl-header" dangerouslySetInnerHTML={{__html: this.state.pdfSkeleton.IntroText}}>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        this.state.pricelist.hasZeissExcl ?
                                        <p className="excl-intro">* exclusive ZEISS BRANDED</p>
                                        : null
                                    }
                                    {
                                        this.state.uniqueMaterialList.map(material => (
                                            <div key={"material-tables-"+material}>
                                                {
                                                    this.state.pricelist.PLLenses[material] ?
                                                    Object.keys(this.state.pricelist.PLLenses[material]).map(shape => (
                                                        <PricelistLensTable key={this.buildKey(material, shape)}
                                                            brand={this.state.pricelist.PLBrand}
                                                            material={material} 
                                                            shape={shape}
                                                            allSizes={this.state.pricelist.PLLenses[material][shape]['allSizes']}
                                                            allBrand={this.state.pricelist.PLLenses[material][shape]['allBrand']}
                                                            lenses={this.state.pricelist.PLLenses[material][shape]['lenses']}
                                                            sign={this.state.pricelist.CurrencySign}
                                                        ></PricelistLensTable>
                                                    ))
                                                    : null
                                                }
                                                {
                                                    this.state.pricelist.PLCoatings[material] ?
                                                    <PricelistCoatingTable key={"coating_"+this.state.pricelist.ID+material}
                                                        material={material}
                                                        coatings={this.state.pricelist.PLCoatings[material]['coatings']}
                                                        allShapes={this.state.pricelist.PLCoatings[material]['allShapes']}
                                                        sign={this.state.pricelist.CurrencySign}
                                                    ></PricelistCoatingTable>
                                                    : null
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        this.state.pdfSkeleton && this.state.pdfSkeleton.FooterText?
                                        <div className="pl-header" dangerouslySetInnerHTML={{__html: this.state.pdfSkeleton.FooterText}}>
                                        </div>
                                        :
                                        null
                                    }  
                                </div>                               
                                : null
                            }
                        </div>
                    </div>
                }

                {   
                    this.state.isLoading ?      
                    <div>
                        <div className="black-layer">
                        </div>   
                        <Loader
                            type="TailSpin"
                            color="#008bd0"
                            height={100}
                            width={100}
                            timeout={1500000}                 
                            className="spinner"
                        />
                    </div>       
                      
                    :
                    null
                }

            </div>
        );
    }

}


const mapStateToProps = state => {

    let user; 
    if(state.auth.user) {
        user = state.auth.user;
    }
    else {
        user = JSON.parse(localStorage.getItem('user'));        
    }

    return {
        user: user
    };
}

export default withRouter(connect(mapStateToProps, null)(Pricelist));
