import React, { useState, useEffect } from 'react';

import './Persona.css';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const Persona = (props) => {
    const [carouselValue, setCarouselValue] = useState(0);

    useEffect(() => {
        let el = document.getElementById('persona-mobile-carousel');
        if (el) el.scrollIntoView({behavior: 'smooth', block: 'center'});
    })

    return (
        <div className="personas-container">
            <div className="box-persona opacity-box" onClick={ () => props.clickedBackPersona()}>
                <img src={props.persona.Image} />
                <p>{props.persona.Title} </p>                
            </div>
            <div className="persona-categories d-none d-md-block">
                {
                    props.persona.Categories.map((category) => (
                        <div className="box-category"  onClick={ () => props.clicked(category)}>
                            <div className="img-container">
                                <img src={category.Icon} />
                                <p>{category.Title} </p>
                            </div>
                        </div>
                    ))   
                }
            </div>
            <div className="persona-categories-mobile d-block d-md-none" id="persona-mobile-carousel">
            <Carousel 
                value={carouselValue}
                onChange={value => setCarouselValue(value)}
                centered
                slidesPerPage={2}
            >
                {
                    props.persona.Categories.map((category) => (
                        <div className="box-category"  onClick={ () => props.clicked(category)}>
                            <div className="img-container">
                                <img src={category.Icon} />
                                <p>{category.Title} </p>
                            </div>
              
                        </div>
                    ))
                }
            </Carousel>
            <Dots className="ZEISS_dots_carousel" value={carouselValue} onChange={value => setCarouselValue(value)} number={props.persona.Categories.length} />
            </div>
        </div>
    );
}

export default Persona;