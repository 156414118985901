import React, { Component } from "react";
import ELearningMenu from './Menu/ELearningMenu';
import Personas from './Personas/Personas';
import Persona from './Persona/Persona';
import Category from './Category/Category';
import IntermediateLevel from './IntermediateLevel/IntermediateLevel';
import Level from './Level/Level';
import { ClickOutside } from 'reactjs-click-outside';
import { config } from '../../../config';
import './ELearning.css';
import axios from '../../../axios';
import LevelExtraText from "./Level/LevelExtraText";
import HiglightText from "./HiglightText";
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import * as actions from '../../../store/actions/index';
const { Modal, ModalBody, ModalHeader,Skeleton, Icon } = require('precise-ui');

class ELearning extends Component {
    state = {
        activePersona: null,
        activeCategory: null,
        activeIntermediateLevel: null,
        activeLevel: null,
        personas: [],
        menu: [],
        selectedMenuItem: null,
        openVideoPopUp: false,
        isYouTubeVideo: false,
        activeVideoURL: '',
        searchValue: '',
        searchResultActive: false,
        searchResultActiveText: '',
        searchResults: [],
        openMaterialPopup: false,
        materials: [],
        selectedCollectionId: null,
        showSkeleton: true,
    }

    currentURL = '';    
    historyStack = [];
   

    componentDidMount() {
        if(this.props.isPageVisible) {
            this.props.onMountCheckFPCookie();
        
            let inteLevelURL = '';
            let levelURL = '';

            if(this.props.match.params.intLevel){ 
                inteLevelURL = this.props.match.params.intLevel;
                if(this.props.match.params.level){ 
                    levelURL = this.props.match.params.level;
                }
            }      



            window.scrollTo(0, 0);
            let data = {dummy: '1', accountType: this.props.user.accountType};

            this.currentURL = '';

            axios.post('/getElearningMainStructure', JSON.stringify(data),{
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                this.setState({
                    ...this.state,
                    personas: response.data.Personas,
                    menu: response.data.Menu,
                    showSkeleton: false
                }, () => {this.manageURL(inteLevelURL,levelURL)});
                
            })
            .catch(err => {
                console.log(err);
            });
        }
        else {
            this.props.history.push("/home");
        }      

    }

    manageURL(intLevelURL, levelURL){
        //AC
        
        if (levelURL != '' && intLevelURL == levelURL) {         
            let path = this.searchForLevelByURL(levelURL);

            if(path && path.persona && path.category) {
                this.setState({
                    ...this.state,
                    activePersona: path.persona,
                    activeCategory: path.category,
                    activeLevel: path.level,
                    activeIntermediateLevel: path.intermediateLevel,
                    selectedMenuItem: null
                }, () => { window.scrollTo(0, 0);});
            }
         
              
        }
        else if(intLevelURL != '' && levelURL == ''){
            let path = this.searchForIntermediateLevelByURL(intLevelURL);
            if(path && path.persona && path.category) {
                this.setState({
                    ...this.state,
                    activePersona: path.persona,
                    activeCategory: path.category,
                    activeLevel: path.level,
                    activeIntermediateLevel: path.intermediateLevel,
                    selectedMenuItem: null
                }, () => { window.scrollTo(0, 0);});
            }          
        }
        else if(levelURL != '' && intLevelURL != levelURL) {
           let path1 = this.searchForIntermediateLevelByURL(intLevelURL);
           let path2 = this.searchForLevelByURL(levelURL);
            this.setState({
                ...this.state,
                activePersona: path1.persona,
                activeCategory: path1.category,
                activeLevel: path2.level,
                activeIntermediateLevel: path1.intermediateLevel,
                selectedMenuItem: null
            }, () => { window.scrollTo(0, 0);});
        }

    }

    searchForLevelByURL(levelURL) {

        let result = { persona: null, category: null, intermediateLevel: null, level: null};

        this.state.personas.forEach(persona => {
            persona.Categories.forEach( category => {
                category.Levels.forEach( level => {                        
                    if(level.Type == 'IntermediateLevel') {
                        level.Levels.forEach( finalLevel => {
                            let url = this.sanitazeURL(finalLevel.Title);
                            if(url == levelURL) {
                                result.persona = persona;
                                result.category = category;
                                result.intermediateLevel = null;
                                result.level = finalLevel;       
                            }
                        })
                    }
                    else {
                        let url = this.sanitazeURL(level.Title);
                        if(url == levelURL) {
                            result.persona = persona;
                            result.category = category;
                            result.intermediateLevel = null;
                            result.level = level;       
                        }
                    }
                })
            })
        })

        return result;

    }

    searchForIntermediateLevelByURL(levelURL) {

        let result = { persona: null, category: null, intermediateLevel: null, level: null};

        this.state.personas.forEach(persona => {
            persona.Categories.forEach( category => {
                category.Levels.forEach( level => {                        
                    if(level.Type == 'IntermediateLevel') {
                        let url = this.sanitazeURL(level.Title);
                        if(url == levelURL) {
                            result.persona = persona;
                            result.category = category;
                            result.intermediateLevel = level;
                            result.level = null;
                        }
                    }
                })
            })
        })

        return result;
    }


    sanitazeURL(value) {
        return value.toLowerCase().replace(/\s/g, '-').replace(/[^a-zA-Z0-9 -]/g, "")
    }

    componentDidUpdate(){    
        if(this.historyStack.length > 0) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', this.onBackButtonEvent);
        }         
    }

    onBackButtonEvent = (e) => {
        
        this.historyStack.pop();
        if(this.historyStack.length > 0) {
            console.log(this.historyStack);
            let lastIndex = this.historyStack.length - 1;
            if(this.historyStack[lastIndex].Type == 'Persona'){
                this.backToCategory(0)
            }
            else if(this.historyStack[lastIndex].Type == 'Category') {
                this.backToLevels(0);
            }
            else if(this.historyStack[lastIndex].Type == 'IntermediateLevel') {
                this.backToIntermediateLevel(false);
            }
        }
        else {
            this.backToPersonas(true);
        }

        e.preventDefault();      
    }

    componentWillUnmount = () => {
        window.removeEventListener('popstate', this.onBackButtonEvent);
    }

    searchFieldOnChange = event => {    
        this.setState({
            searchValue: event.target.value
        });    
    };

    handleClickPersona(persona) {

        this.historyPushUserELearning();

        this.setState({
            ...this.state,
            activePersona: persona
        });

        let url = persona.Title.toLowerCase();
        this.sendAnalytics(url);
        this.historyStack.push(persona);
    }

    handleClickCategory(category) {

        this.historyPushUserELearning();

        let url = this.currentURL;
        if(category.Levels.length == 1) {
            this.handleClickIntermediateLevel(category.Levels[0], false);
            url += '/' + category.Levels[0].Title.toLowerCase();
            this.historyStack.push(category.Levels[0]);
        }    
        else {
            this.setState({
                ...this.state,
                activeCategory: category
            });
            url += '/' + category.Title.toLowerCase();
            this.historyStack.push(category);
        }           
        this.sendAnalytics(url);
    }

    handleClickIntermediateLevel(level, sendAnalytics) {   


        if(level.Type == 'Level') {
            
            //AC
            let url = this.sanitazeURL(level.Title);
            this.historyPushUserELearning(url+"/"+url);
            
            this.setState({
                ...this.state,
                activeLevel: level
            }, () => { window.scrollTo(0, 0);});
        }
        else {
            //AC
            let url = this.sanitazeURL(level.Title);
            this.historyPushUserELearning(url);

            this.setState({
                ...this.state,
                activeIntermediateLevel: level
            }, () => { window.scrollTo(0, 0);});
        }

        if(sendAnalytics){
            let url = this.currentURL;
            url += '/' + level.Title.toLowerCase();
            this.sendAnalytics(url);
            this.historyStack.push(level);
        }       
    }

    handleClickMenuItem(in_level) { 

        this.state.personas.forEach(persona => {
            persona.Categories.forEach( category => {
                category.Levels.forEach( level => {
                    if(level.Type == in_level.Type && level.ID == in_level.ID) {
                        if(in_level.Type == 'Level') {
                            let url = this.sanitazeURL(level.Title);
                            this.historyPushUserELearning(url+"/"+url);
                            this.setState({
                                ...this.state,
                                activePersona: persona,
                                activeCategory: category,
                                activeLevel: level,
                                activeIntermediateLevel: null,
                                selectedMenuItem: null
                            }, () => { window.scrollTo(0, 0);});                     
                        }
                        else {
                            let url = this.sanitazeURL(level.Title);
                            this.historyPushUserELearning(url);
                            this.setState({
                                ...this.state,
                                activePersona: persona,
                                activeCategory: category,
                                activeIntermediateLevel: level,
                                selectedMenuItem: null,
                                activeLevel: null
                            }, () => { window.scrollTo(0, 0);});
                        }

                        this.currentURL = "";
                        let url = persona.Title.toLowerCase() + '/' + category.Title.toLowerCase() +'/' + level.Title.toLowerCase();
                        this.sendAnalytics(url);       
                    }                  
                });
            });
        });        
    }

    handleClickLearnMoreSearchResult(in_level) { 

        this.state.personas.forEach(persona => {
            persona.Categories.forEach( category => {
                category.Levels.forEach( level => {
                    if(level.Type == 'IntermediateLevel') {
                        level.Levels.forEach(finalLevel => {
                            if(finalLevel.ID == in_level.ID) {
                                this.setState({
                                    ...this.state,
                                    activePersona: persona,
                                    activeCategory: category,
                                    activeLevel: finalLevel,
                                    activeIntermediateLevel: null,
                                    selectedMenuItem: null
                                }, () => { this.backFromSearch(); window.scrollTo(0, 0);})
                                this.currentURL = "";
                                let url = persona.Title.toLowerCase() + '/' + category.Title.toLowerCase() +'/' + finalLevel.Title.toLowerCase();
                                this.sendAnalytics(url)
                            }
                        });
                    }
                    else {
                        if(level.ID == in_level.ID) {
                            this.setState({
                                ...this.state,
                                activePersona: persona,
                                activeCategory: category,
                                activeLevel: level,
                                activeIntermediateLevel: null,
                                selectedMenuItem: null
                            }, () => { this.backFromSearch();  window.scrollTo(0, 0);});
                            this.currentURL = "";
                            let url = persona.Title.toLowerCase() + '/' + category.Title.toLowerCase() +'/' + level.Title.toLowerCase();
                            this.sendAnalytics(url)
                        }
                    }
                });
            });
        });        
    }

    handleClickLevel(level) {

        let newUrl = this.sanitazeURL(this.state.activeIntermediateLevel.Title) + "/"+ this.sanitazeURL(level.Title);
        this.historyPushUserELearning(newUrl);


        this.setState({
            ...this.state,
            activeLevel: level
        }, () => { window.scrollTo(0, 0);});

        let url = this.currentURL;
        url += '/' + level.Title.toLowerCase();
        this.sendAnalytics(url);
        this.historyStack.push(level);
    }

    handleClickMedia(media) {
        if(media.Type == 'Video' || media.Type == 'YouTubeVideo') {
            let isYouTubeVideo = false;
            if(media.Type == 'YouTubeVideo') {
                isYouTubeVideo = true;
            }            
            this.setState({
                ...this.state,
                openVideoPopUp: true,
                activeVideoURL: media.Value,
                isYouTubeVideo: isYouTubeVideo
            });
        }
        else if(media.Type == 'CatalogueLink'){
            this.manageCatalogueLink(media);
        }
        else{
            window.open(media.Value, "_blank");
        }
    }

    manageCatalogueLink(media) {
        if(media.Value.Type == 'Material') {
            if(media.Value.ID > 0 ) {
                this.props.history.push("/catalogue/"+media.Value.ID);
            }                        
        }
        else if(media.Value.Type == 'Collection') {
            //aprire popup per selezione catalogo
            if(media.Value.ID > 0) {
                let data = { 
                    idCollection: media.Value.ID,
                    role: this.props.user.catalogueRole
                };

                axios.post('/getMaterialsByCollection', JSON.stringify(data),{
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(response => {
                    if(response.data) {
                        this.setState({
                            ...this.state,
                            selectedCollectionId: media.Value.ID,
                            openMaterialPopup: true,
                            materials: response.data.Values
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                });           
            }
          
        }
    }

    handleClickMaterial(material) {
        if(material.ID > 0 && this.state.selectedCollectionId > 0) {
            this.props.history.push("/catalogue/"+material.ID+"/"+this.state.selectedCollectionId);            
        }        
    }

    renderConditionally() {
        if(this.state.activePersona == null) {
            return <Personas personas={this.state.personas} clicked={(persona) => this.handleClickPersona(persona)} ></Personas>
        }
        else if(this.state.activeCategory == null && this.state.activeLevel == null && this.state.activeIntermediateLevel == null) {
            return  <Persona persona={this.state.activePersona} clicked={(category) => this.handleClickCategory(category)} clickedBackPersona={ () => this.backToPersonas(true)}  ></Persona>
        }
        else if(this.state.activeIntermediateLevel == null && this.state.activeLevel == null ) {
            return  <Category 
                persona={this.state.activePersona} 
                category={this.state.activeCategory} 
                clicked={(level) => this.handleClickIntermediateLevel(level, true)} 
                clickedBackPersona={ () => this.backToPersonas(true)}  
                clickedBackCategory={ () => this.backToCategory(1)}  
            ></Category>
        }
        else if(this.state.activeLevel == null){
            return  <IntermediateLevel level={this.state.activeIntermediateLevel} clicked={(level) => this.handleClickLevel(level)}  ></IntermediateLevel>            
        }
        else{
            return <Level level={this.state.activeLevel} clicked={(media) => this.handleClickMedia(media)} user={this.props.user}></Level>            
        }
    }

    backToPersonas(removeHistory) {

        this.historyPushUserELearning();

        this.setState({
            ...this.state,
            activePersona: null,
            activeCategory: null,
            activeIntermediateLevel: null,
            activeLevel: null
        });
        
        if(removeHistory) {
            this.historyStack = [];
        }
    }

    backToCategory(historyStepsBehind) {
        
        this.historyPushUserELearning();

        this.setState({
            ...this.state,
            activeCategory: null,
            activeIntermediateLevel: null,
            activeLevel: null
        });

        if( historyStepsBehind == 1) {
            this.historyStack.pop();
        }
        else if( historyStepsBehind == 2) {
            this.historyStack.pop();
            this.historyStack.pop();
        }
    }

    backToLevels(historyStepsBehind) {

        this.historyPushUserELearning();

        this.setState({
            ...this.state,
            activeIntermediateLevel: null,
            activeLevel: null
        });

        if( historyStepsBehind == 1) {
            this.historyStack.pop();
        }
        else if( historyStepsBehind == 2) {
            this.historyStack.pop();
            this.historyStack.pop();
        }
    }

    backToIntermediateLevel(removeHistory) {

        let url = this.sanitazeURL(this.state.activeIntermediateLevel.Title);
        this.historyPushUserELearning(url);

        this.setState({
            ...this.state,
            activeLevel: null
        });

        if(removeHistory) {
            this.historyStack.pop();
        }
    }

    backToPersona() {

        this.historyPushUserELearning();

        this.setState({
            ...this.state,
            activeLevel: null
        });

        this.historyStack.pop();
    }

    handleOnHoverMenu(menuItem) {
        this.setState({
            ...this.state,
            selectedMenuItem: menuItem
        });
    }

    handleClickOutside() {
        this.setState({
            ...this.state,
            selectedMenuItem: null
        });
    }

    handleCloseVideoPopUp() {
        this.setState({openVideoPopUp: false});
    }

    handleCloseMaterialPopup() {
        this.setState({openMaterialPopup: false});
    }


    renderBreadCrumps() {
        if(this.state.activePersona == null) {
            this.currentURL = '';
            return null;            
        }
        else if(this.state.activeCategory == null  && this.state.activeLevel == null) { 
            this.currentURL = this.state.activePersona.Title.toLowerCase();
            return <span>
                    <span onClick={ () => this.backToPersonas(true)} > Home / </span>
                    <span >{this.state.activePersona.Title} </span> 
                </span>
        }
        else if(this.state.activeCategory == null  && this.state.activeLevel !== null ) { 
            this.currentURL = this.state.activePersona.Title.toLowerCase() + '/' + this.state.activeLevel.Title.toLowerCase();
            return <span>
                    <span onClick={ () => this.backToPersonas(true)} > Home / </span>
                    <span onClick={ () => this.backToPersona()} > {this.state.activePersona.Title} </span> 
                    <span > / {this.state.activeLevel.Title} </span> 
                </span>
        }
        else if(this.state.activeIntermediateLevel == null && this.state.activeLevel == null ) {
            this.currentURL = this.state.activePersona.Title.toLowerCase() + '/' + this.state.activeCategory.Title.toLowerCase();
            return  <span> 
                        <span onClick={ () => this.backToPersonas(true)} > Home / </span> 
                        <span onClick={ () => this.backToCategory(1)} > {this.state.activePersona.Title} </span> 
                        <span> / {this.state.activeCategory.Title} </span>  
                    </span>
        }
        else if(this.state.activeLevel == null){
            this.currentURL = this.state.activePersona.Title.toLowerCase() + '/' + this.state.activeCategory.Title.toLowerCase() + '/' + this.state.activeIntermediateLevel.Title.toLowerCase();
            return  <span> 
                    <span onClick={ () => this.backToPersonas(true)}> Home / </span> 
                    <span onClick={ () => this.backToCategory(2)}  > {this.state.activePersona.Title} </span> 
                    <span onClick={ () => this.backToLevels(1)} > / {this.state.activeCategory.Title} </span>  
                    <span> / {this.state.activeIntermediateLevel.Title} </span>  
                </span>            
        }
        else  if(this.state.activeLevel !== null && this.state.activeIntermediateLevel == null){
            this.currentURL = this.state.activePersona.Title.toLowerCase() + '/' + this.state.activeCategory.Title.toLowerCase() + '/' + this.state.activeLevel.Title.toLowerCase();
            return  <span> 
                <span onClick={ () => this.backToPersonas(true)} > Home / </span> 
                <span onClick={ () => this.backToCategory(2)} > {this.state.activePersona.Title} </span> 
                <span onClick={ () => this.backToLevels(1)} > / {this.state.activeCategory.Title} </span>  
                <span> / {this.state.activeLevel.Title} </span>  
             </span>                  
        }
        else if(this.state.activeLevel !== null && this.state.activeIntermediateLevel !== null) {
            this.currentURL = this.state.activePersona.Title.toLowerCase() + '/' + this.state.activeCategory.Title.toLowerCase() + '/' + this.state.activeIntermediateLevel.Title.toLowerCase() + '/' + this.state.activeLevel.Title.toLowerCase();
            return  <span> 
            <span onClick={ () => this.backToPersonas(true)} > Home /  </span> 
            <span onClick={ () => this.backToCategory(3)} > {this.state.activePersona.Title} </span> 
            <span onClick={ () => this.backToLevels(2)} > / {this.state.activeCategory.Title} </span>  
            <span onClick={ () => this.backToIntermediateLevel(true)} > / {this.state.activeIntermediateLevel.Title} </span>  
            <span> / {this.state.activeLevel.Title} </span>  
         </span>   
        }
    }

    searchFieldHandler = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();             
            this.startSearch();
        }
    }

    startSearch = () => {

        let searchQuery =  this.state.searchValue;
        if(searchQuery != '') {
            this.setState({
                searchResultActiveText: searchQuery,
                searchValue: ''
            }); 
    
            const data = {
                searchQuery: searchQuery,
                accountType: this.props.user.accountType
            }
            axios.post("/elearningSearchByString", data)
            .then(result => {
                this.setState({
                    searchResultActive: true,
                    searchResults: result.data
                }); 
               
            })
            .catch(error => {
                console.log(error);
            })
        }
       
    }

    getHighlightedText(text) {
        var regex = new RegExp("(?<!</?[^>]*|&[^;]*)("+ this.state.searchResultActiveText+")", "gi");
        return text.replace(regex, "<mark>$1</mark>");
    }

    backFromSearch() {
        this.setState({
            searchValue: '',
            searchResultActive: false,
            searchResultActiveText: '',
            searchResults: []
        }); 
    }

    renderSkeletonPersonas() {
        return <div className="personas-container">
            <Skeleton height={406} className="box-persona" />
            <Skeleton height={406} className="box-persona" />
            <Skeleton height={406} className="box-persona" />
        </div>
    }

    sendAnalytics(url) {
        //ReactGA.initialize(config.analytics);
        let finalURL = encodeURI(url.replace(/\s/g, '-'));
        let eLearningUrl = this.props.user.accountType == 'Contact' ? '/leads-e-learning' : '/e-learning';
        //ReactGA.pageview(eLearningUrl+finalURL);
    }

    historyPushUserELearning(moreUrl) {
        moreUrl = !moreUrl ? '' : moreUrl;
        let eLearningUrl = this.props.user.accountType == 'Contact' ? '/leads-e-learning' : '/e-learning';
        this.props.history.push(`${eLearningUrl}/${moreUrl}`);
    }

    render () {
       
        return (
            <div>
                <div className="page-container">
                    <div class="header-gray-line"> </div>  
                    {
                        this.props.user.accountType == 'Contact' && !this.props.user.fullPrivilegeRejected ?
                        <div className="banner-full" onClick={() => {  this.props.toggleFullPrivilegeModal() }} >
                            <span class="icon-hint-warning m-r-10"></span>
                            <span>To access the full e-learning content, please fill in your company information.</span>    
                            <Icon name="KeyboardArrowRight" size={2} />
                        </div>
                        :
                        null
                    }                  
                    <div>
                        <ClickOutside outsideCall={()=> this.handleClickOutside()}>
                            <div>
                                <ELearningMenu 
                                    menu={this.state.menu} 
                                    selectedMenuItem={this.state.selectedMenuItem} 
                                    onHover={(menuItem) => this.handleOnHoverMenu(menuItem)}
                                    itemClicked={(level) => this.handleClickMenuItem(level)}
                                    changeSearchQuery={(event) => this.searchFieldOnChange(event)}
                                    keyDown={(event) => this.searchFieldHandler(event)}>                            
                                </ELearningMenu>
                            </div>                           
                        </ClickOutside> 
                        {
                            this.state.searchResultActive ?
                            <div>
                                <p onClick={ () => this.backFromSearch()} className="back-button"> &lt; Back </p>
                                <h1> Search result for <i> "{this.state.searchResultActiveText}" </i> </h1> 
                                {
                                    this.state.searchResults.length > 0 ?
                                    <div>
                                    {
                                        this.state.searchResults.map((result) => (
                                            <div className="el-img-search-container">
                                                <div className="el-img-search-content-container">
                                                    <div className="el-img-search-result" onClick={() => this.handleClickLearnMoreSearchResult(result)}>
                                                        {
                                                            result.Image !== null ?
                                                            <img src={result.Image} />    
                                                            : 
                                                            null
                                                        }                                                
                                                        <HiglightText HTML={ this.getHighlightedText(result.Title) }></HiglightText>
                                                    </div>
                                                    <div className="el-img-search-content">
                                                        {
                                                            result.SubTitle != null && result.SubTitle !== '' ?
                                                            <HiglightText HTML={ this.getHighlightedText(result.SubTitle) }></HiglightText>
                                                            :
                                                            null
                                                        }            
                                                        {
                                                            result.Text !== null && result.Text !== '' ? 
                                                            <LevelExtraText  HTML={ this.getHighlightedText(result.Text) } />    
                                                            :
                                                            null
                                                        }                                         
                                                        {
                                                            result.ExtraText !== null && result.ExtraText !== '' ? 
                                                            <LevelExtraText  HTML={this.getHighlightedText(result.ExtraText) }/>    
                                                            :
                                                            null
                                                        }          
                                                    </div>
                                                </div>
                                                <div className="el-img-search-footer">
                                                    <p className="learn-more" onClick={() => this.handleClickLearnMoreSearchResult(result)}> Learn more &#8594; </p>
                                                </div>
                                            </div>
                                        ))   
                                    }
                                    </div>
                                    :
                                    <div>
                                        <p> No results found.</p>
                                    </div>
                                    
                                }
                              
                            </div>
                            :
                            <div>
                                <div className="breadcrumps-container">
                                    {this.renderBreadCrumps()}
                                </div>
                                {
                                    this.state.showSkeleton ?
                                    this.renderSkeletonPersonas()
                                    :
                                    this.renderConditionally()
                                }                            
                            </div>
                        }
                       
                        <Modal open={this.state.openVideoPopUp} onClose={this.handleCloseVideoPopUp.bind(this)} style={{ 'max-width': '968px'}}>
                        <ModalBody>
                            { this.state.isYouTubeVideo ? 
                                <iframe className="video-popup" height="500" src={this.state.activeVideoURL} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                :
                                <video className="video-popup" controls>
                                    <source src={this.state.activeVideoURL} type="video/mp4" />
                                </video>
                            }                          
                        </ModalBody>
                        </Modal>

                        <Modal open={this.state.openMaterialPopup} onClose={this.handleCloseMaterialPopup.bind(this)} style={{ 'max-width': '1270px'}}>
                            <ModalHeader title="Select a material" />
                            <ModalBody>
                                Please select a material for this collection.
                            <div className="material-container">
                                {
                                        this.state.materials.map((material, index) => (
                                            <div key={material.ID} className="product-card pc-small" onClick={ () => { this.handleClickMaterial(material) }}>
                                                <div className="box" >
                                                    <div className="box-content-material">
                                                        <div className="material-box-name">
                                                            <p> {material.Value} </p>
                                                        </div>
                                                        <div className="material-desc">
                                                            <ul className="material-desc-list">
                                                            {
                                                                material.ExtDescription.map( (materialDesc) => (
                                                                    <li>
                                                                        {materialDesc}
                                                                    </li>
                                                                ))
                                                            }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>                
            </div>
        )
    }
}

const mapStateToProps = state => {

    let user; 
    if(state.auth.user) {
        user = state.auth.user;
    }
    else {
        user = JSON.parse(localStorage.getItem('user'));
    }

    return {
        user: user,
        isPageVisible: checkPageVisibilites(user) 
    };

    function checkPageVisibilites(user) {

        if(user) {
            if(['Account', 'Contact', 'Advanced'].includes(user.accountType)) {
                return true;
            }
            return false;   
        }
        else
            return false;        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleFullPrivilegeModal: () => dispatch(actions.toggleFullPrivilegeModal())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ELearning));
