import React, {Component} from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actionCreators from '../../store/actions/index';
import axios from '../../axios';
import ReactGA from 'react-ga';
const { Button } = require('precise-ui');

class XPayOutCome extends Component {
    
    state = {
        status: null
    }

    componentDidMount () {
        const search = window.location.search;       
        const status =  this.getQueryStringValue(search, 'status');
        const transCode = this.getQueryStringValue(search, 'code');

        if(status == 'pass' || status == 'fail') {
            this.setState({
                status: status
            });

            if(status == 'pass'){
                this.analyticsEcomerceTrack(transCode);
            }
        }
        else {
            this.props.history.push("/home");
        }     

    }

    getQueryStringValue (search, key) {  
        return decodeURIComponent(search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));  
    }  

    analyticsEcomerceTrack(transCode) {
        //ANALYTICS
        //1. farsi ritornare il trans code oltre allo stato
        /*ReactGA.plugin.require('ec', { debug: true });

        ReactGA.plugin.execute('ec', 'addProduct', {
            id: '987654'
        });

        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
            id: transCode
        });

        ReactGA.pageview(window.location.pathname + window.location.search); */    
    
    }

    componentDidUpdate(prevProps) {
        if (this.state.status === 'pass' && this.props.authUser !== prevProps.authUser) {
            this.props.emptyCart(this.props.authUser.zeissId);
        }
        else if(this.state.status === 'fail') {
            this.getUserCart();   
        }
    }

    handleContinueShopping  = () => {
        this.props.history.push("/e-shop/products");
    }

    getUserCart = () => {
        if (this.props.authUser && this.props.authUser.zeissId) {
            axios.get('/getUserCart/'+this.props.authUser.zeissId+'/'+this.props.authUser.priceList)
            .then(response => {
                if (response.data.type === "success") {
                    this.props.updateCart(response.data.payload);
                } else {
                    console.log(response.data.error);
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }


    render () {
        return (
            <div className="page-container thank-you-container">
                <div className="header-gray-line"> </div>
                <div className="m-b-30">
                </div>
                {
                    this.state.status == 'pass' ?
                    <div>
                        <div className="thank-you-message">
                            Thank you!
                            <br/>
                            Your order has been confirmed.
                            <br/>
                            You will receive an email as soon your order will be shipped.
                        </div>
                    </div>     
                    :
                    <div>
                        <div className="error-message">
                            There has been an error with your order.
                            <br/>
                            Please contact the customer service.
                            <br/>
                        </div>
                    </div>     
                }
               
                <div className="m-b-30">
                    <Button className="btn-shopping" onClick={this.handleContinueShopping}>Continue shopping</Button>
                </div>     
            </div>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        authUser: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        emptyCart: (idzeiss) => dispatch(actionCreators.emptyCart(idzeiss)),
        updateCart: (items) => dispatch(actionCreators.updateCart(items))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(XPayOutCome));
