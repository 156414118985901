import React from 'react';
import './PricelistLensTable.css';

const pricelistLensTable = props => (
    <div className="responsive-table">
        <table className="pricelist-table" id={props.id}>
            <colgroup>
                <col className="brand-column"></col>
                <col className="type-column"></col>
                {
                    props.allSizes.map(size => <col key={"lenses_size_column_"+size} className="size-column"></col>)
                }
            </colgroup>
            <tbody>
                <tr>
                    <td className="text-bold material-head">Material</td>
                    <td className="text-bold material-head" colSpan={props.allSizes.length + 1}>{props.material}</td>
                </tr>
                <tr className="highlight-row">
                    <td className="text-white">Shape</td>
                    <td className="text-white" colSpan={props.allSizes.length + 1}>{props.shape}</td>
                </tr>
                <tr className="header-row">
                    <td>Range</td>
                    <td>Color Type</td>
                    {
                        props.allSizes.map(size => <td key={"lenses_"+props.material+"_"+props.shape+"_"+size} className="size-cell" dangerouslySetInnerHTML={{__html: size}}></td>)
                    }
                </tr>
                {
                    props.allBrand.map(brand => (
                        Object.keys(props.lenses[brand]).map((colorType, idx) => (
                            <tr key={"lenses_"+props.material+"_"+props.shape+"_"+brand+"_"+colorType} className="data-row">
                                {
                                    idx == 0 ?
                                    <td rowSpan={Object.keys(props.lenses[brand]).length}>{brand}</td>
                                    : null
                                }
                                <td className="detail-row">{colorType}</td>
                                {
                                    props.allSizes.map(size => (
                                        <td key={"lenses_"+props.material+"_"+props.shape+"_"+brand+"_"+colorType+"_"+size} className="text-center detail-row">
                                            {
                                                props.lenses[brand][colorType] && props.lenses[brand][colorType][size] ? 
                                                (
                                                    <span dangerouslySetInnerHTML={{__html: props.sign + props.lenses[brand][colorType][size].toFixed(2)}}></span>
                                                )
                                                : 'NA'
                                            }
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    ))
                }
            </tbody>
        </table>
    </div>
);

export default pricelistLensTable;