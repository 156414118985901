import React from 'react';
import './ModalRenderInfo.css';
import { Modal, ModalBody, ModalHeader, Checkbox, ModalFooter, Button } from 'precise-ui/dist/es6';

const modalRenderInfo = props => {
    const itemStyle = {
        verticalAlign: 'middle',
        margin: '10px 5px 0'
    };

    let dontShowMeAgain = false;
    const onChangeHandler = val => {
        dontShowMeAgain = val.value;
    }

    return (
        <Modal open={props.open} onClose={() => props.close(dontShowMeAgain)} >
            <ModalHeader title="LENS RENDER" />
            <ModalBody>
                <div>
                    The renders don’t show the complete SKU but illustrate the main components of the lens separately: <br/>
                    <br/>
                     - The lens tint  <br/>
                     - The coating tint  <br/>
                     - The type of shape.  <br/>
                    <br/>
                    <br/>
                    The coating render is applied on a grey lens and may vary in real life according to the lens base tint.  <br/>  <br/>
                    The shape tint is a default tint.
                </div>
                 <br/>
                <Checkbox style={itemStyle} onChange={onChangeHandler}>Don't show me again</Checkbox>
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={() => props.close(dontShowMeAgain)}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default modalRenderInfo;