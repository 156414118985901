import axios from 'axios';
import {config} from './config';

const instance = axios.create({
    baseURL: config.backend + 'home/',
    withCredentials: true
});


instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (403 === error.response.status) {
        localStorage.setItem('user', null); 
        localStorage.removeItem('user');
        window.location.href = "/home";
    } else {
      return Promise.reject(error)
    }
  }
)

export default instance;