import { Button } from 'precise-ui/dist/es6';
import React from 'react';
import { Link } from 'react-router-dom';
import './SectionLink.css';

const sectionLink = props => {
    const r = new RegExp('^(?:[a-z]+:)?//', 'i');
    if (!r.test(props.path)) {
        if (props.asButton) {
            return <Button to={props.path} className="big-cta">{!!props.label ? props.label : 'Learn more'} &#8594;</Button>;
        } else {
            return <Link to={props.path} className="big-cta learn-more">{!!props.label ? props.label : 'Learn more'} &#8594;</Link>;
        }
    } else {
        if (props.asButton) {
            return <Button href={props.path} className="big-cta">{!!props.label ? props.label : 'Learn more'} &#8594;</Button>;
        } else {
            return <a href={props.path} className="big-cta learn-more">{!!props.label ? props.label : 'Learn more'} &#8594; </a>;
        }
    }
}

export default sectionLink;