import React, { Component } from "react";
import { withRouter } from 'react-router';
import {config} from '../../../config';
import './Packaging.css';
import pap from '../../../res/images/pap.jpg';
import hdpe from '../../../res/images/hdpe.jpg';
import lpde from '../../../res/images/lpde.jpg';
import img07 from '../../../res/images/07.jpg';
import pp from '../../../res/images/pp.jpg';
import for50 from '../../../res/images/50-for.jpg';
 

class Packaging extends Component {

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render () {
        return (
            <div className="page-container cd-container">
                <div className="header-gray-line"> </div>
                <p></p>
                <div className="cd-content">            
            
                    <strong>Etichettatura Ambientale degli imballaggi</strong>
                    <p>
                        In forza del Decreto Legislativo 116/20 in tema di etichettatura ambientale, attraverso cui sono state aggiornate le norme su rifiuti e imballaggi, è più facile capire la destinazione finale degli imballaggi e gli elementi di cui sono composti.
                    </p>
                    <p>
                    Si invita a prendere visione della tabella sottostante per comprendere la tipologia di imballaggi utilizzati da ZEISS:
                    </p>
                    <table className="table-packaging">
                        <tr>
                            <th>Imballaggi</th>
                            <th>Abbreviazione</th>
                            <th>Codice</th>
                            <th>Simbolo</th>
                        </tr>
                        
                        <tr>
                            <td>Scatola</td>
                            <td>PAP</td>
                            <td>20</td>
                            <td><img src={pap} alt="PAP" width="70" height="70"/></td>
                        </tr>
                        <tr>
                            <td>Veline</td>
                            <td>HDPE</td>
                            <td>2</td>
                            <td><img src={hdpe} alt="PE-HD" width="70" height="70"/></td>
                        </tr>
                        <tr>
                            <td>Veline</td>
                            <td>LDPE</td>
                            <td>4</td>
                            <td><img src={lpde} alt="PE-LD" width="70" height="70"/></td>
                        </tr>
                        <tr>
                            <td>Foam</td>
                            <td>LDPE</td>
                            <td>4</td>
                            <td><img src={lpde} alt="PE-LD" width="70" height="70"/></td>
                        </tr>
                        <tr>
                            <td>Cuscinetti d'aria</td>
                            <td>HDPE</td>
                            <td>2</td>
                            <td><img src={hdpe} alt="PE-HD" width="70" height="70"/></td>
                        </tr>
                        <tr>
                            <td>Sacchetto plastica</td>
                            <td> &gt;PA+PE &lt;7 o &gt;PET+PE&lt; 7 </td>
                            <td>7</td>
                            <td><img src={img07} alt="O" width="70" height="70"/></td>
                        </tr>
                        <tr>
                            <td>Nastro adesivo </td>
                            <td>PP</td>
                            <td>5</td>
                            <td><img src={pp} alt="PP" width="70" height="70"/></td>
                        </tr>
                        <tr>
                            <td>Pallet legno </td>
                            <td>FOR</td>
                            <td>50</td>
                            <td><img src={for50} alt="50" width="70" height="70"/></td>
                        </tr>
                         <tr>
                            <td>Pallet plastica</td>
                            <td>HDPE</td>
                            <td>2</td>
                            <td><img src={hdpe} alt="PE-HD" width="70" height="70"/></td>
                        </tr>
                        <tr>
                            <td>Film/coperture per pallet</td>
                            <td>LDPE</td>
                            <td>4</td>
                            <td><img src={lpde} alt="PE-LD" width="70" height="70"/></td>
                        </tr>
                    </table>
           </div>
        </div>
        )
    }
}

export default withRouter(Packaging);