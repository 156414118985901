import React, { Component } from "react";
import SlideShow from '../../../components/SlideShow/SlideShow'
import './HomePage.css';
import axios from '../../../axios';


import RightSection from '../../../components/Section/RightSection/RightSection';
import LeftSection from '../../../components/Section/LeftSection/LeftSection';
import {ZeissIDUtils} from "../../../components/Auth/ZeissID";


const { Button, Icon } = require('precise-ui');


class HomePage extends Component {
    state = {
        sections: [],
        slideShowImages: []
    }

    componentDidMount() {
        const path = this.props.match.path;
        axios.post('/getPageContentByUrl', {
            url: path.replace("/", '')
        }).then(response => {
            this.setState({
                ...this.state,
                sections: response.data.Section,
                slideShowImages: response.data.SlideShow
            });
        });
        window.scrollTo(0, 0);
    }

    signupHandler = ( event ) => {
        let zeissID = new ZeissIDUtils();
        zeissID.signUp();
    }

    redirectAboutusHandler = () => {
        this.props.history.push("/about-us");
    }

    render () {
        let sections = [];
        sections.push(this.state.sections.map((section, i) => {
            if (i % 2 === 0) {
                return <RightSection key={section.ID} section={section} />
            } else {
                return <LeftSection key={section.ID} section={section} />
            }
        }));

        

        return (
            <div>
                <div className="header-blue-line"> </div>
                <SlideShow images={this.state.slideShowImages}></SlideShow>
                <div className="arrow-down">
                    <Icon name='KeyboardArrowDown'  size={7} />
                </div>
                <div className="HomePage">
                    {sections}
                    
                    <div className="page-container">
                        <div className="signup-block">
                            <div className="signup-header">
                                <h2>Sign Up</h2>
                                <p>To get started, please register here.</p>
                            </div>
                            <div className="signup-content">
                                <Button  buttonStyle="primary" className="big-cta" style={{minWidth: '300px' }} onClick={this.signupHandler} > Sign Up </Button> 
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
            
        )
    }
}

export default HomePage;
